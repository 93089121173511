import { codeInputCommonAttrs } from '../../common/$models/constants'
import { validationConfigs } from '../../common/modelDefinitions/validationConfigs'
import { displayModelNameWithLabel } from '../../common/utils'
import { addModelDoc } from '../../plugins/HelpDoc/coreDocsHelperFunctions'
import { ModelDef } from '../../types'

const colBehaviorTypeAndLabel = {
  direct: 'ダイレクト',
  func: '関数制御',
  // リレーション先照合
  // referenceToM2O: 'リレーション先照合',
  // passThrough: 'パススルー',
}
const modelName = 'dataImportSettings'

addModelDoc(
  modelName,
  'データインポート設定では、外部システムから出力されたCSVやExcelファイルを取り込む設定を作成可能です。定義後は、 <router-link to="/importDataFromExternal">データインポート実行</router-link> 画面より利用可能です。<br/>必要に応じて、Javascript関数による変換処理・カスタマイズが可能であるため、多彩なケースに対応可能です。',
)

const model: ModelDef = {
  tableName: modelName,
  tableLabel: 'データインポート設定',
  bulkControllable: true,
  tableComment: '',
  primaryKeyColType: 'UUID',
  defaultSort: {
    key: 'createdAt',
    order: 'desc',
  },
  formColumnGroups: {
    fields: {
      type: 'tab',
      groupKey: 'tab1',
      label: '取り込み列毎の設定',
      order: 1,
    },
    validate: {
      type: 'tab',
      groupKey: 'tab1',
      label: '入力値チェック設定',
      order: 2,
    },
    convertSettings: {
      type: 'tab',
      groupKey: 'tab1',
      label: '変換処理設定',
      order: 3,
    },
    advanced: {
      type: 'tab',
      groupKey: 'tab1',
      label: '高度な設定',
      order: 4,
    },
  },
  columns: {
    name: {
      label: 'インポート設定名',
      type: 'STRING',
      facet: true,
      unique: true,
      // editable: false,
      // editableOnCreate: true,
      searchable: true,
      // inputHelpText: '名称は後から変更することができませんのでご注意ください。',
      validate: {
        notEmpty: true,
      },
      inputAttrs: { wrapperClass: 'col-4' },
      afterComponent:
        '<span class="small text-muted" v-if="$parent.value">/importDataFromExternal/{{$parent.value}} <br/>で個別画面としてアクセス可能です。</span>',
      listItemAttrs: { class: { 'no-ellipsis': true } },
    },
    targetModelName: {
      type: 'SELECT',
      label: 'インポート先モデル',
      // editable: false,
      // editableOnCreate: true,
      strictSelections: true,
      validate: {
        notEmpty: true,
      },
      selections: () => {
        return $core.$modelsLoader.modelNames
      },
      customLabel: (val, callerVueInstance, recordRoot) => {
        return displayModelNameWithLabel(val)
      },
      editCallback({ row, newValue, oldValue }) {
        if (newValue && !row.name) {
          row.name = displayModelNameWithLabel(newValue, false, true)
        }
        return row
      },
      inputAttrs: { wrapperClass: 'col-4' },
    },
    desc: {
      label: '管理用メモ',
      type: 'TEXT',
      inputAttrs: { wrapperClass: 'col-4' },
    },
    pathThroughOriginalProps: {
      groupKey: 'fields',
      label: 'パススルー取込を実施',
      type: 'BOOLEAN',
      default: false,
      inputAttrs: { wrapperClass: 'col-12' },
      inputHelpText:
        '取込フィールドの設定に加えて、Excel列名(1列目)の項目名をそのまま取り込みます。 一覧表示の "Excelエクスポート" からダウンロードしたデータを変更して、そのまま取り込む際に便利です。',
    },
    disableImportIfHasValidationErrors: {
      groupKey: 'validate',
      label: '値チェックのエラーが1つでもある場合は 取込を禁止する',
      type: 'BOOLEAN',
      defaultValue: false,
      visibleOnIndex: false,
      virtualColumnOf: 'metaData',
      width: { xs: 48 },
    },
    useDateConversion: {
      groupKey: 'advanced',
      // TODO: 未実装 on ./importServiceWithDataImportSetting.ts
      label: '日付変換を利用する',
      type: 'BOOLEAN',
      default: true,
      inputAttrs: { wrapperClass: 'col-2' },
      visibleOnIndex: false,
      editCallback({ row, newValue, oldValue }) {
        if (newValue !== oldValue && newValue && !row.dateConversionFormat) {
          row.dateConversionFormat = 'yyyy-mm-dd'
        }
        return row
      },
    },
    dateConversionFormat: {
      groupKey: 'advanced',
      // TODO: 未実装 on ./importServiceWithDataImportSetting.ts
      label: '日付変換フォーマット',
      type: 'STRING',
      enableIf: { useDateConversion: true },
      default: 'yyyy-mm-dd',
      visibleOnIndex: false,
    },
    useBeforeFormatFunction: {
      groupKey: 'advanced',
      label: '変換処理前関数(全件対象)を利用する',
      type: 'BOOLEAN',
      visibleOnIndex: false,
      inputAttrs: { wrapperClass: 'col-12' },
    },
    beforeFormatFunction: {
      groupKey: 'advanced',
      label: '変換処理前関数(全件対象)',
      type: 'TEXT',
      afterComponent:
        '<div class="small text-muted">async ({<code>originalDataRows</code>, <code>instance</code>, <code>sourceData</code>, <code>sourceDataIndex</code>}): Promise => { (...); return originalDataRows } のコンテキストで実行されます。 変換処理前の 取込データを変更するには <code>originalDataRows</code> (配列) を書き換えてください。</div>',
      inputAttrs: {
        ...codeInputCommonAttrs,
        placeholder: 'originalDataRows = originalDataRows.filter(...)',
      },
      enableIf: { useBeforeFormatFunction: true },
      visibleOnIndex: false,
    },
    useAfterFormatFunction: {
      groupKey: 'advanced',
      label: '変換処理後関数(全件対象)を利用する',
      type: 'BOOLEAN',
      visibleOnIndex: false,
      inputAttrs: { wrapperClass: 'col-12' },
    },
    afterFormatFunction: {
      groupKey: 'advanced',
      label: '変換処理後関数(全件対象)',
      type: 'TEXT',
      // comment: 'async ({formattedDataRows}): Promise => { (...) } のコンテキストで実行されます。formattedDataRows のプロパティを設定、書き換えください。',
      afterComponent:
        '<div class="small text-muted">async ({<code>formattedDataRows</code>, <code>instance</code>, <code>originalDataRows</code>}): Promise => { (...); return formattedDataRows } のコンテキストで実行されます。 変換処理結果を変更するには <code>formattedDataRows</code> (配列) を書き換えてください。</div>',
      inputAttrs: codeInputCommonAttrs,
      enableIf: { useAfterFormatFunction: true },
      visibleOnIndex: false,
    },
    beforeEachRowConvertFunction: {
      groupKey: 'advanced',
      label: '1行単位での処理関数(変換前処理)',
      visibleOnIndex: false,
      afterComponent:
        '<div class="small text-muted">async ({<code>row</code>, <code>sourceDataRow</code>, <code>allSourceDataRows</code>, <code>sourceDataRowIndex</code>}): Promise  => { (...); return row } のコンテキストで実行されます。 <code>row</code> のプロパティを設定、書き換えください。</div>',
      type: 'TEXT',
      inputAttrs: codeInputCommonAttrs,
    },
    excludeFields: {
      groupKey: 'advanced',
      enableIf: (row) => !!row.targetModelName,
      type: 'MULTISELECT',
      label: '取込対象外フィールド (保護フィールド)',
      visibleOnIndex: false,
      inputAttrs: { wrapperClass: 'col-12' },
      selections(record, currentValue, initialValue) {
        return [''].concat($core.$models[record.targetModelName]?.colNames || [])
      },
      inputHelpText:
        '指定したフィールドは取込時に更新されず、値が保護されます。各行の変換処理後に除外処理を実施します。',
    },
    useDirectBulkUpsertOnSave: {
      groupKey: 'advanced',
      label: '保存時に 簡易・高速一括保存処理 を利用する',
      type: 'BOOLEAN',
      default: false,
      visibleOnIndex: false,
      inputHelpText:
        'このオプションを選択した場合には 保存処理は高速化されますが、 <strong>リレーション先のレコードは保存されず、変更履歴は記録されなくなります</strong>。挙動が変わることに注意してください。',
      width: { xs: 48, sm: 24 },
      virtualColumnOf: 'metaData',
    },
    fields: {
      groupKey: 'fields',
      enableIf: (row) => !!row.targetModelName,
      label: '取り込み列毎の変換処理設定',
      type: 'ARRAY_OF_OBJECT',
      visibleOnIndex: false,
      inputAttrs: {
        wrapperClass: 'col-12',
      },
      minValueLength: 0,
      columns: {
        behavior: {
          label: '取込挙動',
          type: 'SELECT',
          strictSelections: true,
          selections: () => Object.keys(colBehaviorTypeAndLabel),
          customLabel: (val) => colBehaviorTypeAndLabel[val],
          default: 'direct',
          validate: { notEmpty: true },
          inputAttrs: { wrapperClass: 'col-2' },
        },
        dataCol: {
          type: 'STRING',
          label: '取り込みデータ列名',
          validate: { notEmpty: true },
          inputAttrs: { wrapperClass: 'col-2' },
        },
        tCol: {
          type: 'STRING',
          label: '保存先カラム名',
          validate: { notEmpty: true },
          width: {
            md: 18,
          },
          dynamicSelections: true,
          selections(record, currentValue, initialValue, recordRoot) {
            if (!$core.$models[recordRoot.targetModelName]) {
              return []
            }
            const selectedTColNames = (recordRoot.fields || []).map((f) => f.tCol)
            return [''].concat(
              $core.$models[recordRoot.targetModelName].colNames.filter(
                (colName) => selectedTColNames.indexOf(colName) === -1,
              ),
            )
          },
          customLabel: (val, callerVueInstance, recordRoot) => {
            if (!$core.$models[recordRoot.targetModelName]) {
              return ''
            }
            if ($core.$models[recordRoot.targetModelName].colLabels[val]) {
              return `${$core.$models[recordRoot.targetModelName].colLabels[val]} (${val})`
            }
            return val
          },
          inputAttrs: { wrapperClass: 'col-2' },
          enableIf: (row, parentRecord) => {
            return ['direct'].includes(row.behavior)
          },
        },
        execPriority: {
          type: 'NUMBER',
          label: '処理順',
          inputAttrs: { wrapperClass: 'col-1', placeholder: '0' },
          inputHelpText: '優先度 昇順',
        },
        tFunc: {
          enableIf: (row, parentRecord) => row.behavior === 'func',
          label: '変換処理関数',
          type: 'TEXT',
          validate: { notEmpty: true },
          afterComponents: [
            {
              template: `
                <div>
                  <b-button variant="outline-secondary" size="sm" @click="openExplanationModal">
                    関数の説明を表示
                  </b-button>
                </div>
              `,
              methods: {
                openExplanationModal() {
                  $core.$modals.openModal({
                    component: {
                      template: `
                        <div class="small">
                          <p>下記関数表現のコンテキストで実行されます。 <code>row</code> のプロパティを設定、書き換えください。</p>
                          <pre class="d-block" style="white-space: pre-wrap">async ({
  row, // 変換処理後の行データ (output)
  dataColName, // 取り込みデータ列名
  value, // 取り込みデータ列の値
  sourceDataRow, // 取り込みデータ行
  allSourceDataRows, // 全取り込みデータ行
  sourceDataRowIndex // 取り込みデータ行のインデックス
}): Promise &lt;row> => {
  (...); // 入力した処理内容
  return row
}</pre>
                          <p>設定例:</p>
                          <pre class="bg-dark p-2 text-white" style="white-space: pre-wrap">if (value === 'A') {
  row['status'] = '承認済み'
}</pre>
                        </div>
                      `,
                    },
                    modalProps: {
                      title: '変換処理関数の説明',
                      size: 'lg',
                      okOnly: true,
                      okTitle: '閉じる',
                    },
                  })
                },
              },
            },
          ],
          inputAttrs: {
            ...codeInputCommonAttrs,
          },
        },
        // useColumnDefValidation: {
        //   type: 'BOOLEAN',
        //   label: '保存先フィールドのカラム定義の入力値チェック設定 (バリデーション) を適用する',
        //   width: {xs: 48},
        //   enableIf: (row, recordRoot) => {
        //     return ['direct'].includes(row.behavior) && row.tCol && Object.keys($core.$models[recordRoot.targetModelName]?.columns[row.tCol]?.validate || {}).length
        //   },
        // },
        // validationConfigs: {
        //   ...validationConfigs,
        //   groupKey: '',
        //   displayAsDetail: undefined,
        //   hideLabel: false,
        //   enableIf: (row, recordRoot) => {
        //     return ['direct'].includes(row.behavior)
        //   }
        // },
        //         referenceToM2OModelName: {
        //           beforeComponents: [
        //             `<div class="small">
        //   <div class="mb-1">M2Oリレーション先への照合設定</div>
        //   <HelpDoc>
        //     取込値をM2Oリレーション先のプライマリキー（id値）に変換する設定ができます。<br/>
        //     <br/>
        //     例：『受注』データのインポート時<br/>
        //     - 取り込みデータ列名: <code>取引先コード</code> <br/>
        //     - 保存先フィールド名: <code>取引先 (company)</code>  (M2Oリレーション)<br/>
        //     - 照合モデル: <code>取引先 (companies)</code><br/>
        //     - 照合カラム: <code>取引先コード (code)</code><br/>
        //     <br/>
        //     この設定により、正しいリレーションとして保存（idへ変換）されます。
        //   </HelpDoc>
        // </div>`
        //           ],
        //           type: 'STRING',
        //           label: '照合モデル',
        //           validate: { notEmpty: true },
        //           dynamicSelections: true,
        //           selections(record, currentValue, initialValue, recordRoot) {
        //             return Object.keys($core.$models)
        //           },
        //           customLabel(val) {
        //             return displayModelNameWithLabel(val)
        //           },
        //           enableIf: (row, parentRecord) => row.behavior === 'referenceToM2O',
        //           afterComponents: [
        //             {
        //               template: `<div v-show="warningMessage" class="small alert alert-warning p-2 mt-2">{{warningMessage}}</div>`,
        //               props: ['record'],
        //               computed: {
        //                 warningMessage() {
        //                   return this.record.referenceToM2OModelName
        //                 },
        //               }
        //             }
        //           ],
        //           width: {
        //             md: 18
        //           }
        //         },
        //         referenceToM2OMatchCol: {
        //           type: 'STRING',
        //           label: '照合カラム',
        //           dynamicSelections: true,
        //           selections(record, currentValue, initialValue, recordRoot) {
        //             return $core.$models[record.referenceToM2OModelName]?.colNames || []
        //           },
        //           validate: { notEmpty: true },
        //           enableIf: (row, parentRecord) => row.behavior === 'referenceToM2O' && row.referenceToM2OModelName,
        //         },
      },
      validate: {
        // notEmpty: true,
        doNotDuplicateDataColOrTargetColName: (value, col, modelName, record) => {
          if (!value) {
            return false
          }
          // dataCol 重複
          const dupDataCols = value.filter(
            (x, i, self) => self.indexOf(x) !== self.lastIndexOf(x),
          )
          // tCol 重複
          const dupTCols = value.filter(
            (x, i, self) => self.indexOf(x) !== self.lastIndexOf(x),
          )
          if (dupDataCols.length || dupTCols.length) {
            return (
              `${
                dupDataCols.length
                  ? `取り込みデータ列名 "${dupDataCols.join('", "')}" が重複して設定されています。`
                  : ''
              }` +
              `${
                dupTCols.length
                  ? `保存先フィールド名 "${dupTCols.join('", "')}" が重複して設定されています。`
                  : ''
              }`
            )
          }
          return false
        },
      },
    },
    validationConfigsByImportColumns: {
      type: 'ARRAY_OF_OBJECT',
      virtualColumnOf: 'metaData',
      groupKey: 'validate',
      label: '保存先カラム毎のバリデーション設定',
      inputHelpText: '保存先カラム毎に入力値チェック設定を適用することができます。',
      width: { xs: 48 },
      minValueLength: 0,
      columns: {
        targetCol: {
          type: 'STRING',
          label: '保存先カラム名',
          validate: { notEmpty: true },
          selections(record, currentValue, initialValue, recordRoot) {
            return $core.$models[recordRoot.targetModelName].colNames
          },
          customLabel: (val, callerVueInstance, recordRoot) => {
            const label = $core.$models[recordRoot.targetModelName].colLabels[val]
            return label ? `${label} (${val})` : val
          },
        },
        useColumnDefValidation: {
          type: 'BOOLEAN',
          label:
            '保存先フィールドのカラム定義の入力値チェック設定 (バリデーション) を適用する',
          width: { md: 36 },
          enableIf: (row, recordRoot) => {
            return (
              row.targetCol &&
              Object.keys(
                $core.$models[recordRoot.targetModelName]?.columns[row.targetCol]
                  ?.validate || {},
              ).length
            )
          },
        },
        validationConfigs: {
          ...validationConfigs,
          groupKey: '',
          displayAsDetail: undefined,
          hideLabel: false,
          width: { xs: 48 },
        },
      },
    },
    // リレーション先への変換設定
    convertSettingsToRelationId: {
      type: 'ARRAY_OF_OBJECT',
      virtualColumnOf: 'metaData',
      label: 'リレーション先への変換設定',
      hideLabel: true,
      beforeComponents: [
        `<div class="small"><div class="mb-1">M2Oリレーション先への変換設定</div><HelpDoc>取込値を リレーション先の プライマリキー (id値) へ変換したい場合に、設定をすることができます。 <br/>例えば 『<code>"受注"</code> データをインポートする場合に、取り込み列 <code>"取引先コード"</code> の値を M2Oリレーション先のモデル <code>"取引先" (companies)</code> に登録されている <code>"取引先コード" (code)</code> カラムと照合して、正しくリレーションとして保存 (<code>id</code> へ 変換)』 する設定を記載可能です。</HelpDoc></div>`,
      ],
      groupKey: 'convertSettings',
      minValueLength: 0,
      columns: {
        /**
         * - columns
         *     - キーとなるカラム (複数)
         *     - 変換先モデル
         *     - 変換先値 格納カラム
         */
        relationModelName: {
          type: 'SELECT',
          label: '変換先モデル',
          selections: () => {
            return $core.$modelsLoader.modelNames
          },
          customLabel: (val, callerVueInstance, recordRoot) => {
            return displayModelNameWithLabel(val)
          },
        },
        relationIdCol: {
          type: 'STRING',
          label: 'リレーション先のプライマリキー (id) を保存するカラム名',
          validate: { notEmpty: true },
          // dynamicSelections: true,
          // selections(record, currentValue, initialValue, recordRoot, ) {
          //   return $core.$models[record.relationModelName].colNames
          // },
          width: { md: 24 },
        },
        targetCol: {
          type: 'ARRAY_OF_OBJECT',
          label: '取込値・照合条件マッピング設定',
          enableIf: (row) => !!row.relationModelName,
          inputHelpText:
            'キーとなる取り込み列と 変換先モデルのカラム値 の対応 を指定します。 (複数設定可能)',
          columns: {
            dataCol: {
              type: 'STRING',
              label: '取込データ列名',
              validate: { notEmpty: true },
              inputAttrs: { wrapperClass: 'col-2' },
              selections(record, currentValue, initialValue, recordRoot) {
                return [''].concat((recordRoot.fields || []).map((f) => f.dataCol))
              },
              inputHelpText: '直接指定も可能です。',
            },
            targetCol: {
              type: 'STRING',
              label: '変換先モデルの 検索・照合対象カラム',
              validate: { notEmpty: true },
              dynamicSelections: true,
              selections(
                record,
                currentValue,
                initialValue,
                recordRoot,
                callerVueInstance,
              ) {
                const parentRow = $core.$utils.findParentVueComponentByComponentName(
                  callerVueInstance,
                  'oneRowOfArrayOfObjectInput',
                  2,
                )?.record
                const relationModelName = parentRow?.relationModelName
                if (!relationModelName) {
                  return []
                }
                return $core.$models[relationModelName].colNames
              },
            },
            matchType: {
              type: 'SELECT',
              label: '照合方法',
              selections: () => [
                { value: '_eq', label: '完全一致' },
                { value: '_starts_with', label: '前方一致' },
                { value: '_ends_with', label: '後方一致' },
                { value: '_contains', label: '部分一致' },
              ],
              default: '_eq',
              inputAttrs: { wrapperClass: 'col-2' },
            },
          },
          width: { xs: 48 },
        },
      },
      width: { xs: 48 },
    },
    // カラム値による 重複判別の設定
    duplicateCheckSettings: {
      type: 'ARRAY_OF_OBJECT',
      virtualColumnOf: 'metaData',
      label: 'カラム値による重複判別の設定',
      hideLabel: true,
      beforeComponents: [
        `<div class="small"><div class="mb-1">カラム値による重複判別の設定</div><HelpDoc>取込値による重複判定 および 更新処理を行う場合に、設定をすることができます。設定内容に従って取込時に レコードを検索し、マッチした場合は プライマリキー (id) を取得して 取込値にセットします。 <br/>例: 『<code>"受注"</code> データをインポートする場合に、取り込み列 <code>"受注番号"</code> の値の重複をチェックして レコードが存在するのであれば 更新、存在しない場合は新規登録』 する設定を記載可能です。 </HelpDoc></div>`,
      ],
      groupKey: 'convertSettings',
      minValueLength: 0,
      columns: {
        dataCol: {
          type: 'STRING',
          label: '取込データ列名',
          validate: { notEmpty: true },
          inputAttrs: { wrapperClass: 'col-2' },
          selections(record, currentValue, initialValue, recordRoot) {
            return [''].concat((recordRoot.fields || []).map((f) => f.dataCol))
          },
          inputHelpText: '直接指定も可能です。',
        },
        targetCol: {
          type: 'STRING',
          label: '重複チェック対象カラム',
          validate: { notEmpty: true },
          dynamicSelections: true,
          selections(record, currentValue, initialValue, recordRoot, callerVueInstance) {
            const parentRecord = recordRoot || {}
            const targetModelName = parentRecord.targetModelName
            if (!targetModelName) {
              return []
            }
            return $core.$models[targetModelName].colNames
          },
        },
        // matchType: {
        //   type: 'SELECT',
        //   label: '照合方法',
        //   selections: () => [
        //     { value: '_eq', label: '完全一致' },
        //     { value: '_starts_with', label: '前方一致' },
        //     { value: '_ends_with', label: '後方一致' },
        //     { value: '_contains', label: '部分一致' },
        //   ],
        //   default: '_eq',
        //   inputAttrs: { wrapperClass: 'col-2' },
        // },
      },
      width: { xs: 48 },
    },
    metaData: {
      type: 'JSON',
      visible: false,
    },
  },
  modelType: 'admin',
}

export const dataImportSettings = model

// action to import data
