<template>
  <slot></slot>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { registerComposableComponentSettings } from '../../ComposableComponents'
import { QueryStateController } from './QueryStateController'
import type { QueryStateControllerOptions } from './QueryStateControllerTypes'

registerComposableComponentSettings('QueryStateControllerWrapper', {
  name: 'QueryStateControllerWrapper',
  configColumns: {
    urlSyncEnabled: {
      label: 'URL同期を有効にする',
      type: 'BOOLEAN',
    },
    urlSyncIncludeKeys: {
      label: 'URL同期対象とするキー',
      type: 'MULTISELECT',
      enableIf: (row: any) => row.urlSyncEnabled,
    },
    localStorageEnabled: {
      label: 'LocalStorage同期を有効にする',
      type: 'BOOLEAN',
    },
    localStorageKey: {
      label: '保存時のキー名',
      type: 'STRING',
      enableIf: (row: any) => row.localStorageEnabled,
    },
    localStorageTtl: {
      label: '保存期間（秒）',
      type: 'NUMBER',
      inputAttrs: {
        placeholder: '3600',
        suffix: '秒',
      },
      inputHelpText: 'デフォルト: 1時間 (3600秒)',
      defaultValue: 60 * 60,
      enableIf: (row: any) => row.localStorageEnabled,
    },
    localStorageIncludeKeys: {
      label: '保存対象とするキー',
      type: 'MULTISELECT',
      enableIf: (row: any) => row.localStorageEnabled,
    },
    initialState: {
      label: '初期状態',
      type: 'STRING',
      inputComponent: 'CodeEditor',
    },
    stateEditCallbackString: {
      label: '状態更新時のコールバック (文字列)',
      type: 'TEXT',
      inputComponent: 'CodeEditor',
      inputAttrs: {
        placeholder: '',
        initCode: `/**
 * @type {(
 *   state: Record<string, any>,
 *   beforeState: Record<string, any>,
 *   queryStateController: any,
 * ) => Promise<Record<string, any>>}
 */
const stateEditCallback = async (state, beforeState, queryStateController) => {
  // state の 変更を検知して発火
  return state
}
return stateEditCallback`,
      },
    },
  },
  hasDefaultSlot: true,
})

/**
 * QueryStateControllerWrapper コンポーネント
 *
 * ## 用途
 * - 配下に QueryStateController を利用する Input, および サービスを配置する
 * - state の状態を記憶する機能を提供
 *   - URL同期 (optional)
 *   - 初期状態の設定
 */
export default defineComponent({
  name: 'QueryStateControllerWrapper',

  provide() {
    return {
      queryStateController: this.queryStateController,
    }
  },

  props: {
    /**
     * 初期状態
     */
    initialState: {
      type: Object,
      default: () => ({}),
    },

    /**
     * URL同期を有効にするかどうか
     */
    urlSyncEnabled: {
      type: Boolean,
      default: false,
    },

    /**
     * URL同期から除外するキー
     */
    urlSyncIncludeKeys: {
      type: Array as () => string[],
      default: () => [],
    },

    /**
     * LocalStorage同期を有効にするかどうか
     */
    localStorageEnabled: {
      type: Boolean,
      default: false,
    },

    /**
     * LocalStorage保存時のキー名
     */
    localStorageKey: {
      type: String,
      default: '',
    },

    /**
     * LocalStorage保存期間（秒）
     */
    localStorageTtl: {
      type: Number,
      default: 60 * 60, // 1時間
    },

    /**
     * LocalStorage保存対象とするキー
     */
    localStorageIncludeKeys: {
      type: Array as () => string[],
      default: () => [],
    },

    stateEditCallback: {
      type: Function as PropType<QueryStateControllerOptions['stateEditCallback']>,
      default: null,
    },
    stateEditCallbackString: {
      type: String,
      default: null,
    },
  },
  data() {
    const getInitialState = () => {
      if (typeof this.initialState === 'string') {
        try {
          return $core.$utils.tryParseAsObject(this.initialState)
        } catch (error) {
          console.error('初期状態のパースに失敗しました', {
            error,
            initialState: this.initialState,
          })
          return {}
        }
      }
      return this.initialState
    }
    const initialState = getInitialState()

    // this.stateEditCallbackString の パース
    let stateEditCallback = this.stateEditCallback
    if (this.stateEditCallbackString) {
      const stateEditCallbackParsed = $core.$utils.executeStringDefinedFunction({
        functionString: this.stateEditCallbackString,
        functionArgValues: {},
        nonAwait: true,
        errorThrow: false,
      })
      if (stateEditCallbackParsed && typeof stateEditCallbackParsed === 'function') {
        stateEditCallback = stateEditCallbackParsed
      }
    }
    const data = {
      queryStateController: new QueryStateController({
        initialState: initialState,
        urlSyncEnabled: this.urlSyncEnabled,
        urlSyncIncludeKeys: this.urlSyncIncludeKeys,
        stateEditCallback: stateEditCallback,
        localStorage: this.localStorageEnabled
          ? {
              enabled: true,
              key: `qscw__${this.localStorageKey}`,
              ttl: this.localStorageTtl,
              includeKeys: this.localStorageIncludeKeys,
            }
          : undefined,
      }),
    }

    return data
  },
})
</script>
