<template>
  <div class="d-flex align-items-center pt-1 pb-1">
    <div class="filter-logic text-right">
      <span v-if="filterObject.isFirstObject">条件:</span>
      <b-form-select
        v-else-if="filterObject.isSecondObject"
        class="flex-1 text-uppercase"
        :modelValue="operator"
        @update:modelValue="updateOperator"
        :options="options"
        size="sm"
      ></b-form-select>
      <span
        class="text-uppercase text-right"
        v-else
        >{{ filterObject.FilterGroupService.filterLogic }}</span
      >
    </div>
    <FilterRuleColumnAppend class="ml-2 mr-1" />
    <component
      :is="calcedComponent"
      :modelValue="!!filterObject.value ? filterObject.value.value : null"
      @update:modelValue="updateValue"
      @update:operator="updateRuleOperator"
    />
  </div>
</template>
<script lang="ts">
import { computed } from 'vue'
import { FilterItemService } from '../../../FilterItemService'
import FilterRuleColumnAppend from './FilterRuleColumnAppend.vue'
export default {
  components: {
    FilterRuleColumnAppend,
  },
  provide() {
    return {
      filterObject: computed(() => this.filterObject),
    }
  },
  props: {
    filterObject: {
      type: FilterItemService,
      required: true,
    },
  },
  data() {
    return {
      operator: 'and',
      options: ['and', 'or'],
    }
  },
  computed: {
    calcedComponent() {
      return this.filterObject.component
    },
  },
  methods: {
    updateOperator(val) {
      this.operator = val
      this.filterObject.updateOperator(val)
    },
    updateValue(value) {
      this.filterObject.updateValue(value)
    },
    updateRuleOperator(operator) {
      this.filterObject.updateItemOperator(operator)
    },
  },
}
</script>
