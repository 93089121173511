<template>
  <div
    class="mr-1 filter-result-item"
    style="position: relative"
    v-if="displayLabel"
  >
    <div
      role="button"
      @click.prevent="showFilterItemDropDown"
      class="filter-item"
      :class="{ active: filterItemService.hasValue }"
    >
      <Ficon :type="filterItemService.icon" />
      {{ displayLabel }}
      <span
        class="value"
        :class="{ 'reverse-show': isNumberTypicalOperator }"
        v-if="filterItemService.hasValue"
        ><code>{{ filterItemService.formatValue }}</code>
        <b>{{ filterItemService.formatOperator }}</b></span
      >
      <span
        @click.prevent.stop="() => filterItemService.removeFilter()"
        class="trash-icon"
      >
        <Ficon type="trash" />
      </span>
    </div>
    <DropdownWrapper
      style="top: 32px"
      :width="320"
      class="p-1"
      v-model="filterItemService.isFocus"
    >
      <component
        v-if="filterItemService"
        :is="calcedComponent"
        :modelValue="calcedValue"
        :initialOperator="caledOperator"
        @update:modelValue="updateValue"
        @update:operator="updateOperator"
        @onDismissDropdown="dismissDropdown"
      />
    </DropdownWrapper>
  </div>
</template>
<script lang="ts">
import { computed, inject } from 'vue'
import { ComponentResolver } from '../../../../../common/$models/ModelDef'
import DropdownWrapper from '../../../../../plugins/DropdownWrapper/DropdownWrapper.vue'
import { ComposableDataListService } from '../../ComposableDataListService'
import { FilterControlsService } from '../../FilterControlsService'
import { FilterItemService } from '../../FilterItemService'

export default {
  name: 'FilterResultItem',
  components: {
    DropdownWrapper,
  },
  provide() {
    return {
      filterItemService: computed(() => this.filterItemService),
    }
  },
  props: {
    filterItemService: {
      type: FilterItemService,
      required: true,
    },
  },
  setup() {
    return {
      ComposableDataListServiceInstance: inject<ComposableDataListService | null>(
        'ComposableDataListServiceInstance',
        null,
      ),
      FilterControlsServiceInstance: inject<FilterControlsService | null>(
        'FilterControlsServiceInstance',
        null,
      ),
    }
  },
  data() {
    return {
      modalShow: false,
    }
  },
  computed: {
    calcedComponent(): string | ComponentResolver {
      return this.filterItemService.component
    },
    calcedValue() {
      return this.filterItemService.value
        ? this.filterItemService.value.value
        : this.calcedComponent === 'FilterItemSelect'
          ? []
          : null
    },
    caledOperator() {
      return this.filterItemService.value ? this.filterItemService.value.operator : null
    },
    isNumberTypicalOperator() {
      return this.filterItemService.isNumberTypicalOperator
    },
    displayLabel() {
      return this.filterItemService.displayLabel
    },
  },
  watch: {
    'filterItemService.isFocus'(val) {
      this.modalShow = val
    },
  },
  created() {
    this.modalShow = this.filterItemService.isFocus
  },
  methods: {
    showFilterItemDropDown() {
      if (this.filterItemService.isFocus) {
        this.filterItemService.updateFocus(false)
      } else {
        this.FilterControlsServiceInstance?.focusFilterItem(
          this.filterItemService.columnNamePathDotConnected,
        )
      }
    },
    updateValue(value) {
      this.filterItemService.updateValue(value)
    },
    updateOperator(operator) {
      this.filterItemService.updateItemOperator(operator)
    },
    dismissDropdown() {
      this.filterItemService.updateFocus(false)
    },
  },
}
</script>
