import { $appHook } from '../../../common/$appHook'
import { ModelDef } from '../../../common/$models/ModelDef'
import { ModelExtensionRecordCommentsConfig } from '../common/coreRecordCommentConstants'
import { generateCommentExtensionModelBasedOnModelDefCommentable } from '../common/generateCommentExtensionModelBasedOnModelDefCommentable'
import { registerFrontRecordCommentsServersideComposableService } from './registerFrontRecordCommentsServersideComposableService'
// Load Styles
import { defineAsyncComponent } from 'vue'
import { ModelExtension } from '../../ModelExtensionModelsLoader/common'
import './_record-comments.scss'

export const installFrontRecordCommentsFeature = () => {
  // ModelDef を 読み込む $appHook で、ModelDefの拡張を読み込ませる
  $appHook.on(
    'modelDefs.beforeLoad',
    (modelDefs: ModelDef[]): ModelDef[] => {
      return generateCommentExtensionModelBasedOnModelDefCommentable(modelDefs)
    },
    'installFrontRecordCommentsFeature',
  )

  // Register components
  registerRecordCommentsVueComponents()
  // Register appHook components
  registerRecordCommentsFrontAppHooks()

  // Register serverside composable service
  registerFrontRecordCommentsServersideComposableService()
}

const registerRecordCommentsVueComponents = () => {
  $core.VueClass.component(
    'RecordCommentThreadListInModelForm',
    defineAsyncComponent(
      () => import('./components/RecordCommentThreadListInModelForm.vue'),
    ),
  )
  $core.VueClass.component(
    'RecordCommentsByRecordId',
    defineAsyncComponent(() => import('./components/RecordCommentsByRecordId.vue')),
  )
}

/**
 * $appHook を 利用した、レコードコメント挙動の設定
 */
const registerRecordCommentsFrontAppHooks = () => {
  // Model単位で挙動を設定していくので、$appHook で設定する
  $appHook.on(
    '$CORE.ModelFactory.init.before',
    ({ modelDefinition }: { modelDefinition: ModelDef }) => {
      setTimeout(() => {
        modelDefinition.extensions?.forEach((ex) => {
          if (isDefaultVisibleExtension(ex)) {
            registerRecordCommentFrontComponentsByModel(
              modelDefinition,
              ex as ModelExtensionRecordCommentsConfig,
            )
          }
        })
      }, 1)
    },
    'installFrontRecordCommentsFeatureForEachModel',
  )
}

const isDefaultVisibleExtension = (ex: ModelExtension) => {
  return (
    ex.extensionType === 'recordComments' &&
    ex.enabled === true &&
    ex.hidden !== true &&
    (!ex.key || ex.key === 'default')
  )
}

/**
 * Model単位での挙動の設定 (主に AppHook を利用した表示箇所の設定)
 */
const registerRecordCommentFrontComponentsByModel = (
  modelDef: ModelDef,
  ex: ModelExtensionRecordCommentsConfig,
) => {
  const isGroupedEdit = !!modelDef.groupedEditKeys
  const commentComponent = {
    template: `<RecordCommentThreadListInModelForm v-if="enabled" :exConfig="exConfig" />`,
    computed: {
      exConfig() {
        return ex
      },
      enabled() {
        if (!isGroupedEdit) {
          return true
        }
        return !!this.$parent.$parent.$attrs?.groupedEditIsParent
      },
    },
  }
  // 1. ModelForm に RecordCommentThreadListInModelForm を追加する
  $appHook.on(
    `$CORE.admin.resolveComponent.model.${modelDef.tableName}.edit.modelForm.beforeForm`,
    (components) => [...components, commentComponent],
    'recordCommentsDisplayForEachRecord',
  )
  if (ex.enabledVirtualModelNames?.length > 0) {
    // 2. VirtualModel に RecordCommentsByRecordId を追加する
    ex.enabledVirtualModelNames.forEach((virtualModelName) => {
      $appHook.on(
        `$CORE.admin.resolveComponent.model.${virtualModelName}.edit.modelForm.beforeForm`,
        (components) => [...components, commentComponent],
        'recordCommentsDisplayForEachRecord',
      )
    })
  }
}
