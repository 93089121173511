import { $models, $modelsLoader } from '../$models'
import { coreDefaultModelsDBDefineablesModelNames } from './coreDefalutModelsDBDefineables'
import { modelDefinitions } from './modelDefinitions'
import { $modelDefinitionsLoaderService } from './modelDefinitionsLoaderService'
import { prepareModelExtensionsInstallBehavior } from './ModelExtensions/prepareModelExtensionsInstallBehavior'

/**
 * modelDefinitionsの挙動をInstall
 */
export const useModelDefinitionsFeature = () => {
  // Extensions の 挙動は 最初にセットアップしておく
  prepareModelExtensionsInstallBehavior()
  if (!$models.modelDefinitions) {
    $modelsLoader.loadModel(modelDefinitions)
  }
  // Add
  $core.$modelDefinitionsLoaderService = $modelDefinitionsLoaderService()
  /**
   * modelsDoc page
   */
  $core.$router.addRoutes([
    {
      path: '/modelsDoc',
      component: $frameworkUtils.defineAsyncComponent(() => import('./modelsDoc.vue')),
    },
  ])
  /**
   * モデル定義一覧における 表示の追加
   */
  $core.$appHook.on(
    'modelIndex.model:modelDefinitions.headerAppend',
    (components) => {
      return [
        ...[components || []],
        {
          template: `<router-link class="btn btn-outline-secondary" to="/modelsDoc">設計書</router-link>`,
        },
        {
          template: `<a href="#" @click.prevent="() => $core.$modelsLoader.generateAllModelsTypeDefinitions({ downloadAsFile: true })" class="btn btn-outline-secondary ml-1">Type定義を出力</a>
`,
        },
      ]
    },
    'appendModelDefUtilButtons',
  )
  // DBDefineable core defalut models を 編集している際の 注意書きを付与
  const addWarningHookFunc = (comopnents) => {
    return [
      ...[comopnents || []],
      {
        template: `<div v-if="isCoreDefaultModel" class="alert alert-warning p-2">⚠️ CORE Framework の デフォルトモデルを編集しています。カラム定義の変更を実施する場合には、十分に検討してください。</div>`,
        computed: {
          isCoreDefaultModel() {
            const modelName = $core.$utils.findParentVueComponentByComponentName(
              this,
              'ModelForm',
            )?.data?.tableName
            return coreDefaultModelsDBDefineablesModelNames.includes(modelName || '')
          },
        },
      },
    ]
  }
  $core.$appHook.on(
    '$CORE.admin.resolveComponent.model.modelDefinitions.create.modelForm.beforeForm',
    addWarningHookFunc,
    'appendWarningMessageOnEditCoreDefaultModels',
  )
  $core.$appHook.on(
    '$CORE.admin.resolveComponent.model.modelDefinitions.edit.modelForm.beforeForm',
    addWarningHookFunc,
    'appendWarningMessageOnEditCoreDefaultModels',
  )
}
