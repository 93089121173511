import ComposableDataList from './components/ComposableDataList.vue'
import ComposableDataListColumnSearchInput from './components/ComposableDataListColumnSearchInput.vue'
import ComposableDataListSearchWrapper from './components/ComposableDataListSearchWrapper.vue'
import DataListKeywordSearch from './components/DataListQueryControls/DataListKeywordSearch.vue'
import DataListLimitControl from './components/DataListQueryControls/DataListLimitControl.vue'
import DataListPagination from './components/DataListQueryControls/DataListPagination.vue'
import DataListQueryFilterGroupToggle from './components/DataListQueryControls/DataListQueryFilterGroupToggle.vue'
import DataListSortSelection from './components/DataListQueryControls/DataListSortSelection.vue'

import DataListDisplayContainer from './components/DataListDisplay/DataListDisplayContainer.vue'
import DataListKeywordFilter from './components/DataListKeywordFilter.vue'
import DataListQueryFilter from './components/DataListQueryFilter.vue'

import ModelDataFilterSavedSearchConditions from '../../../front/ModelIndex/ModelDataFilterSavedSearchConditions.vue'
import DataListBulkControlAction from '../../ModelRecordBulkControlService/front/components/DataListBulkControlAction.vue'
import DataListDisplayCheckboxBulk from '../../ModelRecordBulkControlService/front/components/DataListDisplayCheckboxBulk.vue'
import DataListDisplayColumnItem from './components/DataListDisplay/DataListDisplayColumnItem.vue'
import DataListDisplayDragSortableList from './components/DataListDisplay/DragSortableList/DataListDisplayDragSortableList.vue'
import DataListDisplayTable from './components/DataListDisplay/Table/DataListDisplayTable.vue'
import DataListDisplayTableBody from './components/DataListDisplay/Table/DataListDisplayTableBody.vue'
import DataListDisplayTableBodyCellItem from './components/DataListDisplay/Table/DataListDisplayTableBodyCellItem.vue'
import DataListDisplayTableBodyRow from './components/DataListDisplay/Table/DataListDisplayTableBodyRow.vue'
import DataListDisplayTableHeader from './components/DataListDisplay/Table/DataListDisplayTableHeader.vue'
import DataListDisplayTableHeaderColumnItem from './components/DataListDisplay/Table/DataListDisplayTableHeaderColumnItem.vue'

import DataListDisplayCard from './components/DataListDisplay/Card/DataListDisplayCard.vue'
import CascadeColumnSelectInput from './components/FilterControls/CascadeColumnSelectInput.vue'
import CascadeColumnSelectPanel from './components/FilterControls/CascadeColumnSelectPanel.vue'
import CCSearchFilterInputWithColumn from './components/SearchFilterInputs/CCSearchFilterInputWithColumn.vue'
export const initComposableDataListComponents = () => {
  $core.VueClass.component('ComposableDataList', ComposableDataList)
  $core.VueClass.component(
    'ComposableDataListColumnSearchInput',
    ComposableDataListColumnSearchInput,
  )
  $core.VueClass.component(
    'ComposableDataListSearchWrapper',
    ComposableDataListSearchWrapper,
  )

  $core.VueClass.component('DataListDisplayContainer', DataListDisplayContainer)
  $core.VueClass.component('DataListPagination', DataListPagination)
  $core.VueClass.component('DataListLimitControl', DataListLimitControl)
  $core.VueClass.component('DataListQueryFilter', DataListQueryFilter)
  $core.VueClass.component(
    'DataListQueryFilterGroupToggle',
    DataListQueryFilterGroupToggle,
  )
  $core.VueClass.component('DataListKeywordSearch', DataListKeywordSearch)
  $core.VueClass.component('DataListKeywordFilter', DataListKeywordFilter)
  $core.VueClass.component('DataListSortSelection', DataListSortSelection)
  $core.VueClass.component(
    'ModelDataFilterSavedSearchConditions',
    ModelDataFilterSavedSearchConditions,
  )

  // Table components
  $core.VueClass.component('DataListDisplayTable', DataListDisplayTable)
  $core.VueClass.component('DataListDisplayTableBody', DataListDisplayTableBody)
  $core.VueClass.component('DataListDisplayTableBodyRow', DataListDisplayTableBodyRow)
  $core.VueClass.component(
    'DataListDisplayTableBodyCellItem',
    DataListDisplayTableBodyCellItem,
  )
  $core.VueClass.component('DataListDisplayColumnItem', DataListDisplayColumnItem)
  $core.VueClass.component('DataListDisplayTableHeader', DataListDisplayTableHeader)
  $core.VueClass.component(
    'DataListDisplayTableHeaderColumnItem',
    DataListDisplayTableHeaderColumnItem,
  )
  $core.VueClass.component('DataListDisplayCheckboxBulk', DataListDisplayCheckboxBulk)
  $core.VueClass.component('DataListBulkControlAction', DataListBulkControlAction)
  $core.VueClass.component(
    'DataListDisplayDragSortableList',
    DataListDisplayDragSortableList,
  )

  // Search filter input
  $core.VueClass.component('CCSearchFilterInputWithColumn', CCSearchFilterInputWithColumn)

  // Card layout
  $core.VueClass.component('DataListDisplayCard', DataListDisplayCard)

  // Cascade column select
  $core.VueClass.component('CascadeColumnSelectInput', CascadeColumnSelectInput)
  $core.VueClass.component('CascadeColumnSelectPanel', CascadeColumnSelectPanel)
}
