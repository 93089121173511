import { ColumnDef, ColumnDefByColName, ModelDef } from './ModelDef'

type RelationshipType =
  | 'RELATIONSHIP_MANY_TO_ONE'
  | 'RELATIONSHIP_ONE_TO_MANY'
  | 'RELATIONSHIP_MANY_TO_MANY'

export interface ModelTypeDefinitionGenerateOptions {
  useStrictNullChecks?: boolean
  makePropsOptional?: boolean
  definitionPrefix?: string
  disableModelNameTransform?: boolean
  customTypeMapping?: Record<string, string>
  replaceExportInterfaceToType?: boolean
  addUserCreatedAndUpdated?: boolean
}

type ModelTypeMap = Record<string, string>

const appendableColumns: ColumnDefByColName = {
  userCreated: {
    type: 'RELATIONSHIP_MANY_TO_ONE',
    label: '作成者',
    relationshipManyToOne: {
      collectionName: 'directus_users',
    },
  },
  userUpdated: {
    type: 'RELATIONSHIP_MANY_TO_ONE',
    label: '更新者',
    relationshipManyToOne: {
      collectionName: 'directus_users',
    },
  },
}

export class ModelTypeDefinitionGenerator {
  private modelTypeMap: ModelTypeMap = {}
  private options: ModelTypeDefinitionGenerateOptions
  constructor(options: ModelTypeDefinitionGenerateOptions = {}) {
    this.options = Object.assign(
      {
        useStrictNullChecks: false,
        makePropsOptional: false,
        definitionPrefix: 'Model',
        disableModelNameTransform: false,
        replaceExportInterfaceToType: false,
        addUserCreatedAndUpdated: false,
      } as ModelTypeDefinitionGenerateOptions,
      options,
    )
  }

  public generateAllModelTypeDefinitions(models: ModelDef[]): string {
    let output = ''
    for (const model of models) {
      this.modelTypeMap[model.tableName] = this.generateModelName(model.tableName)
    }
    for (const model of models) {
      output += this.generateModelTypeDefinition(model) + '\n\n'
    }
    return output
  }

  public generateModelTypeDefinition(modelDef: ModelDef): string {
    const modelName = this.generateModelName(modelDef.tableName)
    let output = this.generateModelComment(modelDef)
    if (this.options.replaceExportInterfaceToType) {
      output += `export type ${modelName} = {\n`
    } else {
      output += `export interface ${modelName} {\n`
    }

    for (const [colName, column] of Object.entries(modelDef.columns)) {
      output += this.generateColumnDefinition(colName, column, modelDef)
    }
    if (this.options.addUserCreatedAndUpdated) {
      const appendCols = ['userCreated', 'userUpdated']
      appendCols.forEach((colName) => {
        output += this.generateColumnDefinition(
          colName,
          appendableColumns[colName],
          modelDef,
        )
      })
    }

    output += '}\n\n'

    return output
  }

  private generateModelComment(modelDef: ModelDef): string {
    return `/**
 * # Model定義 "${modelDef.tableName}" (${modelDef.tableLabel})
 * @comment ${modelDef.tableComment || ''}
 *
 * @primaryKeyColType ${modelDef.primaryKeyColType || 'unknown'}
 */\n`
  }

  private generateModelName(tableName: string): string {
    if (this.options.disableModelNameTransform) {
      return tableName
    }
    return (
      this.options.definitionPrefix +
      tableName
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('')
    )
  }

  private generateColumnDefinition(
    colName: string,
    column: ColumnDef,
    modelDef: ModelDef,
  ): string {
    const columnType = this.generateColumnType(column, modelDef)
    const columnComment = this.generateColumnComment(column)
    const nullableType = this.options.useStrictNullChecks
      ? `${columnType} | null`
      : columnType
    const escapedColName = this.escapePropertyName(colName)
    return `${columnComment}  ${escapedColName}${this.options.makePropsOptional ? '?' : ''}: ${nullableType};\n\n`
  }

  private escapePropertyName(name: string): string {
    return `'${name.replace(/'/g, "\\'")}'`
  }

  private generateColumnType(column: ColumnDef, modelDef: ModelDef): string {
    if (this.options.customTypeMapping && this.options.customTypeMapping[column.type]) {
      return this.options.customTypeMapping[column.type]
    }

    switch (column.type) {
      case 'MULTISELECT':
        return 'string[]'
      case 'STRING':
      case 'TEXT':
      case 'RICHTEXT':
      case 'FILEUPLOAD':
      case 'FILE':
      case 'SELECT':
      case 'UUID':
        return 'string'
      case 'NUMBER':
      case 'FLOAT':
      case 'BIGINTEGER':
      case 'DOUBLE':
      case 'DECIMAL':
        return this.generateNumericType(column)
      case 'BOOLEAN':
        return 'boolean'
      case 'DATEONLY':
      case 'DATETIME':
      case 'TIME':
        return 'string'
      case 'JSON':
        return 'Record<string, unknown>'
      case 'RELATIONSHIP_MANY_TO_ONE':
        return this.generateRelationshipType(column, 'RELATIONSHIP_MANY_TO_ONE')
      case 'RELATIONSHIP_ONE_TO_MANY':
        return this.generateRelationshipType(column, 'RELATIONSHIP_ONE_TO_MANY')
      case 'ARRAY_OF_OBJECT':
        return this.generateArrayOfObjectType(column)
      default:
        console.warn(
          `Unknown column type: ${column.type} for column ${column.name} in table ${modelDef.tableName}`,
        )
        return 'any'
    }
  }

  private generateNumericType(column: ColumnDef): string {
    if (column.numericPrecision && column.numericScale) {
      return `number /* precision: ${column.numericPrecision}, scale: ${column.numericScale} */`
    }
    return 'number'
  }

  private generateRelationshipType(
    column: ColumnDef,
    relationType: RelationshipType,
  ): string {
    switch (relationType) {
      case 'RELATIONSHIP_MANY_TO_ONE':
        return `string | ${this.generateModelName(column.relationshipManyToOne?.collectionName || '')}`
      case 'RELATIONSHIP_ONE_TO_MANY':
        return `${this.generateModelName(column.relationshipOneToMany?.collectionName || '')}[]`
      default:
        return 'unknown'
    }
  }

  private generateArrayOfObjectType(column: ColumnDef): string {
    if (column.columns) {
      let objectType = '{\n'
      for (const [subColName, subColumn] of Object.entries(column.columns)) {
        const subColumnType = this.generateColumnType(subColumn, {
          tableName: '',
          columns: column.columns,
        } as ModelDef)
        const escapedSubColName = this.escapePropertyName(subColName)
        objectType += `    ${escapedSubColName}: ${subColumnType};\n`
      }
      objectType += '  }'
      return `${objectType}[]`
    }
    return 'any[]'
  }

  private generateColumnComment(column: ColumnDef): string {
    const commentLines = []
    if (column.label) {
      commentLines.push(`@label ${column.label}`)
    }
    if (column.adminComment) {
      commentLines.push(`@description ${column.adminComment}`)
    }
    if (column.type === 'RELATIONSHIP_ONE_TO_MANY') {
      commentLines.push(
        `@relation ${column.relationshipOneToMany?.collectionName || ''} (one-to-many)`,
      )
    }
    if (column.type === 'RELATIONSHIP_MANY_TO_ONE') {
      commentLines.push(
        `@relation ${column.relationshipManyToOne?.collectionName || ''} (many-to-one)`,
      )
    }

    if (commentLines.length === 0) {
      return ''
    }

    return `  /**\n${commentLines.map((line) => `   * ${line}`).join('\n')}\n   */\n`
  }
}

export const generateModelTypeDefinitions = (
  models: ModelDef[],
  options: ModelTypeDefinitionGenerateOptions = {},
  downloadAsFile = false,
): string => {
  const generator = new ModelTypeDefinitionGenerator(options)
  const typeDefs = generator.generateAllModelTypeDefinitions(models)
  if (downloadAsFile) {
    $core.$utils.downloadStringAsFile(typeDefs, 'modelTypes.d.ts')
  }
  return typeDefs
}
