import { QueryMany } from '@directus/sdk'
import { ModelFactory } from '../$models'
import { matchesFilter } from '../../plugins/ComposableDataListComponents/front/FilterControlsService'
import { ColumnDef, ColumnDefByColName, VirtualModel } from '../../types'
import { singletonInstanceSummoner } from '../singletonInstanceSummoner'

/**
 * 元のModel から DeepMerge する際に、特定のプロパティのみマージするためのキー
 */
export const virtualModelDeepMergeableColumnKeys: (keyof ColumnDef)[] = [
  'relationshipManyToOne',
  'relationshipOneToMany',
  'inputAttrs',
]

const mergeColumnDef = (
  baseModelColDef: ColumnDef,
  virtualModelColDef: ColumnDef,
): ColumnDef => {
  // 移植する props を deep merge する
  // TODO: 必要な props のみ 移植するように調整...
  return $core.$utils.deepmergeOnlySpecificProps(
    virtualModelDeepMergeableColumnKeys,
    baseModelColDef || {},
    virtualModelColDef,
  )
}

export class VirtualModelFactory extends ModelFactory {
  private _columnsMergedWithBaseModel: ColumnDefByColName = null
  baseModel: string
  name: string
  overrideAttrs?: string[] | undefined
  dataFilters?: { [colName: string]: string | any } | QueryMany<any>

  constructor(virtualModelDef: VirtualModel) {
    // @ts-ignore
    super(virtualModelDef)
  }

  /**
   * baseModel とマージされたカラムを返す
   */
  get columnsMergedWithBaseModel(): ColumnDefByColName {
    if (this._columnsMergedWithBaseModel) {
      return this._columnsMergedWithBaseModel
    }
    const baseModel = $core.$models[this.baseModel]
    this._columnsMergedWithBaseModel = Object.keys(this.columns).reduce(
      (columns, colName) => {
        const baseModelCol = baseModel?.columns?.[colName]
        const isVirtualColumnOf = this.columns[colName]?.virtualColumnOf
        if (!baseModelCol && !isVirtualColumnOf) {
          if ($core.$embAuth?.user?.isAdmin) {
            const msg = `VirtualModel "${this.name}" の カラム ${colName} の元となるカラムが存在しません`
            $core.$toast.warningToast(msg)
            console.error(msg)
          }
          return columns
        }
        // columns[colName] = Object.assign({}, baseModel?.columns?.[colName] || {}, this.columns[colName])
        // // 特定の props のみ 元Modelから deepmerge する, それ以外は shallow merge
        // console.log(`this.columns[colName]:`, this.columns[colName])
        const deepMerged = mergeColumnDef(baseModelCol, this.columns[colName])
        if (!deepMerged.type) {
          if ($core.$embAuth?.user?.isAdmin) {
            const msg = `VirtualModel "${this.name}" の カラム ${colName} にて type が定義されていません`
            $core.$toast.warningToast(msg)
            console.error(msg)
          }
          return columns
        }
        columns[colName] = deepMerged
        return columns
      },
      {},
    )
    return this._columnsMergedWithBaseModel
  }
}

class VirtualModelsLoader {
  $virtualModels: { [virtualModelName: string]: VirtualModelFactory }
  _autoLoaded: boolean

  constructor() {
    this.$virtualModels = {}
    this._autoLoaded = false
  }

  static get instance(): VirtualModelsLoader {
    return singletonInstanceSummoner('VirtualModelsLoader', VirtualModelsLoader)
  }

  async loadModel(virtualModelDef: VirtualModel) {
    if (!virtualModelDef.name) {
      console.error('virtualModelDef.name is required', virtualModelDef)
      return
    }
    // @ts-ignore
    this.$virtualModels[virtualModelDef.name] = new VirtualModelFactory(virtualModelDef)
  }

  async loadModels(virtualModelDefs: VirtualModel[]) {
    for (let i = 0; virtualModelDefs.length > i; i++) {
      await this.loadModel(virtualModelDefs[i])
    }
  }
}

export const $virtualModelsLoader = VirtualModelsLoader.instance

export const $virtualModels: { [vModelName: string]: VirtualModelFactory } =
  $virtualModelsLoader.$virtualModels

/**
 * Record の 状態を元に VirtualModel の名前を検出する
 * @param collectionName
 * @param data
 */
export const detectVModelNameWithRecord = (collectionName, data) => {
  if (!data) {
    return ''
  }
  const found = Object.keys($virtualModels).reduce((res, vModel) => {
    const dataFilterKeys = Object.keys($virtualModels[vModel].dataFilters || {})
    const vm = $virtualModels[vModel]
    if (vm.baseModel === collectionName && dataFilterKeys.length) {
      const keyValueMatched =
        dataFilterKeys.filter((c) => vm.dataFilters[c] === data[c]).length ===
        dataFilterKeys.length
      if (keyValueMatched) {
        res = vModel
        return res
      }
      const isObjectFilter = typeof vm.dataFilters === 'object'
      if (isObjectFilter) {
        // @ts-ignore
        const filterMatched = matchesFilter(vm.dataFilters, [data])
        if (filterMatched?.length) {
          res = vModel
          return res
        }
      }
    }
    return res
  }, '')
  return found
}
