import { singletonInstanceSummoner } from '../../../common/singletonInstanceSummoner'

const defaultTTLSec = 86400 // 1 day

/**
 * IndexedDBに保存するアイテムのインターフェース
 */
interface IndexedDbCacheItem {
  /**
   * キャッシュアイテムのキー
   */
  key: string
  /**
   * 有効期限のタイムスタンプ (ミリ秒)
   */
  expireAt: number
  /**
   * 保存する値
   */
  value: any
}

/**
 * # IndexedDbCache
 *
 * ## 概要
 * `IndexedDbCache` は、TTL (Time to Live) 付きでデータをブラウザの IndexedDB に保存・取得するためのサービスです。
 * LocalStorageと比較して以下の利点があります：
 * - より大きなデータ量を保存可能
 * - 構造化されたデータの保存が容易
 * - 非同期APIによる効率的なデータ操作
 *
 * ## ユースケース
 * - 大容量データのキャッシュ: API レスポンスの結果など、大きなデータセットのキャッシュ
 * - オフライン対応: オフライン時に必要となるデータの保存
 * - 構造化データの保存: 複雑なオブジェクトや配列の保存
 *
 * ## メソッド
 * - `set(key: string, val: any, TTLInSeconds?: number): Promise<boolean>`: 指定されたキーと値を、指定されたTTLでIndexedDBに保存します。
 * - `get(key: string): Promise<any>`: 指定されたキーのデータをIndexedDBから取得します。
 * - `clear(key: string): Promise<void>`: 指定されたキーのデータをIndexedDBから削除します。
 * - `getWithFetchFunc(key: string, fetchFunction: Function, TTLInSeconds?: number): Promise<any>`: キャッシュ取得または関数実行による取得を行います。
 */
export class IndexedDbCache {
  private readonly dbName = 'coreCache'
  private readonly storeName = 'cacheStore'
  private readonly version = 1
  private dbConnection: Promise<IDBDatabase> | null = null

  /**
   * 現在のタイムスタンプ (ミリ秒) を取得します
   * @private
   */
  private get _now(): number {
    return new Date().getTime()
  }

  static get instance() {
    return singletonInstanceSummoner('IndexedDbCache', IndexedDbCache)
  }

  /**
   * データベース接続を取得または初期化します
   * @private
   */
  private getDb(): Promise<IDBDatabase> {
    if (this.dbConnection) {
      return this.dbConnection
    }

    this.dbConnection = new Promise((resolve, reject) => {
      try {
        const request = indexedDB.open(this.dbName, this.version)

        request.onerror = () => {
          reject(request.error)
        }

        request.onsuccess = () => {
          resolve(request.result)
        }

        request.onupgradeneeded = (event) => {
          const db = request.result
          if (!db.objectStoreNames.contains(this.storeName)) {
            db.createObjectStore(this.storeName, { keyPath: 'key' })
          }
        }
      } catch (e) {
        reject(e)
      }
    })

    return this.dbConnection
  }

  /**
   * トランザクションを取得します
   * @private
   */
  private async getTransaction(
    mode: IDBTransactionMode = 'readonly',
  ): Promise<IDBTransaction> {
    const db = await this.getDb()
    return db.transaction(this.storeName, mode)
  }

  /**
   * オブジェクトストアを取得します
   * @private
   */
  private async getStore(mode: IDBTransactionMode = 'readonly'): Promise<IDBObjectStore> {
    const transaction = await this.getTransaction(mode)
    return transaction.objectStore(this.storeName)
  }

  /**
   * TTL付きでIndexedDBに保存
   * @param key 保存するデータのキー
   * @param val 保存する値
   * @param TTLInSeconds データの有効期限 (秒), デフォルトは `defaultTTLSec` (1日)
   * @returns 保存に成功した場合は `true`, 失敗した場合は `false` を返します。
   *
   * @example
   * ```ts
   * // 24時間保持
   * await $indexedDbCache.set('userSettings', { theme: 'dark' }, 60 * 60 * 24)
   *
   * // 無期限
   * await $indexedDbCache.set('apiKey', 'your_api_key', -1)
   * ```
   */
  async set(
    key: string,
    val: any,
    TTLInSeconds: number | -1 = defaultTTLSec,
  ): Promise<boolean> {
    try {
      const store = await this.getStore('readwrite')
      const item: IndexedDbCacheItem = {
        key,
        expireAt: TTLInSeconds === -1 ? -1 : this._now + TTLInSeconds * 1000,
        value: val,
      }

      return new Promise((resolve, reject) => {
        const request = store.put(item)
        request.onsuccess = () => resolve(true)
        request.onerror = () => {
          console.error('Error storing data:', request.error)
          reject(request.error)
        }
      })
    } catch (e) {
      console.error('Error in set:', e)
      return false
    }
  }

  /**
   * IndexedDBから取得
   * TTL(有効期限) が切れている場合は、nullを返す
   * @param key 取得するデータのキー
   * @returns 保存されている値, 有効期限が切れている or 保存されていない場合は `null`
   *
   * @example
   * ```ts
   * const userSettings = await $indexedDbCache.get('userSettings')
   * if (userSettings) {
   *   // userSettingsオブジェクトが存在する (有効期限内)
   *   console.log(userSettings.theme)
   * } else {
   *   // userSettingsオブジェクトは存在しない, もしくは有効期限切れ
   * }
   * ```
   */
  async get(key: string): Promise<any> {
    try {
      const store = await this.getStore('readonly')
      return new Promise((resolve, reject) => {
        const request = store.get(key)
        request.onsuccess = () => {
          const cached: IndexedDbCacheItem | undefined = request.result
          if (!cached) {
            resolve(null)
            return
          }

          // キャッシュが有効期限以内であれば
          if (cached.expireAt === -1 || cached.expireAt >= this._now) {
            resolve(cached.value)
            return
          }

          // 有効期限切れのデータは削除して null を返す
          this.clear(key).finally(() => resolve(null))
        }
        request.onerror = () => {
          console.error('Error retrieving data:', request.error)
          reject(request.error)
        }
      })
    } catch (e) {
      console.error('Error in get:', e)
      return null
    }
  }

  /**
   * IndexedDBから削除
   * @param key 削除するデータのキー
   *
   * @example
   * ```ts
   * await $indexedDbCache.clear('userSettings')
   * ```
   */
  async clear(key: string): Promise<void> {
    try {
      const store = await this.getStore('readwrite')
      return new Promise((resolve, reject) => {
        const request = store.delete(key)
        request.onsuccess = () => resolve()
        request.onerror = () => {
          console.error('Error deleting data:', request.error)
          reject(request.error)
        }
      })
    } catch (e) {
      console.error('Error in clear:', e)
    }
  }

  /**
   * set と get をまとめて行うときに利用
   * @param key 保存するデータのキー
   * @param fetchFunction キャッシュが存在しない場合に実行される関数。データ取得処理を実装します。
   * @param TTLInSeconds データの有効期限 (秒), デフォルトは `defaultTTLSec` (1日)
   * @returns 取得したデータ, キャッシュに保存されている場合はキャッシュされたデータを返します。
   *
   * @example
   * ```ts
   * // APIからデータを取得する関数
   * const fetchUserData = async () => {
   *   const res = await $core.$axios.get('/users/me')
   *   return res.data
   * }
   *
   * // ユーザーデータを取得する
   * const userData = await $indexedDbCache.getWithFetchFunc('userData', fetchUserData, 60 * 60)
   * ```
   */
  async getWithFetchFunc<T = any>(
    key: string,
    fetchFunction: () => Promise<T>,
    TTLInSeconds: number = defaultTTLSec,
  ): Promise<T> {
    const cached = await this.get(key)
    if (cached !== null) {
      return cached
    }

    const fetched = await fetchFunction()
    await this.set(key, fetched, TTLInSeconds)
    return fetched
  }
}

/**
 * IndexedDbCache のインスタンス
 */
export const $indexedDbCache = IndexedDbCache.instance
