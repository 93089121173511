import { makeItReactive } from '../../$frameworkUtils/$frameworkUtils'
import { $appHook, genExecutableHookSet, genHookSet } from '../../../common/$appHook'
import { singletonInstanceSummoner } from '../../../common/singletonInstanceSummoner'
import { registerDefalutEmailPasswordEmbAuthActions } from './registerDefalutEmailPasswordEmbAuthActions'

export const embAuthHookBaseName = '$embAuth'

export const embAuthHooks = {
  signIn: genExecutableHookSet(`${embAuthHookBaseName}.signIn`),
  signOut: genExecutableHookSet(`${embAuthHookBaseName}.signOut`),
  signUp: genExecutableHookSet(`${embAuthHookBaseName}.signUp`),
  authStateChanged: genHookSet(`${embAuthHookBaseName}.authStateChanged`),
  restoreAuthSession: genExecutableHookSet(`${embAuthHookBaseName}.restoreAuthSession`),
  getUserData: genExecutableHookSet(`${embAuthHookBaseName}.getUserData`),
  refreshUserData: genExecutableHookSet(`${embAuthHookBaseName}.refreshUserData`),
  updateUserData: genExecutableHookSet(`${embAuthHookBaseName}.updateUserData`),
}

export type EmbeddableAuthUserData = Record<string, any>

export type EmbUserData = Partial<{
  id: '349b8437-4377-49d5-9b97-c4cf314cb889' | string
  first_name: 'DU' | string
  last_name: null | string
  email: 'admin@example.com' | string
  password: '**********' | string
  location: null | string
  title: null | string
  description: null | string
  tags: null | string
  avatar: null | string
  language: 'en-US' | string
  theme: 'auto' | string
  tfa_secret: null | string
  status: 'active' | string
  role: '04ae123f-4ed2-4587-850d-fc9e3fd4d4dd' | string
  token: 'cfaf74cb-43fd-4417-b076-1a06fe8b5b68' | string
  last_access: '2021-10-05T22:14:17+09:00' | string
  last_page: '/users' | string
  shouldChangePassword: false | string
  isAdmin: true | boolean
  coreRoles: string[]
  createdAt: '2021-10-05T21:10:52' | string
  updatedAt: '2021-10-05T21:11:56' | string
  id_migrated: null | string
  userCreated: null | string
  userUpdated: '349b8437-4377-49d5-9b97-c4cf314cb889' | string
  name: 'DU' | string
  metaData: Record<string, any>
  organizations?: any[]
}>

/**
 * # $core.$embAuth
 *
 * CORE Framework では $core.$embAuth オブジェクトを通じて現在のユーザーに関する情報にアクセスすることができます。
 * - 参照系: このオブジェクトを使用して、ユーザーの権限、ロール、認証ステータスなどの情報を取得し、それに基づいてさまざまな処理を行うことが可能です。
 * - 更新系: このオブジェクトを使用して、ユーザーの情報を更新することができます。
 * - 認証系: このオブジェクトを使用して、ユーザーの認証を行うことができます。
 */
export class EmbeddableAuth {
  // $auth: Partial<EmbAuthInjectableAuthInstance>
  hookBaseName: string
  user: EmbUserData | null
  hasRetrievedSessionPromise: Promise<any> | null
  public isLoggedIn: boolean

  constructor() {
    this.hookBaseName = embAuthHookBaseName
    this.user = {}
    this.hasRetrievedSessionPromise = null
    this.isLoggedIn = false
  }

  static get initAsVueObservableObject(): EmbeddableAuth {
    return makeItReactive(singletonInstanceSummoner('EmbeddableAuth', EmbeddableAuth))
  }

  get id() {
    return this.user?.id
  }

  async signIn(args) {
    const res = await this._doExecutableHook(embAuthHooks.signIn.baseName, args)
    if (res) {
      this.isLoggedIn = true
    }
    return res
  }

  async _doExecutableHook(hookBaseName, additionalArgs: any = null) {
    return $appHook.doExecutableHook({
      hookBaseName: hookBaseName,
      additionalArgs,
      callerContext: this,
    })
  }

  async signOut(args = {}) {
    const res = await this._doExecutableHook(embAuthHooks.signOut.baseName, args)
    this.hasRetrievedSessionPromise = null
    return res
  }

  async signUp(args = {}) {
    return await this._doExecutableHook(embAuthHooks.signUp.baseName, args)
  }

  async restoreAuthSession(args = {}) {
    return this._doExecutableHook(embAuthHooks.restoreAuthSession.baseName, args)
  }

  async getUserData(args = {}): Promise<EmbeddableAuthUserData | null> {
    const res: EmbeddableAuthUserData = await this._doExecutableHook(
      embAuthHooks.getUserData.baseName,
      args,
    )
    this.user = res
    this.isLoggedIn = !!res
    return res
  }

  /**
   * ログイン状態をチェック
   */
  async retrieveLoginStateWithWait(): Promise<any | null> {
    if (this.user?.id) {
      return this.user
    }
    try {
      return this.getUserData()
    } catch (e) {
      console.error({ e })
      return null
    }
  }

  async refreshUserData(args = {}) {
    await this._doExecutableHook(embAuthHooks.refreshUserData.baseName, args)
    this._updateCallback()
  }

  async _updateCallback() {
    this.isLoggedIn = !!this.id
  }

  async updateUserData(data: any) {
    await this._doExecutableHook(embAuthHooks.updateUserData.baseName, data)
    await this.getUserData()
  }

  get userUid() {
    return this.id
  }

  get userData() {
    return this.user
  }
}

registerDefalutEmailPasswordEmbAuthActions()
export const $embAuth: EmbeddableAuth = EmbeddableAuth.initAsVueObservableObject
