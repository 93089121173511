<template>
  <div
    class="modern-filter"
    style="position: relative"
  >
    <div
      @click="toggleShow"
      class="d-flex align-items-center filter-item"
      :class="{
        active: filterLength,
      }"
      role="button"
    >
      <Ficon type="tasks" />
      <span :class="filterLength ? `badge badge-pill bg-secondary` : ''"
        >{{ filterLength }}件</span
      >のフィルター
      <span
        @click.prevent="removeModernFilter"
        class="trash-icon"
      >
        <Ficon type="trash" />
      </span>
    </div>
    <div
      class="modern-filter--modal-container"
      :style="
        FilterControlsServiceInstance.value.filterGroupService.isFocus
          ? ''
          : 'display: none;'
      "
    >
      <div class="modern-filter--modal">
        <div
          class="modern-filter--modal-close-button hover-fade position-absolute"
          style="top: 0px; right: 3px; font-size: 16px; z-index: 10; background: white"
          :style="
            FilterControlsServiceInstance.value.filterGroupService.isFocus
              ? ''
              : 'display: none;'
          "
          @click="
            () => (FilterControlsServiceInstance.value.filterGroupService.isFocus = false)
          "
        >
          <ficon type="x" />
        </div>
        <ModernFilterWrapper />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, inject } from 'vue'
import { FilterControlsService } from '../../FilterControlsService'
import ModernFilterWrapper from './ModernFilterComponents/ModernFilterWrapper.vue'

export default {
  components: { ModernFilterWrapper },
  setup() {
    return {
      FilterControlsServiceInstance: computed(() =>
        inject<FilterControlsService>('FilterControlsServiceInstance'),
      ),
    }
  },
  computed: {
    filterLength() {
      return Object.values(
        this.FilterControlsServiceInstance.value.filterGroupService.filterObjects,
      ).length
    },
  },
  methods: {
    toggleShow() {
      if (this.FilterControlsServiceInstance.value.filterGroupService.isFocus) {
        this.FilterControlsServiceInstance.value.filterGroupService.isFocus = false
      } else {
        this.FilterControlsServiceInstance.value.focusModernFilter()
      }
    },
    removeModernFilter(event) {
      event.stopPropagation()
      this.FilterControlsServiceInstance.value.removeModernFilter()
    },
  },
}
</script>
<style lang="scss">
.modern-filter--modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 1080;
  .modern-filter--modal {
    position: relative;
    width: 960px;
    max-width: 96vw;
    background-color: white;
    overflow: scroll;
    border-radius: var(--bs-border-radius);
    padding: 12px 8px;
    margin: 20px 0;
    .modern-filter--modal-close-button {
      position: absolute;
      top: 6px;
      right: 8px;
      font-size: 16px;
      z-index: 10;
      background: white;
      border-radius: 50%;
    }
  }
}
</style>
