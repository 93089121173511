import { ComposableComponentInstanceItem } from '../../../../ComposableComponents/ComposableComponentInstanceItem'
import { ComponentPublicInstance } from 'vue'

/**
 * ComposableComponentItemRenderer にて、初期化前に ComposableComponentInstanceItem に設定された内容に応じて処理, resolve する関数
 *
 * ## 例:
 * - 初期化前に何らかの dynamic な変数を指定したい場合の __func__beforeInitFunction を解決する (ユーザ名を表示するなど)
 */
export const composableComponentItemBeforeRenderResolver = async (
  renderInstance: ComponentPublicInstance,
  item: ComposableComponentInstanceItem,
): Promise<Record<string, any>> => {
  let res = {}
  const useBeforeInitFunction =
    item.advancedSettings?.useBeforeInitFunction &&
    item.advancedSettings?.__func__beforeInitFunction
  if (useBeforeInitFunction) {
    try {
      const beforeInitResolved = await resolveBeforeInitFunction(renderInstance, item)
      res = $core.$utils.deepmerge(res, beforeInitResolved)
    } catch (e) {
      console.error(e)
    }
  }
  return res
}

// 内容が同一な関数は 実行可能な状態で object cache しておく
const _functionCaches = {}

const resolveBeforeInitFunction = async (
  renderInstance: ComponentPublicInstance,
  item: ComposableComponentInstanceItem,
): Promise<Record<string, any>> => {
  try {
    const func = getExecutable(item.advancedSettings.__func__beforeInitFunction)
    return func({ item, thisInstance: renderInstance })
  } catch (e) {
    console.error(e)
    return {}
  }
}

const getExecutable = (functionString: string): Function => {
  const key = $core.$utils.md5(functionString)
  if (_functionCaches[key]) {
    return _functionCaches[key]
  }
  // Executable な 関数を、文字列から生成
  const executable = $core.$utils.executeStringDefinedFunction({
    functionString: functionString,
    returnAsExecutableFunction: true,
    functionArgValues: { item: null, thisInstance: null }, // 一旦Nullで渡して, 実行時に実際の引数を渡す
  })
  _functionCaches[key] = executable
  return executable
}
