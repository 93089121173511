<template>
  <div
    class="cascade-column-select-input"
    :class="
      showSelections
        ? 'cascade-column-select-input--expanded'
        : 'cascade-column-select-input--collapsed'
    "
  >
    <div
      :class="showSelections ? 'selected-columns--expanded' : ''"
      class="selected-columns d-flex flex-column gap-2 position-relative"
    >
      <div
        class="selected-columns--expanded-close-button hover-fade position-absolute radius-sm"
        style="top: 0px; right: 3px; font-size: 16px; z-index: 10; background: white"
        :style="showSelections ? '' : 'display: none;'"
        @click="() => (showSelections = false)"
      >
        <ficon type="x" />
      </div>
      <div class="d-flex align-items-center flex-wrap gap-1">
        <template v-if="$refs.cascadeColumnSelectPanel">
          <div
            v-for="(c, index) in calcedValueAndLabels"
            :key="index"
            class="selected-column d-flex align-items-center gap-1"
          >
            <span class="selected-column-label">{{ c.label }}</span>
            <span
              class="text-danger d-inline-block cursor-pointer"
              style="padding: 0px 2px; font-size: 9px"
              @click="removeColumn(c.value)"
            >
              <ficon type="x" />
            </span>
          </div>
        </template>
        <div class="position-relative d-flex align-items-center">
          <span
            class="btn btn-outline-success btn-sm"
            style="padding: 1px 3px; font-size: 11px"
            :style="showSelections ? 'display: none;' : ''"
            @click="() => (showSelections = !showSelections)"
          >
            {{ modelValue?.length > 0 ? '' : '追加' }}
            <ficon type="plus" />
          </span>
        </div>
      </div>
      <div
        :style="showSelections ? 'display: block;' : 'display: none;'"
        :key="modelName"
      >
        <slot name="cascade-column-select-panel-prepend" />
        <CascadeColumnSelectPanel
          :on-column-select="handleColumnSelect"
          :model-name="modelName"
          :columns-by-col-name="columnsByColName"
          :enable-keyword-search="enableKeywordSearch"
          :getNestedColumnFilterSelectionFromColumnsByColumnsArgs="
            getNestedColumnFilterSelectionFromColumnsByColumnsArgs
          "
          @column-selected="handleColumnSelected"
          @search-changed="handleSearchChanged"
          ref="cascadeColumnSelectPanel"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { ColumnDef } from '../../../../../common/$models/ModelDef'
import {
  FilterColumnSelection,
  GetNestedColumnFilterSelectionFromColumnsByColumnsArgs,
} from '../../FilterControlsService'

/**
 * カスケード選択形式のカラム選択入力コンポーネント
 *
 * @description
 * CascadeColumnSelectPanel.vueを利用して、v-modelに対応したカラム選択入力を提供します。
 * ModelFormでの使用を想定しており、基本的なフォーム入力コンポーネントとして機能します。
 *
 * @features
 * - v-model対応
 * - カラム検索機能（オプショナル）
 * - 指定カラムのフィルタリング
 * - ModelForm互換
 *
 * @example
 * ```vue
 * <template>
 *   <CascadeColumnSelectInput
 *     v-model="selectedColumns"
 *     :model-name="'users'"
 *     :columns-by-col-name="columnDefs"
 *     :specified-col-names="['name', 'email']"
 *     :enable-keyword-search="true"
 *   />
 * </template>
 *
 * < script>
 * export default {
 *   data() {
 *     return {
 *       selectedColumns: [],
 *       columnDefs: {
 *         name: { label: '名前' },
 *         email: { label: 'メールアドレス' }
 *       }
 *     }
 *   }
 * }
 * < / script>
 * ```
 *
 * @props
 * - modelValue (Array): 選択されたカラムパスの配列
 * - modelName (string): モデル名
 * - columnsByColName (Object): カラム定義オブジェクト
 * - specifiedColNames (Array): 選択可能なカラム名の配列
 * - enableKeywordSearch (boolean): キーワード検索の有効化
 *
 * @emits
 * - update:modelValue: 選択値が変更された時に発火
 */
export default {
  name: 'CascadeColumnSelectInput',
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    modelName: {
      type: String,
      default: null,
    },
    columnsByColName: {
      type: Object as PropType<Record<string, ColumnDef>>,
      default: null,
    },
    specifiedColNames: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    enableKeywordSearch: {
      type: Boolean,
      default: true,
    },
    getNestedColumnFilterSelectionFromColumnsByColumnsArgs: {
      type: Object as PropType<GetNestedColumnFilterSelectionFromColumnsByColumnsArgs>,
      default: null,
    },
    prependDisplayLabels: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showSelections: false,
    }
  },
  computed: {
    effectiveColumns(): Record<string, ColumnDef> {
      // columnsByColNameが指定されている場合はそれを優先
      if (Object.keys(this.columnsByColName || {}).length > 0) {
        return this.columnsByColName
      }
      // modelNameが指定されている場合はFilterControlsServiceから取得
      if (this.modelName) {
        return $core.$models[this.modelName].columns || {}
      }
      // どちらも指定されていない場合は空オブジェクト
      return {}
    },
    calcedNestedColumnSelectionsByKey(): {
      [key: string]: FilterColumnSelection
    } {
      return this.$refs.cascadeColumnSelectPanel?.calcedNestedColumnSelectionsByKey || {}
    },
    calcedValueAndLabels(): {
      value: string
      label: string
    }[] {
      if (!this.$refs.cascadeColumnSelectPanel) {
        return [] // まだコンポーネントが生成されていない場合は空配列
      }
      return (this.modelValue || [])?.map((colPath) => {
        return {
          value: colPath,
          label: this.getColumnLabel(colPath),
        }
      })
    },
  },
  methods: {
    handleColumnSelect(colNamePath: string[]) {
      const newValue = [...(this.modelValue || [])]
      if (!newValue.includes(colNamePath.join('.'))) {
        newValue.push(colNamePath.join('.'))
        this.$emit('update:modelValue', newValue)
      }
    },
    handleColumnSelected(colNamePath: string[]) {
      // 必要に応じて追加の処理
    },
    handleSearchChanged(searchStr: string) {
      // 必要に応じて追加の処理
    },
    removeColumn(colPath: string) {
      const newValue = this.modelValue.filter((path) => path !== colPath)
      this.$emit('update:modelValue', newValue)
    },
    getColumnLabel(colPath: string): string {
      const parts = colPath.split('.')
      const filterCols = this.prependDisplayLabels?.length
        ? [...this.prependDisplayLabels].map((label) => ({
            label,
          }))
        : []
      parts.reduce((acc: FilterColumnSelection, part, index) => {
        // calcedNestedColumnSelectionsByKey を 利用して 探索していく
        const col =
          index === 0
            ? this.calcedNestedColumnSelectionsByKey[part]
            : acc?.children?.find((c) => c.value === part)
        if (col) {
          filterCols.push(col)
        }
        return col
      }, {} as FilterColumnSelection)
      return filterCols.map((col) => col?.label).join(' > ')
    },
  },
}
</script>

<style scoped>
.cascade-column-select-input--expanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1030;
  > .selected-columns--expanded {
    max-width: 98vw;
    width: 800px;
    overflow-y: auto;
    background-color: #fff;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
.cascade-column-select-input {
  min-width: 200px;
}

.selected-column {
  padding: 4px 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.selected-column-label {
  font-size: 0.9em;
  color: #495057;
}
</style>
