import { ModelFactory } from '../../../common/$models'
import {
  ColumnDef,
  ColumnDefByColName,
  ColumnType,
  ColumnTypes,
} from '../../../common/$models/ModelDef'
import { ComposableDataListService } from './ComposableDataListService'

const ifValidNumberThenEq = (keyword) => (isNaN(keyword) ? null : '_eq')
const stringTypeOp = () => '_contains'
// const numberTypeColumns: ColumnType[] = ['NUMBER', 'FLOAT', 'BIGINTEGER', 'DECIMAL', 'DOUBLE']
// const stringTypeColumns: ColumnType[] = ['STRING', 'FILE', 'FILEUPLOAD', 'TEXT', 'UUID', 'SELECT', 'MULTISELECT', 'REFERENCE', 'RICHTEXT']

export const keywordSearchOperatorByColumnType: {
  [colType in ColumnType]: Function | null
} = {
  NUMBER: ifValidNumberThenEq,
  FLOAT: ifValidNumberThenEq,
  BIGINTEGER: ifValidNumberThenEq,
  DECIMAL: ifValidNumberThenEq,
  DOUBLE: ifValidNumberThenEq,
  STRING: stringTypeOp,
  FILE: stringTypeOp,
  FILEUPLOAD: stringTypeOp,
  TEXT: stringTypeOp,
  SELECT: stringTypeOp,
  MULTISELECT: stringTypeOp,
  REFERENCE: stringTypeOp,
  RICHTEXT: stringTypeOp,
  // 検索対象から除外するもの
  UUID: null,
  DATEONLY: null,
  BOOLEAN: null,
  ARRAY_OF_OBJECT: null,
  JSON: null,
  CONDITIONAL_EXPRESSION: null,
  DATETIME: null,
  TIME: null,
  RELATIONSHIP_MANY_TO_ANY: null,
  RELATIONSHIP_MANY_TO_ONE: null,
  RELATIONSHIP_ONE_TO_MANY: null,
}

export const isKeywordSearchableCol = (col: ColumnDef) => {
  return (
    !!keywordSearchOperatorByColumnType[col.type] &&
    !col.doNotSyncModel &&
    !col.virtualColumnOf
  )
}

export const generateKeywordSearchTargetColumns = ({
  columns,
  modelName,
  relationNestLevel = 2,
  currentNested = 1,
  nestedPath = '',
}: {
  columns: ColumnDefByColName
  modelName: string
  relationNestLevel?: number
  currentNested?: number
  nestedPath?: string
}): string[] => {
  return Object.keys(columns).reduce((res, colName) => {
    const col = columns[colName]
    // password のみ 特殊なので...
    if (modelName === 'directus_users' && col.name === 'password') {
      return res
    }
    if (isKeywordSearchableCol(col)) {
      res.push(`${nestedPath}${colName}`)
    }
    // M2O or O2M Relationship なら、 1段先まで検索対象にする
    if (
      [ColumnTypes.RelationshipManyToOne, ColumnTypes.RelationshipOneToMany].indexOf(
        // @ts-ignore
        col.type,
      ) >= 0 &&
      currentNested < relationNestLevel
    ) {
      const relatedModelNames =
        col.type === ColumnTypes.RelationshipManyToOne
          ? col.relationshipManyToOne?.collectionName
          : col.relationshipOneToMany?.collectionName
      const nextColumns = $core.$models[relatedModelNames]?.columns
      const nextNested = currentNested + 1
      if (!nextColumns) {
        console.warn(
          `[generateKeywordSearchTargetColumns] Warning: nextColumns not found:`,
          col.relationshipManyToOne?.collectionName,
        )
      }
      if (nextColumns) {
        const nextRes = generateKeywordSearchTargetColumns({
          columns: nextColumns,
          modelName: relatedModelNames,
          relationNestLevel,
          currentNested: nextNested,
          nestedPath: `${nestedPath}${colName}.`,
        })
        res = res.concat(nextRes)
      }
    }
    return res
  }, [])
}

export class DataListKeywordFilterService {
  public DataListService: ComposableDataListService
  public keywordSearchTargetColumns: string[] = []
  public vueInstance: any
  public columns: ColumnDefByColName = {}
  public readonly filterConditionKeyName: string

  constructor({
    DataListService,
    keywordSearchTargetColumns = [],
    vueInstance,
    filterConditionKeyName = 'keywordFilterCondition',
  }) {
    this.DataListService = DataListService
    this.keywordSearchTargetColumns = keywordSearchTargetColumns
    this.vueInstance = vueInstance
    this.filterConditionKeyName = filterConditionKeyName

    if (this.keywordSearchTargetColumns.length === 0) {
      this._init()
    }
  }

  get model(): ModelFactory {
    return this.DataListService.model
  }
  get virtualModel(): ModelFactory {
    return this.DataListService.virtualModel
  }

  /**
   * *, columnName.* などの検索対象カラムに対して、ネストを考慮した配列を返す
   */
  get convertedKeywordSearchTargetColumns() {
    return this.keywordSearchTargetColumns.reduce((acc, item) => {
      // *を含めていない場合には、そのまま追加
      if (item.indexOf('*') === -1) {
        acc.push(item)
      } else {
        // *だけの場合には、全てのカラムを追加(その中で検索可能なカラムだけをターゲットに)
        if (item === '*') {
          const searchableColumns = Object.keys(this.columns).filter((key) => {
            return !!keywordSearchOperatorByColumnType[this.columns[key].type]
          })
          acc = acc.concat(searchableColumns)
        } else {
          // * が含まれている場合には、*を含むカラムを追加
          // .*を削除
          const tempItem = item.replace('.*', '')
          const lastLevelColumnKeys = this.getLastLevelColumnKeys(tempItem, this.columns)
          lastLevelColumnKeys.forEach((lastLevelColumnKey) => {
            acc.push(`${tempItem}.${lastLevelColumnKey}`)
          })
        }
      }
      return acc
    }, [])
  }

  private async _init() {
    if (Array.isArray(this.model.columns)) {
      this.columns = this.model.columns.reduce((acc, column) => {
        acc[column.name || column.key] = column
        return acc
      }, {})
    } else {
      this.columns = this.model.columns
    }
    if (this.virtualModel?.keywordSearchTargetColumns?.length) {
      this.keywordSearchTargetColumns = this.virtualModel.keywordSearchTargetColumns
    } else if (this.model?.keywordSearchTargetColumns) {
      // TODO: filter
      this.keywordSearchTargetColumns = this.model.keywordSearchTargetColumns
    }

    if (!this.keywordSearchTargetColumns?.length) {
      this.keywordSearchTargetColumns = generateKeywordSearchTargetColumns({
        columns: this.columns,
        modelName: this.DataListService.modelName,
      })
    }
  }

  getLastItemColumnType(
    items: string,
    initialColumns: ColumnDefByColName,
  ): ColumnType | undefined {
    const parts = items.split('.')

    if (parts.length === 0) return undefined

    const processColumn = (
      currentIndex: number,
      currentColumns: ColumnDefByColName,
    ): ColumnType | undefined => {
      const part = parts[currentIndex]
      const column = currentColumns[part]

      if (!column) {
        throw new Error(`Column not found: ${part}`)
      }

      if (column.excludeFromSearch) {
        return undefined
      } else if (currentIndex === parts.length - 1) {
        return column.type
      } else if (
        column.relationshipManyToOne?.collectionName ||
        column.relationshipOneToMany?.collectionName
      ) {
        const relationModelName =
          column.relationshipManyToOne?.collectionName ||
          column.relationshipOneToMany?.collectionName
        const nextColumns = $core.$models[relationModelName]?.columns
        return processColumn(currentIndex + 1, nextColumns)
      } else {
        throw new Error(
          `Expected relationshipManyToOne_collectionName for column: ${part}`,
        )
      }
    }

    return processColumn(0, initialColumns)
  }

  getLastLevelColumnKeys(items: string, initialColumns: ColumnDefByColName): string[] {
    const parts = items.split('.')

    if (parts.length === 0) return []

    let res: string[] = []
    const processColumn = (currentIndex: number, currentColumns: ColumnDefByColName) => {
      const part = parts[currentIndex]
      const column = currentColumns[part]

      if (!column) {
        throw new Error(`Column not found: ${part}`)
      }

      if (
        currentIndex === parts.length - 1 &&
        column.relationshipManyToOne?.collectionName
      ) {
        const nextColumns =
          $core.$models[column.relationshipManyToOne?.collectionName]?.columns
        res = Object.keys(nextColumns)
      } else if (currentIndex !== parts.length - 1) {
        const nextColumns =
          $core.$models[column.relationshipManyToOne?.collectionName]?.columns
        processColumn(currentIndex + 1, nextColumns)
      } else {
        throw new Error(
          `Expected relationshipManyToOne_collectionName for column: ${part}`,
        )
      }
    }

    processColumn(0, initialColumns)
    return res
  }

  refreshQuery(keyword) {
    if (!keyword) {
      this._applyKeywordFilterCondition({})
      return
    }
    // queryArrGroup は、_or で結合される
    const queryArrGroup = []
    const keywordUnits = keyword.split(' ')
    keywordUnits.forEach((keywordUnit) => {
      console.log(
        `this.convertedKeywordSearchTargetColumns:`,
        this.convertedKeywordSearchTargetColumns,
      )
      debugger
      const queryArr = this.convertedKeywordSearchTargetColumns.reduce((qArr, item) => {
        const itemArr = item.split('.')
        try {
          const colType = this.getLastItemColumnType(item, this.columns)
          const operator = keywordSearchOperatorByColumnType[colType]?.(keywordUnit)
          if (!operator) {
            return qArr
          }
          // itemArr の 数だけネストした オブジェクトを作成する
          const currentQuery = itemArr.reverse().reduce(
            (acc, item) => {
              return {
                [item]: acc,
              }
            },
            { [operator]: keywordUnit },
          )
          qArr.push(currentQuery)
        } catch (e) {
          console.warn(`[refreshQuery] Warning:`, e)
        }

        return qArr
      }, [])
      if (queryArr.length > 0) {
        queryArrGroup.push({ _or: queryArr })
      }
    })

    const query =
      queryArrGroup.length > 0
        ? {
            _and: queryArrGroup,
          }
        : {}
    this._applyKeywordFilterCondition(query)
  }
  _applyKeywordFilterCondition(query) {
    this.DataListService.query.applyFilter(this.filterConditionKeyName, query)
  }
}
