<template>
  <div
    class="file-uploader"
    :class="{
      'file-uploader--dragging': dragging,
      'file-uploader--compact': compact,
    }"
    @dragover="handleDragover"
    @dragenter="handleDragover"
    @dragleave="handleDragleave"
    @drop="handleDrop"
  >
    <!-- 非表示のファイル入力 -->
    <input
      ref="fileInput"
      type="file"
      :multiple="multiple"
      :accept="accept"
      @change="handleFileChange"
      class="d-none"
    />

    <!-- アップロードゾーン -->
    <div
      class="upload-zone"
      :class="{ 'upload-zone--dragging': dragging }"
      @click.prevent="() => $refs.fileInput.click()"
    >
      <div class="upload-message">
        <template v-if="!compact">
          <ficon
            type="cloud-upload-alt"
            size="2x"
          />
          <p>
            {{ multiple ? '複数' : '' }}ファイルをドラッグ＆ドロップ
            <span class="text-muted small d-block">または</span>
          </p>
        </template>
        <span
          v-if="!disableEdit"
          :class="btnClass"
          variant="outline-primary"
          size="sm"
        >
          <ficon type="plus" /> {{ buttonText || 'ファイルを選択' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { $fileManager } from './FileManager'
import { uploadPathPrefixProps } from './uploadPathPrefixProps'

// Default 50MB
const maxFileSize = process.env.CORE_UPLOAD_MAX_FILESIZE || 51200000

export default {
  name: 'FileUploader',
  props: {
    ...uploadPathPrefixProps,
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '',
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    btnClass: {
      type: String,
      default: 'btn btn-sm btn-outline-secondary',
    },
    buttonText: {
      type: String,
      default: '',
    },
    showSuccess: {
      type: Boolean,
      default: false,
    },
    uploadMaxFileSize: {
      type: Number,
      default: maxFileSize,
    },
    fileValidationFunction: {
      type: Function,
      default: null,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragging: false,
      uploading: false,
    }
  },
  methods: {
    /**
     * ファイル選択時の処理
     */
    async handleFileChange(event) {
      const files = Array.from(event.target.files || [])
      if (!files.length) return

      await this.uploadFiles(files)
      // 入力をリセット
      event.target.value = ''
    },

    /**
     * ファイルのアップロード処理
     */
    async uploadFiles(files) {
      if (this.disableEdit || this.uploading) return

      try {
        this.uploading = true
        $core.$loading.start('アップロードしています...')

        // ファイルサイズのチェック
        for (const file of files) {
          if (this.uploadMaxFileSize > 0 && file.size > this.uploadMaxFileSize) {
            $core.$toast.errorToast(
              `ファイルサイズが大き過ぎます: ${file.name} (size: ${file.size})`,
            )
            return
          }
        }

        // バリデーションチェック
        if (this.fileValidationFunction) {
          for (const file of files) {
            const validationResult = await this.fileValidationFunction(file)
            if (!validationResult) {
              return
            }
          }
        }

        // 単一ファイルの場合
        if (!this.multiple) {
          const { filePath, uploadResult } = await $fileManager.upload(
            files[0],
            null,
            {
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total,
                )
                $core.$loading.start(`アップロードしています... (${percentCompleted}%)`)
              },
            },
            this.filePathPrefix,
            this.filePathGenerateFunction,
          )

          this.$emit('upload-complete', filePath, uploadResult)
          if (this.showSuccess) {
            $core.$toast.successToast('アップロード成功')
          }
          return
        }

        // 複数ファイルの場合
        const { uploaded, failedFileNames } = await $fileManager.bulkUploadFiles(
          files,
          this.filePathPrefix,
          this.filePathGenerateFunction,
        )

        // 失敗したファイルがある場合は通知
        if (failedFileNames.length > 0) {
          $core.$toast.errorToast(
            `以下のファイルのアップロードに失敗しました:\n${failedFileNames.join('\n')}`,
          )
        }

        // 成功したファイルのパスを通知
        const uploadedPaths = uploaded.map((item) => item.filePath)
        this.$emit('upload-complete', uploadedPaths)

        if (this.showSuccess && uploaded.length > 0) {
          $core.$toast.successToast(
            `${uploaded.length}件のファイルをアップロードしました`,
          )
        }
      } catch (error) {
        console.error('Upload error:', error)
        $core.$toast.errorToast('アップロードに失敗しました')
      } finally {
        this.uploading = false
        $core.$loading.finish()
      }
    },

    /**
     * ドラッグ&ドロップ関連のハンドラ
     */
    handleDragover(e) {
      e.preventDefault()
      e.stopPropagation()
      this.dragging = true
      e.dataTransfer.dropEffect = 'copy'
    },

    handleDragleave(e) {
      e.preventDefault()
      e.stopPropagation()
      this.dragging = false
    },

    async handleDrop(e) {
      e.preventDefault()
      e.stopPropagation()
      this.dragging = false

      const files = Array.from(e.dataTransfer.files || [])
      if (!files.length) return

      if (!this.multiple && files.length > 1) {
        $core.$toast.errorToast('1ファイルのみ選択してください')
        return
      }

      await this.uploadFiles(files)
    },
  },
}
</script>

<style lang="scss">
.file-uploader {
  --card-radius: 0.5rem;
  --spacing: 8px;
  --spacing-sm: 4px;
  --btn-zoom: 0.85;

  // アップロードゾーン
  .upload-zone {
    background: var(--bs-gray-25);
    border: 1.5px dashed var(--bs-gray-300);
    border-radius: var(--card-radius);
    padding: var(--spacing);
    transition: all 0.2s ease;

    &--dragging {
      background: var(--bs-gray-200);
      border-color: var(--bs-gray-500);
    }

    .upload-message {
      text-align: center;
      color: var(--bs-gray-600);

      .ficon {
        margin-bottom: var(--spacing-sm);
        color: var(--bs-gray-500);
      }

      p {
        margin-bottom: 2px;
        font-size: 0.8rem;
      }

      .btn {
        zoom: var(--btn-zoom);
      }
    }
  }

  // コンパクトモード
  &--compact {
    --btn-zoom: 0.8;

    .upload-zone {
      background: transparent;
      border: none;
      padding: 0;

      .upload-message {
        .btn {
          // padding: 0.25rem 0.5rem;
          font-size: 0.75rem;
        }
      }
    }
  }
}
</style>
