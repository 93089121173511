import { ComponentPropsOptions } from 'vue'
import { ColumnDef, ColumnDefByColName } from '../../common/$models/ModelDef'
import { $composableComponentSettingsManager } from './$composableComponentSettingsManager'
import { ComposableComponentSettings } from './ComposableComponentSettings'

/**
 * ComposableComponentSettings を登録する関数
 * この関数を通ることで、ビルダーから利用可能なコンポーネントになる
 * @param componentName
 * @param settings
 * @param props
 * @param defaultValues
 */
export const registerComposableComponentSettings = (
  componentName: string,
  settings: ComposableComponentSettings,
  {
    props = null,
    defaultValues = null,
  }: {
    props?: ComponentPropsOptions
    defaultValues?: Record<string, any>
  } = {},
) =>
  $composableComponentSettingsManager.registerComponent(componentName, settings, {
    props,
    defaultValues,
  })

/**
 * Vue の props[].type の型を ColumnDef['type'] に変換する for ComposableComponentSettings
 */
const convertVuePropTypeToColumnDefType = (prop): ColumnDef['type'] | null => {
  switch (prop?.type) {
    case String:
      return 'STRING'
    case Number:
      return 'NUMBER'
    case Boolean:
      return 'BOOLEAN'
    case Array:
      return null
    case Object:
      return null
    case Date:
      return 'DATEONLY'
    default:
      switch (prop.default) {
        case typeof Boolean:
          return 'BOOLEAN'
        case typeof Number:
          return 'NUMBER'
        case typeof String:
          return 'STRING'
        case typeof Array:
          return null
      }
  }
}

/**
 * Vue の props を ColumnDef に変換する for ComposableComponentSettings
 * @param props
 * @param defaultValues
 */
export const vuePropsIntoColumnDefs = (
  props: ComponentPropsOptions,
  defaultValues: Record<string, any> = null,
): ColumnDefByColName => {
  return Object.keys(props).reduce((acc, key) => {
    const prop = props[key]
    const type = convertVuePropTypeToColumnDefType(prop)
    if (!type) {
      console.warn(`[vuePropsIntoColumnDefs] type is not defined for ${key}`)
      return acc
    }
    const isRequired = prop.required === true
    acc[key] = {
      name: key,
      label: prop?.label || key,
      type,
      defaultValue: defaultValues?.[key] || prop?.default || 'STRING',
      validate: isRequired ? { notEmpty: true } : {},
    }
    return acc
  }, {})
}
