import { matchesFilter } from '../../plugins/ComposableDataListComponents/front/FilterControlsService'

/**
 * レコードの配列 に対して フィルタリング条件に合致したデータを返す
 * @param filterQuery
 * @param records
 *
 * @example
 * ```ts
 * const records = [
 *   { id: 1, name: 'Alice', age: 25 },
 *   { id: 2, name: 'Bob', age: 30 },
 *   { id: 3, name: 'Charlie', age: 35 },
 * ]
 * const filterQuery = {
 *   age: {
 *     _gte: 30,
 *   },
 * }
 * const filteredRecords = $core.$utils.filterRecordsByFilterQuery(filterQuery, records)
 * // result: 2 records => [ { id: 2, name: 'Bob', age: 30 }, { id: 3, name: 'Charlie', age: 35 } ]
 * ```
 */
export const filterRecordsByFilterQuery = matchesFilter
