import {
  ColGroupTypes,
  ColumnDef,
  ColumnDefByColName,
  ColumnDefGroupDef,
  ModelDefFormColumnGroups,
} from '../../common/$models/ModelDef'
import { toComponent } from '../../common/utils'

/**
 * カラム定義から選択肢を生成
 */
export const fetchSelectionsWithColDef = async ({
  modelName,
  colDef,
  record = {},
  value = '',
  initialValue = '',
  recordRoot = {},
  callerVueInstance = {},
}: {
  modelName: string
  colDef: ColumnDef
  record?: any
  value?: any
  initialValue?: any
  recordRoot?: any
  callerVueInstance?: any
}) => {
  let list = []
  // 1. load "additionalSelectOptions.prepend"
  if (
    colDef.additionalSelectOptions &&
    colDef.additionalSelectOptions.prepend &&
    colDef.additionalSelectOptions.prepend.length
  ) {
    list = list.concat(colDef.additionalSelectOptions.prepend)
  }
  // 2. load selections
  if (colDef.selectionsWithSelectOptionsMasterGroupName) {
    // 2-A. load with selectionsWithSelectOptionsMasterGroupName
    const _values = await $core.$models['selectOptionsMaster'].find({
      filter: {
        group: {
          _eq: colDef.selectionsWithSelectOptionsMasterGroupName,
        },
      },
      sort: ['sort'],
    })
    const addList = _values.map((r) => r.value)
    if (addList) {
      list = list.concat(addList)
    }
  } else if (colDef.selectionsWithColNameFacet && $core.$models[modelName]) {
    try {
      const addList = (
        await $core.$models[modelName]?.fetchFacetNamesByColName(
          colDef.selectionsWithColNameFacet,
        )
      ).filter((v) => !!v)
      if (addList) {
        list = list.concat(addList)
      }
    } catch (e) {
      console.log(`.selectionsWithAlgoFacetsColName, Error`, e)
    }
  } else if (typeof colDef.selections === 'function') {
    // 2-C. function pattern
    const addList = await colDef.selections(
      record,
      value,
      initialValue,
      recordRoot,
      callerVueInstance,
    )
    if (addList) {
      list = list.concat(addList)
    }
  } else {
    // 2-D. ENUM pattern
    list = list.concat(
      colDef.type.indexOf('(') === -1
        ? []
        : colDef.type.replace(/^.+\((.+)\)/, '$1').split(','),
    )
  }
  // 3. load with selectionsWithSelectOptionsMasterGroupName
  if (!list) {
    list = []
  }
  if (colDef?.additionalSelectOptions?.append) {
    list = list.concat(colDef.additionalSelectOptions.append)
  }
  return list
}

/**
 * ModelFormGroup に渡すためのcolumnsとして整形, with before, after components
 */
export const convertColumnsForModelFormGroups = (
  columns: ColumnDefByColName,
  columnGroupFormattedDef: ColumnGroupFormattedDef = {},
): { [colNameOrAdditionalComponentName: string]: any } => {
  const parentGroupKeys = Object.keys(columnGroupFormattedDef)
  const colNamesByChildGroups = Object.values(columnGroupFormattedDef).reduce(
    (res, group) => {
      if (group?.items) {
        Object.values(group.items).map((item) => {
          res[item.key || item.label] = item.targetColNames
        })
      }
      return res
    },
    {},
  )
  const childGroupKeys = Object.keys(colNamesByChildGroups)
  const addedGroupComponent = {}
  return Object.keys(columns).reduce((res, colName) => {
    const col: ColumnDef = columns[colName]
    if (!col) {
      return res
    }
    const additionalExtendProps = {
      isExtraComponent: true,
      displayAsDetail: !!col.displayAsDetail,
    }
    // グルーピングを利用しているなら...
    res = __addGroupingControlColIfNeeded(
      res,
      colName,
      col,
      parentGroupKeys,
      colNamesByChildGroups,
      childGroupKeys,
      addedGroupComponent,
      additionalExtendProps,
      columnGroupFormattedDef,
    )
    // beforeComponent があるときは、追加する
    res = __addBeforeComponentsIfNeeded(res, col, colName, additionalExtendProps)
    res[colName] = col
    return res
  }, {})
}

const __addBeforeComponentsIfNeeded = (res, col, colName, additionalExtendProps) => {
  if (col.beforeComponent || col.beforeComponents?.length) {
    const components = (col.beforeComponents || []).concat(
      col?.beforeComponent ? [col.beforeComponent] : [],
    )
    components.forEach((component, i) => {
      const c = {
        props: {},
        computed: {},
        ...toComponent(component),
        ...additionalExtendProps,
      }
      c.props = Object.assign(
        {},
        {
          record: {},
          recordRoot: {},
          ModelFormService: {},
          key: {},
          colName: {},
          value: {},
          modelName: {},
          readOnlyMode: {},
          passedColDefinition: {},
          virtualModelName: {},
        },
        c.props,
      )
      c.computed.shouldVisibleWithGroupState = function () {
        return (
          !col.groupKey ||
          this.ModelFormService?.visibleStateByColumnGroupName[
            this.ModelFormService.columnGroupParentKeyByChildGroupKey[col.groupKey]
          ] === col.groupKey
        )
      }
      const hasEnableIf = typeof col.enableIf === 'function'
      c.computed.enableIf = function () {
        // グループキーが有るなら
        if (!this.shouldVisibleWithGroupState) {
          return false
        }
        // ココで、enableIf を付与するには...
        if (hasEnableIf) {
          if (this.record && typeof col.enableIf === 'function') {
            return col.enableIf(this.record, this.recordRoot)
          }
        }
        return true
      }
      c.computed.styles = function () {
        return `order: ${col.orderOnForm}; z-index: ${Math.ceil(col.orderOnForm / 1000)};`
      }
      if (c.template) {
        c.template = `<div :style="styles" v-if="enableIf">${c.template}</div>`
      }
      res[`${colName}_beforeComponent_${i}`] = c
    })
  }
  return res
}

/**
 * タブ or Accordion などのグループコントロールを追加する
 */
const __addGroupingControlColIfNeeded = (
  res,
  colName,
  col,
  parentGroupKeys,
  colNamesByChildGroups,
  childGroupKeys,
  addedGroupComponent,
  additionalExtendProps,
  columnGroupFormattedDef,
) => {
  const groupKey = col.groupKey
  if (!groupKey) {
    return res
  }
  const parentGroupKey = Object.keys(columnGroupFormattedDef).find(
    (group) => !!columnGroupFormattedDef[group]?.items?.[groupKey],
  )
  const orderBaseBase = 1000 + parentGroupKeys.indexOf(parentGroupKey) * 1000
  const orderBase = orderBaseBase + childGroupKeys.indexOf(groupKey) * 100
  // おおもとのModelをいじってしまっているので注意
  // TODO: ModelFormService へ移植
  // 並び順を整理
  col.orderOnForm =
    col.orderOnForm >= orderBase ? col.orderOnForm : orderBase + (col.orderOnForm || 0)
  // Grouping の１つ目である場合のみ, Control コンポーネントを付与
  if (
    addedGroupComponent[parentGroupKey] ||
    colNamesByChildGroups[groupKey]?.[0] !== colName
  ) {
    return res
  }
  const groupDef = columnGroupFormattedDef[parentGroupKey]
  addedGroupComponent[parentGroupKey] = true
  // TODO: [Vue warn] Vue received a Component which was made a reactive object が出るので...
  res[`${colName}_beforeComponent_group__${groupKey}`] = {
    template: `
      <ColumnGroupingControlForBeforeComponent
        :groupDef="groupDef"
        :groupKey="groupKey"
        :style="wrapperStyle"
      />`,
    computed: {
      groupKey() {
        return parentGroupKey
      },
      groupDef(): any {
        return groupDef
      },
      wrapperStyle() {
        return `order: ${orderBaseBase - 1}; z-index: ${Math.ceil(col.orderOnForm / 1000)};`
      },
      colDef() {
        return col
      },
    },
    ...additionalExtendProps,
  }
  return res
}

export type ColumnGroupFormattedDefItem = ColumnDefGroupDef & {
  targetColNames: string[]
  orderOnForm: number
}

export type ColumnGroupFormattedDef = {
  [groupKey: string]: {
    groupType: ColGroupTypes
    items: {
      [itemKey: string]: ColumnGroupFormattedDefItem
    }
  }
}

export const formatGroupDef = (
  res,
  groupDef: ColumnDefGroupDef,
  colNamesByGroupKey: { [keyName: string]: string[] },
  orderOnForm = 0,
): ColumnGroupFormattedDef => {
  const groupKey =
    (groupDef?.type === 'tab' ? groupDef?.groupKey : null) ||
    groupDef?.key ||
    groupDef?.label
  if (!groupDef?.type || !groupKey) {
    return res
  }
  if (!res[groupKey]) {
    res[groupKey] = {
      groupType: groupDef.type,
      items: {},
    }
  }
  const itemKey = groupDef?.key || groupDef?.label
  if (!res[groupKey].items[itemKey]) {
    res[groupKey].items[itemKey] = {
      ...groupDef,
      key: itemKey,
      targetColNames: colNamesByGroupKey[itemKey],
      orderOnForm,
    }
  }
  return res
}

/**
 * ネストしている columns も含めて formatColumnGroupDefs する
 * @param res
 * @param columns
 */
const __getColNamesByGroupKeyRecursive = (res, columns) => {
  return Object.keys(columns).reduce((res, colName) => {
    const col = columns[colName]
    const groupKey = col?.groupKey
    if (!groupKey) {
      return res
    }
    res[groupKey] = (res[groupKey] || [])
      .concat(colName)
      .sort((a, b) => (columns[a].orderOnForm || 0) - (columns[a].orderOnForm || 0))
    if (col.columns) {
      res = __getColNamesByGroupKeyRecursive(res, col.columns)
    }
    return res
  }, res)
}

export const formatColumnGroupDefs = (
  groupDefs: ModelDefFormColumnGroups,
  columns: ColumnDefByColName,
): ColumnGroupFormattedDef => {
  const colNamesByGroupKey = __getColNamesByGroupKeyRecursive({}, columns)
  return Object.keys(groupDefs)
    .sort((a, b) => (groupDefs[a].order || 0) - (groupDefs[b].order || 0))
    .reduce((res, key) => {
      return formatGroupDef(
        res,
        {
          ...groupDefs[key],
          key,
        },
        colNamesByGroupKey,
      )
    }, {})
}
