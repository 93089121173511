<template>
  <div
    class="daterange-input"
    :class="[{ 'is-invalid': !!errorMessage }, { 'is-disabled': disabled }]"
    :key="renderKey"
    :data-render-key="renderKey"
  >
    <div class="d-flex align-items-center gap-2">
      <!-- 開始日の入力 -->
      <datepicker
        :style="{ width: inputWidthPx ? `${inputWidthPx}px` : undefined }"
        :model-value="queryStateController.state?.[stateKeyStartDate]"
        v-bind="startDateAttrs || {}"
        @update:model-value="
          (value) => {
            queryStateController.state[stateKeyStartDate] = $core
              .$dayjs(value)
              .format('YYYY-MM-DD')
          }
        "
      />

      <span>-</span>

      <!-- 終了日の入力 -->
      <datepicker
        :style="{ width: inputWidthPx ? `${inputWidthPx}px` : undefined }"
        :model-value="queryStateController.state?.[stateKeyEndDate]"
        v-bind="endDateAttrs || {}"
        @update:model-value="
          (value) => (queryStateController.state[stateKeyEndDate] = value)
        "
      />
    </div>

    <!-- エラーメッセージ -->
    <div
      v-if="errorMessage || startDateError || endDateError"
      class="invalid-feedback d-block"
    >
      {{ errorMessage || startDateError || endDateError }}
    </div>

    <!-- ヘルプテキスト -->
    <small
      v-if="helpText"
      class="form-text text-muted"
    >
      {{ helpText }}
    </small>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { registerComposableComponentSettings } from '../ComposableComponents'

registerComposableComponentSettings(`DaterangeInput`, {
  name: 'DaterangeInput',
  hasDefaultSlot: true,
  configColumns: {
    stateKeyStartDate: {
      type: 'STRING',
      label: 'クエリキー (開始日)',
    },
    stateKeyEndDate: {
      type: 'STRING',
      label: 'クエリステートキー (終了日)',
    },
  },
})

export default defineComponent({
  name: 'DaterangeInput',
  inject: ['queryStateController'],

  /**
   * 受け取るプロパティの定義
   */
  props: {
    stateKeyStartDate: {
      type: String,
      required: true,
    },
    stateKeyEndDate: {
      type: String,
      required: true,
    },

    startDateAttrs: {
      type: Object,
      default: undefined,
    },
    endDateAttrs: {
      type: Object,
      default: undefined,
    },
    inputWidthPx: {
      type: Number,
      default: 160,
    },
  },

  /**
   * コンポーネントが発火するイベントの定義
   */
  emits: ['update:modelValue', 'change', 'error'],

  /**
   * データオプション
   * コンポーネントのデータを定義します
   */
  data() {
    return {
      // 内部状態
      startDate: null,
      endDate: null,
      startDateError: '',
      endDateError: '',
    }
  },

  /**
   * 計算プロパティの定義
   */
  computed: {
    renderKey(): string {
      return `${this.stateKeyStartDate}-${this.stateKeyEndDate}-${this.queryStateController.state?.[this.stateKeyStartDate]}-${this.queryStateController.state?.[this.stateKeyEndDate]}`
    },

    /**
     * クエリステートコントローラの取得
     */
    qs() {
      return this.queryStateController.state || {}
    },
  },
})
</script>

<style scoped></style>
