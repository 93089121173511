<template>
  <div
    class="flex-fill"
    style="position: relative"
  >
    <div class="d-flex align-items-center">
      <b-form-select
        style="width: 120px"
        class="mr-1"
        :modelValue="operator"
        @update:modelValue="updateOperator"
        :options="options"
        size="sm"
      ></b-form-select>
      <b-form-input
        v-if="operator !== 'isnull' && operator !== 'isnotnull'"
        size="sm"
        :modelValue="modelValue"
        @update:modelValue="change"
      />
      <!-- <a
        v-if="!disableSetting"
        class="ml-auto"
        role="button"
        @click="toggleSetting"
      >
        <Ficon
          class="ml-2"
          type="ellipsis-h"
        />
      </a> -->
      <FilterItemManagerForCustom :visible="true" />
    </div>
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { FilterItemService } from '../../../FilterItemService'
import { FILTER_OPERATOR_FOR_STRING } from '../../../FilterRuleService'
import FilterItemManagerForCustom from '../FilterItemManagerForCustom.vue'

export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    disableSetting: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FilterItemManagerForCustom,
  },
  setup() {
    return {
      filterObject: inject('filterObject'),
    }
  },
  data() {
    return {
      operator: null,
      isVisibleSetting: false,
    }
  },
  computed: {
    label() {
      return this.filterObject.colDef.label || this.filterObject.colDef.name
    },
    options() {
      // { value: xxxx'', text: 'xxxxx' }のような形で返す
      return Object.keys(FILTER_OPERATOR_FOR_STRING).map((operator) => {
        return {
          value: operator,
          text: FILTER_OPERATOR_FOR_STRING[operator],
        }
      })
    },
  },
  created() {
    this.operator = this.options.length > 0 ? this.options[0].value : null
  },
  methods: {
    toggleSetting() {
      this.isVisibleSetting = !this.isVisibleSetting
    },
    change(value) {
      this.$emit('update:modelValue', {
        value,
        operator: this.operator,
      })
    },
    updateOperator(value) {
      this.operator = value
      this.$emit('update:operator', this.operator)
    },
  },
}
</script>
