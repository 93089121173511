<template>
  <div>
    <div
      v-if="val"
      class="input-file-selector-image-wrap"
    >
      <div
        v-if="isImage"
        class="position-relative"
      >
        <img
          class="bg-transparency-grid d-block mx-auto"
          :src="previewImageUrl"
          style="width: auto; max-width: 100%; max-height: 280px"
        />
      </div>
      <code>{{ formattedValue }}</code>
      <a
        @click.prevent="
          () => $core.$imgix.openFileLink(val, {}, isImage ? null : 'download')
        "
        href="#"
        class="float-right"
      >
        <ficon
          type="external-link-alt"
          class="ml-2"
        />
      </a>
    </div>
    <span
      v-if="!disabled"
      class="btn btn-sm btn-outline-secondary mt-2"
      v-single-click="openFileSelectorModal"
      >ファイルを{{ val ? '変更' : '選択' }}</span
    >
    <span
      v-if="!disabled && val"
      class="btn btn-sm btn-outline-danger mt-2 ml-2"
      v-single-click="() => $emit('update:modelValue', { value: '' })"
    >
      削除
    </span>
  </div>
</template>

<script lang="ts">
import { isImageLikeExtensionFileName } from '../../common/utils'
import { filenameSeparator, getOriginalFileNameFromFilePath } from './FileManager'

export default {
  name: 'InputFileSelector',
  props: {
    disabled: { type: Boolean, default: false },
    modelValue: {},
    col: {},
  },
  data: () => ({
    selected: '',
  }),
  computed: {
    val() {
      return this.modelValue
    },
    formattedValue() {
      if (!this.modelValue) return null
      return this.shouldUseOriginalFileName
        ? getOriginalFileNameFromFilePath(
            this.modelValue.replace(new RegExp(`^.+${filenameSeparator}(.+)$`), '$1'),
          )
        : this.modelValue
    },
    shouldUseOriginalFileName() {
      return this.col?.inputAttrs?.useOriginalFileName
    },
    isImage(): boolean {
      return isImageLikeExtensionFileName(this.val)
    },
    openLinkBind() {
      if (this.isImage) {
        return {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      } else {
        return {
          download: true,
        }
      }
    },
    previewImageUrl() {
      return this.$core.$imgix.buildUrl(this.val)
    },
  },
  beforeUnmount() {
    window.removeEventListener('fileSelectorModal:selected', this.selectedCallback)
  },
  methods: {
    // 一時的にEventListenerをAdd for Modal内での画像選択
    selectedCallback(data) {
      this.$emit('update:modelValue', data.detail)
      window.removeEventListener('fileSelectorModal:selected', this.selectedCallback)
    },
    openFileSelectorModal() {
      window.addEventListener('fileSelectorModal:selected', this.selectedCallback)
      $core.$modals.openModal({
        component: $frameworkUtils.defineAsyncComponent(
          () => import('./FileSelector.vue'),
        ),
        modalProps: {
          onClose: () => {
            window.removeEventListener(
              'fileSelectorModal:selected',
              this.selectedCallback,
            )
          },
          dialogClass: 'core-modal-type--ListView',
        },
      })
    },
  },
}
</script>
