import { ModelDef } from '../../../common/$models/ModelDef'
import { IDLike } from '../../../types'

type ModelCoreUserNotificationRelatedRecord = {
  modelName?: string
  virtualModelName?: string
  recordId?: IDLike
}

export type ModelCoreUserNotification = {
  id: string
  uniqueIdentifier?: Record<string, any>
  category?: string
  categorySub?: string
  user: string
  readAt?: string
  title?: string
  body?: string
  titleForMessageSender?: string
  bodyForMessageSender?: string
  useDefaultTemplate?: boolean
  templateKey?: string
  templateKeyForMessageSender?: string
  metaData?: Record<string, any>
  linkUrl?: string
  relatedRecords?: ModelCoreUserNotificationRelatedRecord[]
}

/**
 * User への 通知データを保管するテーブル
 * 基本的には参照のみ
 */
export const core_user_notifications: ModelDef = {
  tableName: 'core_user_notifications',
  tableLabel: '通知',
  // updatable: false,
  // creatable: false,
  // deletable: false,
  primaryKeyColType: 'STRING',
  modelType: 'admin',
  defaultFieldsParamExpression: [
    '*',
    'user.*',
    'actioned_user.*',
    'related_records.*',
    'related_records.item.*',
  ],
  columns: {
    id: { type: 'STRING', label: 'ID' },
    // id を生成するための hash key
    uniqueIdentifier: {
      type: 'ARRAY_OF_OBJECT',
      label: 'uniqueIdentifier',
      editable: false,
    },
    // 通知の種類
    category: { type: 'STRING', label: '通知の種類' },
    // 通知の種類Sub
    categorySub: { type: 'STRING', label: '通知のサブタイプ' },
    // 通知先 ユーザID
    user: {
      type: 'RELATIONSHIP_MANY_TO_ONE',
      relationshipManyToOne: {
        collectionName: 'directus_users',
        labelFormatter: (row) => (row ? `${row.first_name} (${row.email})` : ''),
      },
      label: '通知先 ユーザID',
    },
    // Has read?
    readAt: { type: 'DATETIME' },
    // タイトル
    title: { type: 'STRING', label: 'タイトル' },
    // 本文
    body: { type: 'TEXT', label: '本文' },
    // タイトル
    titleForMessageSender: { type: 'STRING', label: '外部ツール向けのタイトル' },
    // 本文
    bodyForMessageSender: { type: 'TEXT', label: '外部ツール向けの本文' },
    // デフォルトのテンプレートを利用するかどうか
    useDefaultTemplate: { type: 'BOOLEAN', label: 'デフォルトテンプレートを利用する' },
    // テンプレートKey
    templateKey: { type: 'STRING', label: 'テンプレートKey' },
    // 外部ツール利用した通知の場合、テンプレートKey
    templateKeyForMessageSender: {
      type: 'STRING',
      label: '外部ツール向けのテンプレートKey',
    },

    // 通知をクリックした場合に遷移する先
    linkUrl: {
      label: 'リンク先URL',
      type: 'STRING',
    },
    relatedRecords: {
      type: 'ARRAY_OF_OBJECT',
      label: '関連レコード',
      columns: {
        modelName: { type: 'STRING', label: 'モデル名' },
        recordId: { type: 'STRING', label: 'レコードID' },
        virtualModelName: { type: 'STRING', label: 'Virtualモデル名' },
      },
    },
    // // Relationship ???
    // related_model_name: { type: 'STRING' },
    // related_record_id: {
    //   label: 'レコードID',
    //   type: 'STRING',
    // },
    // metaData
    metaData: { type: 'ARRAY_OF_OBJECT', visible: false },
    // // 表示するアクション履歴の一覧 ???
    // action_histories: { type: 'ARRAY_OF_OBJECT', visible: false },
  },
}
