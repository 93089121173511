<template>
  <teleport
    to="body"
    :disabled="staticBoolean"
  >
    <b-transition
      :no-fade="true"
      :trans-props="{ duration: 1 }"
      @before-enter="onBeforeEnter"
      @after-enter="onAfterEnter"
      @leave="onLeave"
      @after-leave="onAfterLeave"
    >
      <div
        v-if="shouldShow"
        :id="id"
        ref="element"
        class="modal"
        :class="modalClasses"
        role="dialog"
        :aria-labelledby="`${computedId}-label`"
        :aria-describedby="`${computedId}-body`"
        tabindex="-1"
        v-bind="$attrs"
        @keyup.esc="hide('esc')"
        data-cy-component-key="b-modal"
      >
        <div
          v-if="shouldShow"
          class="modal-dialog"
          :class="modalDialogClasses"
        >
          <div
            class="modal-content"
            :class="contentClass"
          >
            <div
              v-if="!hideHeaderBoolean"
              class="modal-header"
              :class="headerClasses"
            >
              <slot name="header">
                <component
                  :is="titleTag"
                  :id="`${computedId}-label`"
                  class="modal-title"
                  :class="titleClasses"
                >
                  <slot name="title">
                    {{ title }}
                  </slot>
                </component>
                <template v-if="!hideHeaderCloseBoolean">
                  <button
                    v-if="hasHeaderCloseSlot"
                    type="button"
                    v-single-click="() => hide('close')"
                  >
                    <slot name="header-close" />
                  </button>
                  <b-close-button
                    v-else
                    :aria-label="headerCloseLabel"
                    :white="headerCloseWhite"
                    v-single-click="() => hide('close')"
                  />
                </template>
              </slot>
            </div>
            <div
              :id="`${computedId}-body`"
              class="modal-body"
              :class="bodyClasses"
            >
              <slot />
            </div>
            <div
              v-if="!hideFooterBoolean"
              class="modal-footer"
              :class="footerClasses"
            >
              <slot name="footer">
                <slot name="cancel">
                  <b-button
                    v-if="!okOnlyBoolean"
                    type="button"
                    class="btn btn-cancel"
                    :disabled="disableCancel"
                    :size="buttonSize"
                    :variant="cancelVariant"
                    v-single-click="() => hide('cancel')"
                  >
                    {{ cancelTitle }}
                  </b-button>
                </slot>
                <slot name="ok">
                  <b-button
                    type="button"
                    class="btn btn-ok"
                    :disabled="disableOk"
                    :size="buttonSize"
                    :variant="okVariant"
                    v-single-click="() => hide('ok')"
                  >
                    {{ okTitle }}
                  </b-button>
                </slot>
              </slot>
            </div>
          </div>
        </div>
        <slot
          v-if="shouldShow"
          name="backdrop"
        >
          <div
            class="modal-backdrop fade show"
            v-single-click="() => hide('backdrop')"
          />
        </slot>
      </div>
    </b-transition>
  </teleport>
</template>

<script setup lang="ts">
// import type {BModalEmits, BModalProps} from '../types/components'
import { computedEager } from '@vueuse/core'
import type { ColorVariant, InputSize } from 'bootstrap-vue-next'
import {
  BButton,
  BCloseButton,
  BTransition,
  BvTriggerableEvent,
} from 'bootstrap-vue-next'
import { computed, onMounted, ref, toRef, useSlots, watch } from 'vue'

const isEmptySlot = (el: any): boolean => (el?.() ?? []).length === 0
type Booleanish = 'true' | 'false' | '' | boolean
type ClassValue =
  | Array<string | Record<string, boolean | undefined | null>>
  | Record<string, boolean | undefined | null>
  | string
// const useBooleanish = (value: Booleanish) => computedEager(() => Boolean(value))
const useBooleanish = (el) => computedEager(() => Boolean(el?.value || el?.value === ''))
const useId = (id, suffix) =>
  computedEager(
    () => (id || `b-modal-${Math.random().toString(36).substr(2, 10)}`) + `---${suffix}`,
  )
// aria
// autofocus
// close on escape when autofocus

// Note, attempt to return focus to item that openned the modal after close
// Implement auto focus props like autoFocusButton

interface BModalProps {
  displayType?: 'modal' | 'sidebar' | 'sidebar-right' | 'sidebar-left'
  bodyBgVariant?: ColorVariant
  bodyClass?: ClassValue
  bodyTextVariant?: ColorVariant
  busy?: Booleanish
  lazy?: Booleanish
  buttonSize?: InputSize
  cancelDisabled?: Booleanish
  cancelTitle?: string
  cancelVariant?: ColorVariant
  centered?: Booleanish
  contentClass?: ClassValue
  dialogClass?: ClassValue
  footerBgVariant?: ColorVariant
  footerBorderVariant?: ColorVariant
  footerClass?: ClassValue
  footerTextVariant?: ColorVariant
  fullscreen?: boolean | string
  headerBgVariant?: ColorVariant
  headerBorderVariant?: ColorVariant
  headerClass?: ClassValue
  headerCloseLabel?: string
  headerCloseWhite?: Booleanish
  headerTextVariant?: ColorVariant
  hideBackdrop?: Booleanish
  hideFooter?: Booleanish
  hideHeader?: Booleanish
  hideHeaderClose?: Booleanish
  id?: string
  modalClass?: ClassValue
  modelValue?: Booleanish
  noCloseOnBackdrop?: Booleanish
  noCloseOnEsc?: Booleanish
  noFade?: Booleanish
  noFocus?: Booleanish
  okDisabled?: Booleanish
  okOnly?: Booleanish
  okTitle?: string
  okVariant?: ColorVariant
  scrollable?: Booleanish
  // show?: Booleanish
  size?: 'sm' | 'md' | 'lg' | 'xl'
  title?: string
  titleClass?: string
  titleSrOnly?: Booleanish
  titleTag?: string
  static?: Booleanish
  showOnLoad?: Booleanish
}

const props = withDefaults(defineProps<BModalProps>(), {
  busy: false,
  lazy: false,
  displayType: 'modal', // modal, sidebar, sidebar-right, sidebar-left
  buttonSize: 'md',
  cancelDisabled: false,
  cancelTitle: 'Cancel',
  cancelVariant: 'secondary',
  centered: false,
  fullscreen: false,
  headerCloseLabel: 'Close',
  headerCloseWhite: false,
  hideBackdrop: false,
  hideFooter: false,
  hideHeader: false,
  hideHeaderClose: false,
  modelValue: false,
  showOnLoad: false,
  noCloseOnBackdrop: false,
  noCloseOnEsc: false,
  noFade: false,
  noFocus: false,
  okDisabled: false,
  okOnly: false,
  okTitle: 'Ok',
  static: false,
  okVariant: 'primary',
  scrollable: false,
  titleSrOnly: false,
  titleTag: 'h5',
  onClose: () => {},
  onShown: () => {},
})

interface BModalEmits {
  (e: 'update:modelValue', value: boolean): void

  (e: 'show', value: BvTriggerableEvent): void

  (e: 'shown', value: BvTriggerableEvent): void

  (e: 'hide', value: BvTriggerableEvent): void

  (e: 'hidden', value: BvTriggerableEvent): void

  (e: 'hide-prevented'): void

  (e: 'show-prevented'): void

  (e: 'ok', value: BvTriggerableEvent): void

  (e: 'cancel', value: BvTriggerableEvent): void

  (e: 'close', value: BvTriggerableEvent): void
}

const emit = defineEmits<BModalEmits>()

const slots = useSlots()

const computedId = useId(toRef(props, 'id'), 'modal')

const busyBoolean = useBooleanish(toRef(props, 'busy'))
const lazyBoolean = useBooleanish(toRef(props, 'lazy'))
const cancelDisabledBoolean = useBooleanish(toRef(props, 'cancelDisabled'))
const centeredBoolean = useBooleanish(toRef(props, 'centered'))
const hideBackdropBoolean = useBooleanish(toRef(props, 'hideBackdrop'))
const hideFooterBoolean = useBooleanish(toRef(props, 'hideFooter'))
const hideHeaderBoolean = useBooleanish(toRef(props, 'hideHeader'))
const hideHeaderCloseBoolean = useBooleanish(toRef(props, 'hideHeaderClose'))
const modelValueBoolean = useBooleanish(toRef(props, 'modelValue'))
const noCloseOnBackdropBoolean = useBooleanish(toRef(props, 'noCloseOnBackdrop'))
const noCloseOnEscBoolean = useBooleanish(toRef(props, 'noCloseOnEsc'))
const noFadeBoolean = useBooleanish(toRef(props, 'noFade'))
const noFocusBoolean = useBooleanish(toRef(props, 'noFocus'))
const okDisabledBoolean = useBooleanish(toRef(props, 'okDisabled'))
const okOnlyBoolean = useBooleanish(toRef(props, 'okOnly'))
const scrollableBoolean = useBooleanish(toRef(props, 'scrollable'))
const titleSrOnlyBoolean = useBooleanish(toRef(props, 'titleSrOnly'))
const staticBoolean = useBooleanish(toRef(props, 'static'))

const isActive = ref(!!(props.showOnLoad || modelValueBoolean.value))
const element = ref<HTMLElement | null>(null)
const lazyLoadCompleted = ref(false)

const modalClasses = computed(() => [
  props.modalClass,
  `modal-display-type--${props.displayType}`,
  {
    fade: !noFadeBoolean.value,
    show: true,
  },
])

const shouldShow = computed(() => {
  return isActive.value
})

const lazyShowing = computed(
  () =>
    lazyBoolean.value === false ||
    (lazyBoolean.value === true && lazyLoadCompleted.value === true) ||
    (lazyBoolean.value === true && modelValueBoolean.value === true),
)

const hasHeaderCloseSlot = computed(() => !isEmptySlot(slots['header-close']))

const modalDialogClasses = computed(() => [
  props.dialogClass,
  {
    'modal-fullscreen': props.fullscreen === true,
    [`modal-fullscreen-${props.fullscreen}-down`]: typeof props.fullscreen === 'string',
    [`modal-${props.size}`]: props.size !== undefined,
    'modal-dialog-centered': centeredBoolean.value,
    'modal-dialog-scrollable': scrollableBoolean.value,
  },
])

const bodyClasses = computed(() => [
  props.bodyClass,
  {
    [`bg-${props.bodyBgVariant}`]: props.bodyBgVariant !== undefined,
    [`text-${props.bodyTextVariant}`]: props.bodyTextVariant !== undefined,
  },
])

const headerClasses = computed(() => [
  props.headerClass,
  {
    [`bg-${props.headerBgVariant}`]: props.headerBgVariant !== undefined,
    [`border-${props.headerBorderVariant}`]: props.headerBorderVariant !== undefined,
    [`text-${props.headerTextVariant}`]: props.headerTextVariant !== undefined,
  },
])

const footerClasses = computed(() => [
  props.footerClass,
  {
    [`bg-${props.footerBgVariant}`]: props.footerBgVariant !== undefined,
    [`border-${props.footerBorderVariant}`]: props.footerBorderVariant !== undefined,
    [`text-${props.footerTextVariant}`]: props.footerTextVariant !== undefined,
  },
])

const titleClasses = computed(() => [
  props.titleClass,
  {
    ['visually-hidden']: titleSrOnlyBoolean.value,
  },
])
const disableCancel = computed<boolean>(
  () => cancelDisabledBoolean.value || busyBoolean.value,
)
const disableOk = computed<boolean>(() => okDisabledBoolean.value || busyBoolean.value)

const buildTriggerableEvent = (
  type: string,
  opts: Partial<BvTriggerableEvent> = {},
): BvTriggerableEvent =>
  new BvTriggerableEvent(type, {
    cancelable: false,
    target: element.value || null,
    relatedTarget: null,
    trigger: null,
    ...opts,
    componentId: computedId.value,
  })

const hide = (trigger = '') => {
  if (
    (trigger === 'backdrop' && noCloseOnBackdropBoolean.value) ||
    (trigger === 'esc' && noCloseOnEscBoolean.value)
  ) {
    return // do nothing
  }
  // const event = buildTriggerableEvent('hide', { cancelable: trigger !== '', trigger })
  const event = buildTriggerableEvent('hide', { cancelable: true, trigger })

  if (trigger === 'ok') {
    emit(trigger, event)
  }
  if (trigger === 'cancel') {
    emit(trigger, event)
  }
  if (trigger === 'close') {
    emit(trigger, event)
  }
  emit('hide', event)

  if (event.defaultPrevented) {
    emit('update:modelValue', true)
    isActive.value = true
    emit('hide-prevented')
    return
  }
  emit('update:modelValue', false)
  isActive.value = false
}

// TODO: If a show is prevented, it will briefly show the animation. This is a bug
// I'm not sure how to wait for the event to be determined. Before showing
const show = () => {
  const event = buildTriggerableEvent('show', { cancelable: true })
  emit('show', event)
  if (event.defaultPrevented) {
    emit('update:modelValue', false)
    emit('show-prevented')
    return
  }
  isActive.value = true
  emit('update:modelValue', true)
}

const onBeforeEnter = () => show()
const onAfterEnter = () => {
  isActive.value = true
  emit('shown', buildTriggerableEvent('shown'))
  if (lazyBoolean.value === true) lazyLoadCompleted.value = true
}
const onLeave = () => (isActive.value = false)
const onAfterLeave = () => {
  emit('hidden', buildTriggerableEvent('hidden'))
  if (lazyBoolean.value === true) lazyLoadCompleted.value = false
}

onMounted(() => {
  if (shouldShow.value) {
    emit('shown', buildTriggerableEvent('shown'))
  }
})

watch(
  modelValueBoolean,
  (newValue) => {
    if (newValue === true && !noFocusBoolean.value && element.value !== null)
      element.value.focus()
  },
  { flush: 'post' },
)
defineExpose({
  show,
  hide,
})
</script>

<script lang="ts">
export default {
  name: 'BModal',
  inheritAttrs: false,
}
</script>

<style lang="scss" scoped>
.modal {
  display: block;
}

.modal-dialog {
  z-index: 1051;
}
</style>
