<template>
  <component
    v-if="initialized"
    :is="resolvedComponentDef"
  ><slot/></component>
</template>

<script lang="ts">
import { ColumnDefByColName } from '../../../common/$models/ModelDef'
import { registerComposableComponentSettings } from '../../../plugins/ComposableComponents'

const htmlTemplatePartDefault = `<div
  class="border px-2 py-1 rounded-3 bg-white d-flex gap-2 align-items-center"
  v-if="initialized"
  >
  CustomVueComponent:
  <a class="btn btn-primary btn-sm" href="" @click.prevent="() => clicked()"><ficon type="bell"/>クリック</a>
  <!-- 子コンポーネントを表示 -->
  <div class="sample-class--custom-vue-component--children-slot--wrapper">
    <slot></slot>
  </div>
</div>`

const vueComponentDefinitionResolveFunctionDefault = `/**
 * VueComponentDefinition
 * @return {Promise<any>}
 */
return async ({ thisVueInstance }) => {
  console.log({ thisVueInstance })
  const componentDef = {
    // htmlTemplatePart を 利用しない場合には、ここにテンプレートを記述
    // template: '<div>VueComponent <a href="" @click.prevent="() => clicked()">クリック</a></div>',
    props: {},
    data() {
      return {
        initialized: false
      }
    },
    async mounted() {
      this.$nextTick(() => {
        this.initialized = true
      })
    },
    computed: {
      // filtersByKeys() { // 一覧表示時 の filter を 取得する例
      //   return this.$provides.ComposableDataListServiceInstance?.filtersByKeys
      // }
    },
    methods: {
      clicked() {
        console.log('this.$provides:', this.$provides)
        // this.$provides.ComposableDataListServiceInstance // 一覧表示配下の場合
        // this.$provides.ModelFormService // ModelForm 配下の場合
        $core.$toast.info('clicked!!')
      }
    },
  }
  return componentDef
}
`

/**
 * VueComponent コンテンツの設定カラム
 */
export const composableComponentTextContentConfigColumns: ColumnDefByColName = {
  htmlTemplatePart: {
    beforeComponents: [
      {
        template: `<HelpDoc>カスタム Vue component を 定義可能です。<br/><span class="small">※ 汎用的なコンポーネントの場合は フロントカスタムHooks から Global component として登録することを推奨します。</span></HelpDoc>`,
      },
    ],
    label: 'Vue <template/> (HTML) part',
    type: 'TEXT',
    inputComponent: 'CodeEditor',
    inputAttrs: {
      rows: 8,
      language: 'html',
      initCode: htmlTemplatePartDefault,
    },
    defaultValue: () => htmlTemplatePartDefault,
  },
  vueComponentDefinitionResolveFunction: {
    label: 'VueComponent 定義',
    type: 'TEXT',
    inputComponent: 'CodeEditor',
    inputAttrs: {
      rows: 8,
      initCode: vueComponentDefinitionResolveFunctionDefault,
    },
    defaultValue: () => vueComponentDefinitionResolveFunctionDefault,
  },
}
const name = 'CustomVueComponent'
registerComposableComponentSettings(name, {
  label: name,
  category: 'Basic',
  configColumns: composableComponentTextContentConfigColumns,
  defaultProps: {
    htmlTemplatePart: htmlTemplatePartDefault,
    vueComponentDefinitionResolveFunction: vueComponentDefinitionResolveFunctionDefault,
  },
  hasDefaultSlot: true,
})

/**
 * 独自 Vue Component を Builder 内で 設定・表示するためのコンポーネント
 */
export default {
  name: name,
  props: {
    htmlTemplatePart: {
      type: String,
      default: htmlTemplatePartDefault,
    },
    vueComponentDefinitionResolveFunction: {
      type: [Function, String],
      default: vueComponentDefinitionResolveFunctionDefault,
    },
  },
  data() {
    return {
      resolvedComponentDef: null,
      initialized: false,
    }
  },
  computed: {},
  async mounted() {
    if (!this.vueComponentDefinitionResolveFunction) {
      return
    }
    await this.$nextTick()
    this.resolvedComponentDef = await this.resolveComponentDef()
    if (this.resolvedComponentDef) {
      this.initialized = true
    }
  },
  methods: {
    async getVueComponentDefinitionResolveFunctionCallable(): Promise<
      () => Promise<any>
    > {
      try {
        debugger
        return typeof this.vueComponentDefinitionResolveFunction === 'string'
          ? $core.$utils.executeStringDefinedFunction({
            functionString: this.vueComponentDefinitionResolveFunction,
            functionArgValues: {},
            bindingThisObject: this,
            quiet: true,
            errorThrow: true,
          })
          : this.vueComponentDefinitionResolveFunction
      } catch (e) {
        console.error(e)
        return null
      }
    },
    async resolveComponentDef() {
      try {
        const callableResolver =
          await this.getVueComponentDefinitionResolveFunctionCallable()
        if (!callableResolver) {
          return null
        }
        const resolvedComponentDef = await callableResolver({ thisVueInstance: this })
        if (!resolvedComponentDef.template && this.htmlTemplatePart) {
          resolvedComponentDef.template = this.htmlTemplatePart
        }
        return resolvedComponentDef
      } catch (e) {
        console.error(e)
        return null
      }
    },
  },
}
</script>
