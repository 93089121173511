<template>
  <div
    class="model-form-page model-form-page-edit"
    :class="wrapperClass"
  >
    <div
      v-if="!disableHeader"
      :class="`model-form-page--header model-form-page-edit--header ${wrapperClass}-header`"
    >
      <historyBackButton />
      <app-hookable-component
        :resolveHookName="`$CORE.admin.resolveComponent.model.${
          calcedVirtualModelName || modelName
        }.edit.header.title`"
        class="model-form-page--header-title"
      >
        <slot name="title">
          <span v-if="pageTitle">{{ pageTitle }}</span>
          <span v-else>
            {{ tableLabel }} -
            <strong> {{ idValue }}</strong>
          </span>
        </slot>
      </app-hookable-component>
      <slot name="titleAppend"></slot>
      <app-hookable-component
        :resolveHookName="`$CORE.admin.resolveComponent.model.${
          calcedVirtualModelName || modelName
        }.edit.header.before`"
      />

      <displayRawData
        class="float-right small"
        :data="record"
      />
      <a
        v-if="!$route.params.id"
        class="float-right small pr-2 model-edit-open-in-new-tab-link"
        :href="`${location.pathname}#/m/${modelName}/edit/${id}${
          calcedVirtualModelName ? `?virtualModel=${calcedVirtualModelName}` : ''
        }`"
        target="_blank"
      >
        別画面で開く
        <ficon type="external-link-alt" />
      </a>
      <displayRecordRevisionsList
        class="float-right small pr-2"
        :modelName="modelName"
        :record="record"
      />
      <app-hookable-component
        :resolveHookName="`$CORE.admin.resolveComponent.model.${
          calcedVirtualModelName || modelName
        }.edit.header.after`"
      />
    </div>
    <div
      :class="`${wrapperClass}-body`"
      class="model-form-page--body model-form-page-edit--body"
    >
      <app-hookable-component
        v-if="initialized"
        v-bind="modelFormBindProps"
        :key="$route.fullPath"
        ref="AppHookableComponent"
      />
      <div
        v-else
        class="p-4 text-center"
      >
        <loading />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ModelFactory } from '../../common/$models'
import { ModelDef } from '../../common/$models/ModelDef'
import { detectVModelNameWithRecord } from '../../common/$virtualModels'
import displayRawData from '../ModelView/displayRawData.vue'
import displayRecordRevisionsList from '../RecordRevisions/displayRecordRevisionsList.vue'

const defaultNotFoundCallbackFunction = (vueInstance) => {
  $core.$toast.errorToast(
    `データが存在しないようです。 ${vueInstance.model.primaryKeyColName}:${vueInstance.id}`,
  )
}

export default {
  name: 'ModelEditPage',
  components: {
    displayRawData,
    displayRecordRevisionsList,
  },
  props: {
    modelName: { required: true },
    virtualModelName: { required: false, default: null },
    onSubmitFunction: { required: false },
    columns: { required: false, default: false },
    passedId: { required: false, default: null },
    successCallback: { required: false, default: null },
    deleteCallback: { required: false, default: null },
    modalId: { required: false, type: String },
    wrapperClass: { default: '_card' },
    disableHeader: { default: false },
    readOnlyMode: { required: false, default: null },
    overwriteValues: { required: false, default: null },
    pageTitle: { required: false, default: null },
    /**
     * ModelForm に bind する 追加の props を オブジェクトとして渡す
     */
    modelFormProps: {
      required: false,
      default: () => ({}),
      type: Object,
    },
    /**
     * レコードの状態に応じて VirtualModel を自動判定する機能を有効にする場合にのみ true を設定する
     */
    enableAutoJudgeVirtualModel: { required: false, default: false },
  },
  data() {
    return {
      record: {},
      initialized: false,
      defaultValues: {},
      readOnlyModeCalced: null, // Record state based の 編集権限を判定するため
      disableDeleteCalced: null, // Record state based の 編集権限を判定するため
      autoDetectedVirtualModelName: null as string | null, // virtualModelName が自動判定された場合にセットされる
    }
  },
  computed: {
    id(): string | number {
      return this.passedId || this.$route.params.id
    },
    idValue(): string | number {
      const idValueDisplayFunction = $core.$configVars.get(
        `$model.${this.modelName}.idValueDisplayFunction`,
        null,
      )
      if (idValueDisplayFunction) {
        return idValueDisplayFunction(this.record)
      }
      if (this.record.idUnHashed) {
        return this.record.idUnHashed
      }
      if (this.model.primaryKeyCol) {
        return this.record[this.model.primaryKeyColName]
      }
      return this.id
    },
    calcedVirtualModelName(): string | null {
      return this.virtualModelName || this.autoDetectedVirtualModelName
    },
    virtualModel(): ModelFactory {
      return $core.$virtualModels?.[this.calcedVirtualModelName] || null
    },
    model(): ModelFactory {
      return $core.$models[this.modelName]
    },
    resolveHookName(): string {
      return `$CORE.admin.resolveComponent.model.${
        this.calcedVirtualModelName || this.modelName
      }.pages.edit`
    },
    tableLabel(): string {
      // return {{ virtualModel ? virtualModel.tableLabel : model.tableLabel }}
      return this.virtualModel ? this.virtualModel.tableLabel : this.model.tableLabel
    },
    readOnlyModeComputed(): boolean {
      return this.readOnlyModeCalced === true || this.readOnlyMode === true
    },
    /**
     * ModelForm.vue に 渡す すべての props をまとめる
     *
     * :modelName="modelName"
     * :record="record"
     * :onSubmitFunction="onSubmitFunction"
     * :columns="columns"
     * :virtualModelName="virtualModelName"
     * :readOnlyMode="readOnlyMode"
     * :modalId="modalId"
     * @successCallback="(updatedData) => refreshData(updatedData)"
     * @deleteCallback="(deletedData) => execDeleteCallback(deletedData)"
     * :resolveHookName="`$CORE.admin.resolveComponent.model.${
     *   virtualModelName || modelName
     * }.pages.edit`"
     * :defaultComponentResolver="() => mainFormComponent()"
     * :key="$route.fullPath"
     */
    modelFormBindProps() {
      return {
        modelName: this.modelName,
        record: this.record,
        onSubmitFunction: this.onSubmitFunction,
        columns: this.columns,
        virtualModelName: this.calcedVirtualModelName,
        readOnlyMode: this.readOnlyModeComputed,
        disableDelete:
          this.readOnlyModeComputed === true || this.disableDeleteCalced === true,
        modalId: this.modalId,
        onSuccessCallback: (updatedData) => this.refreshData(updatedData),
        onDeleteCallback: (deletedData) => this.execDeleteCallback(deletedData),
        resolveHookName: this.resolveHookName,
        defaultComponentResolver: () => this.mainFormComponent(),
        key: this.$route.fullPath,
        ...this.modelFormProps,
      }
    },
    successCallbackHookName(): string {
      return `${this.resolveHookName}.successCallback`
    },
    isUpdatableByRecordStateFunction(): ModelDef['isUpdatableByRecordState'] | null {
      return (
        this.virtualModel?.isUpdatableByRecordState ||
        this.model.isUpdatableByRecordState ||
        null
      )
    },
  },
  created() {
    this.$nextTick(async () => {
      this.fetch()
    })
  },
  methods: {
    async fetch() {
      this.defaultValues =
        this.virtualModel && this.virtualModel.defaultValues
          ? await this.virtualModel.defaultValues()
          : this.model && this.model.defaultValues
            ? await this.model.defaultValues()
            : {}
      this.$nextTick(async () => {
        try {
          // ここで datasource が問題になることはない
          const overwriteValues = this.overwriteValues || {}
          // Form data として fetch しておく フィールドを指定する
          const fetchFieldsOnEdit = this.virtualModel?.fetchFieldsOnEditForm ||
            this.model?.fetchFieldsOnEditForm || ['*']
          this.record = Object.assign(
            await this.model.findById(
              this.id,
              { virtualModelName: this.virtualModelName },
              fetchFieldsOnEdit,
            ),
            overwriteValues,
          )
          // virtualModelName が 設定されていないなら... 自動判定する
          if (this.enableAutoJudgeVirtualModel && !this.virtualModelName) {
            try {
              this.autoDetectedVirtualModelName = detectVModelNameWithRecord(
                this.modelName,
                this.record,
              )
              console.log(
                `this.autoDetectedVirtualModelName:`,
                this.autoDetectedVirtualModelName,
              )
              debugger
            } catch (e) {
              console.error(`[ModelEditPage] Error on auto detect virtualModelName:`, e)
            }
          }
          try {
            /**
             * レコードの状態に応じて 編集権限を判定する by 関数
             */
            if (typeof this.isUpdatableByRecordStateFunction === 'function') {
              const editableJudgeByRecordState =
                await this.isUpdatableByRecordStateFunction(this.record, this)
              if (editableJudgeByRecordState === false) {
                this.readOnlyModeCalced = true
              }
            }
          } catch (e) {
            console.error(
              `[model.isUpdatableByRecordState] Failed to judge editable by record state:`,
              e,
              { model: this.model, record: this.record },
            )
          }
          try {
            /**
             * レコードの状態に応じて deletable権限を判定する by 関数
             */
            const deletableJudgeByRecordState =
              typeof this.model.isDeletableByRecordState === 'function'
                ? await this.model.isDeletableByRecordState(this.record, this)
                : true
            if (deletableJudgeByRecordState === false) {
              this.disableDeleteCalced = true
            }
          } catch (e) {
            console.error(
              `[model.isDeletableByRecordState] Failed to judge deletable by record state:`,
              e,
              { model: this.model, record: this.record },
            )
          }

          this.initialized = true
        } catch (e) {
          // メッセージ表示の制御
          $core.$configVars.get(
            'model.editPage.showNotFoundMessage',
            defaultNotFoundCallbackFunction,
          )(this)
        }
      })
    },
    refreshData(updatedData) {
      this.initialized = false
      this.$nextTick(async () => {
        if (this.successCallback && typeof this.successCallback === 'function') {
          this.successCallback(updatedData, this)
          return
        }
        if ($core.$appHook.hasHook(this.successCallbackHookName)) {
          $core.$appHook.emit(this.successCallbackHookName, {
            data: updatedData,
            model: this.model,
            virtualModel: this.virtualModel,
            vueInstance: this,
          })
          return
        }
        this.fetch()
      })
    },
    execDeleteCallback(deleted) {
      if (this.deleteCallback && typeof this.deleteCallback === 'function') {
        this.deleteCallback(deleted, this)
      }
    },
    // Dynamic component loading with virtualModelName
    async mainFormComponent() {
      const mainModel = this.virtualModel || this.model
      if (mainModel.groupedEditKeys) {
        return 'ModelFormGroupedEdit'
      }
      return 'ModelForm'
    },
  },
}
</script>
