import { $directusSdk } from '../$directusSdk'
import { registerFunctionLoggedInExecuteOnce } from '../../front/LoggedInExecuteOnceServiceBase'
import { ModelFrontSideAppHooks } from './frontSideAppHooksModel'

class FrontSideAppHooksRegistrator {
  frontSideAppHooks: ModelFrontSideAppHooks[]

  constructor() {
    this.frontSideAppHooks = []
    registerFunctionLoggedInExecuteOnce({
      appHookFunctionRegisterKey: 'FrontSideAppHooksRegistrator',
      onceLoadFunction: userData => {
        if (!userData) {
          return
        }
        return this.loadFrontSideAppHooks()
      }, // 初回ロード時に実行する関数を登録
      useAwaitOnLoad: true, // ロード時にawaitするかどうか ( => $core.$embAuth.user が初期化する前に実施するか、または、初期化後に実施で良い場合はfalse)
      hookPriority: 99999
    })
  }

  async loadFrontSideAppHooks(): Promise<void> {
    try {
      await this._fetchFrontSideAppHooks()
      this._registerFrontSideAppHooks()
    } catch (e) {
      console.error(
        `[FrontSideAppHooksRegistrator.ts] Failed to load frontSideAppHooks. The table might not exist. If so you should run "yarn syncModels --models=frontSideAppHooks" e.message: ${e.message}`,
      )
    }
  }

  async _fetchFrontSideAppHooks(): Promise<void> {
    // @ts-ignore
    this.frontSideAppHooks = (
      await $directusSdk.items('frontSideAppHooks').readByQuery({ limit: -1 })
    ).data
  }

  _registerFrontSideAppHooks(): void {
    for (const hook of this.frontSideAppHooks) {
      registerAppHookBehaviorWithFrontSideAppHookRecord(hook)
    }
  }
}

export const registerAppHookBehaviorWithFrontSideAppHookRecord = (record: ModelFrontSideAppHooks) => {
  try {
    // const func = new Function(`{ return async function (args) { ${record.function} } };`)
    const func = $core.$utils.executeStringDefinedFunction({
      functionString: record.function,
      functionArgValues: {
        args: {},
      },
      returnAsExecutableFunction: true,
      errorThrow: true
    })
    $core.$appHook.on(
      record.appHookName,
      args => {
        return func({ args })
      },
      record.functionName,
      record.priority || undefined,
    )
  } catch (e) {
    console.error(`FrontSideAppHooks - ${record.appHookName}() Error:`, e, { record })
    $core.$errorReporter.r(
      `FrontSideAppHooks - ${record.appHookName}() Error: ${e.message}`,
      e,
    )
  }
}

export const fetchAndRegisterFrontSideAppHooks = () => new FrontSideAppHooksRegistrator()
