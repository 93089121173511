import { markRaw, toRaw } from 'vue'
import { $appHook } from '../$appHook'
import { DBDefinedModelDefinitionColumns } from '../modelDefinitions/DBDefinedModelDefinitionColumns'
import { validationConfigTypes } from '../modelDefinitions/validationConfigs'
import { singletonInstanceSummoner } from '../singletonInstanceSummoner'
import { convertModelDefLikeDataToSaveableDBDefinedModelDefinitionColumns } from './convertModelDefLikeDataToSaveableDBDefinedModelDefinitionColumns'
import { createNewRecordWithColumnDefs } from './createNewRecordWithColumnDefs'
import { ColumnDefGroupDef, ModelDef } from './ModelDef'
import { ModelFactory } from './ModelFactory'
import {
  generateModelTypeDefinitions,
  ModelTypeDefinitionGenerateOptions,
} from './ModelTypeDefinitionGenerator'

/**
 * LoadしたModelsを保持するObject
 * ModelDef を loadModel() できる
 */
class ModelsLoader {
  $models: Record<string, ModelFactory>
  _autoLoaded: boolean
  modelNameSelectOptions: { label; value }[]
  readonly validationConfigTypes = validationConfigTypes

  constructor() {
    this.$models = {}
    this._autoLoaded = false
    this.modelNameSelectOptions = []
  }

  async loadModel(modelDef: ModelDef) {
    return this.loadModels([modelDef])
  }

  async loadModels(modelDefs: ModelDef[]) {
    /**
     * modelDefs.beforeLoad で、ModelDefを拡張できる。 ※ 例: コメント用のModelDefを追加する等
     */
    modelDefs = await $appHook.emit('modelDefs.beforeLoad', modelDefs)
    for (let i = 0; modelDefs.length > i; i++) {
      await this._convertModelDefToModelInstance(modelDefs[i])
    }
    this._callbackAfterModelLoad()
  }

  private async _convertModelDefToModelInstance(modelDef: ModelDef) {
    this.$models[modelDef.tableName] = markRaw(toRaw(new ModelFactory(modelDef)))
  }

  get modelNames(): string[] {
    return Object.keys(this.$models)
  }

  private _callbackAfterModelLoad() {
    this.__genModelNameSelectOptions()
  }

  private __genModelNameSelectOptions(ignoreAdminTypeModel = true): void {
    this.modelNameSelectOptions = this.modelNames.reduce((res, modelName) => {
      if (this.$models[modelName] && this.$models[modelName].modelType !== 'admin') {
        res.push({
          label: this.$models[modelName].tableLabel
            ? `${this.$models[modelName].tableLabel} (${modelName})`
            : modelName,
          value: modelName,
        })
      }
      return res
    }, [])
  }

  public getAllModelNameSelectOptions() {
    return this.modelNames.reduce((res, modelName) => {
      if (this.$models[modelName]) {
        res.push({
          label: this.$models[modelName].tableLabel
            ? `${this.$models[modelName].tableLabel} (${modelName})`
            : modelName,
          value: modelName,
        })
      }
      return res
    }, [])
  }

  static get instance(): ModelsLoader {
    return singletonInstanceSummoner('ModelsLoader', ModelsLoader)
  }

  public async removeDefaultPropsFromDBDefinedModelDefinitions(
    dbDefinedModels: DBDefinedModelDefinitionColumns[],
  ): Promise<DBDefinedModelDefinitionColumns[]> {
    return removeDefaultPropsFromDBDefinedModelDefinitions(dbDefinedModels)
  }

  /**
   * ModelDefLikeData の 配列 を 保存可能な DBDefinedModelDefinitionColumns の配列 に変換する
   * @param modelDefLikeData
   */
  public async convertModelDefLikeDataToSaveableDBDefinedModelDefinitionColumns(
    modelDefLikeData: any[],
  ): Promise<Partial<DBDefinedModelDefinitionColumns>[]> {
    return convertModelDefLikeDataToSaveableDBDefinedModelDefinitionColumns(
      modelDefLikeData,
    )
  }

  /**
   * Type定義 を export する
   * @param models
   * @param options
   */
  generateModelTypeDefinitions(
    models: ModelDef[] | ModelFactory[],
    options = {},
    downloadAsFile = false,
  ): string {
    return generateModelTypeDefinitions(models as any, options, downloadAsFile)
  }

  /**
   * 全てのモデルのType定義を生成
   */
  generateAllModelsTypeDefinitions({
    includeAdminModel = false,
    downloadAsFile = false,
    generateOptions = {},
  }: {
    includeAdminModel?: boolean
    downloadAsFile?: boolean
    generateOptions?: ModelTypeDefinitionGenerateOptions
  } = {}): string {
    const allModels = Object.values(this.$models)
    const targetModels = includeAdminModel
      ? allModels
      : allModels.filter((model) => model.modelType !== 'admin')
    return this.generateModelTypeDefinitions(
      targetModels,
      generateOptions,
      downloadAsFile,
    )
  }
}

export type TModelObjects = { [modelName: string]: ModelFactory }
export const $modelsLoader = ModelsLoader.instance

/**
 * DBDefinedModelDefinitionColumns の定義から、デフォルト値を削除する
 */
const removeDefaultPropsFromDBDefinedModelDefinitions = async (
  dbDefinedModels: DBDefinedModelDefinitionColumns[],
) => {
  const modelDefaultValues = await $core.$models.modelDefinitions.createNew()
  const colDefaultValues = await createNewRecordWithColumnDefs(
    $core.$models.modelDefinitions.columns.columns.columns,
  )
  console.log({ modelDefaultValues, colDefaultValues })
  const colDefaultValuesKeys = Object.keys(colDefaultValues)
  const modelDefaultValuesKeys = Object.keys(modelDefaultValues)
  const ignoreModelKeys = ['primaryKeyColType']
  // TODO: validates について 要検討
  const mustRemoveModelKeys = [
    'validates',
    'userCreated',
    'userUpdated',
    'createdAt',
    'updatedAt',
    'metaData',
  ]
  const ignoreColKeys = ['type']
  const mustRemoveColKeys = ['numeric_precision', 'numeric_scale', 'tempKey']
  return dbDefinedModels.map((model) => {
    mustRemoveModelKeys.forEach((key) => {
      delete model[key]
    })
    modelDefaultValuesKeys.forEach((key) => {
      if (ignoreModelKeys.includes(key)) {
        return
      }
      if (model[key] === modelDefaultValues[key]) {
        delete model[key]
      }
    })
    model.columns = model.columns.map((col) => {
      mustRemoveColKeys.forEach((key) => {
        delete col[key]
      })
      return colDefaultValuesKeys.reduce((colRes, key) => {
        if (ignoreColKeys.includes(key)) {
          return colRes
        }
        if (col[key] === colDefaultValues[key]) {
          delete col[key]
        }
        return colRes
      }, col)
    })
    return model
  })
}
