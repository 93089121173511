import { ModelDef } from '../../../common/$models/ModelDef'
import { convertDBDefinedModelToModelDefObject } from '../../../common/modelDefinitions/convertDBDefinedModelToModelDefObject'
import { DBDefinedModelDefinitionColumns } from '../../../common/modelDefinitions/DBDefinedModelDefinitionColumns'
import { ModelJunctionUsersAndOrgsAndPositions } from './junctionUsersAndOrgsAndPositions'

const tableName = 'organizations'

export type ModelOrganizations = {
  code: string
  parent: string | ModelOrganizations | null
  name: string
  category: string | null
  sort: number
  users?: ModelJunctionUsersAndOrgsAndPositions[]
}

/**
 * DBDefinedModelDefinitionColumns形式でのorganizationsモデル定義
 */
export const organizations__dbDefineable: DBDefinedModelDefinitionColumns = {
  id: '7ec5cc79-1671-4fb9-aca0-d0e7a7b601fb',
  tableName,
  tableLabel: '組織',
  tableComment: '',
  defaultSort_key: 'sort',
  defaultSort_order: 'asc',
  primaryKeyColType: 'UUID',
  otherModelAttributes: `/**
 * @type {ModelDefPartial<ModelDataTypes['organizations']>}
 */
const modelDef = {
  modelType: 'admin',
  beforeSave: function (row) {
    if (row.parent && (row.parent?.id || row.parent) === row.id) {
      delete row.parent // 自分自身を親に設定している場合は親を削除
    }
    return row
  }
}
return modelDef`,
  columns: [
    {
      key: 'id',
      type: 'UUID',
      visible: false,
      editable: false,
      visibleOnIndex: false,
    },
    {
      key: 'code',
      label: '組織コード',
      type: 'STRING',
      unique: true,
      validationConfigs: [{ validationType: 'notEmpty' }],
    },
    {
      key: 'parent',
      label: '親組織',
      type: 'RELATIONSHIP_MANY_TO_ONE',
      relationshipManyToOne_collectionName: tableName,
      relationshipManyToOne_allowEmptySelection: true,
      relationshipManyToOne_labelFormatter: 'return `${row.name} (${row.code})`',
      relationshipManyToOne_onDeleteDbRestriction: 'RESTRICT',
      validationConfigs: [
        {
          validationType: 'customFunc',
          customCodeExpression:
            'return value === row.id ? "組織は自分自身を親に設定できません" : false',
        },
      ],
    },
    {
      key: 'name',
      label: '組織名',
      type: 'STRING',
      unique: true,
      validationConfigs: [{ validationType: 'notEmpty' }],
    },
    {
      key: 'category',
      label: '区分',
      type: 'STRING',
      selectionsWithSelectOptionsMasterGroupName: '組織区分',
      createSelectOptionsMasterOnAddNewSelection: true,
      listItemAttrs_style_maxWidth: 'max-content',
    },
    {
      key: 'sort',
      label: '表示順 (昇順)',
      type: 'NUMBER',
      listItemAttrs_style_maxWidth: 'max-content',
    },
    {
      key: 'users',
      label: '所属ユーザー',
      type: 'RELATIONSHIP_ONE_TO_MANY',
      visible: false,
      relationshipOneToMany_collectionName: 'junctionUsersAndOrgsAndPositions',
      relationshipOneToMany_fieldName: 'organization',
      otherColAttributes: `return {
        inputAttrs: { wrapperClass: 'col-12' }
      }`,
    },
  ],
}

/**
 * ModelDef形式でのorganizationsモデル定義
 */
export const organizations: ModelDef = convertDBDefinedModelToModelDefObject(
  organizations__dbDefineable,
)
