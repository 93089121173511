<template>
  <input
    type="text"
    class="form-control d-inline-block"
    placeholder="キーワード検索"
    :value="keyword"
    @change="changeKeyword"
  />
</template>
<script lang="ts">
import { inject, PropType } from 'vue'
import { ColumnDef, ColumnTypes } from '../../../../common/$models/ModelDef'
import { getRelationColumnLabelAndKey } from '../../../../common/modelDefinitions/getRelationColumnLabelAndKey'
import { registerComposableComponentSettings } from '../../../ComposableComponents'
import { ComposableDataListService } from '../ComposableDataListService'
import {
  DataListKeywordFilterService,
  isKeywordSearchableCol,
} from '../DataListKeywordFilterService'
import { getComposableDataListFromSettingColumnInstance } from '../patrials/dataListComposableColumnNameSelectionSettingColumnDef'

const name = 'DataListKeywordFilter'
registerComposableComponentSettings(name, {
  label: name,
  hasDefaultSlot: true,
  configColumns: {
    keywordSearchTargetColumns: {
      label: 'キーワード検索対象カラム',
      type: 'MULTISELECT',
      selections: (
        record: any,
        currentValue: any,
        initialValue: any,
        recordRoot: any,
        callerVueInstance: any,
      ) => {
        const composableDataList = getComposableDataListFromSettingColumnInstance(
          callerVueInstance,
          'ComposableDataList',
        )
        if (!composableDataList) {
          return []
        }
        const model =
          callerVueInstance.$core.$models[composableDataList.configuredProps.modelName]
        if (!model) {
          return []
        }
        const columns: ColumnDef[] = Object.values(model.columns)
        const selections: { value: string; label: string }[] = []
        for (const column of columns) {
          if (!isKeywordSearchableCol(column)) {
            continue
          }
          // @ts-ignore
          if (
            [
              ColumnTypes.RelationshipManyToOne,
              ColumnTypes.RelationshipOneToMany,
            ].indexOf(column.type) >= 0
          ) {
            const res = getRelationColumnLabelAndKey(column, column.name, column.label)
            res.forEach(({ label, key }) => {
              if (!!key && !!label) {
                selections.push({
                  value: key,
                  label: label,
                })
              }
            })
          } else {
            selections.push({
              value: column.name,
              label: column.label,
            })
          }
        }
        return selections
      },
      customLabel: (val) => {
        if (val.value) {
          return val.label || val.value
        } else {
          return val
        }
      },
    },
  },
  defaultProps: {},
})

export default {
  name,
  props: {
    keywordSearchTargetColumns: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup() {
    return {
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
      ),
    }
  },
  data() {
    return {
      service: null as DataListKeywordFilterService | null,
      keyword: '',
    }
  },
  watch: {
    keywordSearchTargetColumns: {
      handler() {
        if (this.service) {
          this.service.keywordSearchTargetColumns = this.keywordSearchTargetColumns.map(
            (item) => (item.value ? item.value : item),
          )
        }
      },
      immediate: true,
    },
  },
  components: {},
  mounted() {
    if (this.ComposableDataListServiceInstance) {
      this.service = new DataListKeywordFilterService({
        DataListService: this.ComposableDataListServiceInstance,
        keywordSearchTargetColumns:
          this.keywordSearchTargetColumns?.map((item) =>
            item.value ? item.value : item,
          ) || [],
        vueInstance: this,
      })
    }
  },
  methods: {
    async changeKeyword(event) {
      this.value = event.target.value
      this.service.refreshQuery(this.value)
    },
  },
}
</script>
