// @ts-nocheck
import { ModelDef } from '../../common/$models/ModelDef'
import { addModelDoc } from '../../plugins/HelpDoc/coreDocsHelperFunctions'
import { ModelDirectusActivity } from '../DirectusActivity/model_directus_activity'
import { $restoreRevisionService } from './RestoreRevisionService'

const modelName = 'directus_revisions'
addModelDoc(
  modelName,
  `各種データの変更履歴 (日時・更新前後・更新者) および削除履歴を参照可能です。また、履歴の時点におけるデータの状態を復元可能です。`,
)

const recordRevisionLabelFormatter = (row) => {
  const model = $core.$models[row.collection]
  if (!model?.columns) {
    return row.data
  }
  let tooLong = false
  return Object.keys(model.columns).reduce((displayHTML, key) => {
    if (row.data[key] === null) {
      row.data[key] = ''
    }
    if (displayHTML.length >= 180) {
      if (tooLong) {
        return displayHTML
      } else {
        tooLong = true
        return displayHTML + '...'
      }
    }
    return (
      displayHTML +
      `<span class="d-block"><span>${model.columns[key]?.label || key}</span>: ${
        row.data[key]
      }</span>`
    )
  }, '')
}

export const revisionActivitiesLabel = {
  create: '<span class="badge rounded-pill bg-success">新規作成</span>',
  update: '<span class="badge rounded-pill bg-primary">更新</span>',
  delete: '<span class="badge rounded-pill bg-danger">削除</span>',
  revert: '<span class="badge rounded-pill bg-success">更新</span>(復元)',
  comment: '<span class="badge rounded-pill bg-light text-dark">コメント</span>',
  upload: '<span class="badge rounded-pill bg-light text-dark">アップロード</span>',
  login: '<span class="badge rounded-pill bg-light text-dark">ログイン</span>',
  run: '<span class="badge rounded-pill bg-success">RUN</span>',
}

export type ModelDirectusRevisions = {
  id: number
  activity: ModelDirectusActivity | number
  collection: string
  item: string
  data: any // 更新後の状態
  delta: any // 更新リクエストのデータ
  parent?: number
}

export const directus_revisions: ModelDef = {
  tableName: modelName,
  tableLabel: 'データ変更履歴',
  modelType: 'admin',
  updatable: false,
  creatable: false,
  deletable: false,
  timestamps: false,
  defaultSort: { key: 'id', order: 'desc' },
  defaultFieldsParamExpression: ['*.*.*'],
  doNotSyncModel: true,
  columns: {
    id: {
      label: 'ID',
      type: 'NUMBER',
      enableIf: () => false,
      searchBehavior: false,
    },
    activity: {
      label: 'アクティビティ',
      type: 'RELATIONSHIP_MANY_TO_ONE',
      relationshipManyToOne: {
        collectionName: 'directus_activity',
        labelFormatter: (row) => {
          return revisionActivitiesLabel[row.action] || row.action
        },
      },
      searchBehavior: false,
    },
    user: {
      type: 'STRING',
      virtualColumnOf: 'activity',
      label: '実行ユーザ',
      labelFormatter: (row) => {
        return $core.$utils.formatUserDataForDisplay(row.activity?.user)
      },
      // enableIf: () => false
    },
    collection: {
      label: 'モデル定義',
      type: 'STRING',
      validate: { notEmpty: true },
      labelFormatter: (row) => {
        return $core.$models[row.collection]?.tableLabel
          ? `${$core.$models[row.collection]?.tableLabel} (${row.collection})`
          : row.collection
      },
    },
    item: {
      label: 'レコードID',
      type: 'STRING',
      validate: { notEmpty: true },
      // visible: false,
    },
    timestamp: {
      type: 'STRING',
      virtualColumnOf: 'activity',
      label: '日時',
      editable: false,
      labelFormatter: (row) => {
        return row.activity?.timestamp
          ? $core.$dayjs(row.activity?.timestamp).format('YYYY-MM-DD HH:mm:ss')
          : ''
      },
      enableIf: (row) => false,
    },
    data: {
      label: '更新後状態',
      type: 'TEXT',
      inputAttrs: {
        wrapperClass: 'col-12',
        // class: 'bg-dark text-white',
        // style: 'white-space: pre',
      },
      labelFormatter: recordRevisionLabelFormatter,
      disabledComponent: $frameworkUtils.defineAsyncComponent(async () => ({
        template: '<DisplayOtherModelRecordOnForm/>',
      })),
      searchBehavior: false,
    },
    delta: {
      label: '更新リクエスト内容',
      type: 'STRING',
      labelFormatter: (row) => JSON.stringify(row.delta, null, 2),
      visible: false,
    },
    parent: {
      type: 'NUMBER',
      visible: false,
    },
  },
  bulkControlActions: {
    restore: {
      label: '一括復元',
      function: async ({ targetIds }) => {
        if (
          (await $core.$toast.confirmDialog(
            `${targetIds.length} 件のデータを復元します。よろしいですか？`,
          )) === true
        ) {
          await $restoreRevisionService.runWithRevisionIds(targetIds)
        }
      },
    },
  },
}
