import { ComponentPublicInstance } from 'vue'
import { makeItReactive } from '../$frameworkUtils/$frameworkUtils'
import { ColumnDefByColName } from '../../common/$models/ModelDef'
import { detectVModelNameWithRecord } from '../../common/$virtualModels'
import { singletonInstanceSummoner } from '../../common/singletonInstanceSummoner'
import { registerFunctionLoggedInExecuteOnce } from '../../common/utils'

export const ModalTypeMap = {
  CreateView: 'CreateView',
  EditView: 'EditView',
  ListView: 'ListView',
  ModelView: 'ModelView',
} as const
type ModalType = keyof typeof ModalTypeMap
type ModalId = string

/**
 * 真偽値として扱える値の型
 * - true/false: 通常のboolean値
 * - 'true'/'false': 文字列としての真偽値
 * - '': 空文字（true扱い）
 */
type Booleanish = 'true' | 'false' | '' | boolean

/**
 * CSSクラス値として使用可能な型
 * - string: 単一のクラス名または空白区切りのクラス名
 * - Record<string, boolean>: オブジェクト形式のクラス定義
 * - Array<string | Record<string, boolean>>: 上記の配列
 */
type ClassValue =
  | Array<string | Record<string, boolean | undefined | null>>
  | Record<string, boolean | undefined | null>
  | string

/**
 * モーダルイベントの型定義
 * BvEvent と BvTriggerableEvent をマージした型
 */
type BModalEvent = {
  /**
   * イベントがキャンセル可能かどうか
   */
  readonly cancelable: boolean

  /**
   * イベントを発生させたコンポーネントのID
   */
  readonly componentId: string | null

  /**
   * イベントの種類を示す文字列
   */
  readonly eventType: string

  /**
   * 関連するネイティブイベント
   */
  readonly nativeEvent: string | null

  /**
   * イベントに関連する追加のターゲット要素
   */
  readonly relatedTarget: EventTarget | null

  /**
   * イベントのターゲット要素
   */
  readonly target: EventTarget | null

  /**
   * イベントがデフォルトの動作を防止されているかどうか
   */
  readonly defaultPrevented: boolean

  /**
   * イベントのデフォルトの動作を防止するメソッド
   */
  preventDefault: () => void

  /**
   * イベントのトリガー情報
   * - 'ok': OKボタンクリック
   * - 'cancel': キャンセルボタンクリック
   * - 'close': 閉じるボタンクリック
   * - 'backdrop': バックドロップクリック
   * - 'esc': ESCキー押下
   */
  readonly trigger: string | null
}

/**
 * モーダルコンポーネントのprops型定義
 */
type ModalProps = {
  /**
   * モーダルの表示タイプ
   * - modal: 通常のモーダル表示
   * - sidebar: サイドバー表示
   * - sidebar-right: 右サイドバー表示
   * - sidebar-left: 左サイドバー表示
   */
  displayType?: 'modal' | 'sidebar' | 'sidebar-right' | 'sidebar-left'

  /**
   * モーダルの表示/非表示状態
   */
  visible?: boolean

  /**
   * v-modelで使用される値
   */
  modelValue?: Booleanish

  /**
   * 初期表示時に自動的に表示するかどうか
   */
  showOnLoad?: Booleanish

  /**
   * モーダルのサイズ
   */
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'fullscreen'

  /**
   * モーダルを画面中央に配置するかどうか
   */
  centered?: Booleanish

  /**
   * モーダル内容をスクロール可能にするかどうか
   */
  scrollable?: Booleanish

  /**
   * フルスクリーン表示の設定
   * - true: 常にフルスクリーン
   * - false: フルスクリーンなし
   * - 'sm': 小画面でフルスクリーン
   * - 'md': 中画面でフルスクリーン
   * - 'lg': 大画面でフルスクリーン
   * - 'xl': 特大画面でフルスクリーン
   */
  fullscreen?: boolean | 'sm' | 'md' | 'lg' | 'xl'

  /**
   * モーダルのタイトル
   */
  title?: string

  /**
   * タイトル要素に適用するCSSクラス
   */
  titleClass?: string

  /**
   * タイトルのHTML要素タグ
   * @default 'h5'
   */
  titleTag?: string

  /**
   * タイトルを視覚的に隠すかどうか（スクリーンリーダー用）
   */
  titleSrOnly?: Booleanish

  /**
   * ヘッダー部分を非表示にするかどうか
   */
  hideHeader?: Booleanish

  /**
   * ヘッダー要素に適用するCSSクラス
   */
  headerClass?: ClassValue

  /**
   * ヘッダーの背景色バリアント
   * 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
   */
  headerBgVariant?: string

  /**
   * ヘッダーのボーダー色バリアント
   */
  headerBorderVariant?: string

  /**
   * ヘッダーのテキスト色バリアント
   */
  headerTextVariant?: string

  /**
   * ヘッダーの閉じるボタンのラベル（アクセシビリティ用）
   */
  headerCloseLabel?: string

  /**
   * ヘッダーの閉じるボタンを白色にするかどうか
   */
  headerCloseWhite?: Booleanish

  /**
   * ヘッダーの閉じるボタンを非表示にするかどうか
   */
  hideHeaderClose?: Booleanish

  /**
   * モーダル本文要素に適用するCSSクラス
   */
  bodyClass?: ClassValue

  /**
   * モーダル本文の背景色バリアント
   */
  bodyBgVariant?: string

  /**
   * モーダル本文のテキスト色バリアント
   */
  bodyTextVariant?: string

  /**
   * フッター部分を非表示にするかどうか
   */
  hideFooter?: Booleanish

  /**
   * フッター要素に適用するCSSクラス
   */
  footerClass?: ClassValue

  /**
   * フッターの背景色バリアント
   */
  footerBgVariant?: string

  /**
   * フッターのボーダー色バリアント
   */
  footerBorderVariant?: string

  /**
   * フッターのテキスト色バリアント
   */
  footerTextVariant?: string

  /**
   * ボタンのサイズ
   * - 'sm': 小サイズ
   * - 'md': 中サイズ
   * - 'lg': 大サイズ
   */
  buttonSize?: 'sm' | 'md' | 'lg'

  /**
   * OKボタンのみ表示するかどうか（キャンセルボタンを非表示）
   */
  okOnly?: Booleanish

  /**
   * OKボタンを無効化するかどうか
   */
  okDisabled?: Booleanish

  /**
   * OKボタンのテキスト
   */
  okTitle?: string

  /**
   * OKボタンの色バリアント
   */
  okVariant?: string

  /**
   * キャンセルボタンを無効化するかどうか
   */
  cancelDisabled?: Booleanish

  /**
   * キャンセルボタンのテキスト
   */
  cancelTitle?: string

  /**
   * キャンセルボタンの色バリアント
   */
  cancelVariant?: string

  /**
   * モーダルがビジー状態かどうか（ボタンを無効化）
   */
  busy?: Booleanish

  /**
   * モーダルを静的に配置するかどうか（teleportを無効化）
   */
  static?: Booleanish

  /**
   * 遅延ロードを有効にするかどうか
   */
  lazy?: Booleanish

  /**
   * フェードアニメーションを無効にするかどうか
   */
  noFade?: Booleanish

  /**
   * 自動フォーカスを無効にするかどうか
   */
  noFocus?: Booleanish

  /**
   * バックドロップを非表示にするかどうか
   */
  hideBackdrop?: Booleanish

  /**
   * バックドロップクリックでの閉じる動作を無効にするかどうか
   */
  noCloseOnBackdrop?: Booleanish

  /**
   * ESCキーでの閉じる動作を無効にするかどうか
   */
  noCloseOnEsc?: Booleanish

  /**
   * モーダルが閉じられるときのコールバック
   */
  onClose?: (event: BModalEvent) => void

  /**
   * OKボタンがクリックされたときのコールバック
   */
  onOk?: (event: BModalEvent) => void

  /**
   * キャンセルボタンがクリックされたときのコールバック
   */
  onCancel?: (event: BModalEvent) => void

  /**
   * モーダルが表示される直前のコールバック
   */
  onShow?: (event: BModalEvent) => void

  /**
   * モーダルが表示された直後のコールバック
   */
  onShown?: (event: BModalEvent) => void

  /**
   * モーダルが非表示になる直前のコールバック
   */
  onHide?: (event: BModalEvent) => void

  /**
   * モーダルが非表示になった直後のコールバック
   */
  onHidden?: (event: BModalEvent) => void

  /**
   * キャンセルボタンに自動フォーカスするかどうか
   */
  autoFocusToCancel?: boolean

  /**
   * OKボタンに自動フォーカスするかどうか
   */
  autoFocusToOk?: boolean

  /**
   * モーダルのコンテンツ要素に適用するCSSクラス
   */
  contentClass?: ClassValue

  /**
   * モーダルのダイアログ要素に適用するCSSクラス
   */
  dialogClass?: ClassValue

  /**
   * モーダルのルート要素に適用するCSSクラス
   */
  modalClass?: ClassValue
}

export interface Modal {
  modalId?: ModalId
  component?: any
  componentType?: ModalType
  componentProps?: Record<string, any>
  modalProps?: ModalProps
}

let i = 0

/**
 * # $core.$modals
 *
 * `$core.$modals` のメソッドを利用すると、Javascript内からモーダルをコントロール可能です。
 *
 * ## 機能・ユースケース
 * - モーダルでデータ一覧表示 (モデル名, Virtualモデル名, および絞り込み条件を指定可能)
 * - モデル定義名 と primaryKey値 (id) を元に、編集画面を開く
 * - 独自定義したComponentを表示
 * - 現在開いているすべてのモーダルを閉じる
 *
 * ## データ一覧を表示: `openListViewModal()`
 *
 * <CodeSampleWithDemo pac>
 * <template #title><code>$core.$modals.openListViewModal()</code> example</template>
 * - Model名を指定して、Modal内で データの一覧を表示する例です。
 *
 * ```html
 * <span
 *   class="btn btn-primary"
 *   @click.prevent="() => $core.$modals.openListViewModal({
 *     modelName: 'selectOptionsMaster'
 *   })">
 *   Model定義 "selectOptionsMaster" のデータを一覧表示
 *   <ficon type="external-link-alt"/>
 * </span>
 * ```
 * </CodeSampleWithDemo>
 *
 * ## 編集画面を表示: `openEditViewModal({modelName, })`
 *
 * <CodeSampleWithDemo pac desc="Model名およびレコードのIDを指定して、Modal内で データの一覧を表示します。">
 * <template #title><code>$core.$modals.openEditViewModal()</code> example</template>
 *
 * ```html
 * <template>
 *   <span
 *     class="btn btn-primary"
 *     @click.prevent="() => openModal()">
 *     編集画面を開く
 *     <ficon type="external-link-alt"/>
 *   </span>
 * </template>
 * <script>
 *
 * const recordId = 'e34b84b6-ec2d-4dd4-be52-eb4452edc06c'
 * export default {
 *   methods: {
 *     openModal() {
 *       $core.$modals.openEditViewModal({
 *         modelName: 'selectOptionsMaster', // 必須
 *         id: recordId, // 必須 ※ プライマリキーのカラム名が id でなくても、プロパティとしては "id" を指定します。
 *       })
 *     }
 *   }
 * }
 * </script>
 * ```
 * </CodeSampleWithDemo>
 *
 * @category coreFront/modals
 */
export class ModalService {
  /**
   * @hidden
   */
  modalsByModalId: { [modalId: string]: Modal } = {}
  // Modalを表示している Modal.vue インスタンスへの参照を保持
  $vm: ComponentPublicInstance | null = null

  /**
   * @hidden
   */
  static get instance(): ModalService {
    return singletonInstanceSummoner('ModalService', ModalService)
  }

  /**
   * @hidden
   */
  constructor() {
    this.modalsByModalId = makeItReactive({}) // reactive
  }

  private _addModal(modal: Modal): void {
    this.modalsByModalId[modal.modalId] = modal
  }

  /**
   * ModalIdを指定して、Modalを閉じる
   * @param modalId
   * @param closeEvent
   */
  async closeModal(modalId: string, closeEvent: BModalEvent = null): Promise<void> {
    if (closeEvent && $core.$configVars.get('confirmWhenExitWithoutSave', false)) {
      const closeModalCheckFlag = await this.checkModelFormEditChanged(modalId)
      if (!closeModalCheckFlag) {
        closeEvent.preventDefault()
        return
      }
    }

    if (this.modalsByModalId[modalId]?.modalProps) {
      this.modalsByModalId[modalId].modalProps.visible = false
    }
    // delete this.modalsByModalId[modalId] と同義, reactivityのために
    if (this.modalsByModalId[modalId]) {
      delete this.modalsByModalId[modalId]
    }
  }

  /**
   * ModalIdを指定して、VueComponentを取得
   * @param modalId
   */
  findVueComponentByModalId(modalId: string): any {
    const componentType = this.modalsByModalId[modalId]?.componentType
    return componentType ? this.$vm.$refs[componentType][0] : null
  }

  /**
   * モーダル内のModelFormが変更されているかどうかをチェックする
   * @param modalId
   */
  async checkModelFormEditChanged(modalId: string): Promise<boolean> {
    const modalVueComponent = this.findVueComponentByModalId(modalId)
    if (!modalVueComponent) {
      return true
    }
    const ModelForms = modalVueComponent.$refs?.AppHookableComponent?.$refs?.ModelForm
    if (!ModelForms || !ModelForms.length) {
      return true
    }
    const ModelForm = ModelForms[0]
    if (ModelForm.hasDataChanged === true && ModelForm.hasSaved === false) {
      const _msg = '変更が保存されていませんがよろしいですか？'
      if ($core.$configVars.get('useCoreConfirmModal', true)) {
        return await $core.$toast.confirmDialog(_msg)
      } else {
        // 普通のJavascriptモダールを使う
        return window.confirm(_msg)
      }
    }

    return true
  }

  /**
   * 最上部に表示されているModalのModalIdを取得
   */
  get topModalId(): string {
    return Object.keys(this.modalsByModalId || {})[this.openingModalCount - 1]
  }

  /**
   * 最上部に表示されているModalを閉じる
   */
  closeTopModal() {
    if (this.topModalId) {
      this.closeModal(this.topModalId)
    }
  }

  /**
   * すべてのModalを閉じる
   */
  closeAllModal() {
    try {
      // @ts-ignore
      this.$vm.$children.map((_vm) => (_vm?.hide ? _vm?.hide() : null))
    } catch (e) {
      $core.$errorReporter.r(e, this)
    }
  }

  /**
   * モーダルを開く with VueComponent
   *
   * 引数 modalProps は、Bootstrap Vue の Modal Propsである https://bootstrap-vue.org/docs/components/modal#comp-ref-b-modal-props
   *
   *
   * ##### 例: Vueコンポーネント (ファイル) を dynamic import しつつ開く
   * ```ts
   * $core.$modals.openModal({
   *   component: () => import('./someDefinedComponent.vue'),
   *   modalProps: {
   *     title: 'モーダルタイトルとなるテキスト',
   *     onClose: () => {
   *       window.removeEventListener('fileSelectorModal:selected', selectedCallback)
   *     },
   *   },
   * })
   * ```
   * ##### 例: Vueコンポーネント Object インライン定義して開く
   * ```ts
   * const someVariable = 'sample text'
   * const anotherVariable = { a: 'abc' }
   * $core.$modals.openModal({
   *   modalProps: {
   *     hideHeader: true, // ヘッダーを非表示に
   *   },
   *   // Vueコンポーネント定義をオブジェクトで渡すパターン
   *   component: {
   *     template: `<div>サンプル モーダルContent: aValueOfAnotherVariable: {{aValueOfAnotherVariable}}, ${someVariable}</div>`,
   *     data() {
   *       return {
   *         autoLoad: true,
   *         hideControls: false,
   *         displayType: type,
   *       }
   *     },
   *     computed: {
   *       aValueOfAnotherVariable() {
   *         return anotherVariable.a
   *       }
   *     },
   *     methods: {
   *       ...
   *     }
   *   }
   * })
   * ```
   * @param modal
   */
  openModal(modal: Modal): string {
    // configure default modal props
    // generate modal id if not specified or already exists
    const shouldGenerateModalId =
      !modal.modalId || !!this.modalsByModalId?.[modal.modalId]
    const modalId = shouldGenerateModalId ? `modal_${i++}` : modal.modalId
    modal.modalId = modalId
    modal.modalProps = Object.assign(
      {
        centered: false,
        size: 'lg',
        okVariant: 'outline-secondary',
        okTitle: '閉じる',
        okOnly: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        visible: true,
      },
      modal.modalProps,
    )

    // merge onClose
    const { onClose } = modal.modalProps
    modal.modalProps.onClose = (event) => {
      event.preventDefault() //
      if (onClose) {
        onClose(event)
      }
      this.closeModal(modalId, event)
      return false
    }
    // merge onOk
    const { onOk } = modal.modalProps
    modal.modalProps.onOk = (event) => {
      if (onOk) {
        onOk(event)
      }
    }

    if (modal.modalProps.autoFocusToCancel) {
      modal.modalProps.onShown = () => {
        $core.$utils.programmaticFocusWithToggleClass(
          document.querySelector(`#modal-${modalId} .modal-footer button.btn-cancel`),
        )
      }
    }
    if (modal.modalProps.autoFocusToOk) {
      modal.modalProps.onShown = () => {
        $core.$utils.programmaticFocusWithToggleClass(
          document.querySelector(`#modal-${modalId} .modal-footer button.btn-ok`),
        )
      }
    }

    // show
    this._addModal(modal)
    return modal.modalId
  }

  /**
   * モデル名 or Virtualモデル名 & id を渡して、編集モーダルを開く
   * @param id レコードのプライマリキーの値
   * @param modelName モデル名
   * @param virtualModelName optional: Virtualモデル名
   * @param successCallback optional: 保存成功時に呼ばれるコールバック
   * @param deleteCallback optional: 削除時に呼ばれるコールバック
   * @param otherComponentProps optional: その他の編集コンポーネントに渡すprops
   * @param modalProps optional: その他のモーダルに渡すprops
   */
  async openEditViewModal({
    id,
    modelName,
    virtualModelName,
    successCallback,
    deleteCallback,
    otherComponentProps,
    modalProps,
  }: {
    id: string | number
    modelName: string
    virtualModelName?: string
    successCallback?: any
    deleteCallback?: any
    otherComponentProps?: Record<string, any>
    modalProps?: ModalProps
  }): Promise<ModalId> {
    const modal: Modal = {
      componentType: ModalTypeMap.EditView,
      componentProps: {
        ...(otherComponentProps || {}),
        passedId: id,
        modelName,
        virtualModelName,
        columns: (globalThis.$core.$virtualModels[virtualModelName] || {}).colNames,
        successCallback,
        deleteCallback,
      },
      modalProps: modalProps || {},
    }
    return this.openModal(modal)
  }

  /**
   * モデル名 or Virtualモデル名 を元に、新規作成モーダルを開く
   * @param modelName モデル名
   * @param virtualModelName optional: Virtualモデル名
   * @param defaultValues optional: デフォルト値, オブジェクト
   * @param successCallback optional: 保存成功時に呼ばれるコールバック関数
   * @param deleteCallback optional: 削除時に呼ばれるコールバック関数
   * @param otherComponentProps optional: その他の ModelForm コンポーネントに渡すprops
   * @param modalProps optional: その他の モーダルに渡すprops
   */
  openCreateViewModal({
    modelName,
    virtualModelName = null,
    defaultValues = null,
    successCallback = null,
    deleteCallback = null,
    otherComponentProps = {},
    modalProps = {},
  }: {
    modelName: string
    virtualModelName?: string | null
    defaultValues?: any
    successCallback?: any
    deleteCallback?: any
    otherComponentProps?: any
    modalProps?: any
  }): ModalId {
    let modalId = null
    if (!successCallback) {
      successCallback = () => {
        this.closeModal(modalId)
      }
    }
    const modal: Modal = {
      componentType: ModalTypeMap.CreateView,
      componentProps: {
        ...otherComponentProps,
        modelName,
        virtualModelName,
        passedDefaultValues: defaultValues,
        columns: (globalThis.$core.$virtualModels[virtualModelName] || {}).colNames,
        successCallback,
        deleteCallback,
      },
      modalProps,
    }
    modalId = this.openModal(modal)
    return modalId
  }

  /**
   * モデル名 or Virtualモデル名 を元に、一覧モーダルを開く
   * @param modelName モデル名
   * @param virtualModelName optional: Virtualモデル名
   * @param fields optional: 表示するフィールド名の配列
   * @param filters optional: フィルター条件, 設定すると、検索絞り込みが適用された状態で開く
   * @param filter optional: フィルター条件, 設定すると、検索絞り込みが適用された状態で開く
   * @param otherComponentProps optional: その他 一覧コンポーネントに渡すprops
   * @param modalProps optional: その他 モーダルに渡すprops
   */
  openListViewModal({
    modelName,
    virtualModelName = null,
    fields = null,
    filters = null,
    filter = null,
    otherComponentProps = {},
    modalProps = {},
  }: {
    modelName: string
    virtualModelName?: string | null
    fields?: string[] | null
    filters?: Record<string, any> | null
    filter?: Record<string, any> | null
    otherComponentProps?: any
    modalProps?: ModalProps
  }): ModalId {
    const modal: Modal = {
      componentType: ModalTypeMap.ListView,
      componentProps: {
        ...otherComponentProps,
        modelName,
        virtualModelName,
        fields,
        filters: filters || filter,
      },
      modalProps: modalProps,
    }
    return this.openModal(modal)
  }

  /**
   * モデル名 or Virtualモデル名 & id を元に、レコード詳細 (閲覧のみ) のモーダルを開く
   * @param id レコードのプライマリキーの値
   * @param modelName モデル名
   * @param virtualModelName optional: Virtualモデル名
   * @param referenceField optional: リレーションのフィールド名
   * @param otherComponentProps optional: その他 詳細コンポーネントに渡すprops
   * @param modalProps optional: その他 モーダルに渡すprops
   */
  async openModelViewModal({
    id,
    modelName,
    virtualModelName = null,
    referenceField = null,
    otherComponentProps = {},
    modalProps = {},
  }: {
    id: string | number
    modelName: string
    virtualModelName?: string | null
    referenceField?: string | null
    otherComponentProps?: Record<string, any>
    modalProps?: ModalProps
  }): Promise<ModalId> {
    if (referenceField && referenceField !== 'id') {
      const record = (
        await globalThis.$core.$models[modelName].findAllBy({
          [referenceField]: id,
        })
      )[0]
      id = record.id
    }
    if (!virtualModelName) {
      virtualModelName = detectVModelNameWithRecord(
        modelName,
        await globalThis.$core.$models[modelName].findById(id),
      )
    }
    const modal: Modal = {
      componentType: ModalTypeMap.ModelView,
      componentProps: {
        ...otherComponentProps,
        modelName,
        virtualModelName,
        passedId: id,
      },
      modalProps,
    }
    return this.openModal(modal)
  }

  // 現在開いているModalの数をカウント
  get openingModalCount(): number {
    return Object.keys(this.modalsByModalId || {}).length
  }

  /**
   * ModelForm コンポーネントを使って、自由なフォームを表示する Modal
   * 例: 何かのアクションに反応して、なにかを入力させたいときに利用する
   * @return data - 入力されたデータ or null
   *
   * @param columns フォームに表示するカラム定義オブジェクト
   * @param defaultValues フォームに表示するデフォルト値, レコードオブジェクト
   * @param onCloseFunction モーダルが閉じられたときに呼ばれるコールバック関数
   * @param submitButtonLabel フォームの送信ボタンのラベルテキスト
   * @param disableConfirmBeforeSubmit true に設定した場合、フォームの送信前に確認ダイアログを表示しない
   * @param modalProps Modal コンポーネントに渡す props
   * @param modelFormProps ModelForm コンポーネントに渡す props
   */
  async openModelFormModal({
    columns,
    defaultValues = null,
    onCloseFunction = null,
    submitButtonLabel = 'OK',
    disableConfirmBeforeSubmit = true,
    componentProps = {},
    modalProps = {},
    modelFormProps = {},
  }: {
    columns: ColumnDefByColName
    defaultValues?: any
    onCloseFunction?: (event: BModalEvent) => Promise<any>
    componentProps?: Record<string, any>
    modalProps?: ModalProps
    submitButtonLabel?: string
    disableConfirmBeforeSubmit?: boolean
    modelFormProps?: Record<string, any>
  }): Promise<any | null> {
    // 後でModalを閉じられるように、modalIdは指定
    const modalId = $core.$utils.generateRandomString()
    return new Promise((resolve, reject) => {
      const modal: Modal = {
        modalId,
        // @ts-ignore
        component: $frameworkUtils.defineAsyncComponent(
          // @ts-ignore
          () => import('./ModelFormInputtableModal.vue'),
        ),
        componentProps: {
          ...componentProps,
          columns,
          onSubmitFunction: ({ data }) => {
            resolve(data)
          },
          modelFormProps: {
            submitButtonLabel,
            disableConfirmBeforeSubmit,
            ...modelFormProps,
            // ModelForm デフォルト値
            record: defaultValues || {},
            // Submit時 resolve
            onSubmitFunction: (data) => {
              resolve(data)
              setTimeout(() => {
                this.closeModal(modalId)
              }, 1)
            },
          },
        },
        modalProps: {
          ...modalProps,
          async onClose(onCloseEvent) {
            if (typeof onCloseFunction === 'function') {
              resolve(await onCloseFunction(onCloseEvent))
            } else {
              resolve(null)
            }
          },
        },
      }
      this.openModal(modal)
    })
  }
}

export const $modals = ModalService.instance
