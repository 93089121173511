<template>
  <DataListDisplayColumnItem
    :colName="colName"
    :displayIndex="displayIndex"
    wrapper-tag="td"
    ref="dataListDisplayColumnItem"
  >
    <slot />
  </DataListDisplayColumnItem>
</template>
<script lang="ts">
import { registerComposableComponentSettings } from '../../../../../ComposableComponents'
import { dataListComposableColumnNameSelectionSettingColumnDef } from '../../../patrials/dataListComposableColumnNameSelectionSettingColumnDef'

const name = 'DataListDisplayTableBodyCellItem'
registerComposableComponentSettings(name, {
  hasDefaultSlot: true,
  configColumns: {
    colName: dataListComposableColumnNameSelectionSettingColumnDef,
  },
})

export default {
  props: {
    colName: {
      type: String,
      required: true,
    },
    displayIndex: {
      type: Number,
      default: null,
    },
  },
  beforeUnmount() {
    this.$refs.dataListDisplayColumnItem = null
  },
}
</script>
