<template>
  <div
    class="input-image-uploader"
    :class="{
      'input-image-uploader--dragging': dragging,
      'input-image-uploader--compact': layoutType === 'compact',
    }"
  >
    <!-- ファイルがアップロードされている場合 -->
    <div
      v-if="val"
      class="file-card"
    >
      <!-- プレビュー部分 -->
      <div class="file-preview">
        <img
          v-if="showPreviewImage && isImage"
          :src="previewImageUrl"
          :alt="displayFileName"
          class="preview-image"
          :style="{
            maxWidth: maxImageWidth || '280px',
            maxHeight: maxImageHeight || '280px',
            ...(imageStyle || {}),
          }"
        />
        <div
          v-else
          class="file-icon"
        >
          <ficon
            type="file"
            size="2x"
          />
        </div>
      </div>

      <!-- ファイル情報とアクション -->
      <div class="file-content">
        <div class="file-info">
          <code class="file-name">{{ displayFileName }}</code>

          <!-- カスタム表示 -->
          <div
            v-if="contentDisplayFunction && typeof contentDisplayFunction === 'function'"
            v-html="contentDisplayFunction(val)"
            class="custom-display"
          ></div>
          <a
            href="#"
            @click.prevent="
              () => $core.$imgix.openFileLink(val, {}, isImage ? null : 'download')
            "
            class="preview-link"
            title="プレビュー/ダウンロード"
          >
            <ficon type="external-link-alt" />
          </a>
        </div>

        <!-- アクションボタン -->
        <div
          class="file-actions"
          v-if="!disableEdit"
        >
          <b-button
            :class="btnClass"
            variant="outline-primary"
            size="sm"
            v-single-click="() => $refs.imageInput.click()"
          >
            <ficon type="sync" /> {{ buttonText || '変更' }}
          </b-button>

          <b-button
            v-if="enableDeleteButton"
            :class="btnClass"
            variant="outline-danger"
            size="sm"
            v-single-click="emitDelete"
          >
            <ficon type="trash" /> 削除
          </b-button>
        </div>
      </div>
    </div>

    <!-- ファイルがアップロードされていない場合 -->
    <FileUploader
      v-else
      :multiple="false"
      :accept="accept"
      :disableEdit="disableEdit"
      :btnClass="btnClass"
      :buttonText="
        buttonText || `ファイルを${layoutType !== 'compact' ? '選択' : '追加'}`
      "
      :showSuccess="showSuccess"
      :uploadMaxFileSize="uploadMaxFileSize"
      :fileValidationFunction="fileValidationFunction"
      :filePathPrefix="filePathPrefix"
      :filePathGenerateFunction="filePathGenerateFunction"
      :compact="uploadPartCompact"
      @upload-complete="handleUploadComplete"
    />
  </div>
</template>
<script lang="ts">
import { $imgix } from '../$libs/$imgix'
import {
  generateStrongTimeStampString,
  isImageLikeExtensionFileName,
} from '../../common/utils'
import {
  $fileManager,
  filenameSeparator,
  getOriginalFileNameFromFilePath,
} from './FileManager'
import FileUploader from './FileUploader.vue'
import { uploadPathPrefixProps } from './uploadPathPrefixProps'
// Default 50MB
const maxFileSize = process.env.CORE_UPLOAD_MAX_FILESIZE || 51200000

export default {
  components: {
    FileUploader,
  },
  props: {
    ...uploadPathPrefixProps,
    modelValue: {},
    filenameDefinitionLogicFunction: { type: Function, required: false },
    disableEdit: { type: Boolean, default: false },
    btnClass: {
      default: 'btn btn-sm btn-outline-secondary',
      type: String,
    },
    showPreviewImage: {
      default: true,
      type: Boolean,
    },
    buttonText: {
      default: '',
      type: String,
    },
    showSuccess: {
      type: Boolean,
      default: false,
    },
    successCallbackFunction: {
      default: null,
      type: Function,
    },
    contentDisplayFunction: {
      default: null,
      type: Function,
    },
    useOriginalFileName: {
      default: true,
      type: Boolean,
    },
    enableDeleteButton: {
      default: true,
      type: Boolean,
    },
    uploadMaxFileSize: {
      default: maxFileSize,
      type: Number,
    },
    fileValidationFunction: {
      default: null,
      type: Function,
    },
    maxImageHeight: {
      default: null,
      type: String,
    },
    maxImageWidth: {
      default: null,
      type: String,
    },
    imageStyle: {
      default: null,
      type: Object,
    },
    layoutType: {
      default: 'default',
      type: String as PropType<'default' | 'compact'>,
    },
    uploadPartCompact: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      dragging: false,
    }
  },
  computed: {
    val() {
      return this.modelValue
    },
    domain() {
      return $imgix.domain
    },
    previewImageUrl() {
      return this.val ? $imgix.buildUrl(this.val, { w: 450 }) : null
    },
    isImage() {
      return isImageLikeExtensionFileName(this.val)
    },
    shouldUseOriginalFileName() {
      if (this.useOriginalFileName === false) {
        return false
      }

      if (
        $core.$utils.findNearestParentVueComponentByName(this, 'modelInput')?.colDef
          ?.inputAttrs?.useOriginalFileName === false
      ) {
        return false
      }

      return true
    },
    // shouldUseOriginalFileName の場合には、sliceして表示してあげる
    displayFileName() {
      return this.shouldUseOriginalFileName
        ? getOriginalFileNameFromFilePath(
            this.val.replace(new RegExp(`^.+${filenameSeparator}(.+)$`), '$1'),
          )
        : this.val
    },
  },
  methods: {
    /**
     * アップロード完了時のハンドラ
     * @param {string} filePath アップロードされたファイルのパス
     * @param {object} uploadResult アップロード結果の詳細
     */
    handleUploadComplete(filePath, uploadResult) {
      this.$emit('update:modelValue', filePath)

      if (this.successCallbackFunction) {
        this.successCallbackFunction({ filePath, uploadResult })
      }

      if (this.contentDisplayFunction) {
        this.$nextTick(() => {
          this.$forceUpdate()
        })
      }
    },

    /**
     * 削除ボタンクリック時のハンドラ
     */
    emitDelete() {
      this.$emit('update:modelValue', '')
    },
  },
}
</script>

<style lang="scss">
.input-image-uploader {
  --card-bg: var(--bs-white);
  --card-border: var(--bs-gray-200);
  --card-radius: 0.5rem;
  --preview-size: 180px;
  --preview-size-compact: 60px;
  --spacing: 12px;
  --spacing-sm: 6px;
  --btn-zoom: 0.9;

  border-radius: var(--card-radius);
  overflow: hidden;
  transition: all 0.2s ease;

  // ファイルカード
  .file-card {
    background: var(--card-bg);
    border: 1px solid var(--card-border);
    border-radius: var(--card-radius);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    padding: var(--spacing-sm);
    align-items: center;

    .file-preview {
      flex-shrink: 0;
      width: var(--preview-size);
      height: var(--preview-size);
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--bs-gray-100);
      border-radius: calc(var(--card-radius) - 2px);
      overflow: hidden;

      .preview-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-width: 100% !important;
        max-height: 100% !important;
      }

      .file-icon {
        color: var(--bs-gray-600);
      }
    }

    .file-content {
      flex: 1;
      min-width: 0; // Prevent flex item from overflowing
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--spacing-sm);

      .file-info {
        .file-name {
          display: inline;
          font-size: 0.8rem;
          word-break: break-all;
          margin-bottom: var(--spacing-sm);
        }

        .custom-display {
          font-size: 0.8rem;
          color: var(--bs-gray-600);
        }
        .preview-link {
          font-size: 0.8rem;
          padding: 0.25rem;
        }
      }

      .file-actions {
        display: flex;
        gap: var(--spacing-sm);
        align-items: center;
        justify-content: flex-end;
        zoom: var(--btn-zoom);

        .btn {
          display: inline-flex;
          align-items: center;
          gap: 0.25rem;
          padding: 0.25rem 0.5rem;

          .ficon {
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  // コンパクトモード
  &--compact {
    --preview-size: var(--preview-size-compact);
    --btn-zoom: 0.8;

    .file-card {
      padding: var(--spacing-sm);
      gap: var(--spacing-sm);
      flex-direction: row;

      .file-preview {
        width: var(--preview-size-compact);
        height: var(--preview-size-compact);
      }

      .file-content {
        .file-info {
          .file-name {
            font-size: 0.75rem;
            // margin-bottom: 0.25rem;
          }
        }

        .file-actions {
          .btn {
            padding: 0.25rem 0.5rem;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}
</style>
