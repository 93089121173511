import { ModelFactory } from '../../$models'
import { ModelExtensionConfigBase } from '../../../plugins/ModelExtensionModelsLoader/common'

/**
 * # Front 検索Cache Model Extension
 * - Model単位で Front (Browser) 内での 検索・fetch のキャッシュを有効化します
 *
 * ## 有効化方法
 * - Model定義の extensions に以下の設定を追加
 * ```ts
 * extensions: [
 *   {
 *     extensionType: 'cachedFindable',
 *     enabled: true,
 *     cacheTTLSeconds: 60 * 5, // キャッシュの有効期限 (秒)
 *     purgeCacheOnUpdate: true, // 更新時にキャッシュをパージするかどうか
 *   }
 * ]
 * ```
 *
 * ## 利用シーン
 * - 頻繁に検索・fetch を実施するモデルに対して Object キャッシュを利用するように設定可能
 *
 * ## 挙動
 * - キャッシュは オブジェクトキャッシュ (変数へ格納) であるため、ブラウザリロード時, 新しいタブとして開いた場合 等にはキャッシュは消失します
 * - クエリ毎に、キャッシュが存在する場合は、キャッシュを返却します
 *   - キャッシュキーは filter, limit, fields, offset, aggregate その他, すべてのクエリパラメータを含めたものとなります
 * - キャッシュがパージされるタイミングは 設定変更が可能です。
 *
 */
export const installModelExtensionCachedFindable = (
  model: ModelFactory,
  config: CachedFindableConfig = {
    cacheTTLSeconds: cacheTTLSecondsDefault,
    purgeCacheOnUpdate: true,
  },
) => {
  const obCacheKeyBase = `cachedFindableModel_${model.tableName}__`
  const cacheTTLSeconds = config.cacheTTLSeconds || cacheTTLSecondsDefault
  if (!model.overrides) {
    model.overrides = {}
  }
  model.overrides.find = async function (query) {
    const obCacheKey = `${obCacheKeyBase}${$core.$utils.md5(JSON.stringify(query) + model.tableName)}`
    return $core.$obCache.get(
      obCacheKey,
      async () => {
        // 通常の find メソッドを実行
        return await model.defaultFind(query)
      },
      cacheTTLSeconds,
    )
  }
  const purgeCacheOnUpdate = config?.purgeCacheOnUpdate !== false
  if (purgeCacheOnUpdate) {
    // 対象Modelに対して save, delete event が発生した場合には キャッシュを即時削除する
    const bulkDeleteObCaches = () => {
      $core.$obCache.bulkDeleteWithKeyRegExp(new RegExp(`^${obCacheKeyBase}`))
    }
    $core.$appHook.on(`${model.tableName}.afterSave`, () => {
      bulkDeleteObCaches()
    })
    $core.$appHook.on(`${model.tableName}.afterDelete`, () => {
      bulkDeleteObCaches()
    })
  }
}

const cacheTTLSecondsDefault = 15 * 60

export const extensionTypeOfModelExtensionCachedFindable = 'cachedFindable'

type CachedFindableConfig = {
  /**
   * キャッシュの有効期限 (秒)
   * - 0 の場合は、キャッシュを無効化
   * - デフォルトは 15分
   */
  cacheTTLSeconds?: number
  /**
   * 更新時にキャッシュをパージするかどうか
   * - デフォルトは true
   */
  purgeCacheOnUpdate?: boolean
}
export type ModelExtensionCachedFindableConfig = ModelExtensionConfigBase &
  CachedFindableConfig & {
    extensionType: typeof extensionTypeOfModelExtensionCachedFindable
  }
