<template>
  <span
    style="cursor: pointer"
    v-single-click="submit"
  >
    {{ isMultipleDelete ? `${ids.length}件を` : '' }}削除
  </span>
</template>

<script lang="ts">
export default {
  name: 'DeleteLink',
  props: {
    modelName: { required: true },
    id: { required: true },
    url: {},
    modalId: { required: false },
  },
  data() {
    return {
      model: {},
    }
  },
  computed: {
    ids() {
      return typeof this.id !== 'object' ? [this.id] : this.id
    },
    isMultipleDelete() {
      return this.ids.length > 1
    },
  },
  methods: {
    async submit() {
      if (
        await $core.$toast.confirmDialog('削除します、よろしいですか？', {
          okVariant: 'danger',
          okTitle: '削除する',
        })
      ) {
        $core.$loading.start('削除しています。', 'overlay')
        try {
          // 削除リクエスト
          await $core.$storeMethods.bulkDelete({
            collectionName: this.modelName,
            docIds: this.ids,
            skipConfirm: true,
          })
          this.$emit('deleteCallback', { value: this.id })
          if (this.modalId) {
            $core.$modals.closeModal(this.modalId)
          }
        } catch (e) {
          $core.$toast.errorToast(`削除に失敗しました。${JSON.stringify(e?.message)}`)
          console.error(
            `[/front_nuxt/components/ModelForm.vue] Error: `,
            JSON.stringify(e),
          )
          throw e
        } finally {
          $core.$loading.finish()
        }
      }
    },
  },
}
</script>
