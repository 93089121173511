export interface ToastOptions {
  // Commonly used props
  toaster?: string
  title?: string
  variant?: string
  solid?: boolean
  noAutoHide?: boolean
  noHoverPause?: boolean
  autoHideDelay?: number
  noCloseButton?: boolean
  appendToast?: boolean
  isStatus?: boolean
  noFade?: boolean
  toastClass?: string | string[] | Array<any> | object | any
  headerClass?: string | string[] | Array<any> | object | any
  bodyClass?: string | string[] | Array<any> | object | any
  href?: string
  to?: string | object | any

  // Catch all
  [key: string]: any
}

const genToastInnerHTML = (message: string) => {
  return `<div class="toast-inner"><span class="toast-inner-message">${message}</span><div class="toast-close-button"></div></div>`
}

const addToast = (message: string, options: ToastOptions) => {
  // トーストラッパー要素を取得または作成
  let toastWrapper = document.getElementById('custom-toast-wrapper')
  if (!toastWrapper) {
    toastWrapper = document.createElement('div')
    toastWrapper.id = 'custom-toast-wrapper'
    // トーストラッパーのスタイルを設定
    window.document.body.appendChild(toastWrapper)
  }

  // トースト要素を作成
  let toast = document.createElement('div')
  toast.classList.add('custom-toast')
  // バリアント（種類）を設定（デフォルトは 'info'）
  const variant = options.variant || 'info'
  toast.classList.add(`toast-${variant}`)
  // トーストのメッセージを設定
  toast.innerHTML = genToastInnerHTML(message)
  // 閉じるボタンを設定
  const closeButton = toast.querySelector('.toast-close-button')
  const closeToast = () => {
    if (!toast) return
    toast.classList.add('fade-out')
    setTimeout(() => {
      toastWrapper.removeChild(toast)
      toast = null
      closeButton.removeEventListener('click', closeToast)
    }, 300)
  }
  closeButton.addEventListener('click', closeToast)
  // トーストをラッパーに追加
  toastWrapper.appendChild(toast)

  // 自動的にトーストを非表示にする（オプションで制御可能）
  if (options.autoHideDelay === 0 || options.noAutoHide) return
  setTimeout(() => {
    closeToast()
  }, options.autoHideDelay || 2000)
}

export const ToastHandlers = {
  toast: (message, anyother: any) => {
    return addToast(message, anyother)
  },
}
