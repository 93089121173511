import { ModelDef } from '../../../common/$models/ModelDef'
import { convertDBDefinedModelToModelDefObject } from '../../../common/modelDefinitions/convertDBDefinedModelToModelDefObject'
import { DBDefinedModelDefinitionColumns } from '../../../common/modelDefinitions/DBDefinedModelDefinitionColumns'
import { ModelDirectusUsers } from '../../../front/Users/directus_users'
import { ModelOrganizations } from './organizations'

export type ModelJunctionUsersAndOrgsAndPositions = {
  user: ModelDirectusUsers
  organization: ModelOrganizations
  position: string
}

/**
 * DBDefinedModelDefinitionColumns形式でのjunctionUsersAndOrgsAndPositionsモデル定義
 */
export const junctionUsersAndOrgsAndPositions__dbDefineable: DBDefinedModelDefinitionColumns =
  {
    id: '34a7a047-3ee9-4764-a869-654553fefd62',
    tableName: 'junctionUsersAndOrgsAndPositions',
    tableLabel: 'ユーザー所属組織',
    tableComment: '',
    primaryKeyColType: 'UUID',
    defaultSort_key: 'createdAt',
    defaultSort_order: 'desc',
    otherModelAttributes: `return {
    modelType: 'admin'
  }`,
    columns: [
      {
        key: 'user',
        label: 'ユーザー',
        type: 'RELATIONSHIP_MANY_TO_ONE',
        relationshipManyToOne_collectionName: 'directus_users',
        relationshipManyToOne_onDeleteDbRestriction: 'CASCADE',
        relationshipManyToOne_labelFormatter:
          'return row.first_name ? `${row.first_name} (${row.email})` : row.email',
        validationConfigs: [{ validationType: 'notEmpty' }],
      },
      {
        key: 'organization',
        label: '組織',
        type: 'RELATIONSHIP_MANY_TO_ONE',
        relationshipManyToOne_collectionName: 'organizations',
        relationshipManyToOne_onDeleteDbRestriction: 'CASCADE',
        relationshipManyToOne_labelFormatter: 'return `${row.name}(${row.code})`',
        validationConfigs: [{ validationType: 'notEmpty' }],
      },
      {
        key: 'position',
        label: '役職',
        type: 'RELATIONSHIP_MANY_TO_ONE',
        relationshipManyToOne_collectionName: 'positions',
        relationshipManyToOne_onDeleteDbRestriction: 'CASCADE',
        relationshipManyToOne_labelFormatter: 'return `${row.name}(${row.code})`',
      },
    ],
  }

/**
 * ModelDef形式でのjunctionUsersAndOrgsAndPositionsモデル定義
 */
export const junctionUsersAndOrgsAndPositions: ModelDef =
  convertDBDefinedModelToModelDefObject(junctionUsersAndOrgsAndPositions__dbDefineable)
