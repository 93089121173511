<template>
  <div
    v-show="modelValue"
    class="dropdown-content"
    :style="dropdownStyle"
    ref="dropdownRef"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { DropdownStyle } from './types'

/**
 * ドロップダウンの表示位置を計算する関数
 * @param dropdownEl ドロップダウン要素のDOM要素
 * @param width ドロップダウンの幅
 * @returns 表示位置のスタイルオブジェクト
 */
function calculateDropdownPosition(dropdownEl: HTMLElement): DropdownStyle {
  // ウィンドウの幅を取得
  const windowWidth = window.innerWidth
  // const windowHeight = window.innerHeight

  // 基本スタイル
  const style: DropdownStyle = {
    // width: typeof width === 'number' ? `${width}px` : width,
  }

  // ドロップダウン要素の位置とサイズを取得
  const dropdownRect = dropdownEl.getBoundingClientRect()

  // 右端からはみ出す量を計算
  const overflowRight = dropdownRect.right - windowWidth
  if (overflowRight > 0) {
    style.left = `${(overflowRight + 10) * -1}px`
  }

  return style
}

export default {
  name: 'DropdownWrapper',

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    width: {
      type: [String, Number] as PropType<string | number>,
      default: null,
    },
    maxHeight: {
      type: [String, Number] as PropType<string | number>,
      default: undefined,
    },
    preventOverflow: {
      type: Boolean,
      default: true,
    },
    followScroll: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'positioned'],

  data() {
    return {
      dropdownCalcedPosition: {} as DropdownStyle,
    }
  },
  computed: {
    dropdownStyle() {
      return {
        ...this.dropdownCalcedPosition,
        width: this.width ? `${this.width}px` : undefined,
        maxHeight: `${this.maxHeight}px`,
      }
    },
  },

  watch: {
    modelValue: {
      handler(newVal: boolean) {
        if (newVal) {
          // 表示時に位置を計算
          this.$nextTick(() => {
            this.updatePosition()
          })
        } else {
          // 非表示時にスタイルをリセット
          this.dropdownCalcedPosition = {}
        }
      },
      immediate: true,
    },
  },

  methods: {
    /**
     * ドロップダウンの位置を計算して更新
     */
    async updatePosition() {
      // 100ms 待ってから位置を計算する (rendered 後 に実行したいため)
      await new Promise((resolve) => setTimeout(resolve, 100))
      const dropdownRef = this.$refs.dropdownRef as HTMLElement | undefined
      console.log('dropdownRef', dropdownRef)
      if (!dropdownRef || !this.modelValue) return

      this.dropdownCalcedPosition = calculateDropdownPosition(dropdownRef)

      // 最大高さの設定
      if (this.maxHeight) {
        this.dropdownCalcedPosition.maxHeight =
          typeof this.maxHeight === 'number' ? `${this.maxHeight}px` : this.maxHeight
      }

      this.$emit('positioned')
    },
  },
}
</script>

<style scoped>
.dropdown-content {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--bs-border-radius);
  overflow: visible;
  max-width: 94vw;
  z-index: 1040;
  transition: all 0.1s ease-in-out;
}
</style>
