import { $appHook } from '../../$appHook'
import { ModelFactory } from '../../$models'
import { ModelDef } from '../../$models/ModelDef'
import { ModelExtension } from '../../../plugins/ModelExtensionModelsLoader/common'
import {
  extensionTypeOfModelExtensionCachedFindable,
  installModelExtensionCachedFindable,
} from './ModelExtensionCachedFindable'

const installFunctionByExtensionType: {
  [key: string]: (model: ModelFactory, ex: ModelExtension) => void
} = {
  [extensionTypeOfModelExtensionCachedFindable]: (model, ex) =>
    // @ts-ignore
    installModelExtensionCachedFindable(model, ex),
}

const installModelExtensions = ({
  modelDefinition,
  instance,
}: {
  modelDefinition: ModelDef
  instance: ModelFactory
}) => {
  instance.extensions?.forEach((ex) => {
    if (!ex.enabled) return
    const installFunction = installFunctionByExtensionType[ex.extensionType]
    if (installFunction) {
      try {
        installFunction(instance, ex)
      } catch (e) {
        console.warn(
          `Failed to install ModelExtension "${ex.extensionType}" for Model "${instance.tableName}"`,
          e,
        )
      }
    }
  })
  // return void
}

/**
 * ModelFactory の 初期化直後に ModelExtensions を読み込む挙動を設定
 */
export const prepareModelExtensionsInstallBehavior = () => {
  $appHook.on('CORE.frontApp.afterInit', () => {
    Object.keys($core.$models).forEach((modelName) => {
      const model = $core.$models[modelName]
      installModelExtensions({ modelDefinition: model.modelDef, instance: model })
    })
  })
}
