import { $appHook } from '../$appHook'
import { $modelsLoader } from '../$models'
import { frontAppHooks } from '../../front/frontAppHooks'
import { registerFunctionLoggedInExecuteOnce } from '../../front/LoggedInExecuteOnceServiceBase'
import { convertDBDefinedModelToModelDefObject } from './convertDBDefinedModelToModelDefObject'

export class ModelDefinitionsLoaderService {
  constructor() {
    // 初期化された時点で、ログイン後に実行される関数を登録
    registerFunctionLoggedInExecuteOnce({
      appHookFunctionRegisterKey: 'ModelDefinitionsLoaderService',
      onceLoadFunction: (userData) => {
        if (!userData) {
          return
        }
        return this.loadModelDefinitions()
      }, // 初回ロード時に実行する関数を登録
      useAwaitOnLoad: true, // ロード時にawaitするかどうか ( => $core.$embAuth.user が初期化する前に実施するか、または、初期化後に実施で良い場合はfalse
      hookPriority: 1000,
    })
  }

  async loadModelDefinitions(modelNames = []) {
    try {
      // modelDefinitionsからmodel情報を取得
      const findQuery = {
        limit: -1,
        sort: ['tableName'],
        filter: modelNames.length === 0 ? {} : { tableName: { _in: modelNames } },
      }
      const modelDefinitions = await $core.$models.modelDefinitions.find(findQuery)
      const formattedModelDefinitions = []
      // ModelDefの型に整形
      for (const unformattedModelDefinition of modelDefinitions) {
        unformattedModelDefinition.isDefinedOnDb = true
        // $core.$modelsLoaderを利用してmodelをロード
        let formattedModelDefinition = convertDBDefinedModelToModelDefObject(
          unformattedModelDefinition,
        )
        const hookName = frontAppHooks.modelDefinitions.beforeInitOnModelDefinitionLoaded(
          formattedModelDefinition.tableName,
        )
        if ($appHook.hasHook(hookName)) {
          formattedModelDefinition = await $appHook.emit(
            hookName,
            formattedModelDefinition,
          )
        }
        formattedModelDefinitions.push(formattedModelDefinition)
      }
      await $modelsLoader.loadModels(formattedModelDefinitions)
      await $appHook.emit(frontAppHooks.modelDefinitions.loaded, { modelNames })
    } catch (e) {
      console.error(
        `[modelDefinitionsLoaderService.ts] Failed to load modelDefinition. There might not exists the table, if so you should run "yarn syncModels --models=modelDefinitions" e.message: ${e.message}`,
      )
    }
  }

  /**
   * DBDefinedModelDefinition データを ModelDefの型に整形
   */
  formatDBDefinedModelIntoImportableModelDef = convertDBDefinedModelToModelDefObject
}

export const $modelDefinitionsLoaderService = () => new ModelDefinitionsLoaderService()
