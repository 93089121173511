<template>
  <!-- コンポーネントをレンダリング -->
  <component
    :is="componentIs"
    :ref="`col:${colName}`"
    :colName="colName"
    :value="ModelFormService.$modelFormVueInstance.data[colName]"
    :record="ModelFormService.$modelFormVueInstance.data"
    :recordRoot="ModelFormService.$modelFormVueInstance.data"
    :ModelFormService="ModelFormService"
    :modelName="ModelFormService.modelName"
    :readOnlyMode="ModelFormService.readOnlyMode"
    :passedColDefinition="ModelFormService.model ? null : col"
    :virtualModelName="ModelFormService.virtualModelName"
    :validation="true"
    v-bind="$attrs"
    @update:value-and-error="
      ($event) => {
        ModelFormService.$modelFormVueInstance.editCallback(
          ModelFormService.$modelFormVueInstance.data,
          colName,
          $event.value,
          ModelFormService.$modelFormVueInstance.data[colName],
          $event.error,
          $event.modelInputVm,
        )
      }
    "
  />
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { ModelFormService } from './ModelFormService'
export default defineComponent({
  name: 'ModelFormColumn',
  props: {
    colName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // 親から必要なデータを inject
    const ModelFormServiceInstance = inject(
      'ModelFormServiceInstance',
    ) as ModelFormService

    if (!ModelFormServiceInstance) {
      throw new Error('ModelFormColumn は ModelForm の内部で使用してください')
    }

    ModelFormServiceInstance.targetColumns

    return {
      ModelFormService: ModelFormServiceInstance as ModelFormService,
    }
  },
  computed: {
    col() {
      return this.ModelFormService.targetColumns[this.colName]
    },
    componentIs() {
      if (this.col.isExtraComponent) {
        return {
          ...this.col,
        }
      }
      return 'ModelFormGroup'
    },
  },
})
</script>
