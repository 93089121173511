<template>
  <div
    class="model-form-page model-form-page-create"
    :class="wrapperClass"
  >
    <div
      v-if="!disableHeader"
      class="model-form-page--header model-form-page-create--header"
      :class="`${wrapperClass}-header`"
    >
      <historyBackButton />
      <app-hookable-component
        class="model-form-page--header-title"
        :resolveHookName="`$CORE.admin.resolveComponent.model.${
          virtualModelName || modelName
        }.new.title`"
      >
        <slot name="title">
          {{
            virtualModel && virtualModel.tableLabel
              ? virtualModel.tableLabel
              : model.tableLabel
          }}
          新規作成
        </slot>
      </app-hookable-component>
      <slot name="titleAppend"></slot>
      <app-hookable-component
        :resolveHookName="`$CORE.admin.resolveComponent.model.${
          virtualModelName || modelName
        }.new.header.before`"
      />
      <app-hookable-component
        :resolveHookName="`$CORE.admin.resolveComponent.model.${
          virtualModelName || modelName
        }.new.header.after`"
      />
    </div>
    <div
      v-if="initialized"
      class="model-form-page--body model-form-page-create--body"
      :class="`${wrapperClass}-body`"
    >
      <app-hookable-component
        v-if="initialized"
        v-bind="modelFormBindProps"
        :key="$route.fullPath"
        ref="AppHookableComponent"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { ModelFactory } from '../../common/$models'

export default {
  name: 'ModelCreatePage',
  props: {
    modelName: { required: true },
    virtualModelName: { required: false, default: null },
    onSubmitFunction: { required: false },
    columns: { required: false, default: false },
    passedDefaultValues: {},
    successCallback: { required: false },
    wrapperClass: { default: '_card' },
    disableHeader: { default: false },
    modalId: { required: false, type: String },
    /**
     * ModelForm に bind する 追加の props を オブジェクトとして渡す
     */
    modelFormProps: {
      required: false,
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    return {
      initialized: false,
      defaultValues: {},
    }
  },
  computed: {
    appHookName(): string {
      return `$CORE.admin.resolveComponent.model.${
        this.virtualModelName || this.modelName
      }.pages.new`
    },
    virtualModel(): ModelFactory {
      return $core.$virtualModels?.[this.virtualModelName] || null
    },
    model(): ModelFactory {
      return $core.$models[this.modelName]
    },
    tableLabel(): string {
      // return {{ virtualModel ? virtualModel.tableLabel : model.tableLabel }}
      return this.virtualModel ? this.virtualModel.tableLabel : this.model.tableLabel
    },
    /**
     * ModelForm.vue に 渡す すべての props をまとめる
     *
     * :modelName="modelName"
     * :virtualModelName="virtualModelName"
     * :record="defaultValues"
     * :passedDefaultValues="defaultValues"
     * :onSubmitFunction="onSubmitFunction"
     * :columns="columns"
     * :modalId="modalId"
     * :forceTreatAsNewRecord="true"
     * @successCallback="callSuccessCallback"
     * :resolveHookName="appHookName"
     * :defaultComponentResolver="() => mainFormComponent()"
     */
    modelFormBindProps(): Record<string, any> {
      return {
        modelName: this.modelName,
        virtualModelName: this.virtualModelName,
        record: this.defaultValues,
        passedDefaultValues: this.defaultValues,
        onSubmitFunction: this.onSubmitFunction,
        columns: this.columns,
        modalId: this.modalId,
        forceTreatAsNewRecord: true,
        onSuccessCallback: (updatedData) => this.callSuccessCallback(updatedData),
        resolveHookName: this.appHookName,
        defaultComponentResolver: () => this.mainFormComponent,
        key: this.$route.fullPath,
        ...this.modelFormProps,
      }
    },
    mainFormComponent(): string {
      if (
        ($core.$virtualModels[this.virtualModelName] &&
          $core.$virtualModels[this.virtualModelName].groupedEditKeys) ||
        this.model.groupedEditKeys
      ) {
        return 'ModelFormGroupedEdit'
      }
      return 'ModelForm'
    },
    successCallbackHookName(): string {
      return `${this.appHookName}.successCallback`
      // const hookName =
      // if($core.$appHook.hasHook(hookName)) {
      //   return () => $core.$appHook.emit(hookName)
      // }
    },
  },
  watch: {
    $route(to, from) {
      this.$nextTick(async () => {
        Object.assign(this.$data, this.$options.data.apply(this))
        this.init()
      })
    },
  },
  created() {
    this.$nextTick(async () => {
      this.init()
    })
  },
  beforeRouteLeave(to, from, next) {
    debugger
  },
  methods: {
    async callSuccessCallback(data) {
      if (this.successCallback) {
        this.successCallback(data, this)
        return
      }
      // Hook があれば それを優先する
      if ($core.$appHook.hasHook(this.successCallbackHookName)) {
        $core.$appHook.emit(this.successCallbackHookName, {
          data,
          model: this.model,
          virtualModel: this.virtualModel,
          vueInstance: this,
        })
        return
      }

      // "追加で新規作成しますか？" の default confirm
      if (
        !this.modalId &&
        $core.$configVars.get(
          'model.createPage.confirmDialogForAddNewAfterAdded',
          true,
        ) === true
      ) {
        if (
          (await $core.$toast.confirmDialog(`追加で新規作成しますか？`, {
            okVariant: 'success',
          })) === true
        ) {
          this.initialized = false
          setTimeout(() => this.init(), 600)
          return // end
        }
      }
      if (!this.modalId && $core.$router.currentRoute.path.endsWith('/new')) {
        // 追加で新規作成しない & 新規作成時('/new'でURLが終わるとき)は一覧ページへ遷移する
        $core.$router.push({
          path: $core.$router.currentRoute.path.slice(0, -4),
          query: $core.$router.currentRoute.query,
        })
      }
      return
    },
    async init() {
      this.initialized = false
      this.defaultValues = await this.getDefaultValues()
      this.$nextTick(async () => {
        this.initialized = true
      })
    },
    async getDefaultValues() {
      return Object.assign(
        {},
        (await this.virtualModel?.createNew()) || (await this.model?.createNew()) || {},
        this.passedDefaultValues || {},
      )
    },
  },
}
</script>
