import { ModelDef } from '../../../common/$models/ModelDef'
import { convertDBDefinedModelToModelDefObject } from '../../../common/modelDefinitions/convertDBDefinedModelToModelDefObject'
import { DBDefinedModelDefinitionColumns } from '../../../common/modelDefinitions/DBDefinedModelDefinitionColumns'

/**
 * DBDefinedModelDefinitionColumns形式でのpositionsモデル定義
 */
export const positions__dbDefineable: DBDefinedModelDefinitionColumns = {
  id: 'ec6658cd-5997-44bf-95c0-6c623f51d07c',
  tableName: 'positions',
  tableLabel: '役職',
  tableComment: '',
  defaultSort_key: 'sort',
  defaultSort_order: 'asc',
  primaryKeyColType: 'UUID',
  otherModelAttributes: `return {
    modelType: 'admin'
  }`,
  columns: [
    {
      key: 'id',
      type: 'UUID',
      visible: false,
    },
    {
      key: 'code',
      label: '役職コード',
      type: 'STRING',
      unique: true,
      validationConfigs: [{ validationType: 'notEmpty' }],
      otherColAttributes: `return {
        inputAttrs: {
          wrapperClass: 'w-auto'
        },
      }`,
    },
    {
      key: 'name',
      label: '役職名',
      type: 'STRING',
      unique: true,
      validationConfigs: [{ validationType: 'notEmpty' }],
      otherColAttributes: `return {
        inputAttrs: { wrapperClass: 'col-4' }
      }`,
    },
    {
      key: 'sort',
      label: '表示順(昇順)',
      type: 'NUMBER',
      otherColAttributes: `return {
        inputAttrs: { wrapperClass: 'col-2' }
      }`,
    },
  ],
}

/**
 * ModelDef形式でのpositionsモデル定義
 */
export const positions: ModelDef = convertDBDefinedModelToModelDefObject(
  positions__dbDefineable,
)
