<template>
  <div
    class="position-relative"
    v-if="initialized"
  >
    <component
      v-for="(col, colName) in displayColumns"
      :is="col.isExtraComponent ? col : 'ModelFormGroup'"
      :key="colName"
      :colName="colName"
      :value="row[colName]"
      :record="row"
      :recordRoot="recordRoot"
      :modelName="modelName"
      :passedColDefinition="col"
      :readOnlyMode="readOnlyMode"
      :virtualModelName="virtualModelName"
      :validation="true"
      :ModelFormService="ModelFormService"
      @update:value-and-error="
        (__$event) => {
          if (isNaN(rowIndex) === false && __$event && col && colName) {
            $emit('update:one-row-array-of-object', rowIndex, __$event, col, colName)
          }
        }
      "
    />
    <div
      v-if="hasDetailNestedColumns"
      class="array-of-object--display-as-detail-link"
      style="order: 90000"
    >
      <a
        href="#"
        class="small"
        @click.prevent="() => $refs.rowDetailModal.show()"
        v-html="detailLinkText"
      />
      <b-modal
        size="lg"
        ok-only
        ok-title="もどる"
        v-model="showModal"
        ok-variant="outline-secondary"
        footer-class="model-form-submit-actions"
        :title="`${detailLinkText} - ${row.key || ''} ${row.label || ''}`"
        ref="rowDetailModal"
        :static="true"
      >
        <div class="px-1">
          <div
            ref="oneRowArrayOfObjectDisplayAsDetailWrapper"
            class="row one-row-array-of-object--display-as-detail-wrapper"
          >
            <component
              v-for="(col, colName) in asDetailColumns"
              :is="col.isExtraComponent ? col : 'ModelFormGroup'"
              :key="colName"
              :colName="colName"
              :value="row[colName]"
              :record="row"
              :recordRoot="recordRoot"
              :modelName="modelName"
              :readOnlyMode="readOnlyMode"
              :passedColDefinition="col"
              :virtualModelName="virtualModelName"
              :validation="true"
              :ModelFormService="ModelFormService"
              @update:value-and-error="
                (__$event) => {
                  if (isNaN(rowIndex) === false && __$event && col && colName) {
                    $emit(
                      'update:one-row-array-of-object',
                      rowIndex,
                      __$event,
                      col,
                      colName,
                    )
                  }
                }
              "
            />
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script lang="ts">
import { markRaw, PropType, toRaw } from 'vue'
import { ColumnDef, ColumnDefByColName } from '../../../common/$models/ModelDef'
import { convertColumnsForModelFormGroups } from '../index'

export default {
  name: 'OneRowOfArrayOfObjectInput',
  props: {
    modelValue: { required: true },
    col: {
      type: Object as PropType<ColumnDef>,
      required: true,
    },
    columnsForOneRow: {
      type: Object as PropType<ColumnDefByColName>,
      required: true,
    },
    row: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    recordRoot: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    readOnlyMode: { required: false, default: null },
    ModelFormService: {},
    modelName: {
      type: [String, null],
      required: false,
    },
    virtualModelName: {
      type: [String, null],
      required: false,
    },
  },
  data() {
    return {
      initialized: false,
      visible: false,
      showModal: false,
    }
  },
  computed: {
    record() {
      return this.row
    },
    /**
     * colDef.displayAsDetail === true のカラムがあるかどうか
     */
    hasDetailNestedColumns(): boolean {
      return !!Object.keys(this.columns).find(
        (col) => this.columns[col].displayAsDetail === true,
      )
    },
    detailLinkText() {
      const def = this.col?.inputAttrs?.detailLinkText
      if (typeof def === 'function') {
        return def(this.row, this.recordRoot)
      }
      return def || '詳細設定'
    },
    isEnabledByColName() {
      return Object.keys(this.columns).reduce((res, colName) => {
        const col = this.columns[colName]
        let isEnabled = true
        // enableIf false のときのみ正しく返せば良い
        if (!col.enableIf) {
          return res
        }
        if (typeof col.enableIf === 'function') {
          isEnabled = col.enableIf(this.row, this.record)
        } else {
          isEnabled =
            Object.keys(col.enableIf).filter(
              (keyName) => !(this.row[keyName] === col.enableIf[keyName]),
            ).length === 0
        }
        if (isEnabled === false) {
          res[colName] = false
        }
        return res
      }, {})
    },
    columns() {
      return markRaw(
        toRaw(
          convertColumnsForModelFormGroups(
            this.columnsForOneRow,
            this.ModelFormService?.columnGroupFormattedDef || {},
          ),
        ),
      )
    },
    displayColumns() {
      return Object.keys(this.columns).reduce((cols, colName) => {
        if (this.columns[colName].displayAsDetail === true) {
          return cols
        }
        cols[colName] = this.columns[colName]
        return cols
      }, {})
    },
    asDetailColumns() {
      return Object.keys(this.columns).reduce((cols, colName) => {
        if (this.columns[colName].displayAsDetail !== true) {
          return cols
        }
        cols[colName] = this.columns[colName]
        return cols
      }, {})
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initialized = true
    })
  },
}
</script>
