import { $appHook } from '../../common/$appHook'

/**
 * モデル一覧画面のヘルプドキュメントを追加する
 */
export const addModelDoc = (
  modelName: string,
  doc: string,
  virtualModelName: string = null,
) => {
  addInAppDoc(
    `${virtualModelName ? 'virtualModel' : 'model'}:${modelName}`,
    doc,
    `modelIndex.model:${modelName}.${
      virtualModelName ? `virtualModel:${virtualModelName}.` : ''
    }indexListBefore`,
  )
}

export const addInAppDoc = (docKey: string, doc: string, appHookName: string = null) => {
  $appHook.on(
    appHookName,
    (cs) => {
      return cs.concat({
        template: `<HelpDoc doc-key="${docKey}">${doc}</HelpDoc>`,
      })
    },
    'doc',
  )
}
