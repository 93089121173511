import { ID, ManyItems } from '@directus/sdk'
import { markRaw, toRaw } from 'vue'
import { $appHook } from '../$appHook'
import { $directusSdk } from '../$directusSdk'
import {
  validateConditionalExpressionBuilder,
  validateWithColDef,
} from '../../front/ModelForm/ModelInput/validateWithColDef'
import { matchesFilter } from '../../plugins/ComposableDataListComponents/front/FilterControlsService'
import { FindFilter, FindQuery } from '../../types'
import { anotherDataSourceFrontModelDefFactory } from './anotherDataSourceFrontModelDefFactory'
import { createNewRecordWithColumnDefs } from './createNewRecordWithColumnDefs'
import { initColumnDef } from './initColumnDef'
import {
  ColumnDef,
  ColumnDefByColName,
  ColumnType,
  EditCallback,
  EditCallbackForColumn,
  ErrorMessagesObject,
  ErrorMessagesResult,
  findAllByArgument,
  ModelDatasourceType,
  ModelDef,
  ModelFunctionOverrides,
  ModelValidationConfigItem,
} from './ModelDef'

const defaultDataSource = 'directus'

export const modelFactoryAppHooks = {
  init: {
    before: '$CORE.ModelFactory.init.before',
    after: '$CORE.ModelFactory.init.after',
  },
  byModelName(modelName: string) {
    return {
      init: {
        before: `$CORE.ModelFactory.${modelName}.init.before`,
        after: `$CORE.ModelFactory.${modelName}.init.after`,
      },
    }
  },
}

export const columnDefinitionTemplates: ColumnDefByColName = {
  createdAt: {
    name: 'createdAt',
    label: '作成日時',
    type: 'DATETIME',
    editable: false,
    inputAttrs: { disabled: true, placeholder: '--' },
    labelFormatter: (row) =>
      row.createdAt ? $core.$dayjs(row.createdAt).format('YYYY-MM-DD HH:mm:ss') : '',
    enableIf: () => false,
    orderOnForm: 10099,
  },
  updatedAt: {
    name: 'updatedAt',
    label: '更新日時',
    type: 'DATETIME',
    editable: false,
    inputAttrs: { disabled: true, placeholder: '--' },
    labelFormatter: (row) =>
      row.updatedAt ? $core.$dayjs(row.updatedAt).format('YYYY-MM-DD HH:mm:ss') : '',
    enableIf: () => false,
    orderOnForm: 10099,
  },
  userCreated: {
    name: 'userCreated',
    label: '作成者',
    type: 'RELATIONSHIP_MANY_TO_ONE',
    relationshipManyToOne: {
      collectionName: 'directus_users',
    },
    enableIf: () => false,
    visibleOnIndex: false,
  },
  userUpdated: {
    name: 'userUpdated',
    label: '最終更新者',
    type: 'RELATIONSHIP_MANY_TO_ONE',
    relationshipManyToOne: {
      collectionName: 'directus_users',
    },
    enableIf: () => false,
    visibleOnIndex: false,
  },
}

export type ErrorMessagesCallbackArgs<ModelDataType = any> = {
  value: any
  record: ModelDataType
  modelName: string
  initialValue: any
  colDef: any
  recordRoot: any
  modelInputVm: any
}
export interface ErrorMessagesCallback<ModelDataType = any> {
  ({
    value,
    record,
    modelName,
    initialValue,
    colDef,
    recordRoot,
    modelInputVm,
  }: ErrorMessagesCallbackArgs<ModelDataType>): Promise<ErrorMessagesObject>
}

export interface ValidateColumn<ModelDataType = any> {
  ({
    value,
    col,
    modelName,
    record,
    recordRoot,
  }: {
    value: any
    col: ColumnDef
    modelName: string
    record: ModelDataType | Record<string, any>
    recordRoot: ModelDataType | Record<string, any>
  }): Promise<string | undefined>
}

// 検索クエリが大きすぎるURLになっているかどうか判別するサイズの定義
const MAX_FIND_URL_QUERY_BYTE_SIZE =
  Number(process.env.MAX_FIND_URL_QUERY_BYTE_SIZE) || 2000

const defaultSortDefToStringArray = (
  defaultSort: ModelDef['defaultSort'],
  fallback: string[] = [],
): string[] => {
  if (!defaultSort) {
    return fallback
  }
  if (typeof defaultSort === 'string') {
    return defaultSort.split(',')
  }
  if (Array.isArray(defaultSort)) {
    return defaultSort
  }
  if (defaultSort.key) {
    if (Array.isArray(defaultSort.key)) {
      return defaultSort.key
    }
    return `${defaultSort.order === 'desc' ? '-' : ''}${defaultSort.key as string}`.split(
      ',',
    )
  }
  return fallback
}

const validateWithColDefFunc = ({
  value,
  record,
  modelName,
  initialValue,
  colDef,
  recordRoot,
  $modelInputVm,
}) => {
  if (!colDef) return ''
  const override = $core.$configVars.configs['$core.overrides.validateWithColDefFunc']
  if (override) {
    return override({
      value,
      record,
      modelName,
      initialValue,
      colDef,
      recordRoot,
      $modelInputVm,
    })
  }
  return validateWithColDef({
    value,
    modelName,
    record,
    colDef,
    initialValue,
    recordRoot,
    $modelInputVm,
  })
}

const validateColumnFunc = async ({
  value,
  col,
  modelName,
  record,
  modelInputVm,
}): Promise<ErrorMessagesObject> => {
  if (!modelInputVm) return {}
  if (
    modelInputVm.forceRequired === true &&
    (typeof value === 'undefined' || value === null || value === '')
  ) {
    return {
      [col.name || col.key]: ['入力必須です'],
    }
  }
  if (modelInputVm.validation === false) {
    return {}
  }
  if (col.type === 'CONDITIONAL_EXPRESSION') {
    const eMessage = await validateConditionalExpressionBuilder({
      value,
      record,
      modelName,
      initialValue: modelInputVm.initialValue,
      colDef: modelInputVm.colDef,
      recordRoot: modelInputVm.recordRoot,
    })
    return {
      [col.name || col.key]: [eMessage],
    }
  }
  // @ts-ignore
  const eMessage = await validateWithColDefFunc({
    value,
    record,
    modelName,
    initialValue: modelInputVm.initialValue,
    colDef: modelInputVm.colDef,
    recordRoot: modelInputVm.recordRoot,
    $modelInputVm: modelInputVm,
  })
  if (eMessage) {
    return {
      [col.name || col.key]: [eMessage],
    }
  }
}

const getValidateItemMessage = async <ModelDataType = any>({
  validateItem,
  value,
  record,
  modelName,
  initialValue,
  colDef,
  recordRoot,
  modelInputVm,
}: {
  validateItem: ModelValidationConfigItem<ModelDataType>
  value: any
  record: ModelDataType | Record<string, any>
  modelName: string
  initialValue: any
  colDef: any
  recordRoot: ModelDataType | Record<string, any>
  modelInputVm: any
}): Promise<ErrorMessagesResult<ModelDataType>[]> => {
  // 1 validationItem.isDefinedAsFunction が true のときは、その関数を実行する
  if (validateItem.isDefinedAsFunction) {
    if (typeof validateItem.isInvalidCheckFunction === 'string') {
      const asyncFunc = async function (data) {
        // @ts-ignore
        const func = new Function('data', validateItem.isInvalidCheckFunction)
        return await func(data)
      }
      const res = await asyncFunc(record)
      if (res) {
        return validateItem.errorMessageByColumnName
      }
      return []
    } else if (typeof validateItem.isInvalidCheckFunction === 'function') {
      const res = await validateItem.isInvalidCheckFunction(record as ModelDataType)
      if (res) {
        return validateItem.errorMessageByColumnName
      }
    }
    return []
  }
  if (validateItem.isInvalidCheckConditionObject) {
    // 2 validationItem.isDefinedAsFunction が false のときは、matchesFilter から
    // isInvalidCheckConditionObject が Trueの場合 エラーメッセージを返す
    const res = matchesFilter(validateItem.isInvalidCheckConditionObject, record as any[])
    if (res) {
      return validateItem.errorMessageByColumnName
    }
    return []
  }
  return []
}

const defaultNotSortableColumnTypes: ColumnType[] = [
  'JSON',
  'CONDITIONAL_EXPRESSION',
  'RELATIONSHIP_ONE_TO_MANY',
  'ARRAY_OF_OBJECT',
]

/**
 * ColumnDef を インスタンス化して ColumnDefService として返す
 * - インスタンス化されたModel定義 (ModelFactory) の columns には、ColumnDefService が格納される
 * - ColumDef に加えて 算出系の getter methods (logics) などを増強
 */
export class ColumnDefService<ModelRecordType = any> extends ColumnDef {
  private caches: Record<string, any> = {}
  private _model: ModelFactory<ModelRecordType>
  constructor(colDef: ColumnDef, model: ModelFactory<ModelRecordType>) {
    super()
    Object.keys(colDef).forEach((key) => {
      this[key] = colDef[key]
    })
    Object.defineProperty(this, '_model', {
      value: model,
      enumerable: false,
      writable: false,
      configurable: false,
    })
  }

  // modelへのアクセサメソッド
  public get model(): ModelFactory<ModelRecordType> {
    return this._model
  }

  /**
   * 一覧にて Sortable かどうか
   */
  get isSortable(): boolean {
    return this.returnCacheIfExist('isSortable', calcIsSortable)
  }

  /**
   * getter methods 用, 一度計算した値をキャッシュするためのメソッド
   * - calcFunc は 外部定義できるように 引数に this を渡す
   * @param keyName
   * @param calcFunc
   * @private
   */
  private returnCacheIfExist(
    keyName: string,
    calcFunc: (thisSv: ColumnDefService<ModelRecordType>) => any,
  ) {
    if (this.caches[keyName] !== undefined) {
      return this.caches[keyName]
    }
    this.caches[keyName] = calcFunc(this)
    return this.caches[keyName]
  }
}

const calcIsSortable = (col: ColumnDefService) => {
  if (col.virtualColumnOf || col.doNotSyncModel) {
    return false
  }
  if (defaultNotSortableColumnTypes.includes(col.type)) {
    return false
  }
  return true
}

/**
 * # $models[modelName]
 *
 * ## このモデルの説明!
 *
 * @coreDocPath $core/10.handleData/101.models/ModelFactory
 */
export class ModelFactory<ModelRecordType = any> extends ModelDef<ModelRecordType> {
  datasource: ModelDatasourceType
  modelDef: ModelDef<ModelRecordType>
  /**
   * プライマリキーのカラム定義を保持します
   */
  primaryKeyCol?: ColumnDef<ModelRecordType>
  /**
   * プライマリキーのカラム名称
   */
  primaryKeyColName: string
  colLabels: Record<string, any>
  comment?: string
  timestamps?: boolean
  editCallbacksByColname: { [colName: string]: EditCallbackForColumn<ModelRecordType> }
  editCallback: EditCallback<ModelRecordType>
  errorMessagesCallback: ErrorMessagesCallback<ModelRecordType>
  validateColumn: ValidateColumn<ModelRecordType>
  facetColNames: string[]
  hasVirtualColumn: boolean
  // @ts-ignore
  defaultSort: string[]
  /**
   * Overrides, find系の挙動を書き換えをする
   */
  overrides?: ModelFunctionOverrides
  // overrides
  // onSubmitFunction?: (data: ModelDataType) => Promise<ModelDataType> | ModelDataType
  // // overrides
  // onDeleteFunction?: (data: ModelDataType) => Promise<ModelDataType> | ModelDataType

  doNotSyncModel?: true
  enableKeywordSearch?: boolean
  defaultValues?: () => Promise<ModelRecordType>
  defaultFieldsParamExpression: string[] = ['*.*']
  /**
   * Model定義をFileで定義しているか、DBに保管しているかどうか。
   * `CORE/src/common/modelDefinitions/modelDefinitionsLoaderService.ts` で true 設定されている
   */
  isDefinedOnDb = false

  // @ts-ignore
  columns: {
    [colName: string]: ColumnDefService<ModelRecordType>
  }

  constructor(modelDef: ModelDef<ModelRecordType>) {
    modelDef = markRaw(toRaw(modelDef))
    super()
    $appHook.emit(ModelFactory.hooks.init.before, { modelDefinition: modelDef })
    $appHook.emit(ModelFactory.hooks.byModelName(modelDef.tableName).init.before, {
      modelDefinition: modelDef,
    })
    // anotherDataSource が指定されている場合は、そのデータソースを使う
    if (modelDef.anotherDataSourceName) {
      modelDef = anotherDataSourceFrontModelDefFactory<ModelRecordType>(
        {
          modelDef,
          dataSourceName: modelDef.anotherDataSourceName,
        },
        false,
      )
    }
    this.modelDef = modelDef
    /**
     * ModelDef の 全key をそのまま引き継ぐ
     */
    const inheritKeys = Object.keys(modelDef)
    for (const key of inheritKeys) {
      this[key] = modelDef[key]
    }
    this.colLabels = {}
    this.datasource = modelDef.datasource || defaultDataSource
    this.editCallbacksByColname = {}
    this.timestamps = modelDef.timestamps !== false // default true
    this.hasVirtualColumn = false // default false
    this.facetColNames = []
    // TODO: 以下ごちゃってる箇所を切り出してキレイにしたい...

    // TODO: コレ不要?? 要調査 & 検討
    if (this.timestamps && !modelDef.columns.createdAt) {
      modelDef.columns.createdAt = {
        ...columnDefinitionTemplates.createdAt,
      } as ColumnDef<ModelRecordType>
    }
    if (this.timestamps && !modelDef.columns.updatedAt) {
      modelDef.columns.updatedAt = {
        ...columnDefinitionTemplates.updatedAt,
      } as ColumnDef<ModelRecordType>
    }
    if (!modelDef.columns.userCreated) {
      modelDef.columns.userCreated = {
        ...columnDefinitionTemplates.userCreated,
      } as ColumnDef<ModelRecordType>
    }
    if (!modelDef.columns.userUpdated) {
      modelDef.columns.userUpdated = {
        ...columnDefinitionTemplates.userUpdated,
      } as ColumnDef<ModelRecordType>
    }

    Object.keys(modelDef.columns).map((colName: string) => {
      // @ts-ignore
      modelDef.columns[colName] = new ColumnDefService<ModelRecordType>(
        initColumnDef<ModelRecordType>(colName, modelDef.columns[colName], this),
        this,
      )
    })
    /**
     * プライマリキーを columns として 設定する if not set
     */
    this.primaryKeyColName = this.primaryKeyCol?.name || 'id'
    if (!modelDef.columns[this.primaryKeyColName]) {
      modelDef.columns[this.primaryKeyColName] = {
        name: this.primaryKeyColName,
        type: this.primaryKeyColType || this.primaryKeyCol?.type || 'NUMBER',
        visible: false,
        primaryKey: true,
      }
    }
    if (!this.primaryKeyCol && modelDef.columns[this.primaryKeyColName]) {
      this.primaryKeyCol = modelDef.columns[this.primaryKeyColName]
    }

    /**
     * editCallback 挙動を設定
     */
    this.editCallback = async ({
      row,
      key,
      newValue,
      oldValue,
      isNewRecord,
      callerVueInstance,
    }) => {
      if (newValue === oldValue && !isNewRecord) {
        return row
      }
      // Model定義にあるとき
      if (modelDef.editCallback) {
        row = await modelDef.editCallback({
          row,
          key,
          newValue,
          oldValue,
          isNewRecord,
          callerVueInstance,
        })
      }
      // Auto calc 関連
      if (this.editCallbacksByColname[key]) {
        row = await this.editCallbacksByColname[key]({
          row,
          newValue,
          oldValue,
          isNewRecord,
          callerVueInstance,
        })
      }
      return row
    }

    // validatesの挙動を設定
    this.errorMessagesCallback = async ({
      value,
      record,
      modelName,
      initialValue,
      colDef,
      recordRoot,
      modelInputVm,
    }) => {
      const col = colDef
      const res: Record<string, string[]> = {}
      // 2. モデルフォーム全体的なvalidateを実行
      const modelValidates = this.modelDef.validates || []
      for (const validateItem of modelValidates) {
        const modelRes = await getValidateItemMessage<ModelRecordType>({
          validateItem,
          value,
          record,
          modelName,
          initialValue,
          colDef,
          recordRoot,
          modelInputVm,
        })
        if (modelRes) {
          modelRes.forEach((v, k) => {
            // res[v.colName] = v.errorMessages.map((e) => e.message))
            const errorMessages = v.errorMessages.map((e) => e.message)
            res[v.colName] = res[v.colName]
              ? res[v.colName].concat(errorMessages)
              : errorMessages
          })
          // res = Object.assign({}, res, modelRes)
        }
      }
      return res
    }

    // @ts-ignore calc defaultSort
    this.defaultSort = defaultSortDefToStringArray(modelDef.defaultSort, [
      this.columns.createdAt ? `-createdAt` : `-${this.primaryKeyColName}`,
    ])

    $appHook.emit(ModelFactory.hooks.init.after, {
      modelDefinition: modelDef,
      instance: this,
    })
    $appHook.emit(ModelFactory.hooks.byModelName(modelDef.tableName).init.after, {
      modelDefinition: modelDef,
      instance: this,
    })
  }

  get colNames() {
    return Object.keys(this.columns)
  }

  /**
   * 新規レコード作成時のデータを生成するためのメソッド
   */
  async createNew(): Promise<ModelRecordType> {
    // TODO use default value object
    const rewRec = await createNewRecordWithColumnDefs(this.columns)
    return Object.assign(
      {},
      rewRec,
      this.modelDef.defaultValues ? await this.modelDef.defaultValues() : {},
    ) as ModelRecordType
  }

  static get hooks() {
    return modelFactoryAppHooks
  }

  /**
   * @param id
   * overrideable
   */
  async findById(
    id: ID,
    findOption: { virtualModelName?: string } = {},
    fields: string[] = null,
  ) {
    if (this.overrides?.findById) {
      return this.overrides.findById(id)
    }
    const data = (
      await this._find({
        filter: { [this.primaryKeyColName]: { _eq: id } },
        fields: fields || this.defaultFieldsParamExpression,
      })
    )?.data?.[0]
    return this._formatDataAfterFetched([data], findOption?.virtualModelName)[0]
  }

  /**
   * Directus SDK の このテーブルを操作するAPIへの参照
   */
  get directusItemRef() {
    return $directusSdk.items(this.tableName)
  }

  /**
   * virtualColumn のみ取得
   */
  get virtualColumns() {
    return Object.values(this.columns).filter((c) => !!c.virtualColumnOf)
  }

  /**
   * virtualColumn 定義に従ってdataを "展開" する
   * @param data
   */
  expandDataWithVColumns(data) {
    return !this.hasVirtualColumn
      ? data
      : this.virtualColumns.reduce((res, vCol) => {
          if (data[vCol.virtualColumnOf]?.[vCol.name] !== undefined) {
            res[vCol.name] = data[vCol.virtualColumnOf]?.[vCol.name]
          }
          return res
        }, data)
  }

  /**
   * virtualColumn 定義に従ってdataを "収縮" する, 主にデータ保存前に利用する
   * @param data
   */
  compressDataWithVColumns(data) {
    if (!this.hasVirtualColumn) {
      return data
    }
    return this.virtualColumns.reduce((res, vCol) => {
      if (data[vCol.name] === undefined) {
        return res
      }
      if (
        !res[vCol.virtualColumnOf] ||
        typeof res[vCol.virtualColumnOf] !== 'object' ||
        Array.isArray(res[vCol.virtualColumnOf])
      ) {
        res[vCol.virtualColumnOf] = {}
      }
      res[vCol.virtualColumnOf][vCol.name] = data[vCol.name]
      return res
    }, data)
  }

  /**
   * 検索取得 main
   * - `.find({filter, limit, sort, virtualModelName})` を利用して、APIからデータを検索・取得可能です。
   *
   * ```js
   * // データの取得の基本: await $core.$models.[modelName].find()
   * // デフォルト 100件まで, 取得順のデフォルト: idの降順(DESC)
   * await $core.$models.selectOptionsMaster.find()
   *
   * // IDで1件のみ取得(ID 10のものを取得): findById(10)
   * await $core.$models.selectOptionsMaster.findById(10)
   *
   * // 取得件数制御, 5件のみ (デフォルト 100): find({ limit: 5 })
   * await $core.$models.selectOptionsMaster.find({ limit: 5 })
   *
   * // sort 表示順制御 (昇順): find({limit: 5, sort: 'colName'})
   * await $core.$models.selectOptionsMaster.find({limit: 5, sort: 'createdAt'}) // 作成日 昇順
   * await $core.$models.selectOptionsMaster.find({limit: 5, sort: 'value'}) // 値 昇順
   * await $core.$models.selectOptionsMaster.find({limit: 5, sort: '-createdAt'}) // 作成日 降順
   * await $core.$models.selectOptionsMaster.find({limit: 5, sort: 'group,-createdAt'}) // グループ 昇順, 作成日 降順
   * ```
   *
   * ### データの検索条件 `filter` の指定方法
   *
   * ```js
   * await $core.$models.[modelName].find({
   *   filter: {
   *     [フィールド名]: {
   *       [operator]: '値'
   *     }
   *   }
   * })
   * ```
   *
   * ```js
   * // 検索の例: グループ名が "勘定科目" で一致するものを検索
   * await $core.$models.selectOptionsMaster.find({filter: { group: { _eq: "勘定科目"}}})
   *
   * // 検索の例: グループ名に "勘定科目" が 含まれるものを検索
   * await $core.$models.selectOptionsMaster.find({filter: { group: { _contain: "勘定科目"}}})
   * ```
   *
   * #### 検索の "値" の Type定義
   *
   * ```ts
   * export type FindValueBasicTypes =
   *  | '$CURRENT_USER' // 現在のユーザID, 利用例: {filters: { editor: {_eq: '$CURRENT_USER' } }}
   *  | '$NOW' // 現在時刻, 利用例: {filters: { publishedAt: {_gte: '$NOW' } }}
   *  | string
   *  | number
   *  | boolean
   * ```
   *
   * ```js
   * // 作成者がログイン中のユーザである "選択肢マスタ" を取得
   * await $core.$models.selectOptionsMaster.find({filter: {userCreated: {_eq: '$CURRENT_USER'}}})
   * ```
   *
   * #### filter: {} の Type定義
   *
   * ```ts
   * export type FindFilterOperations = {
   *  // = (Equal)
   *  // await $core.models.selectOptionsMaster.find({ filter: { group: { _eq: "勘定科目" } } })
   *  _eq?: FindValueBasicTypes
   *  // <> (Not equal)
   *  // .find({ filter: { group: { _neq: "勘定科目" } } })
   *  _neq?: FindValueBasicTypes
   *  // LIKE "%something%"
   *  // .find({ filter: { group: { _contains: "勘定科目" } } })
   *  _contains?: string
   *  // NOT LIKE "%something%"
   *  // .find({ filter: { group: { _ncontains: "勘定科目" } } })
   *  _ncontains?: string
   *  // IN ('A', 'B', 'C')
   *  // .find({ filter: { group: { _in: ["勘定科目", "経費種別"] } } })
   *  _in?: FindValueBasicTypes[]
   *  // NOT IN ('A', 'B', 'C')
   *  // .find({ filter: { group: { _nin: ["勘定科目", "経費種別"] } } })
   *  _nin?: FindValueBasicTypes[]
   *  // 経費申請.日付(date)が 2021-10-01 〜 2021-10-31 (date型)
   *  // await $core.$models.expenses.find({ filter: { date: { _between: ["2021-10-01", "2021-10-31"] } } })
   *  // 作成日が 2021-10-01 〜 2021-10-31 (datetime型)
   *  // .find({ filter: { createdAt: { _between: ["2021-10-01 00:00:00", "2021-10-31 23:59:59"] } } })
   *  _between?: [FindValueBasicTypes, FindValueBasicTypes]
   *  // 作成日が 2021-10-01 〜 2021-10-31 ではないもの (datetime型)
   *  // .find({ filter: { createdAt: { _nbetween: ["2021-10-01 00:00:00", "2021-10-31 23:59:59"] } } })
   *  _nbetween?: [FindValueBasicTypes, FindValueBasicTypes]
   *  // > 123 (Greater than なので gt)
   *  // .find({ filter: { createdAt: { _gt: "2021-10-31 23:59:59" } } })
   *  _gt?: FindValueBasicTypes
   *  // >= '2021-01-01' (Greater than equal なので gte)
   *  // .find({ filter: { createdAt: { _gte: "2021-10-31 23:59:59" } } })
   *  _gte?: FindValueBasicTypes
   *  // < '2021-01-01' (Less than なので lt)
   *  // .find({ filter: { createdAt: { _lt: "2021-10-31 23:59:59" } } })
   *  _lt?: FindValueBasicTypes
   *  // <= '2021-01-01' (Less than equal なので lte)
   *  // .find({ filter: { createdAt: { _lte: "2021-10-31 23:59:59" } } })
   *  _lte?: FindValueBasicTypes
   *  // IS NULL
   *  _null?: true
   *  // IS NOT NULL
   *  _nnull?: true
   *  // IS EMPTY
   *  _empty?: true
   *  // IS NOT EMPTY
   *  _nempty?: true
   *  _or?: { [columnName: string]: FindFilterOperations }[] // OR 条件でネスト可能
   *  _and?: { [columnName: string]: FindFilterOperations }[] // AND 条件でネスト可能
   * }
   * ```
   *
   * OR条件の組み合わせ は 配列にします。
   * ```js
   * // filter: { _or: [{...}, {...}] }
   * // 条件1: 作成日が10月以降のもの
   * //   { createdAt: { _gte: "2021-10-01 00:00:00" } }
   * // または、条件2: group 名が 'taskStatus' のもの
   * //   { group: {_eq: 'taskStatus'} }
   * await $core.$models.selectOptionsMaster.find({
   *   filter: {
   *     _or: [
   *     { createdAt: { _gte: "2021-10-01 00:00:00" } },
   *       { group: {_eq: 'taskStatus'} },
   *     ]
   *   }
   * })
   * ```
   *
   * 上記をSQLで書くと:
   * ```sql
   * SELECT * FROM selectOptionsMaster
   *   WHERE
   *   `createdAt` >= "2021-10-01 00:00:00"
   *   OR
   *   `group` = 'taskStatus'
   * ```
   *
   * OR条件とAND条件の組み合わせ: _or と _and を正しく利用する
   * ```js
   * // filter: { _or: [ { _and: [{...}, {...}]} , {...}] }
   * // 条件1: 作成日が10月以降のもので "勘定" をグループ名に含むもの
   * //  { createdAt: { _gte: "2021-10-01 00:00:00" }, group: { _contains: "勘定"} }
   * // または、条件2: group 名が 'taskStatus' のもの
   * //  { group: {_eq: 'taskStatus'} }
   * await $core.$models.selectOptionsMaster.find({
   *   filter: {
   *     _or: [
   *       {
   *         _and: [
   *           { createdAt: { _gte: "2021-10-01 00:00:00" } },
   *           { group: { _contains: "勘定"} }
   *         ],
   *       },
   *       { group: {_eq: 'taskStatus'} },
   *     ]
   *   }
   * })
   * ```
   *
   * // SQLで書くと:
   * ```sql
   * SELECT * FROM selectOptionsMaster
   * WHERE
   *   (`createdAt` >= "2021-10-01 00:00:00" AND `group` LIKE "%勘定%" )
   *   OR
   *   (`group` = 'taskStatus')
   * ```
   *
   * @param query
   */
  async find<T = ModelRecordType>(query: FindQuery<T> | null = null): Promise<T[]> {
    // @ts-ignore TODO: なぜErrorになるか...
    const data = ((await this._find(query)).data as T[]) || []
    return this._formatDataAfterFetched(data, query?.virtualModelName) as T[]
  }

  /**
   * findの主体
   * @param query
   * @private
   */
  public async _find(
    query,
  ): Promise<{ data?: any[]; meta?: { filter_count?: number; total_count?: number } }> {
    if (this.overrides?.find) {
      return this.overrides.find(query)
    }
    return this.defaultFind(query)
  }

  /**
   * デフォルトの検索処理
   * @param query
   */
  public async defaultFind(
    query,
  ): Promise<{ data?: any[]; meta?: { filter_count?: number; total_count?: number } }> {
    // query が大きすぎるときは、別のEndpoint POSTメソッドを使う
    if (this._shouldFetchItemsFromSearchItemsEndpoint(query)) {
      // @ts-ignore
      return $core.$d.transport.post(`/core/searchItems/${this.tableName}`, { query })
    }
    return this.directusItemRef.readByQuery(this._fixQuery(query))
  }

  private _fixQuery(query) {
    if (!query) {
      return {}
    }
    if (typeof query.sort === 'string' && query.sort) {
      query.sort = query.sort.split(',')
    }
    return query
  }

  _shouldFetchItemsFromSearchItemsEndpoint(query): boolean {
    if (query?.limit === -1) {
      return true
    }
    // query param が URL として長過ぎるかどうか for CloudFront or other CDN or managed services
    return query?.filter && JSON.stringify(query).length > MAX_FIND_URL_QUERY_BYTE_SIZE
  }

  /**
   * データ取得後の整形
   * @param data
   * @param virtualModelName
   */
  _formatDataAfterFetched<T = ModelRecordType>(
    data: T[],
    virtualModelName: string = null,
  ): T[] {
    return data.map((d) => {
      if (!d) {
        return d
      }
      if (virtualModelName && $core.$virtualModels[virtualModelName]?.hasVirtualColumn) {
        d = $core.$virtualModels[virtualModelName].expandDataWithVColumns(d)
      }
      if (this.hasVirtualColumn) {
        d = this.expandDataWithVColumns(d)
      }
      // record.id compat
      if (this.primaryKeyColName !== 'id') {
        Object.defineProperty(d, 'id', {
          enumerable: false,
          configurable: false,
          value: d[this.primaryKeyColName],
          writable: false,
        })
      }
      // TODO: 下記に移行したほうがよい...？ そんなコト無いかも...？
      // Object.defineProperty(d, '__pKey', {
      //   enumerable: false,
      //   configurable: false,
      //   value: d[this.primaryKeyColName],
      //   writable: false
      // })
      return d
    })
  }

  /**
   * 1件取得
   * @param query
   */
  async findOne<T = ModelRecordType>(query: FindQuery<T> = {}): Promise<T> {
    query.limit = 1
    const res = await this.find(query)
    return res[0]
  }

  /**
   * TODO: 削除したい 検索
   * @deprecated
   */
  get findAll(): <ModelDataType = ModelRecordType>(
    query?: FindQuery<ModelDataType>,
  ) => Promise<ModelDataType[]> {
    return this.find
  }

  /**
   * 単純なequal合致の組み合わせによる検索を提供
   * ```ts
   * $core.$models[modelName].findAllBy({userId: 'xxx-xx-xx-xxx'})
   * ```
   * @deprecated
   */
  findAllBy<ModelDataType = ModelRecordType>(
    query: { [fieldName: string]: any },
    noLimit = false,
    addtionalParams = null,
  ): Promise<ModelDataType[]> {
    // query: FindQuery<ModelDataType>
    query = backwardCompatFindFilterStringIntoFindFilter(query)
    // @ts-ignore
    let param: FindQuery<ModelDataType> = { filter: query }
    if (noLimit === true) {
      param.limit = -1
    }
    if (addtionalParams) {
      // @ts-ignore
      param = Object.assign({}, param, addtionalParams)
    }
    return this.find(param)
  }

  /**
   * 検索取得 with total count
   * @param query
   */
  async findWithCount<ModelDataType = ModelRecordType>(
    query: FindQuery<any>,
  ): Promise<ManyItems<ModelDataType>> {
    if (!query.meta) {
      // @ts-ignore
      query.meta = ['filter_count']
    }
    const { data, meta } = await this._find(query)
    return {
      data: this._formatDataAfterFetched(data, query?.virtualModelName),
      meta,
    }
  }

  /**
   * Find by ids
   * @param ids
   * @param query
   */
  async findByIds<ModelDataType = ModelRecordType>(
    ids: ID[],
    query: FindQuery<ModelDataType> = {},
  ): Promise<ModelDataType[]> {
    return this.find<ModelDataType>({
      ...query,
      // @ts-ignore
      filter: {
        [this.primaryKeyColName]: {
          _in: ids,
        },
      },
    })
  }

  /**
   * TODO: 🍊 削除するか?
   * @param facets
   * @param filters
   */
  async fetchFacets(
    facets: string[] = ['*'],
    filters: findAllByArgument = '',
  ): Promise<{ [colName: string]: { [valueName: string]: number } }> {
    return {}
  }

  /**
   * ある1つのカラム名で、facetを取得する
   */
  async fetchFacetNamesByColName(colName: string): Promise<string[]> {
    try {
      return (
        await this.find({
          groupBy: [colName],
          limit: -1,
        })
      ).map((r) => r[colName])
    } catch (e) {
      return []
    }
  }

  async countBy(filters: FindFilter<any> | string): Promise<number> {
    filters = backwardCompatFindFilterStringIntoFindFilter(filters)
    const res = await this.findWithCount({
      filter: filters,
      limit: 1,
    })
    return res?.meta?.filter_count || 0
  }

  /**
   * 検索フィルタ絞り込みに利用できるカラムを返す
   */
  get filterableColumns(): ColumnDef[] {
    return Object.values(this.columns).filter(
      (col: ColumnDef) =>
        col.visible !== false && col.searchBehavior !== false && !col.virtualColumnOf,
    ) as ColumnDef[]
  }
}

/**
 * V2 => V3 互換のためのString => Object:FindFilter する
 * Algoliaの検索を文字列でぶっこんでいたため、そういう事になっている
 * @param filters
 */
export const backwardCompatFindFilterStringIntoFindFilter = <T = any>(
  filters: string | FindFilter<T>,
): FindFilter<T> => {
  if (typeof filters !== 'string') {
    return filters
  }
  console.warn(`[Chageme:backwardCompatFindFilterStringIntoFindFilter] filters:`, filters)
  const ANDSplited = filters.split(' AND ')
  const filterObject: FindFilter<T> = ANDSplited.reduce((res, keyVal: string) => {
    const [key, val] = keyVal.split(':')
    // is object, {_in: ['some', 'other']} など、クエリですでに定義されているならそのまま返却
    res[key] = typeof val === 'object' && val !== null ? val : { _eq: val }
    return res
  }, {})
  console.warn(
    `[Chageme:backwardCompatFindFilterStringIntoFindFilter] filterObject:`,
    filterObject,
  )
  return filterObject
}
