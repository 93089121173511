<template>
  <div class="multi-file-upload-input">
    <!-- ファイルプレビューエリア -->
    <div
      class="preview-area"
      v-if="files.length"
    >
      <div
        v-for="(file, index) in files"
        :key="file"
        class="file-item"
      >
        <InputImageUploader
          v-model="files[index]"
          :maxImageWidth="maxImageWidth"
          :maxImageHeight="maxImageHeight"
          :filePathPrefix="filePathPrefix"
          :filePathGenerateFunction="filePathGenerateFunction"
          :disableEdit="disableEdit || disabled"
          :disabled="disabled"
          :enableDeleteButton="true"
          @update:modelValue="handleFileUpdate(index, $event)"
          :layoutType="'compact'"
        />
      </div>
    </div>

    <!-- 追加ボタン -->
    <div
      v-if="!shouldDisableAddButton"
      class="add-button-area"
    >
      <FileUploader
        :multiple="true"
        :filePathPrefix="filePathPrefix"
        :filePathGenerateFunction="filePathGenerateFunction"
        :buttonText="buttonText || `ファイルを追加${maxFilesText}`"
        :compact="files.length > 0"
        @upload-complete="handleUploadComplete"
      />
    </div>
  </div>
</template>

<script lang="ts">
import FileUploader from './FileUploader.vue'
import InputImageUploader from './InputImageUploader.vue'
import { uploadPathPrefixProps } from './uploadPathPrefixProps'

export default {
  name: 'MultiFileUploadInput',
  components: {
    InputImageUploader,
    FileUploader,
  },
  props: {
    modelValue: {}, // string | string[]
    maxFiles: { type: Number, default: null },
    maxImageWidth: { type: String, default: '150px' },
    maxImageHeight: { type: String, default: '150px' },
    buttonText: { type: String, default: '' },
    ...uploadPathPrefixProps,
    disableEdit: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      files: [],
    }
  },
  computed: {
    maxFilesText() {
      return this.maxFiles ? `(${this.files.length}/${this.maxFiles})` : ''
    },
    shouldDisableAddButton() {
      if (this.disableEdit || this.disabled) {
        return true
      }
      return !!(this.maxFiles && this.files.length >= this.maxFiles)
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        this.files = this.parseValue(val)
      },
    },
  },
  methods: {
    /**
     * 文字列または配列の値を配列に変換
     */
    parseValue(val) {
      if (Array.isArray(val)) return val
      return val ? val.split(',').filter((v) => v) : []
    },

    /**
     * 特定のインデックスのファイルが更新された時の処理
     * @param {number} index - 更新対象のファイルのインデックス
     * @param {string} value - 新しいファイルパス（削除時は空文字列）
     */
    handleFileUpdate(index, value) {
      try {
        if (!value) {
          // 削除の場合
          if (index >= 0 && index < this.files.length) {
            // 配列から要素を削除
            this.files.splice(index, 1)
            // 配列を詰めて再構築（不要な空要素を除去）
            this.files = this.files.filter((f) => f)
          }
        } else {
          // 更新の場合
          if (index >= 0 && index < this.files.length) {
            this.files[index] = value
          }
        }
        // modelValueを更新
        this.updateModelValue()
      } catch (error) {
        console.error('Error in handleFileUpdate:', error)
        $core.$toast.errorToast('ファイルの更新中にエラーが発生しました')
      }
    },

    /**
     * アップロード完了時の処理
     * @param {string[]} filePaths アップロードされたファイルのパス配列
     */
    handleUploadComplete(filePaths) {
      // 配列でない場合は配列に変換
      const paths = Array.isArray(filePaths) ? filePaths : [filePaths]

      // 最大ファイル数を超えないように追加
      const remainingSlots = this.maxFiles
        ? this.maxFiles - this.files.length
        : paths.length
      const newFiles = paths.slice(0, remainingSlots)

      if (newFiles.length) {
        this.files.push(...newFiles)
        this.updateModelValue()
      }

      // 最大数を超えた場合は通知
      if (paths.length > remainingSlots) {
        $core.$toast.warningToast(
          `最大ファイル数(${this.maxFiles})を超えるため、${paths.length - remainingSlots}件のファイルは追加されませんでした。`,
        )
      }
    },

    /**
     * modelValueの更新
     */
    updateModelValue() {
      const value = Array.isArray(this.modelValue) ? this.files : this.files.join(',')
      this.$emit('update:modelValue', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.multi-file-upload-input {
  .preview-area {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .file-item {
    position: relative;
    //padding: 0.25rem;
    //border: 1px solid #dee2e6;
    border-radius: 0.25rem;

    // コンパクト表示用のスタイル
    :deep(.input-image-uploader) {
      .btn {
        margin-top: 0.25rem !important;
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem;
      }

      code {
        font-size: 0.75rem;
        word-break: break-all;
      }
    }
  }

  .add-button-area {
    // コンパクト表示用のスタイル
    :deep(.input-image-uploader) {
      .btn {
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem;
      }
    }
  }
}
</style>
