import { ModelDef } from '../../common/$models/ModelDef'
import { convertDBDefinedModelToModelDefObject } from '../../common/modelDefinitions/convertDBDefinedModelToModelDefObject'
import { DBDefinedModelDefinitionColumns } from '../../common/modelDefinitions/DBDefinedModelDefinitionColumns'

export interface ModelDirectusUsers {
  id?: string
  first_name: string
  email?: string
  password?: string
  status?: string
  isAdmin: boolean
  coreRoles?: string[]
  role?: string | Record<string, any>
  metaData?: any
  organizations?: any[]
  thumbImg?: string
  [key: string]: any
}

/**
 * DBDefinedModelDefinitionColumns 形式での directus_users モデル定義
 */
export const directus_users__dbDefineable: DBDefinedModelDefinitionColumns = {
  id: '5282ce02-22cf-472a-83ed-444bc850f19a',
  tableName: 'directus_users',
  tableLabel: 'ユーザ管理',
  tableComment: '',
  timestamps: true,
  defaultSort_key: 'id',
  defaultSort_order: 'desc',
  primaryKeyColType: 'UUID',
  otherModelAttributes: `return {
    modelType: 'admin'
  }`,
  columns: [
    {
      key: 'id',
      type: 'UUID',
      editable: false,
      visibleOnIndex: false,
      otherColAttributes: `return {
        enableIf: (row) => !!row.id
      }`,
    },
    {
      key: 'first_name',
      label: '名前',
      type: 'STRING',
      validationConfigs: [{ validationType: 'notEmpty' }],
    },
    {
      key: 'email',
      label: 'メールアドレス',
      type: 'STRING',
      unique: true,
      validationConfigs: [{ validationType: 'notEmpty' }, { validationType: 'isEmail' }],
    },
    {
      key: 'shouldChangePassword',
      label: 'パスワードを変更する',
      type: 'BOOLEAN',
      visibleOnIndex: false,
      otherColAttributes: `return {
        enableIf: (row) => !!row.id
      }`,
    },
    {
      key: 'password',
      label: 'パスワード',
      type: 'STRING',
      visibleOnIndex: false,
      validationConfigs: [
        { validationType: 'notEmpty' },
        {
          validationType: 'customFunc',
          customCodeExpression: `return $core.$utils.validator.passwordValidator(value) ? false : 'パスワードは8文字以上で、英数字を含む必要があります。'`,
        },
      ],
      enableIfByFilterQuery: {
        _and: [
          {
            shouldChangePassword: { _eq: true },
          },
        ],
      },
    },
    {
      key: 'status',
      label: '有効状態',
      type: 'STRING',
      defaultValue: 'active',
      inputHelpText: '"停止" にすると、ログインできなくなります。',
      otherColAttributes: `return {
        doNotSyncModel: true,
        selections: () => ['active', 'suspended'],
        customLabel: (val) => {
          const labels = {
            active: '有効',
            suspended: '停止'
          }
          return \`\${labels[val]} (\${val})\`
        }
      }`,
    },
    {
      key: 'isAdmin',
      label: '管理者ロール',
      type: 'BOOLEAN',
      defaultValue: false,
      inputHelpText:
        'すべてのデータへのアクセス、更新を許可します。管理者権限の場合は、定義したロールを割り当てることはできません。',
    },
    {
      key: 'coreRoles',
      label: 'ロール(権限)',
      type: 'MULTISELECT',
      inputHelpText: '画面制御に関わる権限を設定',
      otherColAttributes: `return {
        enableIf: (row) => !row.isAdmin,
        async selections() {
          const roles = await $core.$models.user_roles.find()
          return roles.map((r) => r.key)
        }
      }`,
    },
    {
      key: 'role',
      label: 'APIロール',
      type: 'RELATIONSHIP_MANY_TO_ONE',
      visibleOnIndex: false,
      relationshipManyToOne_collectionName: 'directus_roles',
      relationshipManyToOne_labelFormatter: 'return row.name',
      otherColAttributes: `return {
  doNotSyncModel: true,
  afterComponents: [
    {
      template: \`<div v-if="isEmptyValue" class="alert alert-warning small p-1">⚠️ APIロールを付与しない場合、 public API ロールが設定されていない限り、ユーザは全てのデータにアクセスできません。</div>\`,
      props: ['record'],
      computed: {
        isEmptyValue() {
          return !this.record.role
        }
      }
    }
  ]
}`,
    },
    {
      key: 'metaData',
      type: 'ARRAY_OF_OBJECT',
      adminComment: 'ユーザのメタデータを保存するフィールド',
      visible: false,
      editable: false,
    },
    {
      key: 'organizations',
      label: '所属組織',
      type: 'RELATIONSHIP_ONE_TO_MANY',
      visible: false,
      relationshipOneToMany_collectionName: 'junctionUsersAndOrgsAndPositions',
      relationshipOneToMany_fieldName: 'user',
      otherColAttributes: `return {
        inputAttrs: { wrapperClass: 'col-12' }
      }`,
    },
    {
      key: 'thumbImg',
      label: 'プロフィール画像',
      type: 'FILE',
      visible: false,
      inputComponent: 'inputImageUploader',
    },
  ],
}

/**
 */
export const directus_users: ModelDef = convertDBDefinedModelToModelDefObject(
  directus_users__dbDefineable,
)
