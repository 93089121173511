import { addModelDoc } from '../../plugins/HelpDoc/coreDocsHelperFunctions'
import { ModelDef } from '../../types'
import { convertDBDefinedModelToModelDefObject } from '../modelDefinitions/convertDBDefinedModelToModelDefObject'
import { DBDefinedModelDefinitionColumns } from '../modelDefinitions/DBDefinedModelDefinitionColumns'

const modelName = 'selectOptionsMaster'
addModelDoc(
  modelName,
  '選択肢マスタは、各カラムの選択肢を簡易的に管理するためのマスタです。モデル定義 > カラム設定 内の "選択肢マスタ指定" でグループ名を指定することで、このマスタの値を選択肢として利用することが可能です。',
)

/**
 * DBDefinedModelDefinitionColumns形式でのselectOptionsMasterモデル定義
 */
export const selectOptionsMaster__dbDefineable: DBDefinedModelDefinitionColumns = {
  id: '436459e4-2449-4d45-ae09-4cf4cb917a96', // UUID v4
  tableName: modelName,
  tableLabel: '選択肢マスタ',
  primaryKeyColType: 'UUID',
  defaultSort_key: 'group',
  defaultSort_order: 'asc',
  otherModelAttributes: `return {
  bulkEditable: true,
  modelType: 'admin',
  extensions: [
    {
      key: 'defaultCachedFind',
      extensionType: 'cachedFindable',
      enabled: true,
      cacheTTLSeconds: 60 * 5, // キャッシュの有効期限 (秒)
      purgeCacheOnUpdate: true, // 更新時にキャッシュをパージするかどうか
    },
  ]
}`,
  columns: [
    {
      key: 'group',
      label: 'グループ',
      type: 'STRING',
      unique: false,
      validationConfigs: [{ validationType: 'notEmpty' }],
      otherColAttributes: `return {
        selectionsWithColNameFacet: 'group',
        strictSelections: false,
        dynamicSelections: true,
        facet: { showOnSearch: true }
      }`,
    },
    {
      key: 'value',
      label: '値',
      type: 'STRING',
      validationConfigs: [{ validationType: 'notEmpty' }],
      otherColAttributes: `return {
        unique: {
          conditions: (record) => ({
            group: {
              _eq: record.group,
            },
          }),
        },
        facet: true
      }`,
    },
    {
      key: 'category',
      label: '中分類',
      type: 'STRING',
      otherColAttributes: `return {
        facet: { showOnSearch: true }
      }`,
    },
    {
      key: 'sort',
      label: '表示順(昇順)',
      type: 'NUMBER',
      unique: false,
      defaultValue: 0,
      validationConfigs: [{ validationType: 'isInt' }],
    },
  ],
}

/**
 * ModelDef形式でのselectOptionsMasterモデル定義
 */
export const selectOptionsMaster: ModelDef = convertDBDefinedModelToModelDefObject(
  selectOptionsMaster__dbDefineable,
)
