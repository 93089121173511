<template>
  <div
    class="d-flex flex-fill py-1"
    style="position: relative"
  >
    <div
      class="filter-logic text-right mr-1"
      v-if="filterObject"
    >
      <span v-if="filterObject.isFirstObject">条件:</span>
      <b-form-select
        v-else-if="filterObject.isSecondObject"
        class="flex-1 text-uppercase"
        :modelValue="operator"
        @update:modelValue="updateOperator"
        :options="options"
        size="sm"
      ></b-form-select>
      <span
        class="text-uppercase"
        v-else
        >{{ filterObject.parentFilterGroupService.filterLogic }}</span
      >
    </div>
    <div
      class="flex-fill"
      :class="{ group_back: !!filterObject && filterObject.objectType !== 'rule' }"
    >
      <component
        v-for="(filterObject, index) in calcedFilterObjects"
        :key="index"
        :is="filterObjectComponent(filterObject)"
        :filterObject="filterObject"
      />
      <AppendFilterObject />
    </div>

    <FilterItemManagerForCustom
      @onDismiss="() => (isVisibleSetting = false)"
      :visible="true"
    />
  </div>
</template>
<script lang="ts">
import { computed, inject } from 'vue'
import { FilterControlsService } from '../../../FilterControlsService'
import { FilterGroupService } from '../../../FilterGroupService'
import FilterItemManagerForCustom from '../FilterItemManagerForCustom.vue'
import AppendFilterObject from './AppendFilterObject.vue'
export default {
  components: {
    FilterItemManagerForCustom,
    AppendFilterObject,
  },
  provide() {
    return {
      filterObject: computed(() => this.filterObject),
    }
  },
  props: {
    filterObject: {
      type: FilterGroupService,
      required: true,
      default: null,
    },
  },
  setup() {
    return {
      FilterControlsServiceInstance: inject<FilterControlsService>(
        'FilterControlsServiceInstance',
      ),
    }
  },
  data() {
    return {
      operator: 'and',
      options: ['and', 'or'],
      isVisibleSetting: false,
    }
  },
  computed: {
    calcedFilterObjects() {
      if (this.filterObject) {
        return this.filterObject.filterObjects
      } else {
        return this.FilterControlsServiceInstance.filterGroupService.filterObjects
      }
    },
  },
  methods: {
    filterObjectComponent(filterObject) {
      if (filterObject.objectType === 'rule') {
        return 'ModernFilterRule'
      } else {
        return 'ModernFilterWrapper'
      }
    },
    toggleSetting() {
      this.isVisibleSetting = !this.isVisibleSetting
    },
    updateOperator(val) {
      this.operator = val
      this.filterObject.updateOperator(val)
    },
  },
}
</script>
