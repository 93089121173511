<template>
  <div style="position: relative">
    <div class="d-flex align-items-center">
      <span class="column-label mr-2">{{ label }}</span>
      <b-form-select
        class="flex-1"
        :modelValue="operator"
        @update:modelValue="updateOperator"
        :options="options"
        size="sm"
      ></b-form-select>
      <a
        role="button"
        @click="toggleSetting"
      >
        <Ficon
          class="ml-2"
          type="ellipsis-h"
        />
      </a>
    </div>
    <div
      class="mt-1"
      v-if="operator !== 'isnull' && operator !== 'isnotnull'"
    >
      <b-form-input
        ref="filterItemFormInput"
        size="sm"
        :modelValue="modelValue"
        @update:modelValue="change"
      />
    </div>
    <FilterItemManager
      :visible="isVisibleSetting"
      @onDismiss="onDismissDropDown"
    />
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { FilterItemService } from '../../../FilterItemService'
import {
  FILTER_OPERATOR_FOR_STRING,
  FILTER_OPERATOR_FOR_UUID,
} from '../../../FilterRuleService'
import FilterItemManager from '../FilterItemManager.vue'

export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  components: {
    FilterItemManager,
  },
  setup() {
    return {
      filterItemService: inject<FilterItemService<any>>('filterItemService'),
    }
  },
  data() {
    return {
      operator: null,
      isVisibleSetting: false,
    }
  },
  computed: {
    label() {
      return this.filterItemService.colDef.label || this.filterItemService.colDef.name
    },
    options() {
      // { value: xxxx'', text: 'xxxxx' }のような形で返す
      const op =
        this.filterItemService.colDef.type === 'UUID'
          ? FILTER_OPERATOR_FOR_UUID
          : FILTER_OPERATOR_FOR_STRING
      return Object.keys(op).map((operator) => {
        return {
          value: operator,
          text: op[operator],
        }
      })
    },
  },
  watch: {
    'filterItemService.isFocus'(val) {
      if (val && this.$refs.filterItemFormInput) {
        this.$refs.filterItemFormInput.focus()
      }
    },
  },
  created() {
    this.operator = this.options.length > 0 ? this.options[0].value : null
    setTimeout(() => {
      this.$refs?.filterItemFormInput?.focus?.()
    }, 100)
  },
  methods: {
    toggleSetting() {
      this.isVisibleSetting = !this.isVisibleSetting
    },
    change(value) {
      this.$emit('update:modelValue', {
        value,
        operator: this.operator,
      })
    },
    updateOperator(value) {
      this.operator = value
      this.$emit('update:operator', this.operator)
    },
    onDismissDropDown() {
      this.$emit('onDismissDropdown')
    },
  },
}
</script>
