<template>
  <component
    v-bind="wrapTagAttrs"
    v-if="shouldEnabled"
    v-show="shouldVisible"
    class="model-form-group form-group hover-show-parent"
    :class="modelColClass"
    :style="modelColStyle"
    :is="wrapTag"
  >
    <AdminRoleOnly
      v-if="
        $core.$models[modelName]?.isDefinedOnDb &&
        $core.$models[modelName]?.columns?.[colName] &&
        !disableColDefEditLink
      "
      class="hover-show-target model-column-definition-editor-link"
      style="position: absolute; top: 0; right: 0; font-size: 11px"
    >
      <a
        href="#"
        v-single-click="
          () =>
            $core.$modals.openModal({
              component: 'ModelColumnDefinitionEditor',
              componentProps: {
                modelName,
                colName,
              },
            })
        "
        >カラム定義を編集</a
      >
    </AdminRoleOnly>
    <div
      v-if="colDef.hideLabel !== true && !hideLabel"
      class="small model-form-group--row-colname"
    >
      <span class="badge border border-secondary text-secondary rounded-pill">
        {{ colName }}
      </span>
    </div>
    <label
      class="model-form-group--label"
      v-if="shouldDisplayLabel"
    >
      <!-- dirtyだが... -->
      <span
        v-if="colDef?.comment"
        v-b-tooltip.hover="
          colDef?.comment ? `<div class='text-left'>${colDef.comment}</div>` : undefined
        "
      >
        <span
          ref="modelFormGroupLabelText"
          class="model-form-group--label--text"
          >{{ displayColumnLabel }}</span
        >
        <span
          v-if="colDef.validate && colDef.validate.notEmpty"
          class="model-form-group--not-empty-mark"
        />
        <helpicon v-if="colDef.comment" />
      </span>
      <span v-else>
        <span
          ref="modelFormGroupLabelText"
          class="model-form-group--label--text"
          >{{ displayColumnLabel }}</span
        >
        <span
          v-if="colDef.validate && colDef.validate.notEmpty"
          class="model-form-group--not-empty-mark"
        />
      </span>
    </label>
    <modelInput
      ref="modelInput"
      v-bind="modelInputBinds"
      @update:value-and-error="(ev) => change(ev)"
    />
    <template
      v-if="afterComponents.length"
      v-for="(_ac, index) in afterComponents"
      :key="index"
    >
      <component
        class="model-form-group--after-component"
        v-bind="modelInputBinds"
        :is="_ac"
        @update:value-and-error="(ev) => change(ev)"
      ></component>
    </template>
  </component>
</template>

<script lang="ts">
import { ColumnDef, ColumnTypes } from '../../common/$models/ModelDef'
import { matchesFilter } from '../../plugins/ComposableDataListComponents/front/FilterControlsService'

export default {
  name: 'ModelFormGroup',
  props: {
    value: {},
    ModelFormService: {},
    record: { default: () => ({}) },
    recordRoot: {},
    colName: { required: true },
    passedColDefinition: { required: false },
    modelName: { default: '' },
    inputAttrs: { required: false, default: () => Object },
    virtualModelName: { required: false },
    forceEnable: { required: false, default: false },
    forceEditable: { required: false, default: false },
    readOnlyMode: { required: false, default: null },
    shouldEnableFormGUIEditMode: { required: false, default: null },
    hideLabel: { required: false, default: false },
    disableColDefEditLink: { required: false, default: false },
  },
  emits: ['update:value-and-error'],
  data() {
    return {
      v: '',
      isEnabled: true,
      initialized: false,
    }
  },
  computed: {
    col(): ColumnDef {
      return (
        this.passedColDefinition || $core.$models[this.modelName].columns[this.colName]
      )
    },
    colDef(): ColumnDef {
      // deepmerge 対象の props が存在するため...
      return this.vModelCol || this.col
    },
    vModelCol() {
      return (
        $core.$virtualModels[this.virtualModelName]?.columnsMergedWithBaseModel?.[
          this.colName
          ] || null
      )
    },
    modelColClass() {
      return [
        this.colDef.inputAttrs?.wrapperClass || 'col-12 col-sm-4 col-md-3',
        ` model-form-group-col model-form-group-col-${
          this.colName
        } model-form-group-colType-${
          this.colDef.type
        } model-form-group-col--group-key-${this.colDef.groupKey || 'default'}`,
        this.colDef?.width
          ? Object.keys(this.colDef?.width).map((key) =>
              this.colDef.width[key] ? `col-${key}-48-${this.colDef.width[key]}` : '',
            )
          : [],
      ]
    },
    modelColStyle() {
      return [
        this.colDef.inputAttrs?.wrapperStyle || '',
        this.colDef.orderOnForm !== undefined ? `order: ${this.colDef.orderOnForm};` : '',
      ]
    },
    // 新規レコード作成時に、ONE TO MANY であれば表示させない
    isRelationshipO2MNewRecord() {
      return (
        this.ModelFormService?.$modelFormVueInstance?.isNewRecord === true &&
        this.colDef.type === ColumnTypes.RelationshipOneToMany &&
        !this.colDef.inputComponent
      )
    },
    shouldVisibleWithGroupState() {
      return (
        !this.colDef.groupKey ||
        this.ModelFormService?.visibleStateByColumnGroupName[
          this.ModelFormService.columnGroupParentKeyByChildGroupKey[this.colDef.groupKey]
        ] === this.colDef.groupKey
      )
    },
    shouldVisible() {
      return (
        this.colDef.visible !== false &&
        !(this.isRelationshipO2MNewRecord && this.colDef.editableOnCreate !== true) &&
        this.shouldVisibleWithGroupState
      )
    },
    shouldEnabled() {
      if (!this.initialized) {
        return false
      }
      if (this.colDef.visible === false) {
        return false
      }
      return this.isEnabled
    },
    afterComponent() {
      if (!this.colDef.afterComponent) {
        return null
      }
      if (typeof this.colDef.afterComponent === 'string') {
        return {
          template: `<div>${this.colDef.afterComponent}</div>`,
        }
      }
      return this.colDef.afterComponent
    },
    afterComponents() {
      return [
        ...(this.afterComponent ? [this.afterComponent] : []),
        ...(this.colDef.afterComponents || []),
      ].map((component) => {
        if (typeof component === 'string') {
          return component
        }
        return component
      })
    },
    wrapTag() {
      return this.colDef.formGroup?.tag || 'div'
    },
    wrapTagAttrs() {
      const attr = this.colDef.formGroup?.attrs || {}
      // groupKey を Element で付与できるように。 GUI Edit mode などで利用。
      attr['data-col-group-key'] = this.colDef.groupKey || null
      attr['data-col-name'] = this.colDef.name
      return attr
    },
    shouldDisplayLabel() {
      if (this.colDef.hideLabel === true) {
        return false
      }
      if (
        this.colDef.type === 'BOOLEAN' &&
        !this.colDef.inputComponent &&
        this.ModelFormService?.formStyleType !== 'table'
      ) {
        return false
      }
      if (this.hideLabel) {
        return false
      }
      return true
    },
    displayColumnLabel() {
      return this.col?.label || this.colName
    },
    modelInputBinds() {
      return {
        value: this.value,
        col: this.colDef,
        record: this.record,
        colName: this.colName,
        recordRoot: this.recordRoot,
        modelName: this.modelName,
        inputAttrs: this.inputAttrs,
        forceEnable: this.forceEnable,
        forceEditable: this.forceEditable,
        readOnlyMode: this.readOnlyMode,
        virtualModelName: this.virtualModelName,
        ModelFormService: this.ModelFormService,
      }
    },
  },
  watch: {
    isEnabled(newValue, oldValue) {
      this.$nextTick(async () => {
        if (newValue === false && newValue !== oldValue) {
          this.change({ value: this.value, error: '' })
        }
      })
    },
    forceEnable: {
      handler() {
        this.calculateIsEnabled()
      },
      deep: true,
    },
    colDef: {
      handler() {
        this.calculateIsEnabled()
      },
      deep: true,
    },
    record: {
      handler() {
        this.calculateIsEnabled()
      },
      deep: true,
    },
  },
  async created() {
    this.v = this.value
    await this.calculateIsEnabled()
    this.initialized = true
  },
  methods: {
    async calculateIsEnabled() {
      this.isEnabled = await this.calculateIsEnabledValue()
    },
    async calculateIsEnabledValue() {
      // TODO: Permission箇所再構築
      if (this.colDef.enableIf) {
        if (typeof this.colDef.enableIf === 'function') {
          return await this.colDef.enableIf(this.record, this.recordRoot)
        } else {
          return (
            Object.keys(this.colDef.enableIf).filter(
              (keyName) => !(this.record[keyName] === this.colDef.enableIf[keyName]),
            ).length === 0
          )
        }
      } else {
        if (this.colDef.enableIfByFilterQuery) {
          return (
            matchesFilter(this.colDef.enableIfByFilterQuery, [this.record]).length > 0
          )
        } else {
          return true
        }
      }
    },
    change(ev) {
      this.$emit('update:value-and-error', {
        value: ev.value,
        error: ev.error,
        modelInputVm: ev.modelInputVm,
      })
    },
  },
}
</script>
<style lang="scss">
.model-form-group--not-empty-mark {
  color: var(--bs-danger);
  &::before {
    content: '*';
    padding-left: 3px;
  }
}
.input-array-of-object {
  .model-column-definition-editor-link {
    display: none !important;
  }
}
</style>
