import { defineAsyncComponent } from 'vue'
import { ColumnDefByColName } from '../../../../../common/$models/ModelDef'
import { registerComposableComponentSettings } from '../../../../../plugins/ComposableComponents'

/**
 * CSS display flex のためのプロパティ
 */
export const flexContainerProps: ColumnDefByColName = {
  display: {
    type: 'STRING',
    defaultValue: 'flex',
    selections: () => ['flex', 'inline-flex'],
    validate: { notEmpty: true },
  },
  'column-gap': {
    type: 'STRING',
    defaultValue: '10',
    // validate: { notEmpty: true },
    // editCallback: ({ row, newValue, oldValue }) => {
    //   if (newValue !== oldValue && newValue && /^\d+$/.test(newValue)) {
    //     row['column-gap'] = `${newValue}px`
    //   }
    //   return row
    // },
  },
  'row-gap': {
    type: 'STRING',
    defaultValue: '10',
    // validate: { notEmpty: true },
    // editCallback: ({ row, newValue, oldValue }) => {
    //   if (newValue !== oldValue && newValue && /^\d+$/.test(newValue)) {
    //     row['row-gap'] = `${newValue}px`
    //   }
    //   return row
    // },
  },
  'flex-direction': {
    type: 'STRING',
    defaultValue: 'row',
    selections: () => ['row', 'row-reverse', 'column', 'column-reverse'],
    editCallback: ({ row, newValue, oldValue }) => {
      if (newValue !== oldValue) {
        row['flex-wrap'] = newValue?.indexOf('row') >= 0 ? 'wrap' : ''
      }
      return row
    },
  },
  'flex-wrap': {
    type: 'STRING',
    defaultValue: 'wrap',
    selections: () => ['nowrap', 'wrap', 'wrap-reverse'],
  },
  'justify-content': {
    type: 'STRING',
    defaultValue: '',
    selections: () => [
      'flex-start',
      'flex-end',
      'center',
      'space-between',
      'space-around',
      'space-evenly',
    ],
  },
  'align-items': {
    type: 'STRING',
    defaultValue: '',
    selections: () => ['stretch', 'flex-start', 'flex-end', 'center', 'baseline'],
  },
  'align-content': {
    type: 'STRING',
    defaultValue: '',
    selections: () => [
      'stretch',
      'flex-start',
      'flex-end',
      'center',
      'space-between',
      'space-around',
    ],
  },
}

export const addPxIfNumeric = (value: string) => {
  if (value && /^\d+$/.test(value)) {
    return `${value}px`
  }
  return value
}

export const registerFlexComponentsContainer = (Vue) => {
  registerComposableComponentSettings('FlexComponentsContainer', {
    label: 'Flex コンテナー',
    category: 'container',
    configColumns: {
      ...flexContainerProps,
    },
    hasDefaultSlot: true,
    // TODO: named slot への対応... これ、アイテムを移動させたときに色々ややこしいのでどうしたもんかという感じ
    // namedSlots: ['header', 'footer'],
    defaultProps: {
      'column-gap': '10',
      'row-gap': '10',
      'flex-wrap': 'wrap',
    },
  })
  Vue.component(
    'FlexComponentsContainer',
    defineAsyncComponent(() => import('./FlexComponentsContainer.vue')),
  )
}
