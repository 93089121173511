<template>
  <div
    v-if="itemsArray"
    class="row py-2"
  >
    <div
      v-for="item in itemsArray"
      :key="item.id"
      class="col-12 col-sm-3 col-md-2 mb-3"
    >
      <div class="card d-flex flex-column justify-content-between h-100 bg-light">
        <div
          class="modelFileItemImgArea"
          v-single-click="
            (event) =>
              DataListDisplayServiceInstance.invokeItemClickFunction(item.id, event, item)
          "
          :style="{
            backgroundImage: `url(${$core.$imgix.minify(item.filePath)})`,
          }"
        ></div>
        <div class="card-footer _p-1 small bg-white border-gray">
          {{ item.name }}
          <a
            @click.prevent="$core.$imgix.openFileLink(item.filePath)"
            href="#"
            class="float-right ignoreItemClick d-block clearfix"
          >
            <ficon
              type="external-link-alt"
              class="ignoreItemClick"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
/**
 * Called from
 * $core.$appHook.on('modelIndex.model:files.indexList', () => FileList)
 */
export default {
  inject: ['items', 'DataListDisplayServiceInstance'],
  props: {
    // items: { required: true },
    // itemClick: { required: false },
  },
  computed: {
    itemsArray() {
      if (Array.isArray(this.items)) {
        return this.items
      }
      return this.items.value
    },
  },
}
</script>

<style lang="scss">
.modelFileItemImgArea {
  padding-top: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
