import { ColumnDefByColName } from './ModelDef'

/**
 * Create a new record with default values
 * @param columns
 */
export const createNewRecordWithColumnDefs = async <
  ModelRecord extends Record<string, any> = Record<string, any>,
>(
  columns: ColumnDefByColName<ModelRecord>,
): Promise<ModelRecord> => {
  const newRecord = {} as any
  for (const colName in columns) {
    const columnDef = columns[colName]
    const defaultValue =
      columnDef.defaultValue !== undefined ? columnDef.defaultValue : columnDef.default
    if (defaultValue !== undefined) {
      newRecord[colName] =
        typeof defaultValue === 'function' ? await defaultValue() : defaultValue
    }
  }
  return newRecord
}
