import { junctionUsersAndGroups } from './junctionUsersAndGroups'
import { junctionUsersAndOrgsAndPositions } from './junctionUsersAndOrgsAndPositions'
import { organizations } from './organizations'
import { positions } from './positions'
import { userGroups } from './userGroups'

export const userOrgGroupsManagerModels = {
  positions,
  junctionUsersAndGroups,
  junctionUsersAndOrgsAndPositions,
  organizations,
  userGroups,
}
