<template>
  <div
    class="dropdown-menu position-absolute p-2 border-0 shadow-lg"
    style="min-width: 320px; max-width: 94vw; top: 8px; left: 99%"
    :style="visible ? 'display: block;' : 'display: none;'"
  >
    <div
      @click="removeFilter"
      role="button"
      class="d-flex align-items-center filter-manager-button"
    >
      <Ficon
        type="trash"
        class="mr-1"
      />
      Filterを削除
    </div>
    <!--    <div-->
    <!--      @click="changeToModernFilter"-->
    <!--      role="button"-->
    <!--      class="d-flex align-items-center filter-manager-button"-->
    <!--    >-->
    <!--      <Ficon type="list-alt" class="mr-1" />高度のフィルターに追加-->
    <!--    </div>-->
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { FilterItemService } from '../../FilterItemService'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup() {
    return {
      filterItemService: inject<FilterItemService<any>>('filterItemService'),
    }
  },
  methods: {
    removeFilter() {
      this.$emit('onDismiss')
      setTimeout(() => {
        this.filterItemService.removeFilter()
      }, 100)
    },
    // changeToModernFilter() {
    //   // this.filterItemService.changeToModernFilter()
    // },
  },
}
</script>
