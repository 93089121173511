import qs, { ParsedQs } from 'qs'
import { registerFunctionLoggedInExecuteOnce as regFuncLoginOnce } from '../../front/LoggedInExecuteOnceServiceBase'
import { md5 } from '../md5'
import { tryParseAsObject } from './tryParseAsObject'
export * from '../csvParserLite'
export * from '../ValidationService/validator'
export * from './autoDetectEncodingAndConvertToUtf8'
export * from './CancellablePromise'
export * from './columnDisplayValueFormatter'
export * from './deepObjectDiff'
export * from './escapeHtml'
export * from './filterRecordsByFilterQuery'
export * from './fireOnce'
export * from './programmaticFocusWithToggleClass'
export * from './toHourMinutesString'
export { md5, qs, tryParseAsObject }

export const dateIntoDay = (date: string) => {
  return new Date(date).getDay()
}

export const getBrightnessOfColor = (hex: string): number => {
  hex = hex.replace('#', '')
  const c_r = parseInt(hex.substring(0, 2), 16)
  const c_g = parseInt(hex.substring(2, 4), 16)
  const c_b = parseInt(hex.substring(4, 6), 16)
  return (c_r * 299 + c_g * 587 + c_b * 114) / 1000
}

const youbi = ['日', '月', '火', '水', '木', '金', '土']
export const dayIntoYoubi = (dayInt: number) => {
  return youbi[dayInt]
}

export const idHasher = (idString) => {
  return md5(idString)
}

export const getYYYYMMDD = (date = new Date()) => {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  return Number(
    `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}${(
      '0' + date.getDate()
    ).slice(-2)}`,
  )
}

export const dateFormatter = (format, date = new Date()) => {
  return $core.$dayjs(date).format(format)
}

export function reduceByKeyIntoObject(
  array,
  propertyNameOfItem,
  valueFormatter: any = null,
) {
  return array.reduce((res, r) => {
    const key = r[propertyNameOfItem]
    if (!res[key]) {
      res[key] = []
    }
    if (valueFormatter && typeof valueFormatter === 'function') {
      // @ts-ignore
      res[key] = valueFormatter(r)
    } else {
      res[key].push(r)
    }
    return res
  }, {})
}

export class MeasureTime {
  public start: number
  public moduleName: string

  constructor(moduleName = null) {
    this.moduleName = moduleName || '_utils/measureExecTime'
    this.start = Date.now()
  }

  nowTook() {
    return Date.now() - this.start
  }

  nowTookStr() {
    return this.nowTook() / 1000 + ' 秒'
  }

  log(additionalMessage = '') {
    console.log(`[${this.moduleName}] Took ${this.nowTookStr()} ${additionalMessage}`)
  }
}

export const generateUUIDV4 = (withoutHyphen = true) => {
  const arr = new Uint8Array(16)
  window.crypto.getRandomValues(arr)
  arr[6] = (arr[6] & 0x0f) | 0x40 // Version 4
  arr[8] = (arr[8] & 0x3f) | 0x80 // Variant 10

  const uuid = [...arr].map((b) => b.toString(16).padStart(2, '0')).join('')
  if (withoutHyphen) {
    return uuid
  }
  return [
    uuid.slice(0, 8),
    uuid.slice(8, 12),
    uuid.slice(12, 16),
    uuid.slice(16, 20),
    uuid.slice(20),
  ].join('-')
}

// 36進数で getTime() を文字列化
export const generateStrongTimeStampString = (shuffle = true): string => {
  const time36 = new Date().getTime().toString(36).split('').reverse().join('')
  return shuffle ? shuffleString(time36) : time36
}

export const generateRandomString = (length = 12): string => {
  const times = Math.ceil(length / 10)
  return Array.from({ length: times }, () => Math.random().toString(36).substring(2, 15))
    .join('')
    .slice(0, length)
}

export const shuffleString = (stringVal): string => {
  const a = stringVal.split(''),
    n = a.length

  for (let i = n - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const tmp = a[i]
    a[i] = a[j]
    a[j] = tmp
  }
  return a.join('')
}
/**
 * 至近のparent Vue コンポーネントを返す by component name
 * 大文字小文字は区別しない
 * @param VM
 * @param componentName
 */
export const findNearestParentVueComponentByName = (VM, componentName: string) => {
  return findParentVueComponentByComponentName(VM, componentName, 1)
}

/**
 * 指定したミリ秒数だけ待つ
 */
export const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec))

export const appBaseUrl = `${process.env.APP_BASE_URL}`

export const zeroFill = (n: number, digits: number): string => {
  return ('00000000000000000000000000000000' + n).slice(digits * -1)
}

/**
 * あるVueコンポーネントを起点にして, 親コンポーネントを探しに行く関数
 * @param vueComponentInstance
 * @param parentComponentName
 * @param parentLevel - 親を探したいときは1, 親の親の場合は2
 */
export const findParentVueComponentByComponentName = (
  vueComponentInstance: any,
  parentComponentName: string,
  parentLevel = 1,
) => {
  const parentComponentNameLower = parentComponentName.toLowerCase()
  // vueComponentInstance.$parent をずっとたどっていく
  // 見つかった回数 (parentLevelがあるので)
  let foundCount = 0
  const findParentComponentByName = (_vueComponent: any) => {
    if (!_vueComponent.$parent) {
      console.warn(
        `[$utils.findParentVueComponentByComponentName]: component "${parentComponentName}" が見つかりませんでした。`,
      )
      return // 親のVueInstanceがなければ、終了。
    }
    if (
      (
        _vueComponent.$parent.$options.name ||
        _vueComponent.$parent.$options._componentTag
      )?.toLowerCase() === parentComponentNameLower
    ) {
      foundCount++
    }
    if (foundCount === parentLevel) {
      return _vueComponent.$parent
    }
    // ここで、見つかっていなくて、親がまだある状態なら、更に親を探しに行く
    return findParentComponentByName(_vueComponent.$parent)
  }
  return findParentComponentByName(vueComponentInstance)
}

/**
 * あるVueコンポーネントを起点にして, 子コンポーネントを探しに行く関数
 * @param vueComponentInstance
 * @param childComponentName
 * @param childLevel - 子を探したいときは1, 子の子の場合は2 ...
 */
export const findChildrenVueComponentByComponentName = (
  vueComponentInstance: any,
  childComponentName: string,
  childLevel = 1,
): any[] => {
  // vueComponentInstance.$parent をずっとたどっていく
  // 見つかった回数 (parentLevelがあるので)
  const foundChildren: any[] = []
  const findChildComponentByName = (_vueComponents: any[]): any[] => {
    if (!_vueComponents || _vueComponents.length === 0) {
      return foundChildren // 子のVueInstanceがなければ、終了。
    }
    for (const _vueComponent of _vueComponents) {
      if (_vueComponent.$options.name === childComponentName) {
        foundChildren.push(_vueComponent)
      }
      if (foundChildren.length >= childLevel) {
        break
      }
      if (_vueComponent.$children && _vueComponent.$children.length > 0) {
        findChildComponentByName(_vueComponent.$children)
      }
    }
    return foundChildren
  }
  return findChildComponentByName([vueComponentInstance])
}

/**
 * Flatten nested object into `separator` connected key
 */
export const flattenObject = (ob, separator = '.') => {
  const toReturn = {}

  for (const i in ob) {
    // eslint-disable-next-line
    if (!ob.hasOwnProperty(i)) continue

    if (typeof ob[i] == 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i])
      for (const x in flatObject) {
        // eslint-disable-next-line
        if (!flatObject.hasOwnProperty(x)) continue

        toReturn[i + separator + x] = flatObject[x]
      }
    } else {
      toReturn[i] = ob[i]
    }
  }
  return toReturn
}

/**
 * 外部スクリプトを読み込むのに利用, 2重読み込みを回避するために
 * usage: await loadExternalScript('https://cdn.jsdelivr.net/npm/@chartshq/muze@2.0.0/dist/muze.js')
 * @param scriptUrl
 * @param type
 */
export const loadExternalScript = async (
  scriptUrl: string,
  type: 'javascript' | 'css' = null,
  otherAttributes: { [key: string]: string } = {},
) => {
  const getFileType = (url) => {
    const urlWithoutQuery = url.split('?')[0] // クエリパラメータを除外
    const segments = urlWithoutQuery.split('/')
    const lastSegment = segments[segments.length - 1]
    const extension = lastSegment.split('.').pop().toLowerCase()

    if (extension === 'js' || url.includes('/js/') || url.includes('.js')) {
      return 'javascript'
    } else if (extension === 'css' || url.includes('/css/') || url.includes('.css')) {
      return 'css'
    }
    return 'unknown' // 拡張子がない場合や不明な場合
  }

  const fileType = type || getFileType(scriptUrl)
  if (fileType === 'unknown') {
    console.error('Unknown file type for URL:', scriptUrl)
    return
  }

  const attrs = {
    javascript: { tag: 'script', type: 'text/javascript', src: 'src', rel: 'script' },
    css: { tag: 'link', type: 'text/css', src: 'href', rel: 'stylesheet' },
  }

  // ファイルタイプに対応する属性を取得
  const attr = attrs[fileType]

  // 2度読み回避
  const elementId = scriptUrl.replace(/[^0-9a-z]/gi, '')
  if (globalThis.document.getElementById(elementId)) {
    return
  }

  return new Promise((resolve) => {
    const scriptElement = globalThis.document.createElement(attr.tag)
    scriptElement.setAttribute(attr.src, scriptUrl)
    if (fileType === 'css') {
      scriptElement.setAttribute('type', attr.type)
      // @ts-ignore
      scriptElement.setAttribute('rel', attr.rel)
    }
    for (const key in otherAttributes) {
      scriptElement.setAttribute(key, otherAttributes[key])
    }
    scriptElement.id = elementId
    scriptElement.onload = () => {
      resolve(true)
    }
    globalThis.document.head.appendChild(scriptElement)
  })
}

/**
 * camelCase to dash case
 * 'someAttrProp' => 'some-attr-prop'
 */
export const camelCaseToHyphenCase = (str = '') =>
  str.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase())

export const coreFWDomain = 'core-fw.com'

/**
 * Display model name as a format `ModelLabel (modelName)`
 */
export const displayModelNameWithLabel = (
  modelName,
  isVirtualModel = false,
  labelOnly = false,
): string => {
  const models = isVirtualModel ? $core.$virtualModels : $core.$models
  if (labelOnly) {
    return models[modelName]?.tableLabel || modelName
  }
  return (
    modelName +
    (models[modelName]?.tableLabel ? ` (${models[modelName]?.tableLabel})` : '')
  )
}

/**
 * 半角カタカナを全角カタカナに変換
 * @param str
 */
export const hankanaToZenkana = (str) => {
  const kanaMap = {
    ｶﾞ: 'ガ',
    ｷﾞ: 'ギ',
    ｸﾞ: 'グ',
    ｹﾞ: 'ゲ',
    ｺﾞ: 'ゴ',
    ｻﾞ: 'ザ',
    ｼﾞ: 'ジ',
    ｽﾞ: 'ズ',
    ｾﾞ: 'ゼ',
    ｿﾞ: 'ゾ',
    ﾀﾞ: 'ダ',
    ﾁﾞ: 'ヂ',
    ﾂﾞ: 'ヅ',
    ﾃﾞ: 'デ',
    ﾄﾞ: 'ド',
    ﾊﾞ: 'バ',
    ﾋﾞ: 'ビ',
    ﾌﾞ: 'ブ',
    ﾍﾞ: 'ベ',
    ﾎﾞ: 'ボ',
    ﾊﾟ: 'パ',
    ﾋﾟ: 'ピ',
    ﾌﾟ: 'プ',
    ﾍﾟ: 'ペ',
    ﾎﾟ: 'ポ',
    ｳﾞ: 'ヴ',
    ﾜﾞ: 'ヷ',
    ｦﾞ: 'ヺ',
    ｱ: 'ア',
    ｲ: 'イ',
    ｳ: 'ウ',
    ｴ: 'エ',
    ｵ: 'オ',
    ｶ: 'カ',
    ｷ: 'キ',
    ｸ: 'ク',
    ｹ: 'ケ',
    ｺ: 'コ',
    ｻ: 'サ',
    ｼ: 'シ',
    ｽ: 'ス',
    ｾ: 'セ',
    ｿ: 'ソ',
    ﾀ: 'タ',
    ﾁ: 'チ',
    ﾂ: 'ツ',
    ﾃ: 'テ',
    ﾄ: 'ト',
    ﾅ: 'ナ',
    ﾆ: 'ニ',
    ﾇ: 'ヌ',
    ﾈ: 'ネ',
    ﾉ: 'ノ',
    ﾊ: 'ハ',
    ﾋ: 'ヒ',
    ﾌ: 'フ',
    ﾍ: 'ヘ',
    ﾎ: 'ホ',
    ﾏ: 'マ',
    ﾐ: 'ミ',
    ﾑ: 'ム',
    ﾒ: 'メ',
    ﾓ: 'モ',
    ﾔ: 'ヤ',
    ﾕ: 'ユ',
    ﾖ: 'ヨ',
    ﾗ: 'ラ',
    ﾘ: 'リ',
    ﾙ: 'ル',
    ﾚ: 'レ',
    ﾛ: 'ロ',
    ﾜ: 'ワ',
    ｦ: 'ヲ',
    ﾝ: 'ン',
    ｧ: 'ァ',
    ｨ: 'ィ',
    ｩ: 'ゥ',
    ｪ: 'ェ',
    ｫ: 'ォ',
    ｯ: 'ッ',
    ｬ: 'ャ',
    ｭ: 'ュ',
    ｮ: 'ョ',
    '｡': '。',
    '､': '、',
    ｰ: 'ー',
    '｢': '「',
    '｣': '」',
    '･': '・',
  }

  const reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g')
  return str
    .replace(reg, function (match) {
      return kanaMap[match]
    })
    .replace(/ﾞ/g, '゛')
    .replace(/ﾟ/g, '゜')
}

/**
 * 全角英数字を半角に変換 (統一)
 * 全角スペースを半角スペースに変換
 * @param str
 */
export const zenkakuAlphaNumericToHankaku = (str: string) => {
  return str
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    .replace(
      // eslint-disable-next-line no-irregular-whitespace
      /　/g,
      ' ',
    )
}

/**
 * Use this if you want to change url params without Vue reloading
 * ページ遷移無しでURL query を変更したいときに利用する
 * @param queryObject
 */
export const updateUrlQueryOnHashMode = (queryObject: { [paramName: string]: any }) => {
  if (history.pushState) {
    const hashPart = globalThis.location.hash
    const currentQueryString = hashPart.indexOf('?') >= 0 ? hashPart.split('?')[1] : ''
    const searchParams = new URLSearchParams(currentQueryString)
    Object.keys(queryObject).map((key) => {
      searchParams.set(
        key,
        typeof queryObject[key] === 'object'
          ? JSON.stringify(queryObject[key])
          : queryObject[key],
      )
    })
    const hashWithoutQueryParams = hashPart.replace(/\?.+/, '')
    const newurl =
      globalThis.location.protocol +
      '//' +
      globalThis.location.host +
      globalThis.location.pathname +
      hashWithoutQueryParams +
      '?' +
      searchParams.toString()
    globalThis.history.pushState({ path: newurl }, '', newurl)
  }
}

/**
 * Print用function, 引数にHTML Elementを指定してその内部を印刷する
 * Modal内を印刷したい場合などで利用
 * @param elem
 * @param append
 * @param delimiter
 */
export const printElement = (elem, append = false, delimiter = null) => {
  const domClone = elem.cloneNode(true)

  let $printSection = document.getElementById('printSection')

  if (!$printSection) {
    $printSection = document.createElement('div')
    $printSection.id = 'printSection'
    document.body.appendChild($printSection)
  }

  if (append !== true) {
    $printSection.innerHTML = ''
  } else if (append === true) {
    if (typeof delimiter === 'string') {
      $printSection.innerHTML += delimiter
    } else if (typeof delimiter === 'object') {
      $printSection.appendChild(delimiter)
    }
  }

  $printSection.appendChild(domClone)
  globalThis.document.body.classList.add('js-printing-mode')
  setTimeout(() => {
    globalThis.print()
    setTimeout(() => {
      globalThis.document.body.classList.remove('js-printing-mode')
    }, 3000)
  }, 10)
}

/**
 * 文字列定義された関数を実行
 * 主に データエクスポート設定, インポート設定など, 関数定義を保存して実行する場合に利用する
 * Execute string defined function
 *
 * 利用Example1:
 * ```javascript
 * const data = await executeStringDefinedFunction({
 *   functionString: `
 *   originalDataRows = originalDataRows.filter(d => !!d.isAvailable)
 *   return originalDataRows
 *   `,
 *   functionArgValues: {originalDataRows: [1,2,3,4,5]}
 * })
 * ```
 *
 * 利用Example2:
 * ```javascript
 * await executeStringDefinedFunction({
 *   functionString: `${this.exportSettingRecord.beforeFormatFunction}; return originalDataRows`,
 *   functionArgValues: {originalDataRows: this.data},
 *   errorMessagePrefix: '[DataExporter._execBeforeFormatFunction()] ',
 *   successCallback: (formatted) => {
 *     console.log({formatted})
 *     debugger
 *     this.data = formatted
 *   }
 * })
 * ```
 *
 * 利用Example3: 実行可能な関数を生成
 * ```javascript
 * const executableFunc = executeStringDefinedFunction({
 *   functionString: `${this.exportSettingRecord.beforeFormatFunction}; return originalDataRows`,
 *   functionArgValues: {originalDataRows: this.data},
 *   errorMessagePrefix: '[DataExporter._execBeforeFormatFunction()] ',
 *   returnAsExecutableFunction: true
 * })
 * ```
 */
export const executeStringDefinedFunction = ({
  functionString,
  functionArgValues,
  errorThrow = false,
  functionArgExpression = '',
  errorMessagePrefix = '',
  successCallback = null,
  nonAwait = false, // Promise ではなくなる
  bindingThisObject = null, // script 内で this 表現で指し示したいオブジェクトを指定可能
  returnAsExecutableFunction = false,
  quiet = false, // error 時に toast 表示しない
}: {
  functionString: string
  errorThrow?: boolean
  functionArgValues: { [argName: string]: any }
  functionArgExpression?: string
  errorMessagePrefix?: string
  successCallback?: (data) => void
  nonAwait?: boolean
  bindingThisObject?: any
  returnAsExecutableFunction?: boolean
  quiet?: boolean
}) => {
  const functionArg =
    functionArgExpression || `{${Object.keys(functionArgValues || {}).join(', ')}}`
  const functionTemplate = `{ return ${
    nonAwait ? '' : 'async '
  }function (${functionArg}) { ${functionString} } };`
  try {
    /**
     * 関数実行時の引数 (関数内で利用できる変数) を渡す
     */
    const func = new Function(functionTemplate)
    if (returnAsExecutableFunction) {
      return (...args) => func.call(bindingThisObject).call(bindingThisObject, ...args)
    }
    if (nonAwait) {
      const res = func.call(bindingThisObject).call(bindingThisObject, functionArgValues)
      if (successCallback) {
        successCallback(res)
      }
      return res
    } else {
      return new Promise((resolve, reject) => {
        func
          .call(bindingThisObject)
          .call(bindingThisObject, functionArgValues)
          .then((res) => {
            if (successCallback) {
              const callbacked = successCallback(res)
              // @ts-ignore
              if (typeof callbacked?.then === 'function') {
                // @ts-ignore
                callbacked.then((succeededRes) => resolve(res))
              } else {
                return resolve(res)
              }
            } else {
              return resolve(res)
            }
          })
          .catch((e) => {
            const error = `${errorMessagePrefix} 実行時にエラーが発生しました: ${e.message}`
            console.error(error)
            reject(new Error(error))
          })
      })
    }
  } catch (e) {
    console.error(`${errorMessagePrefix} 実行時にエラーが発生しました: ${e.message}`)
    if (!quiet) {
      $core.$errorReporter.r(e, this)
      $core.$toast.errorToast(
        `${errorMessagePrefix} 実行時にエラーが発生しました: ${e.message}, functionTemplate: ${functionTemplate}`,
      )
    }
    if (errorThrow) {
      throw e
    }
  }
}

export const executeStringDefinedVuePropsFunction = (
  // eslint-disable-next-line
  stringOrFunction: Function | string,
  args: any[],
) => {
  if (typeof stringOrFunction === 'function') {
    stringOrFunction(...args)
    return
  } else {
    try {
      return new Function(`return ${stringOrFunction}`)()(...args)
    } catch (e) {
      if ($core.$embAuth.user.isAdmin) {
        $core.$toast.errorToast(e.message)
      }
      console.error(e)
    }
  }
}

/**
 * オブジェクトの複数のプロパティについて、文字列データをJavascript表現へ変換する関数
 * @param record
 * @param propertyNames
 */
export const parsePropsAsJsExpression = (
  record: Record<any, any>,
  propertyNames: string[],
) => {
  propertyNames.map((n) => {
    try {
      record[n] = tryParseAsObject(record[n])
    } catch (e) {
      console.error(
        `[parsePropsAsJsExpression] Failed to parse prop "${n}", ${e.message}`,
        { record },
        e,
      )
    }
  })
  return record
}

/**
 * string defined な文字列を関数に変換しておく
 */ // eslint-disable-next-line
export const stringToFunction = <T = Function>(
  labelFormatterString: string,
  argumentExpression = 'function (row)',
  colName = '',
): T => {
  try {
    const func = new Function(
      `{ return ${argumentExpression} { \n${labelFormatterString}\n } };`,
    )
    return func.call(null)
  } catch (e) {
    console.error(`Failed to parse labelFormatterStringToFunction ${colName}`, e)
    return null
  }
}

const isObject = (item: any) => item && typeof item === 'object' && !Array.isArray(item)

/**
 * Deepmerge object
 * @param target
 * @param sources
 */
export function deepmerge(target, ...sources) {
  target = Object.assign({}, target)
  if (!sources.length) {
    return target
  }

  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        target[key] = deepmerge(target[key] || {}, source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }
  return deepmerge(target, ...sources)
}

/**
 * 特定の props のみ deepmerge する場合
 */
export const deepmergeOnlySpecificProps = <T = Record<string, any>>(
  props: string[],
  target,
  source,
): T => {
  const newTarget = Object.assign({}, target || {}, source)
  props.map((prop) => {
    if (Array.isArray(source[prop])) {
      newTarget[prop] = (Array.isArray(target[prop]) ? target[prop] : []).concat(
        source[prop],
      )
    } else if (typeof source[prop] === 'object') {
      newTarget[prop] = deepmerge(target[prop], source[prop])
    } else if (source[prop]) {
      newTarget[prop] = source[prop]
    }
  })
  return newTarget
}

const ccaApiUrlLocal = 'http://localhost:8055'
const ccaApiUrlStgOrProd =
  process.env.NODE_ENV === 'production'
    ? 'https://api-cloud-admin.core-fw.com'
    : 'https://api-staging-cloud-admin.core-fw.com'

export const ccaApiUrl =
  process.env.NODE_ENV === 'development' ? ccaApiUrlLocal : ccaApiUrlStgOrProd

// core-templates.e10nfhw7bj8t5p.core-fw.com
export const coreTemplatesApiUrl =
  'https://8c8d3959f2404494ad381a6a1876dd2b.e250tryb4s6fi7.core-fw.com'

/**
 * フラットなオブジェクトをネストされたオブジェクトや配列に変換する関数
 * @param object - 変換対象のオブジェクト
 * @param options - オプション設定（splitString を指定可能）
 * @returns ネストされたオブジェクト
 *
 * @example
 *
 * ```
 * // テスト用データ
 * const testData = {
 *   'config.database.host': 'localhost',
 *   'config.database.port': 3306,
 *   'config.server.port': 8080,
 *   'config.columns.0.name': 'some',
 *   'config.columns.1.name': 'some',
 *   'config.columns.2.name': 'some',
 * }
 *
 * // 分割文字を '.' に設定して実行
 * const result = $core.$utils.unFlattenObject(testData, { splitString: '.' })
 * console.log(JSON.stringify(result, null, 2))
 * ```
 */
export const unFlattenObject = (
  object: Record<string, any>,
  { splitString = '_' } = {},
): Record<string, any> => {
  const result: Record<string, any> = {}

  /**
   * 特殊文字をエスケープする関数
   * @param str - エスケープ対象の文字列
   * @returns エスケープ後の文字列
   */
  const escapeRegExp = (str: string): string => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  // 分割文字を正規表現で使用するためにエスケープ
  const escapedSplitString = escapeRegExp(splitString)
  const splitRegExp = new RegExp(escapedSplitString, 'g')

  Object.keys(object).forEach((key: string) => {
    // キーを指定された分割文字で分割
    const keys = key.split(splitRegExp)
    let current = result
    keys.forEach((k, index) => {
      const isLast = index === keys.length - 1
      const nextKey = keys[index + 1]
      const isArrayIndex = !isNaN(Number(k))
      const isNextArrayIndex = !isNaN(Number(nextKey))
      const keyName = isArrayIndex ? Number(k) : k
      // current の 値を Object or Array に設定する
      if (!current) {
        current = {}
      }
      if (isArrayIndex && !Array.isArray(current)) {
        current = []
      } else if (!isArrayIndex && typeof current !== 'object') {
        current = {}
      }

      try {
        if (isLast) {
          // 最後のキーの場合、値を設定
          if (Array.isArray(current)) {
            current[keyName] = object[key]
          } else {
            current[keyName] = object[key]
          }
        } else {
          // 中間のキーの場合、次のレベルの構造を作成
          if (isArrayIndex) {
            // 現在のキーが配列インデックスの場合
            if (!Array.isArray(current)) {
              current = []
            }
            if (!current[keyName]) {
              if (isNextArrayIndex) {
                current[keyName] = []
              } else {
                current[keyName] = {}
              }
            }
            current = current[keyName]
          } else {
            // 現在のキーがオブジェクトのキーの場合
            if (!(keyName in current)) {
              if (isNextArrayIndex) {
                current[keyName] = []
              } else {
                current[keyName] = {}
              }
            }
            current = current[keyName]
          }
        }
      } catch (e) {
        console.error(`${key} のパースに失敗しました`, e, {
          keyName,
          key,
          keys,
          current,
          object,
        })
        debugger
      }
    })
  })

  return result
}

export const arrayToObject = (array, key = '_propKey') => {
  return array.reduce((res, r) => {
    if (r[key]) {
      res[r[key]] = r
    }
    return res
  }, {})
}

// 関数実行で登録も可能
// $core.$utils.registerFunctionLoggedInExecuteOnce
export const registerFunctionLoggedInExecuteOnce = regFuncLoginOnce

export const downloadStringAsFile = (content: string, filename: string) => {
  downloadJsValue(content, filename, 'string')
}
/**
 * JavaScript 値を json, string としてダウンロードさせる
 * @param value - ダウンロードする値
 * @param exportName - エクスポートするファイル名（拡張子あり/なし両方に対応）
 * @param format - 'json' | 'string'
 * @param formatJson - json の場合、整形するかどうか
 */
export const downloadJsValue = (
  value: any,
  exportName: string,
  format: 'json' | 'string' = 'json',
  formatJson = true,
): void => {
  const mimeTypes = {
    json: 'application/json',
    string: 'text/plain',
  }

  const mimeType = mimeTypes[format] || 'text/plain'
  const dataStr = `data:${mimeType};charset=utf-8,${encodeURIComponent(_formatValueAs(value, format, formatJson))}`

  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)

  // 拡張子の処理
  const hasExtension = /\.[^/.]+$/.test(exportName)
  const fileName = hasExtension ? exportName : `${exportName}.${format || 'txt'}`

  downloadAnchorNode.setAttribute('download', fileName)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

/**
 * 値を指定された形式にフォーマットする
 * @param value - フォーマットする値
 * @param format - 'json' | 'string'
 * @param formatJson - json の場合、整形するかどうか
 * @returns フォーマットされた文字列
 */
const _formatValueAs = (
  value: any,
  format: 'json' | 'string',
  formatJson: boolean,
): string => {
  if (format === 'json') {
    return formatJson ? JSON.stringify(value, null, 2) : JSON.stringify(value)
  } else {
    return String(value)
  }
}
/**
 * componentLike な引数を、Vue component として返す
 * @param componentLike
 */
export const toComponent = (componentLike) => {
  if (!componentLike) {
    return null
  }
  if (typeof componentLike === 'string') {
    return {
      template: `<div>${componentLike}</div>`,
    }
  }
  return componentLike
}

/**
 * User 表示用の関数
 */
export const formatUserDataForDisplay = (user): string => {
  if (!user) {
    return ''
  }
  return `${user.first_name} (${user.email})`
}

/**
 * Hash モードの際に、hash以降のQueryString を Parsed で取得する
 */
export const hashedQueryStringParser = (): ParsedQs => {
  const hash = window.location.hash
  const hashQuery = hash?.split('?')?.[1] || ''
  return qs.parse(hashQuery)
}

/**
 * Style を js で定義する
 */
export const defineStyle = (id: string, style: string) => {
  const sElem = window.document.getElementById(id)
  if (!sElem) {
    const style = window.document.createElement('style')
    style.id = id
    window.document.head.appendChild(style)
  }
  setTimeout(() => {
    window.document.getElementById(id).innerHTML = style
  }, 0)
}

/**
 * 与えられた配列 (paths) から、ネストされたオブジェクトを生成する
 * 例: arrayToNestedObject(['a', 'b', 'c'], 'value') => { a: { b: { c: 'value' } } }
 * @param paths
 * @param value
 */
export const arrayToNestedObject = (paths: string[], value): Record<string, any> => {
  return paths.reduceRight((acc, key) => {
    const nestedObject = {}
    nestedObject[key] = acc
    return nestedObject
  }, value)
}

/**
 * ディープなオブジェクトから、指定したプロパティを持つオブジェクトを探す
 * ※ 循環参照を回避するために、Set で探索済みのオブジェクトを記録している
 * ※ Debug目的の利用に留めるべし
 */
export function findObjectWithProperty(
  obj,
  propName,
  ignoreProps = [],
  visited = new Set(),
) {
  visited.add(obj)
  const propertyNames = Object.getOwnPropertyNames(obj)
  for (let i = 0; i < propertyNames.length; i++) {
    const key = propertyNames[i]
    if (ignoreProps.includes(key)) {
      continue
    }
    try {
      if (typeof obj[key] === 'object') {
        if (!visited.has(obj[key])) {
          const found = findObjectWithProperty(obj[key], propName, ignoreProps, visited)
          if (found) {
            return {
              object: found.object,
              path: [key, ...found.path],
            }
          }
        }
      } else if (key === propName) {
        return {
          object: obj,
          path: [key],
        }
      }
    } catch (e) {
      // return null;
    }
  }
  return null
}

export const copyToClipboard = async (data: string | any, quiet = false) => {
  try {
    if (typeof data !== 'string') {
      data = JSON.stringify(data, null, 2)
    }
    await window.navigator.clipboard.writeText(data)
    if (!quiet) {
      $core.$toast.successToast('コピーしました')
    }
  } catch (e) {
    console.error(e)
    if (!quiet) {
      $core.$toast.errorToast('コピーに失敗しました')
    }
  }
}

export const openLinkInNewTabInHashMode = (urlInRouter: string) => {
  window.open(`${window.location.pathname}#${urlInRouter}`, '_blank')
}

/**
 * $core.$utils.getWindowObject() として global の window オブジェクトを取得する
 * - 特に、Vue コンポーネント内で window を参照するときに利用する
 *
 *
 * @example
 * ```vue
 * <a href="#" style="cursor: pointer;"
 *   @click.prevent="() => $core.$modals.openEditViewModal({
 *     modelName: 'appDefinitions',
 *     id: $core.$appDefinitionLoader.appDefinition?.id,
 *     successCallback: () => {
 *       $core.$utils.getWindowObject().location.reload()
 *     },
 *   })"
 * >アプリケーション定義を編集 <ficon type="external-link-alt"/></a>
 * ```
 */
export const getWindowObject = (): Window => {
  return window
}

/**
 * クエリパラメータを削除した上での、ファイル名の拡張子で判別
 */
export const isImageLikeExtensionFileName = (
  fileNameWithPath: string,
  RegExp = /\.(jpg|jpeg|png|gif|svg|webp|bmp|ico|tiff|tif|jfif|pjpeg|pjp|pdf)$/i,
): boolean => {
  return RegExp.test(fileNameWithPath?.replace(/\?.+/, '') || '')
}
