<template>
  <input
    v-if="initialized && enableKeywordSearch"
    type="text"
    class="form-control form-control-sm"
    :value="columnSearchStr"
    ref="columnSearchStrInput"
    @input="changeColumnSearchStr"
    placeholder="項目名で検索..."
  />
  <div
    v-if="initialized"
    class="pt-1 small cascade-column-select-panel--wrapper"
    ref="cascaderPanelWrapper"
    style="border-bottom: 1px solid #ccc; overflow: scroll"
  >
    <el-cascader-panel
      v-if="Object.keys(filteredColumns).length > 0"
      v-model="value"
      :options="options"
      @change="handleChange"
      @expand-change="() => $emit('expand-change')"
      ref="elCascaderPanel"
    />
    <div
      class="pb-1"
      v-else
    >
      該当なし
    </div>
  </div>
</template>

<script lang="ts">
import { ElCascaderPanel } from 'element-plus'
import { inject, PropType } from 'vue'
import { ColumnDef, ColumnDefByColName } from '../../../../../common/$models/ModelDef'
import {
  FilterColumnSelection,
  FilterControlsService,
  getNestedColumnFilterSelectionFromColumnsByColumns,
  GetNestedColumnFilterSelectionFromColumnsByColumnsArgs,
} from '../../FilterControlsService'

/**
 * カラム選択用のカスケードパネルコンポーネント
 *
 * @description
 * このコンポーネントは、ModelDefのカラム定義に基づいて、ネストされたカラム選択UIを提供します。
 * 主にフィルター条件の設定や、カラム選択が必要な場面で使用されます。
 *
 * @features
 * - カラム検索機能
 * - カスケード選択（ネストされたカラムの選択）
 * - 指定カラムのフィルタリング
 * - FilterControlsServiceとの連携（オプショナル）
 *
 * @example
 * ```vue
 * <template>
 *   <!-- モデル名を指定して、対象となるカラムを指定する -->
 *   <CascadeColumnSelectPanel
 *     :model-name="'selectOptionsMaster'"
 *     @column-selected="(colNamePath) => console.log('Selected column path:', colNamePath)"
 *   />
 *   <!-- カラム定義を直接指定する -->
 *   <CascadeColumnSelectPanel
 *     :columns-by-col-name="columnsByColName"
 *     @column-selected="(colNamePath) => console.log('Selected column path:', colNamePath)"
 *   />
 *   <!-- FilterControlsService (inject) を利用する場合 -->
 *   <CascadeColumnSelectPanel
 *     @column-selected="(colNamePath) => console.log('Selected column path:', colNamePath)"
 *   />
 * </template>
 * ```
 *
 * @props
 * - specifiedColNames (string[]): 選択可能なカラム名を制限する場合に指定
 * - onColumnSelect (Function): カラム選択時のコールバック関数
 *
 * @emits
 * - column-selected: カラムが選択された時に発火
 * - search-changed: 検索文字列が変更された時に発火
 *
 * @dependencies
 * - FilterControlsService (オプショナル): provide/injectで注入可能
 * - element-plus: ElCascaderPanelコンポーネントを使用
 *
 * @usage
 * 1. 基本的な使用方法:
 *    - フィルター条件の設定画面でカラム選択用に使用
 *    - フォーム内でのカラム選択UIとして使用
 *
 * 2. FilterControlsServiceと組み合わせた使用:
 *    - FilterControlsServiceをprovideして使用
 *    - カラム定義やフィルター状態の管理を自動化
 *
 * 3. スタンドアロンでの使用:
 *    - FilterControlsServiceなしでも使用可能
 *    - 必要なカラム定義は props `modelName` または `columnsByColName` にて 設定する
 */

export default {
  name: 'CascadeColumnSelectPanel',
  components: {
    ElCascaderPanel,
  },
  props: {
    specifiedColNames: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    onColumnSelect: {
      type: Function as PropType<(colNamePaths: string[]) => void>,
      required: false,
    },
    /**
     * モデル名を指定して、対象となるカラムを指定する
     * ※ FilterControlsServiceInstance が 存在しないときに利用
     */
    modelName: {
      type: String,
      required: false,
    },
    /**
     * カラム定義を直接指定する
     * ※ FilterControlsServiceInstance が 存在しないときに利用
     */
    columnsByColName: {
      type: Object as PropType<{ [key: string]: ColumnDef }>,
      default: null,
    },
    enableKeywordSearch: {
      type: Boolean,
      default: true,
    },
    getNestedColumnFilterSelectionFromColumnsByColumnsArgs: {
      type: Object as PropType<GetNestedColumnFilterSelectionFromColumnsByColumnsArgs>,
      default: null,
    },
    height: {
      type: String,
      default: '400px',
    },
    maxHeight: {
      type: String,
      default: '400px',
    },
  },
  emits: ['column-selected', 'search-changed', 'expand-change'],
  setup() {
    return {
      FilterControlsServiceInstance: inject<FilterControlsService>(
        'FilterControlsServiceInstance',
        {
          columns: {},
        } as FilterControlsService,
      ),
    }
  },
  data() {
    return {
      columnSearchStr: '',
      value: [],
      initialized: false,
    }
  },
  computed: {
    options(): FilterColumnSelection[] {
      if (!this.filteredColumns) {
        return []
      }
      const res = Object.keys(this.filteredColumns)
        .map((key) => {
          return this.calcedNestedColumnSelectionsByKey[key]
        })
        .filter((item) => !!item)
      return res
    },
    calcedNestedColumnSelectionsByKey(): {
      [key: string]: FilterColumnSelection
    } {
      const res = getNestedColumnFilterSelectionFromColumnsByColumns({
        ...(this.getNestedColumnFilterSelectionFromColumnsByColumnsArgs || {}),
        columns: this.columns,
      })
      return res
    },
    columns(): ColumnDefByColName {
      if (this.columnsByColName) {
        return this.columnsByColName
      }
      if (this.modelName) {
        return $core.$models[this.modelName].columns
      }
      if (this.FilterControlsServiceInstance?.columns) {
        return this.FilterControlsServiceInstance.columns
      }
      return {}
    },
    filteredColumns(): {
      [key: string]: ColumnDef
    } {
      if (!this.columns) return {}
      if (this.columnSearchStr === '' && !this.specifiedColNames?.length) {
        return this.columns
      }
      return Object.keys(this.columns).reduce((acc: any, key: string) => {
        const column = this.columns[key]
        if (
          this.columnSearchStr === '' ||
          column.label?.includes(this.columnSearchStr) ||
          key.includes(this.columnSearchStr)
        ) {
          if (this.specifiedColNames?.length) {
            if (this.specifiedColNames.includes(key)) {
              acc[key] = column
            }
          } else {
            acc[key] = column
          }
        }
        return acc
      }, {})
    },
  },
  mounted() {
    if (
      !this.FilterControlsServiceInstance &&
      !this.modelName &&
      !this.columnsByColName
    ) {
      console.error(
        '[CascadeColumnSelectPanel] FilterControlsServiceInstance が 設定されていない場合には、modelName または columnsByColName を設定してください。',
      )
      return
    }
    this.$nextTick(() => {
      this.initialized = true
    })
  },
  beforeUnmount() {
    if (this.$refs.columnSearchStrInput) {
      this.$refs.columnSearchStrInput = null
    }
    if (this.$refs.elCascaderPanel) {
      this.$refs.elCascaderPanel = null
    }
  },
  methods: {
    /**
     * カラムが選択された場合に kick される。
     * - Nest した 選択である場合があるため、ColumnName の 配列で渡される。
     */
    handleChange(selectedColumnPaths: string[] | string) {
      if (typeof selectedColumnPaths === 'string') {
        selectedColumnPaths = [selectedColumnPaths]
      }
      this.$emit('column-selected', selectedColumnPaths)
      this.onColumnSelect?.(selectedColumnPaths)
      this.value = []
    },
    changeColumnSearchStr(event) {
      this.columnSearchStr = event.target.value
      this.$emit('search-changed', this.columnSearchStr)
    },
  },
}
</script>
