import { reactive, watch } from 'vue'
import type {
  ILocalStorageManager,
  IQueryStateController,
  IUrlSyncManager,
  LocalStorageOptions,
  QueryStateControllerOptions,
} from './QueryStateControllerTypes'

/**
 * 状態管理を行うメインコントローラクラス
 */
export class QueryStateController implements IQueryStateController {
  public state: Record<string, any> = reactive({})
  private readonly urlSyncManager?: UrlSyncManager
  private readonly localStorageManager?: LocalStorageManager
  public readonly urlSyncEnabled: boolean
  public readonly localStorageEnabled: boolean
  private readonly urlSyncIncludeKeys: string[]
  private readonly localStorageIncludeKeys: string[]
  public queryStateUpdatedAt = new Date().getTime()
  private readonly _optionProvidedStateEditCallback?: (
    state: Record<string, any>,
    oldState: Record<string, any>,
    queryStateController: QueryStateController,
  ) => Promise<Record<string, any>>

  constructor(options?: QueryStateControllerOptions) {
    this.urlSyncEnabled = options?.urlSyncEnabled ?? false
    this.urlSyncIncludeKeys = options?.urlSyncIncludeKeys ?? []
    this.localStorageEnabled = options?.localStorage?.enabled ?? false
    this.localStorageIncludeKeys = options?.localStorage?.includeKeys ?? []

    if (this.urlSyncEnabled) {
      this.urlSyncManager = new UrlSyncManager()
    }
    if (this.localStorageEnabled && options?.localStorage) {
      this.localStorageManager = new LocalStorageManager(options.localStorage)
    }

    const state = {
      ...options?.initialState,
      ...(this.localStorageEnabled
        ? this.localStorageManager?.initializeFromStorage()
        : {}),
      ...(this.urlSyncEnabled ? this.urlSyncManager?.initializeFromUrl() : {}),
    }
    let oldState = JSON.parse(JSON.stringify(state))
    this.state = reactive(state)
    this._optionProvidedStateEditCallback = options?.stateEditCallback
    let runTimer = null
    setTimeout(() => {
      watch(this.state, async (value) => {
        if (runTimer) {
          // すでに実行中の場合は何もしない
          return
        }
        runTimer = setTimeout(async () => {
          console.log('state changed', { value, oldState, that: this })
          if (this._optionProvidedStateEditCallback) {
            await this.runStateEditCallback(value, oldState, this)
          }
          setTimeout(() => {
            this.updateUrl()
            this.updateLocalStorage()
            oldState = JSON.parse(JSON.stringify(this.state))
            this.queryStateUpdatedAt = new Date().getTime()
            runTimer = null
          }, 1)
        }, 1)
      })
    }, 1000)
  }

  async runStateEditCallback(
    state: Record<string, any>,
    oldState: Record<string, any>,
    queryStateController: QueryStateController,
  ) {
    return queryStateController._optionProvidedStateEditCallback?.(
      state,
      oldState,
      queryStateController,
    )
  }

  get stateCopy() {
    return JSON.parse(JSON.stringify(this.state))
  }

  /**
   * 単一の状態を更新
   */
  public setState<T>(key: string, value: T): void {
    this.state[key] = value
  }

  /**
   * 複数の状態を一括更新
   */
  public setMultipleStates(states: Record<string, any>): void {
    Object.entries(states).forEach(([key, value]) => {
      this.state[key] = value
    })
  }

  public updateUrl() {
    if (this.urlSyncEnabled && this.urlSyncManager) {
      this.urlSyncManager.syncToUrl(this.state, this.urlSyncIncludeKeys)
    }
  }

  public updateLocalStorage() {
    if (this.localStorageEnabled && this.localStorageManager) {
      this.localStorageManager.syncToStorage(this.state, this.localStorageIncludeKeys)
    }
  }

  /**
   * 状態を取得
   */
  public getState<T>(key: string): T | undefined {
    return this.state[key] as T
  }
}

/**
 * URL同期を管理するクラス
 */
/**
 * LocalStorage同期を管理するクラス
 */
class LocalStorageManager implements ILocalStorageManager {
  private readonly key: string
  private readonly ttl: number
  private static readonly DEFAULT_TTL = 24 * 60 * 60 // 24時間（秒）
  private readonly includeKeys: string[]

  constructor(options: LocalStorageOptions) {
    this.key = `qsc__${options.key}`
    this.ttl = options.ttl ?? LocalStorageManager.DEFAULT_TTL
    this.includeKeys = options.includeKeys ?? []
  }

  /**
   * LocalStorageから状態を復元
   */
  public initializeFromStorage(): Record<string, any> {
    const stored = $core.$lsCache.get(this.key) || {}
    try {
      return Object.keys(stored).reduce((acc, key) => {
        if (!this.includeKeys?.length || this.includeKeys.includes(key)) {
          acc[key] = stored[key]
        }
        return acc
      }, {})
    } catch (e) {
      return {}
    }
  }

  /**
   * 状態をLocalStorageに同期
   */
  public syncToStorage(state: Record<string, any>, includeKeys: string[] = []): void {
    const stateToStore = includeKeys.reduce((acc, key) => {
      acc[key] = state[key]
      return acc
    }, {})
    $core.$lsCache.set(this.key, stateToStore, this.ttl)
  }
}

/**
 * URL同期を管理するクラス
 */
class UrlSyncManager implements IUrlSyncManager {
  /**
   * URLから状態を復元
   */
  public initializeFromUrl(): Record<string, any> {
    const params = $core.$router.currentRoute.query
    const state: Record<string, any> = {}

    Object.entries(params).forEach(([key, value]) => {
      try {
        state[key] = JSON.parse(value as string)
      } catch {
        state[key] = value
      }
    })
    console.log('initializeFromUrl', state)

    return state
  }

  /**
   * 状態をURLに同期
   */
  public syncToUrl(state: Record<string, any>, includeKeys: string[] = []): void {
    const params = includeKeys.reduce((acc, key) => {
      acc[key] = state[key]
      return acc
    }, {})

    // CORE Framework の updateUrlQueryOnHashMode を利用
    window.$core.$utils.updateUrlQueryOnHashMode(params)
  }
}
