<template>
  <nav :class="styleClasses">
    <p v-if="hidePagedText !== true">
      全 {{ filter_count }} 件<span v-show="filter_count && limit > 0"
        >中 {{ (page - 1) * limit + 1 }} - {{ displayLastItemOffset }} 件目</span
      >
    </p>
    <ul
      v-if="
        hidePaginationControls !== true && (pageCount > 1 || visibleEvenIfPageCountIsOne)
      "
    >
      <li :class="page === 1 ? 'disabled' : ''">
        <a
          v-single-click:300.prevent="() => moveToPaged(1)"
          href="#"
        >
          <i
            class="las la-angle-double-left"
            aria-hidden
          />
        </a>
      </li>
      <li :class="page === 1 ? 'disabled' : ''">
        <a
          v-single-click:300.prevent="() => moveToPaged(page - 1)"
          href="#"
        >
          <i
            class="las la-angle-left"
            aria-hidden
          />
        </a>
      </li>
      <li
        v-for="i in pageCount"
        :key="i"
        :class="i === page ? 'active' : ''"
        v-show="
          i < page + 5 && i > page - 5 && (displayType === 'default' || !displayType)
        "
      >
        <a
          v-single-click:300.prevent="() => moveToPaged(i)"
          href="#"
        >
          <span>{{ i }}</span>
        </a>
      </li>
      <li
        v-if="displayType === 'numberInput'"
        class="d-flex align-items-center"
        style="gap: 0.5em"
      >
        <input
          type="number"
          min="1"
          :max="pageCount"
          :value="ComposableDataListServiceInstance.query.page"
          @change="
            (_ev) =>
              (ComposableDataListServiceInstance.query.page =
                // @ts-ignore
                Number(_ev.target?.value) || 1)
          "
        />
        <span>/ {{ pageCount }} ページ</span>
      </li>
      <li :class="page === pageCount ? 'disabled' : ''">
        <a
          v-single-click:300.prevent="() => moveToPaged(page + 1)"
          href="#"
          aria-label="Next"
        >
          <i
            class="las la-angle-right"
            aria-hidden
          />
        </a>
      </li>
      <li :class="page === pageCount ? 'disabled' : ''">
        <a
          v-single-click:300.prevent="() => moveToPaged(pageCount)"
          href="#"
          aria-label="Next"
        >
          <i
            class="las la-angle-double-right"
            aria-hidden
          />
        </a>
      </li>
    </ul>
  </nav>
</template>
<script lang="ts">
import { inject } from 'vue'
import { registerComposableComponentSettings } from '../../../../ComposableComponents'
import { ComposableDataListService } from '../../ComposableDataListService'

const name = 'DataListPagination'
const styles = {
  default: {
    label: 'デフォルト (ボタン表示)',
    class: 'data-list-pagination--style-default',
  },
  numberInput: {
    label: 'ページ番号入力',
    class: 'data-list-pagination--style-number',
  },
}

registerComposableComponentSettings(name, {
  label: 'ページ切り替え',
  category: 'DataList',
  description: `データー一覧でページネーションを提供するコンポーネント`,
  images: [
    {
      img: '/images/cc_images/DataListPagination1.png',
    },
  ],
  configColumns: {
    displayType: {
      type: 'STRING',
      strictSelections: true, // 追加禁止
      selections: () => Object.keys(styles),
      customLabel: (val) => styles[val]?.label || '',
      defaultValue: 'default',
    },
    hidePagedText: {
      label: 'ページ数表示を非表示にする',
      type: 'BOOLEAN',
      defaultValue: false,
    },
    hidePaginationControls: {
      label: 'ページ切り替えを非表示にする',
      type: 'BOOLEAN',
      defaultValue: false,
    },
    visibleEvenIfPageCountIsOne: {
      label: 'ページ数が1の時でも表示する',
      type: 'BOOLEAN',
      defaultValue: false,
    },
  },
})

export default {
  name,
  props: {
    displayType: {
      type: String,
      default: 'default',
    },
    hidePagedText: {
      type: Boolean,
      default: false,
    },
    hidePaginationControls: {
      type: Boolean,
      default: false,
    },
    visibleEvenIfPageCountIsOne: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      ComposableDataListServiceInstance: inject<ComposableDataListService>(
        'ComposableDataListServiceInstance',
      ),
    }
  },
  computed: {
    styleClasses() {
      return [styles?.[this.displayType]?.class || styles['default'].class]
    },
    filter_count() {
      return this.ComposableDataListServiceInstance.dataList.filter_count
    },
    page() {
      return this.ComposableDataListServiceInstance.query.page
    },
    limit() {
      return this.ComposableDataListServiceInstance.query.limit
    },
    pageCount() {
      return this.ComposableDataListServiceInstance.dataList.pageCount
    },
    itemLength() {
      return this.ComposableDataListServiceInstance.dataList.itemLength
    },
    maxDisplayableCount() {
      return this.page * this.limit
    },
    displayLastItemOffset() {
      if (this.limit == -1) {
        return this.filter_count
      }
      return this.maxDisplayableCount > this.filter_count
        ? this.filter_count
        : this.maxDisplayableCount
    },
  },
  methods: {
    moveToPaged(page) {
      if (
        !this.ComposableDataListServiceInstance.isLoading &&
        page >= 1 &&
        page <= this.pageCount
      ) {
        this.ComposableDataListServiceInstance.query.page = page
        // ページ移動してからスクロール位置をリセット
        const scrollTableWrapper = document.querySelector('.scroll_table_wrapper')
        if (scrollTableWrapper) {
          scrollTableWrapper.scrollTop = 0
        }
      }
    },
  },
}
</script>
