<template>
  <component
    v-if="initialized"
    :class="wrapperClass"
    :is="wrapWrapTag || 'div'"
    class="hover-show-parent position-relative"
    ref="rendererRef"
  >
    <AdminRoleOnly
      v-if="
        !disableBeforeForm &&
        (virtualModelName
          ? $core.$virtualModels[virtualModelName]?.isDefinedOnDb
          : $core.$models[modelName]?.isDefinedOnDb)
      "
      class="hover-show-target"
      style="position: absolute; top: -14px; right: 0; font-size: 11px; z-index: 99"
    >
      <div class="d-flex gap-1">
        <a
          href="#"
          v-if="$core.$models[modelName]?.isDefinedOnDb && $core.$models[modelName]?.id"
          v-single-click="
            () =>
              $core.$modals.openEditViewModal({
                modelName: 'modelDefinitions',
                id: $core.$models[modelName]?.id,
              })
          "
          >Model定義を編集</a
        >
        <a
          href="#"
          v-if="
            $core.$virtualModels[virtualModelName]?.isDefinedOnDb &&
            $core.$virtualModels[virtualModelName]?.id
          "
          v-single-click="
            () =>
              $core.$modals.openEditViewModal({
                modelName: 'virtualModelDefinitions',
                id: $core.$virtualModels[virtualModelName].id,
              })
          "
          >Virtualモデル定義を編集</a
        >
      </div>
    </AdminRoleOnly>
    <slot
      v-if="!disableBeforeForm"
      name="beforeForm"
    ></slot>
    <app-hookable-component
      v-if="!disableBeforeForm"
      :resolve-hook-name="`${hookNameBase}.beforeForm`"
    />
    <component
      v-bind="wrapperBind"
      ref="colsWrapper"
      :class="wrapTagClassComputed"
      :is="wrapperComponent"
      v-on="wrapperOn"
    >
      <!-- カスタムレンダリング用のslot -->
      <slot
        :columns="targetColumns"
        :data="data"
        :edit-callback="editCallback"
        :model-form-service="ModelFormService"
      >
        <component
          v-for="(col, colName) in targetColumns"
          :key="colName"
          :ref="`col:${colName}`"
          :is="col.isExtraComponent ? col : 'ModelFormGroup'"
          :colName="colName"
          :value="data[colName]"
          :record="data"
          :recordRoot="data"
          :ModelFormService="ModelFormService"
          :modelName="modelName"
          :readOnlyMode="readOnlyMode"
          :passedColDefinition="model ? null : col"
          :virtualModelName="virtualModelName"
          :validation="true"
          @update:value-and-error="
            ($event) => {
              editCallback(
                data,
                colName,
                $event.value,
                data[colName],
                $event.error,
                $event.modelInputVm,
              )
            }
          "
        />
      </slot>
    </component>
    <div
      v-if="disableSubmitAction !== true"
      class="col-12 text-center model-form--error-message-display-area"
    >
      <p
        v-if="isCheckValidate && errorCount"
        class="text-danger"
      >
        入力エラーを修正してください
        <span v-if="!$core.isProduction"><br />{{ validationErrors }}</span>
      </p>
      <!-- <pre
        v-if="!$core.isProduction"
        class="text-white text-left p-2 bg-dark model-form--debug-data-view"
        style="max-height: 240px; overflow: auto; position: relative"
      ><span @click.prevent="() => $core.$utils.copyToClipboard(data)" class="btn btn-outline-secondary btn-sm position-absolute" style="top: 0; right: 0; border-top-right-radius: 0; border-top-left-radius: 0; border-bottom-right-radius: 0;font-size: 10px;padding: 0.4em 0.5em;line-height: 1em;">Copy</span>{{ data }}</pre> -->
    </div>
    <app-hookable-component
      v-if="!disableBeforeForm"
      :resolve-hook-name="`${hookNameBase}.afterForm`"
    />
    <app-hookable-component
      class="model-form--before-submit-actions"
      :resolve-hook-name="`${hookNameBase}.beforeSubmitActions`"
    />
    <slot name="beforeSubmitActions"></slot>
    <app-hookable-component
      v-if="!hideFooter"
      class="model-form--after-form"
      :resolve-hook-name="`${hookNameBase}.submitPart`"
    >
      <div
        v-if="shouldEnableDeleteAction"
        class="mt-5 mb-3 model-form--delete-link"
      >
        <deleteLink
          :id="id"
          :modelName="modelName"
          :deleteFunction="remove"
          class="btn btn-danger"
          :modalId="modalId"
          @deleteCallback="({ value }) => $emit('deleteCallback', data)"
        />
      </div>
      <div
        class="col-12 text-center py-3 model-form-submit-actions d-flex justify-content-center align-items-center gap-2"
      >
        <slot name="beforeActionButton">
          <app-hookable-component
            :resolve-hook-name="`${hookNameBase}.beforeActionButton`"
          />
        </slot>
        <b-button
          v-if="shouldEnableDeleteAction && isReplicable"
          @click="toCreatePageWithReplication"
          variant="outline-primary"
          >複製する
        </b-button>
        <slot name="actionButton">
          <button
            v-if="shouldEnableSubmitAction"
            class="btn btn-success"
            v-single-click="save"
          >
            {{ actionLabel }}
          </button>
          <button
            type="button"
            v-else-if="shouldShowEditLink"
            class="btn btn-outline-success"
            v-single-click="goToEditPage"
          >
            編集する
          </button>
        </slot>
        <slot name="afterActionButton">
          <app-hookable-component
            :resolve-hook-name="`${hookNameBase}.afterActionButton`"
          />
        </slot>
      </div>
    </app-hookable-component>
    <slot name="afterForm"></slot>
    <app-hookable-component
      class="model-form--after-form"
      :resolve-hook-name="`${hookNameBase}.after`"
    />
  </component>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { $appHook } from '../../common/$appHook'
import { ColumnDefByColName, ModelDef } from '../../common/$models/ModelDef'
import { storeMethods } from '../../common/storeMethods'
import { ComposableComponentBuilderService } from '../../plugins/ComposableComponentBuilder/front/ComposableComponentBuilderService'
import { ModelFormService } from './ModelFormService'

/**
 * # ModelForm コンポーネント
 * =======================
 *
 * このコンポーネントは、動的なフォーム生成、データバインディング、バリデーション、
 * そして送信処理を行う高度に柔軟なVue.jsコンポーネントです。
 * モデル定義またはカスタムカラム設定に基づいてフォームを生成し、
 * 新規作成と編集の両方の操作に対応します。
 *
 * 主な特徴:
 * - モデルまたはカスタムカラム定義に基づく動的フォーム生成
 * - 標準モデルと仮想モデルのサポート
 * - カスタマイズ可能なフォームレイアウトとスタイリング
 * - 組み込みのバリデーションとカスタムエラーハンドリング
 * - 柔軟な送信および削除アクション処理
 * - フォームライフサイクルの様々な段階で機能を拡張するためのフック
 *
 * 使用例:
 *
 * ```vue
 * <template>
 *   <ModelForm
 *     modelName="users"
 *     :record="userRecord"
 *     @update="handleUpdate"
 *     @successCallback="handleSuccess"
 *   />
 * </template>
 *
 * <script>
 * import ModelForm from '@/components/ModelForm.vue'
 *
 * export default {
 *   components: { ModelForm },
 *   data() {
 *     return {
 *       userRecord: { id: 1, name: '山田太郎', email: 'yamada@example.com' }
 *     }
 *   },
 *   methods: {
 *     handleUpdate({ data, validationErrors }) {
 *       console.log('フォームデータが更新されました:', data)
 *       console.log('バリデーションエラー:', validationErrors)
 *     },
 *     handleSuccess(savedData) {
 *       console.log('レコードが正常に保存されました:', savedData)
 *     }
 *   }
 * }
 * <\/script>
 * ```
 */

export default {
  name: 'ModelForm',
  provide() {
    return {
      ModelFormServiceInstance: computed(() => this.ModelFormService),
    }
  },
  inject: {
    ComposableComponentBuilderServiceInstance: {
      default: null as ComposableComponentBuilderService | null,
    },
  },
  props: {
    /**
     * フォームを生成するモデルの名前
     * @example "users"
     */
    modelName: {
      required: false,
      default: null,
      type: String as PropType<string>,
    },

    /**
     * Virtualモデルを使用する場合の名前
     * Virtualモデルは、既存のモデルを拡張または変更して使用する場合に利用します
     * @example "adminUsers"
     */
    virtualModelName: {
      required: false,
      default: null as PropType<string>,
    },

    /**
     * カスタムカラム定義
     * モデル定義を上書きする場合や、モデルを使用しない場合に使用します
     * @example {
     *   name: { type: 'string', required: true },
     *   age: { type: 'number', min: 0 }
     * }
     */
    columns: {
      required: false,
      default: null,
      type: [Object, Array, null, Boolean] as PropType<
        ColumnDefByColName | string[] | null | false
      >,
    },

    /**
     * タブ・アコーディオン表示の カラムグループ
     */
    formColumnGroups: {
      required: false,
      default: null,
      type: Object as PropType<ModelDef['formColumnGroups']>,
    },

    /**
     * フォームの初期データ
     * 編集時は既存のレコードデータを含みます
     * @example { id: 1, name: '山田太郎', email: 'yamada@example.com' }
     */
    record: {
      default: () => ({}) as Record<string, any>,
    },

    /**
     * カスタムの送信処理関数
     * 指定がない場合はデフォルトの保存動作が使用されます
     * @example async (data) => {
     *   try {
     *     const result = await api.updateUser(data)
     *     console.log('更新成功:', result)
     *   } catch (error) {
     *     console.error('更新失敗:', error)
     *   }
     * }
     */
    onSubmitFunction: {
      required: false,
      type: Function as PropType<(data: any) => Promise<any>>,
    },

    /**
     * カスタムの削除処理関数
     * 編集操作時のみ適用されます
     * @example async (data) => {
     *   if (confirm('本当に削除しますか？')) {
     *     await api.deleteUser(data.id)
     *     router.push('/users')
     *   }
     * }
     */
    onDeleteFunction: {
      required: false,
      type: Function as PropType<(data: any) => Promise<any>>,
    },

    /**
     * レコードを強制的に新規として扱うかどうか
     * 通常はIDの有無で判断しますが、これをtrueにすると常に新規扱いになります
     * @example true
     */
    forceTreatAsNewRecord: {
      required: false,
      default: false,
    },
    /**
     * フォームを読み取り専用モードに設定
     * すべての入力フィールドが無効化されます
     * @example true
     */
    readOnlyMode: {
      required: false,
      default: false,
    },

    /**
     * 送信ボタンを非表示にし、フォーム送信を無効化
     * @example true
     */
    disableSubmitAction: {
      required: false,
      default: null,
    },

    /**
     * モーダルID
     * 編集モーダルで更新完了時に閉じる動作のために使用します
     * @example "userEditModal"
     */
    modalId: {
      required: false,
      type: String,
    },

    /**
     * 追加のeditCallbackFunction
     * バリデーション等の動作を含めて差し込み処理するための機構です
     * @example (context) => {
     *   const { row, key, newValue } = context
     *   if (key === 'email' && !newValue.includes('@')) {
     *     return { ...row, emailValid: false }
     *   }
     *   return row
     * }
     */
    additionalEditCallbackFunction: {
      required: false,
      type: Function,
    },

    /**
     * 送信ボタンのラベル
     * @example "保存する"
     */
    submitButtonLabel: {
      required: false,
      type: String,
    },

    /**
     * 削除機能を無効化
     * @example true
     */
    disableDelete: {
      required: false,
      default: false,
    },

    /**
     * 送信前の確認ダイアログを無効化
     * @example true
     */
    disableConfirmBeforeSubmit: {
      required: false,
      default: false,
      type: Boolean,
    },

    /**
     * 無視するカラム名のリスト
     * これらのカラムはフォームに表示されません
     * @example ['createdAt', 'updatedAt']
     */
    ignoreColNames: {
      required: false,
      default: () => [],
    },

    /**
     * フォームフィールドを包むタグ
     * @example "form"
     */
    wrapTag: {
      required: false,
      default: 'div',
    },

    /**
     * コンポーネント全体を包むタグ
     * @example "section"
     */
    wrapWrapTag: {
      required: false,
      default: 'div',
    },

    /**
     * フォームフィールドを包むタグのクラス
     * @example "custom-form-wrapper"
     */
    wrapTagClass: {
      required: false,
      default: 'row',
    },

    /**
     * フッターを非表示にする
     * @example true
     */
    hideFooter: {
      required: false,
      default: false,
    },

    /**
     * 送信ボタンを押すときの確認メッセージをオーバーライド
     * @example "本当に保存しますか？変更内容は元に戻せません。"
     */
    beforeSubmitConfirmMessage: {
      required: false,
      type: String,
    },

    /**
     * フォームのスタイルタイプ
     * @example "table"
     */
    formStyleType: {
      required: false,
      default: null,
    },

    /**
     * すべてのフォームフィールドを全幅にする
     * @example true
     */
    forceFullWidth: {
      required: false,
      default: false,
    },

    /**
     * 読み取り専用モード時に編集リンクを表示
     * @example true
     */
    shouldShowEditLink: {
      required: false,
      default: false,
    },

    /**
     * フック名のプレフィックス
     * カスタムフックを使用する際に指定します
     * @example "customHooks"
     */
    resolveHookNamePrefix: {
      required: false,
      default: '',
    },
    /**
     * Model定義 のオーバーライド が 可能な オブジェクト
     */
    modelDefOverrideObject: {
      required: false,
      default: null,
      type: Object as PropType<Partial<ModelDef>>,
    },
    /**
     * フォームの前に表示するコンポーネントを無効化
     * @example true
     */
    disableBeforeForm: {
      required: false,
      default: false,
    },
  },
  emits: ['update', 'deleteCallback', 'successCallback'],
  data() {
    const ModelFormServiceInstance = new ModelFormService(
      // @ts-ignore
      Object.assign({}, this.$props, { $modelFormVueInstance: this }),
    )
    return {
      initialized: false,
      data: {},
      validationErrors: {},
      isCheckValidate: false,
      ModelFormService: ModelFormServiceInstance as ModelFormService,
      hasDataChanged: null,
      hasSaved: false,
      // dragTar: ''
    }
  },
  computed: {
    isReplicable() {
      // modelDefinitionsの場合には非表示にさせる
      if (this.modelName === 'modelDefinitions') {
        return false
      }

      if (this.ModelFormService.getModelAttr('replicable') !== true) {
        return false
      }

      return true
    },
    virtualModel() {
      return this.ModelFormService.virtualModel
    },
    model() {
      return this.ModelFormService.model
    },
    targetColumns() {
      return this.ModelFormService.targetColumns
    },
    editCallbackFunctions() {
      return this.ModelFormService.editCallbackFunctions
    },
    id() {
      return this.record[this.model?.primaryKeyColName]
    },
    isNewRecord() {
      return this.forceTreatAsNewRecord === true || !(this.record && !!this.id)
    },
    actionLabel() {
      return (
        this.submitButtonLabel ||
        (this.isNewRecord
          ? $core.$dict.get(
              'modelForm.actionLabel.create',
              $core.$dict.get('create', '新規作成'),
            )
          : $core.$dict.get(
              'modelForm.actionLabel.edit',
              $core.$dict.get('edit', '更新'),
            ))
      )
    },
    errorCount() {
      return Object.values(this.ModelFormService.validationErrorMessages).filter(
        (v) => !!v,
      ).length
    },
    hookNameBase() {
      if (this.resolveHookNamePrefix) {
        return this.resolveHookNamePrefix
      }
      return `$CORE.admin.resolveComponent.model.${this.virtualModelName || this.modelName}.${
        this.isNewRecord ? 'create' : 'edit'
      }.modelForm`
    },
    shouldEnableSubmitAction() {
      if (this.disableSubmitAction === false) {
        return true
      }
      return (
        !this.readOnlyMode &&
        this.disableSubmitAction !== true &&
        ((!this.isNewRecord && (this.virtualModel || this.model)?.updatable !== false) ||
          (this.isNewRecord && (this.virtualModel || this.model)?.creatable !== false))
      )
    },
    shouldEnableDeleteAction() {
      if (
        this.disableDelete === true ||
        this.isNewRecord === true ||
        (this.virtualModel || this.model)?.deletable === false
      ) {
        return false
      }
      return true
    },
    hasBeforeActionButton() {
      return $core.$appHook.hasHook(`${this.hookNameBase}.beforeActionButton`)
    },
    shouldHideFooterArea() {
      // Footer を隠して良い条件
      // 1. Submit 要素がない
      return (
        this.shouldEnableSubmitAction === false &&
        this.shouldEnableDeleteAction === false &&
        this.shouldShowEditLink === false &&
        this.hasBeforeActionButton === false
      )
    },
    modelFormStyleClass() {
      return `model-form-style-${this.ModelFormService.formStyleType}`
    },
    wrapperClass() {
      return [
        'model-form',
        `model-form-modelName-${this.virtualModelName || this.modelName}`,
        this.modelFormStyleClass,
        this.forceFullWidth ? 'model-form-col-force-full-width' : '',
      ]
    },
    wrapperComponent() {
      return this.wrapTag
    },
    wrapTagClassComputed() {
      return [this.wrapTagClass, 'model-form--inner']
    },
    wrapperBind() {
      return {}
    },
    wrapperOn() {
      return {}
    },
  },
  watch: {
    record(updated, old) {
      // プライマリキーが変わってしまうのを防ぐ
      const hasUpdatedButIdHasChanged =
        this.model?.primaryKeyColName &&
        updated?.[this.model?.primaryKeyColName] &&
        this.data?.[this.model?.primaryKeyColName] &&
        updated[this.model?.primaryKeyColName] !== old[this.model?.primaryKeyColName]
      if (hasUpdatedButIdHasChanged) {
        console.warn(
          `Updated warning hasUpdatedButIdHasChanged!:`,
          this.data,
          updated,
          old,
        )
      } else {
        this.data = Object.assign({}, this.data, updated)
      }
    },
    'ModelFormService.selectionFocus': {
      handler(newValue) {
        // ページビルダーの場合、ページビルダーサービスのselectionFocusを更新する
        if (this.ComposableComponentBuilderServiceInstance) {
          this.ComposableComponentBuilderServiceInstance.settingPanelSelectionFocus =
            newValue
        }
      },
      immediate: true,
    },
  },
  async created() {
    if (!this.model && !this.onSubmitFunction && this.disableSubmitAction !== true) {
      throw new Error(
        `[ModelForm] model定義がないときには、 onSubmitFunction を設定する必要があります。`,
      )
    }
    // Bind fields and default value when this is new record
    const data = Object.assign(
      {},
      this.isNewRecord ? await this.createNewRecord() : this.record,
      this.data,
    )
    this.data = data
    // initialize validationErrors object keys
    this.validationErrors = Object.assign(
      {},
      Object.keys(this.data).reduce((c, t) => {
        c[t] = ''
        return c
      }, {}),
      this.validationErrors,
    )
    // $configVars: フォームにて、バリデーションエラー時にスクロールを実施するかどうかの設定
    this.scrollWhenValidationError = $core.$configVars.get(
      'ModelForm.scrollWhenValidationError',
      true,
    )

    // 新規作成ページでqueryのreplication_recordをチェックして、あれば、recordにmergeする
    if (this.isNewRecord && this.$route.query.replication_record) {
      const replicationRecord = JSON.parse(this.$route.query.replication_record as string)
      this.data = Object.assign({}, this.record, replicationRecord)
    }

    this.hasSaved = false
    this.$nextTick(() => {
      this.initialized = true

      setTimeout(() => {
        this.hasDataChanged = false
      }, 1000)
    })
  },
  beforeUnmount() {
    this.ModelFormService.FilterControlsServiceInstance = null
    this.ModelFormService = null
    if (this.$refs.rendererRef) {
      this.$refs.rendererRef = null
    }
  },
  methods: {
    isColumnValueUpdated(colName, newValue, oldValue) {
      if (newValue === undefined || newValue === null) {
        return true
      }
      if (newValue === oldValue) {
        return false
      }
      return true
    },
    async editCallback(data, colName, newValue, oldValue, error, modelInputVm) {
      if ((this.ignoreColNames || []).indexOf(colName) >= 0) {
        return // do nothing...
      }

      // validationメッセージを集計
      let tempData = Object.assign({}, data)
      tempData[colName] = newValue
      if (this.ModelFormService.errorMessagesCallbackFunction) {
        const validationErrors =
          await this.ModelFormService.errorMessagesCallbackFunction({
            row: tempData,
            key: colName,
            newValue,
            oldValue,
            isNewRecord: this.isNewRecord,
            callerVueInstance: this,
            modelInputVm,
          })

        this.ModelFormService.updateModelValidationErrorMessages(validationErrors)

        this.ModelFormService.udpateColumnValidationErrorMessages(colName, error)
      }

      this.validationErrors = this.ModelFormService.validationErrorMessages

      // ModelInputからの値に変更があるかどうかをチェックする
      if (this.isColumnValueUpdated(colName, newValue, oldValue) === false) {
        // Validation が Update されている可能性があるので emit 実施
        this.$emit('update', { data, validationErrors: this.validationErrors })
        return
      }
      data[colName] = newValue
      if (this.editCallbackFunctions) {
        for (let i = 0; this.editCallbackFunctions.length > i; i++) {
          try {
            data = await this.editCallbackFunctions[i]({
              row: data,
              key: colName,
              newValue,
              oldValue,
              isNewRecord: this.isNewRecord,
              callerVueInstance: this,
            })
          } catch (e) {
            // エラーが発生した場合は、エラーログを出力して、処理を続行する
            console.error(
              `[ModelForm.vue] Error on editCallbackFunctions[${i}], colName: ${colName}`,
              JSON.stringify(e),
            )
          }
        }
      }
      this.data = Object.assign({}, this.data, data)
      // this.validationErrors[colName] = error
      if (this.hasDataChanged === false) {
        this.hasDataChanged = true
      }
      this.$emit('update', { data, validationErrors: this.validationErrors })
    },
    toCreatePageWithReplication() {
      // モーダルを閉じる
      if (this.modalId) {
        $core.$modals.closeModal(this.modalId)
      }
      // 複製可能なカラムを取得
      const columns = this.model?.columns
        ? Object.keys(this.model.columns).filter((colName) => {
            const col = this.model.columns[colName]
            return col.replicable === true
          })
        : []
      // recordから複製可能なカラムのみを抽出
      const record = Object.keys(this.record).reduce((c, t) => {
        if (columns.indexOf(t) >= 0) {
          c[t] = this.record[t]
        }
        return c
      }, {})
      this.$router.push({
        path: `/m/${this.modelName}/new`,
        query: {
          replication_record: JSON.stringify(record),
        },
      })
    },
    async save(): Promise<Record<string, any>> {
      if (this.errorCount) {
        this.isCheckValidate = true
        if (this.scrollWhenValidationError) {
          this.scrollToFirstValidateErrorElement()
        }
        return
      }
      // beforeSaveDataTransformFunction があれば、実行
      if (this._hasExecBeforeSubmitFunction()) {
        const saveTargetData = await this._execBeforeSubmitFunction()
        // falsy になったら cancel
        if (!saveTargetData) {
          return
        }
        this.data = saveTargetData
      }
      if (
        this.disableConfirmBeforeSubmit !== true &&
        (await $core.$toast.confirmDialog(
          this.beforeSubmitConfirmMessage ||
            `${this.actionLabel}します。よろしいですか？`,
          { okVariant: 'success' },
        )) !== true
      ) {
        return
      }

      if (this.onSubmitFunction) {
        return this.onSubmitFunction(this.data)
      }
      return this.executeDefaultSaveBehavior()
    },
    _hasExecBeforeSubmitFunction(): boolean {
      return $appHook.hasHook(`${this.hookNameBase}.beforeSubmit`)
    },
    async _execBeforeSubmitFunction() {
      if (this._hasExecBeforeSubmitFunction()) {
        const res = await $appHook.emit(`${this.hookNameBase}.beforeSubmit`, {
          data: this.data,
          ModelFormInstance: this,
          ModelFormService: this.ModelFormService,
        })
        return res.data
      }
      return this.data
    },
    async executeDefaultSaveBehavior() {
      $core.$loading.start('更新中です...', 'overlay')
      try {
        const saveResult = await storeMethods.upsert({
          modelName: this.modelName,
          virtualModelName: this.virtualModelName,
          data: this.data,
        })
        this.hasSaved = true
        this.$emit('successCallback', saveResult.data[0])
        if (this.modalId) {
          $core.$modals.closeModal(this.modalId)
        }
        return saveResult.data[0]
      } catch (e) {
        $core.$toast.errorToast(
          `${this.actionLabel}に失敗しました。${JSON.stringify(e.message)}`,
        )
        console.error(`[/front_nuxt/components/ModelForm.vue] Error: `, JSON.stringify(e))
        throw e
      } finally {
        $core.$loading.finish()
      }
    },
    async createNewRecord() {
      return Object.assign(this.model ? await this.model.createNew() : {}, this.record)
    },
    async remove() {
      if (
        (await $core.$toast.confirmDialog('削除します。よろしいですか？', {
          okVariant: 'danger',
        })) === false
      ) {
        return
      }
      if (this.onDeleteFunction) {
        const result = await this.onDeleteFunction(this.data)
        return result
      }
    },
    goToEditPage($clickEvent) {
      const editPageRouterPath = `/m/${this.modelName}/edit/${this.id}${
        this.virtualModelName ? `?virtualModel=${this.virtualModelName}` : ''
      }`
      // Modalが開いている状態かどうか...
      if ($core.$modals.openingModalCount > 0) {
        // modal開くときには、現在 readOnly mode で開いているこのmodalを閉じる
        const currentModalId = this.$parent?.$parent?.$parent?.$attrs?.modalId
        if (currentModalId) {
          $core.$modals.closeModal(currentModalId)
        }
        this.$nextTick(() => {
          $core.$modals.openEditViewModal({
            id: this.id,
            modelName: this.modelName,
            virtualModelName: this.virtualModelName,
          })
        })
      } else {
        this.$router.push(editPageRouterPath)
      }
    },
    // エラーinputへscrollする
    scrollToFirstValidateErrorElement() {
      this.$nextTick(() => {
        const firstValidateErrorElement: HTMLElement =
          this.$el.querySelector('.input-has-error')
        if (!firstValidateErrorElement) {
          return
        }
        const formGroupParentElem = firstValidateErrorElement.closest('.form-group')
        if (!formGroupParentElem) {
          return // do nothing...
        }
        formGroupParentElem.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      })
    },
  },
}
</script>
