<template>
  <div>
    <b-form-select
      class="form-control-sm"
      v-model="value"
      :options="options"
    ></b-form-select>
  </div>
</template>

<script lang="ts">
import { registerComposableComponentSettings } from '../ComposableComponents'

registerComposableComponentSettings('SingleSelectInput', {
  name: 'SingleSelectInput',
  configColumns: {
    stateKey: {
      type: 'STRING',
      label: 'ステートキー',
    },
    options: {
      type: 'ARRAY_OF_OBJECT',
      label: '選択肢',
      defaultValue: [],
      columns: {
        value: {
          type: 'STRING',
          label: '値',
        },
        valueType: {
          type: 'STRING',
          label: '値の型',
          selections: () => {
            return [
              { label: '文字列', value: 'STRING' },
              { label: '数値', value: 'NUMBER' },
              { label: '真偽値', value: 'BOOLEAN' },
              { label: 'Javascript式', value: 'OBJECT' },
            ]
          },
          defaultValue: 'STRING',
        },
        text: {
          type: 'STRING',
          label: 'ラベル',
        },
      },
    },
  },
})

export default {
  name: 'SingleSelectInput',
  inject: ['queryStateController'],
  props: {
    options: {
      type: Array,
      required: true,
    },
    stateKey: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: false,
      default: null,
    },
  },
  computed: {
    $qs() {
      return this.queryStateController
    },
    selectOptions() {
      return this.options.map((option) => {
        if (option.valueType === 'STRING') {
          return {
            text: option.text,
            value: option.value,
          }
        }
        if (option.valueType === 'NUMBER') {
          return {
            text: option.text,
            value: Number(option.value),
          }
        }
        if (option.valueType === 'BOOLEAN') {
          return {
            text: option.text,
            value: option.value === 'true',
          }
        }
        if (option.valueType === 'OBJECT') {
          if (typeof option.value === 'string') {
            try {
              option.value = this.$core.$utils.tryParseAsObject(option.value)
            } catch (error) {
              console.error('選択肢の値のパースに失敗しました', { error, option })
            }
          }
          return {
            text: option.text,
            value: option.value,
          }
        }
        return {
          text: option.text,
          value: option.value,
        }
      })
    },
    value: {
      get() {
        return this.$qs.state[this.stateKey]
      },
      set(value: string) {
        this.$qs.state[this.stateKey] = value
      },
    },
  },
}
</script>
