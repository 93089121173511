import { ref } from 'vue'
import { singletonInstanceSummoner } from '../../../common/singletonInstanceSummoner'
import { registerFunctionLoggedInExecuteOnce } from '../../../common/utils'
import { makeItReactive } from '../../../front/$frameworkUtils/$frameworkUtils'
import { ModelCoreUserNotification } from '../models/ModelCoreUserNotifications'
import { coreUserNotificationsRouterPath } from './useUserNotificationsFeature'

/**
 * Tab grouping などで利用するデータ型
 */
type UserNotificationByCategoryWithUnreadCount = {
  title: string // 'すべて',
  icon?: string // 'list',
  notifications: ModelCoreUserNotification[]
  get hasMore(): boolean
}

type UnreadCountByCategory = { [category: string]: number }

const allCountCategoryKey = 'すべて'

const countAllUnreadByCategoryOfThisUser = async (
  fetchCategories: string[] = null,
): Promise<UnreadCountByCategory> => {
  const aggregateFields = ['category']
  const filter: any = {
    user: {
      _eq: $core.$embAuth.userUid,
    },
    readAt: {
      _null: true,
    },
  }
  if (fetchCategories && fetchCategories.length) {
    filter.category = {
      _in: fetchCategories,
    }
  }
  const count: { count: { category: number }; category: string }[] =
    await $core.$models.core_user_notifications.find({
      filter,
      groupBy: aggregateFields,
      aggregate: {
        // @ts-ignore
        count: aggregateFields,
      },
    })
  let allCount = 0
  const countByCategory = count.reduce(
    (acc, cur) => {
      acc[cur.category] = cur.count.category
      allCount += cur.count.category
      return acc
    },
    { [allCountCategoryKey]: 0 },
  )
  countByCategory[allCountCategoryKey] = allCount

  return countByCategory
}

const getNotisByCategoryInitial = (noti): UserNotificationByCategoryWithUnreadCount => {
  return {
    title: noti.category,
    icon: noti.meta_data?.icon || '',
    notifications: [],
    get hasMore() {
      // TODO: 未実装
      return true // this.notifications.length > 3
    },
  }
}

/**
 * $core.$userNotificationsService で利用する
 * User通知を司るService, ログイン時にFetch etc.
 */
export class UserNotificationsService {
  notisById: { [id: string]: ModelCoreUserNotification } = {}
  initPromise: Promise<void>
  lastUpdated = ref(0)
  unreadCountByCategory: UnreadCountByCategory = {}
  visibleNotificationCategories: string[] = []
  allCountCategoryKey = allCountCategoryKey
  enabled = false
  initPromiseResolver: (value?: void | PromiseLike<void>) => void

  constructor() {
    this.initPromise = new Promise<void>((resolve) => {
      this.initPromiseResolver = resolve
    })
  }

  /**
   * 機能を有効化する
   */
  enable() {
    this.registerLoggedInBehavior()
    this.enabled = true
  }

  /**
   * ログイン時に呼ばれる関数を登録しておく
   * これにより、ログイン時に通知をFetchする
   */
  registerLoggedInBehavior() {
    registerFunctionLoggedInExecuteOnce({
      onceLoadFunction: async (args) => {
        setTimeout(async () => {
          await this.initLoad()
          // これをやることで await $core.$userNotificationsService.initPromise が可能となる
          this._markLastUpdated()
          this.initPromiseResolver()
        }, 2000)
        return args
      },
      appHookFunctionRegisterKey: 'userNotificationsService.init',
      useAwaitOnLoad: false,
    })
  }

  async initLoad() {
    if (!$core.$embAuth.userUid) return
    this.visibleNotificationCategories = $core.$configVars.get(
      'userNotifications.visibleNotificationCategories',
      [],
    )
    await Promise.all([
      this.loadCurrentUserNotifications(),
      this.loadUnreadCountByCategory(),
    ])
    this._registerUserNotificationsAfterSaveHook()
    this._markLastUpdated()
  }

  async loadCurrentUserNotifications() {
    const query: any = {
      filter: { user: { _eq: $core.$embAuth.userUid } },
      sort: ['-createdAt'],
    }
    if (this.visibleNotificationCategories.length) {
      query.filter.category = { _in: this.visibleNotificationCategories }
    }
    const notis = await $core.$models.core_user_notifications.find(query)
    this.notisById = notis.reduce((acc, noti) => {
      acc[noti.id] = noti
      return acc
    }, {})
  }

  async loadUnreadCountByCategory() {
    this.unreadCountByCategory = await countAllUnreadByCategoryOfThisUser(
      this.visibleNotificationCategories,
    )
  }

  get allUnreadCountSum(): number {
    return this.unreadCountByCategory[allCountCategoryKey]
  }

  get notis(): ModelCoreUserNotification[] {
    return Object.values(this.notisById)
  }

  get notisByCategory(): {
    [categoryName: string]: UserNotificationByCategoryWithUnreadCount
  } {
    const notis = this.notis
    return notis.reduce(
      (acc, noti) => {
        const groupKey = noti.category
        if (
          !acc[groupKey] &&
          (this.visibleNotificationCategories.length === 0 ||
            this.visibleNotificationCategories.includes(groupKey))
        ) {
          acc[groupKey] = getNotisByCategoryInitial(noti)
        }
        acc[groupKey].notifications.push(this.notisById[noti.id])
        return acc
      },
      {
        [allCountCategoryKey]: {
          ...getNotisByCategoryInitial({ category: allCountCategoryKey }),
          notifications: notis,
        },
      },
    )
  }

  /**
   * 保存された際に、保持している通知データを更新
   */
  async _registerUserNotificationsAfterSaveHook() {
    let theTimer = null
    $core.$appHook.on(
      'core_user_notifications.afterSave',
      async (data) => {
        // 未読数refresh
        if (!theTimer) {
          theTimer = setTimeout(async () => {
            await this.loadUnreadCountByCategory()
            theTimer = null
            this._markLastUpdated()
          }, 500)
        }
        this.notisById[data.id] = Object.assign({}, this.notisById[data.id] || {}, data)
        return data
      },
      'userNotificationsService',
    )
  }

  // すべて既読にする
  async markAllAsRead() {
    // TODO: 変なので... なんとかする
    const allUnreadNotis = await $core.$models.core_user_notifications.find({
      fields: ['id'],
      filter: { user: { _eq: $core.$embAuth.userUid }, readAt: { _null: true } },
      limit: -1,
    })
    this.markAsRead(allUnreadNotis.map((noti) => noti.id))
  }

  async markAsRead(notiIds: string[]) {
    const readAt = $core.$dayjs().format('YYYY-MM-DDTHH:mm:ss')
    await $core.$storeMethods.bulkUpsert({
      modelName: 'core_user_notifications',
      data: notiIds.map((id) => {
        return {
          id,
          readAt,
        }
      }),
      quiet: true,
    })
    notiIds.map((id) => {
      this.notisById[id].readAt = readAt
    })
  }

  _markLastUpdated() {
    // @ts-ignore
    this.lastUpdated = Date.now()
    $core.$appHook.emit('userNotificationsService.lastUpdated', this)
  }

  static get instance(): UserNotificationsService {
    return singletonInstanceSummoner('userNotificationsService', UserNotificationsService)
  }

  goToLink(noti: ModelCoreUserNotification, markAsRead = true) {
    if (noti.linkUrl) {
      $core.$router.push(noti.linkUrl)
      // mark as read
      if (markAsRead && !noti.readAt) {
        setTimeout(() => {
          this.markAsRead([noti.id])
        }, 20)
      }
    }
  }

  goToListPage(notiCategory: string = this.allCountCategoryKey) {
    $core.$router.push({
      path: coreUserNotificationsRouterPath,
      query: { category: notiCategory },
    })
  }
}

export const $userNotificationsService = makeItReactive<UserNotificationsService>(
  UserNotificationsService.instance,
)
