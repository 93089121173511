<template>
  <div
    class="d-flex flex-row align-items-center filter-manager-for-custom ml-auto"
    style="font-size: 11px"
    v-if="filterObject"
  >
    <div
      @click="removeFilter"
      role="button"
      class="btn btn-sm btn-outline-danger border-0 p-1"
    >
      <Ficon
        type="trash"
        title="Filterを削除"
      />
    </div>
    <div
      @click="duplicateFilter"
      role="button"
      class="btn btn-sm btn-outline-primary border-0 p-1"
    >
      <Ficon
        type="copy"
        title="Filterを複製"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { FilterItemService } from '../../FilterItemService'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup() {
    return {
      filterObject: inject('filterObject'),
    }
  },
  methods: {
    removeFilter() {
      this.filterObject.removeFilter()
      this.$emit('onDismiss')
    },
    duplicateFilter() {
      this.filterObject.duplicateFilter()
      this.$emit('onDismiss')
    },
  },
}
</script>
