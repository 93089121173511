import { ModelDef } from '../../../common/$models/ModelDef'
import { convertDBDefinedModelToModelDefObject } from '../../../common/modelDefinitions/convertDBDefinedModelToModelDefObject'
import { DBDefinedModelDefinitionColumns } from '../../../common/modelDefinitions/DBDefinedModelDefinitionColumns'

/**
 * DBDefinedModelDefinitionColumns形式でのuserGroupsモデル定義
 */
export const userGroups__dbDefineable: DBDefinedModelDefinitionColumns = {
  id: '12835436-c4f9-4424-a823-dadf4e71d066', // UUID v4
  tableName: 'userGroups',
  tableLabel: 'ユーザーグループ',
  tableComment: '',
  defaultSort_key: 'createdAt',
  defaultSort_order: 'desc',
  primaryKeyColType: 'UUID',
  otherModelAttributes: `return {
    modelType: 'admin',
    defaultValues: () => ({
      code: $core.$utils.generateRandomString().toUpperCase()
    })
  }`,
  columns: [
    {
      key: 'id',
      label: 'ID',
      type: 'UUID',
      visible: false,
    },
    {
      key: 'code',
      label: 'グループコード',
      type: 'STRING',
      unique: true,
      editableOnCreate: true,
      editable: false,
      inputHelpText: '後から変更することはできません',
      validationConfigs: [{ validationType: 'notEmpty' }],
      otherColAttributes: `return {
        inputAttrs: { wrapperClass: 'col-4' },
        defaultValue: () => $core.$utils.generateRandomString().toUpperCase(),
      }`,
    },
    {
      key: 'name',
      label: 'グループ名',
      type: 'STRING',
      unique: true,
      validationConfigs: [{ validationType: 'notEmpty' }],
      otherColAttributes: `return {
        inputAttrs: { wrapperClass: 'col-4' }
      }`,
    },
  ],
}

/**
 * ModelDef形式でのuserGroupsモデル定義
 */
export const userGroups: ModelDef = convertDBDefinedModelToModelDefObject(
  userGroups__dbDefineable,
)
