// パース方法の定義
const parseMethodsForTryParseAsObject = [
  { method: (str: string) => new Function(str)(), label: '通常パース' },
  { method: (str: string) => new Function(`return ${str}`)(), label: 'return付きパース' },
  { method: JSON.parse, label: 'JSON.parse' },
]

/**
 * 文字列定義状態のオブジェクトをJSオブジェクト化する関数
 * JSON.parse では表現できない関数的な表現も含めてparseする
 * @param objectLikeString パース対象の文字列
 * @returns パースされたオブジェクト
 * @throws パースに失敗した場合のエラー
 */
export const tryParseAsObject = (objectLikeString: string): object => {
  if (typeof objectLikeString !== 'string') {
    return objectLikeString
  }
  // エラーメッセージの生成関数
  const generateErrorMessage = (str: string) =>
    `有効なJSONまたはオブジェクト形式である必要があります。渡された文字列: ${str}`

  // 各パース方法を試行
  for (const { method, label } of parseMethodsForTryParseAsObject) {
    try {
      const parsed = method(objectLikeString)
      if (typeof parsed === 'object' && parsed !== null) {
        return parsed
      }
    } catch (error) {
      // console.error(`${label}でのパースに失敗しました:`, {error, objectLikeString});
    }
  }

  // すべてのパース方法が失敗した場合
  throw new Error(generateErrorMessage(objectLikeString))
}
