import { VirtualModel } from '../../common/$virtualModels/VirtualModel'
import { directus_users } from './directus_users'

// import { addModelDoc } from '../../plugins/HelpDoc/coreDocsHelperFunctions'
//
// addModelDoc('directus_users', 'ユーザ管理', 'coreAppUsers')

export const coreAppUsers: VirtualModel = {
  name: 'coreAppUsers',
  baseModel: 'directus_users',
  tableLabel: 'ユーザ管理',
  tableName: 'coreAppUsers',
  defaultSort: { key: 'id', order: 'desc' },
  modelType: 'admin',
  columns: { ...directus_users.columns },
}
