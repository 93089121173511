import { ColumnDef } from '../../../../common/$models/ModelDef'
import { findNearestParentComposableComponentInstanceItemByName } from '../../../ComposableComponentBuilder/ComposableComponentTypes'
import { ComposableComponentInstanceItem } from '../../../ComposableComponents/ComposableComponentInstanceItem'
import { columnsIntoSelectableOptions } from '../../../ModelServices/modelFactoryUtils'

/**
 * ComposableDataList 配下の設定カラムから ComposableDataList のインスタンスを取得する
 * ※ 動的な選択肢を生成するために必要
 * @param callerVueInstance
 * @param targetComponentName
 */
export const getComposableDataListFromSettingColumnInstance = (
  callerVueInstance,
  targetComponentName = 'ComposableDataList',
): ComposableComponentInstanceItem | null => {
  const ComposableComponentSpecificPropEditForm =
    $core.$utils.findParentVueComponentByComponentName(
      callerVueInstance,
      'ComposableComponentSpecificPropEditForm',
    )
  return findNearestParentComposableComponentInstanceItemByName(
    ComposableComponentSpecificPropEditForm?.selectedComposableItem,
    targetComponentName,
  )
}

/**
 * ComposableDataList 配下の CC 用, 選択可能なカラムを動的に生成するためのコンポーネントの設定値用カラム定義
 */
export const dataListComposableColumnNameSelectionSettingColumnDef: ColumnDef = {
  label: 'カラム名',
  type: 'STRING',
  dynamicSelections: true,
  selections(
    record: any,
    currentValue: any,
    initialValue: any,
    recordRoot: any,
    callerVueInstance: any,
  ) {
    const parent = getComposableDataListFromSettingColumnInstance(callerVueInstance)
    if (!parent) {
      return []
    }
    const virtualModelName = parent?.configuredProps?.virtualModelName
    const modelName = parent?.configuredProps?.modelName
    const columns =
      $core.$virtualModels[virtualModelName]?.columns || $core.$models[modelName]?.columns
    if (!columns) {
      return []
    }
    return columnsIntoSelectableOptions(columns)
  },
}
