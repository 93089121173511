import { selectOptionsMaster__dbDefineable } from '../../common/SelectOptionsMaster/SelectOptionsMasterModel'
import { directus_users__dbDefineable } from '../../front/Users/directus_users'
import { junctionUsersAndGroups__dbDefineable } from '../../plugins/UserOrgGroupsManager/models/junctionUsersAndGroups'
import { junctionUsersAndOrgsAndPositions__dbDefineable } from '../../plugins/UserOrgGroupsManager/models/junctionUsersAndOrgsAndPositions'
import { organizations__dbDefineable } from '../../plugins/UserOrgGroupsManager/models/organizations'
import { positions__dbDefineable } from '../../plugins/UserOrgGroupsManager/models/positions'
import { userGroups__dbDefineable } from '../../plugins/UserOrgGroupsManager/models/userGroups'
import { DBDefinedModelDefinitionColumns } from './DBDefinedModelDefinitionColumns'

export const coreDefaultModelsDBDefineablesModelNames = [
  'directus_users',
  'organizations',
  'junctionUsersAndGroups',
  'junctionUsersAndOrgsAndPositions',
  'positions',
  'userGroups',
  'selectOptionsMaster',
] as const

/**
 * デフォルトのモデル定義を DBDefinedModelDefinitionColumns 形式で定義している objects
 *
 * ## 利用のされ方
 * - Default models を 上書きしたい場合に Front にて modelDefinitions に 保存する, その際に利用される default values となる
 */
export const coreDefaultModelsDBDefineables: {
  [key in (typeof coreDefaultModelsDBDefineablesModelNames)[number]]: DBDefinedModelDefinitionColumns
} = {
  directus_users: directus_users__dbDefineable,
  organizations: organizations__dbDefineable,
  junctionUsersAndGroups: junctionUsersAndGroups__dbDefineable,
  junctionUsersAndOrgsAndPositions: junctionUsersAndOrgsAndPositions__dbDefineable,
  positions: positions__dbDefineable,
  userGroups: userGroups__dbDefineable,
  selectOptionsMaster: selectOptionsMaster__dbDefineable,
}
