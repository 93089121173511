import { ModelDef } from '../../../common/$models/ModelDef'
import { convertDBDefinedModelToModelDefObject } from '../../../common/modelDefinitions/convertDBDefinedModelToModelDefObject'
import { DBDefinedModelDefinitionColumns } from '../../../common/modelDefinitions/DBDefinedModelDefinitionColumns'

/**
 * DBDefinedModelDefinitionColumns形式でのjunctionUsersAndGroupsモデル定義
 */
export const junctionUsersAndGroups__dbDefineable: DBDefinedModelDefinitionColumns = {
  id: 'df468ae0-d028-4f1c-a201-2c9cf06dc2bf',
  tableName: 'junctionUsersAndGroups',
  tableLabel: 'ユーザー所属グループ',
  tableComment: '',
  primaryKeyColType: 'UUID',
  defaultSort_key: 'createdAt',
  defaultSort_order: 'desc',
  otherModelAttributes: `return {
    modelType: 'admin',
    dataFilters: {}
  }`,
  columns: [
    {
      key: 'user',
      label: 'ユーザー',
      type: 'RELATIONSHIP_MANY_TO_ONE',
      relationshipManyToOne_collectionName: 'directus_users',
      relationshipManyToOne_onDeleteDbRestriction: 'CASCADE',
      relationshipManyToOne_labelFormatter:
        'return `${row.last_name || ""} ${row.first_name || ""}`',
    },
    {
      key: 'userGroup',
      label: 'グループ',
      type: 'RELATIONSHIP_MANY_TO_ONE',
      relationshipManyToOne_collectionName: 'userGroups',
      relationshipManyToOne_onDeleteDbRestriction: 'CASCADE',
      relationshipManyToOne_labelFormatter: 'return `${row.name}(${row.code})`',
    },
    {
      key: 'autoCalced',
      label: '自動計算による追加',
      type: 'BOOLEAN',
      editable: false,
      otherColAttributes: `return {
        enableIf: (row) => row.autoCalced
      }`,
    },
  ],
}

/**
 * ModelDef形式でのjunctionUsersAndGroupsモデル定義
 */
export const junctionUsersAndGroups: ModelDef = convertDBDefinedModelToModelDefObject(
  junctionUsersAndGroups__dbDefineable,
)
