import { codeInputCommonAttrs, modelFactoryAppHooks } from '../$models'
import { ColumnTypes, ModelDef } from '../$models/ModelDef'
import { frontAppHooks } from '../../front/frontAppHooks'
import { registerAppHookBehaviorWithFrontSideAppHookRecord } from './FrontSideAppHooksRegistrator'

const appHookNameSelections = {
  [frontAppHooks.afterInit]: {
    label: 'アプリケーション初期化直後 (メイン Vue components レンダリング開始の直前)',
  },
  [modelFactoryAppHooks.init.after]: {
    label: 'モデル定義Load初期直後',
  },
}

export const frontSideAppHooks: ModelDef = {
  tableName: 'frontSideAppHooks',
  tableLabel: 'フロントカスタムHooks',
  primaryKeyColType: 'UUID',
  defaultSort: { key: 'createdAt', order: 'desc' },
  modelType: 'admin',
  columns: {
    appHookName: {
      label: '$appHook 実行キー (appHookName)',
      type: ColumnTypes.String,
      validate: { notEmpty: true },
      afterComponent: {
        template: `<div class="small text-muted">
カスタムHook実行タイミングのkeyを登録します。代表的なAppHookの例:
<div v-for="({label}, key) in appHookNameSelections" :key="key">
  <div class="d-flex align-items-center">
    <code class="mr-1">{{ key }}</code>
    <div>: {{ label }}</div>
  </div>
</div>
</div>`,
        computed: {
          appHookNameSelections() {
            return appHookNameSelections
          },
        },
      },
      width: { xs: 48 },
      listItemAttrs: {
        class: { 'no-ellipsis': true, 'whitespace-wrap': true },
        style: { minWidth: '320px', maxWidth: '320px' },
      },
    },
    functionName: {
      label: '関数登録キー',
      type: ColumnTypes.String,
      validate: { notEmpty: true },
      width: { xs: 48 },
      inputHelpText:
        'Hookへ登録する際の 任意の登録名を指定します。同一の appHookName 内で重複した場合は、 Hook が上書きされます。',
      listItemAttrs: {
        class: { 'no-ellipsis': true, 'whitespace-wrap': true },
        style: { minWidth: '320px', maxWidthSS: '320px' },
      },
    },
    priority: {
      label: '実行順 優先度 (昇順)',
      type: ColumnTypes.Number,
      // width: { xs: 48 },
      inputHelpText:
        'Hookの実行順序を指定します。数値が小さい順に実行されます。設定しない場合 デフォルトで 1000 が適用されます。',
      defaultValue: 1000,
    },
    function: {
      label: 'JavaScript 実行スクリプト',
      type: ColumnTypes.Text,
      validate: { notEmpty: true },
      inputAttrs: {
        ...codeInputCommonAttrs,
      },
      afterComponent: `<div class="small">引数 <code>args</code> が利用可能です。</div>`,
    },
  },
  afterSave: async (saved: any): Promise<boolean> => {
    registerAppHookBehaviorWithFrontSideAppHookRecord(saved)
    return true
  },
  afterDelete: (deletedId: number | string): void => {
    window.location.reload()
  },
}

export type ModelFrontSideAppHooks = {
  id?: string
  appHookName: string
  functionName: string
  priority: number
  function: string
}
