<template>
  <div style="position: relative">
    <div
      @click="toggleModal"
      class="d-flex align-items-center filter-append-button"
      role="button"
    >
      <Ficon
        type="plus"
        class="mr-2"
      />
      フィルターを追加
    </div>
    <div
      class="dropdown-menu position-absolute p-2 border-0 shadow-lg"
      style="min-width: 300px; max-width: 94vw; top: 32px"
      :style="modalShow ? 'display: block;' : 'display: none;'"
    >
      <div
        @click="appendFilterRule"
        role="button"
        class="d-flex align-items-center filter-append-button"
      >
        <Ficon
          type="plus"
          class="mr-1"
        />
        <span>フィルターを追加</span>
      </div>
      <div
        v-if="
          !filterObject ||
          (!!filterObject && filterObject.hierarchyFromControlService < 3)
        "
        @click="appendFilterGroup"
        role="button"
        class="d-flex align-items-center filter-append-button"
      >
        <Ficon
          type="layer-group"
          class="mr-1"
        />
        <span>フィルターグループを追加</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { inject } from 'vue'
import { FilterControlsService } from '../../../FilterControlsService'

export default {
  setup() {
    return {
      FilterControlsServiceInstance: inject<FilterControlsService>(
        'FilterControlsServiceInstance',
      ),
      filterObject: inject('filterObject'),
    }
  },
  data() {
    return {
      modalShow: false,
    }
  },
  methods: {
    toggleModal() {
      this.modalShow = !this.modalShow
    },
    appendFilterRule() {
      if (this.filterObject) {
        this.filterObject.appendFilterRule()
      } else {
        this.FilterControlsServiceInstance.filterGroupService.appendFilterRule()
      }
      this.modalShow = false
    },
    appendFilterGroup() {
      if (this.filterObject) {
        this.filterObject.appendFilterGroup()
      } else {
        this.FilterControlsServiceInstance.filterGroupService.appendFilterGroup()
      }
      this.modalShow = false
    },
  },
}
</script>
