<template>
  <div
    class="filter-item-append"
    style="position: relative"
  >
    <b-button
      size="sm"
      variant="outline-secondary"
      class="_rounded-pill"
      @click.prevent="toggleModal"
      ref="triggerButton"
    >
      <Ficon
        type="filter"
        style="font-size: 1.3em; margin: 0 -1px"
      />
      <span
        v-if="addFilterButtonText"
        class="pl-1"
        >{{ addFilterButtonText }}</span
      >
    </b-button>
    <div
      class="column-select-panel--wrapper position-absolute p-2 border-0 shadow-lg bg-white"
      ref="dropdownMenu"
      :id="dropdownElemId"
      :style="dropdownStyle"
      v-if="toggle"
    >
      <CascadeColumnSelectPanel
        :specified-col-names="specifiedColNames"
        @column-selected="appendFilterItem"
        @expand-change="() => adjustDropdownPosition()"
      />
      <div
        @click="appendModernFilter"
        class="d-flex align-items-center mt-1 hover-opacity"
        style="height: 26px; font-size: 0.9em"
        role="button"
      >
        <Ficon
          type="sort-amount-up"
          class="mx-1"
        />
        <strong
          >高度なフィルター{{
            !FilterControlsServiceInstance?.filterGroupService ? 'を追加' : ''
          }}</strong
        >
        <span
          v-if="
            !!FilterControlsServiceInstance?.filterGroupService &&
            Object.keys(FilterControlsServiceInstance.filterGroupService.filterObjects)
              .length
          "
          class="badge border-rounded bg-secondary ml-2"
          >{{
            Object.keys(FilterControlsServiceInstance.filterGroupService.filterObjects)
              .length
          }}件</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { inject, PropType } from 'vue'
import { FilterControlsService } from '../../FilterControlsService'
import CascadeColumnSelectPanel from './CascadeColumnSelectPanel.vue'

const dropdownBaseWidth = 520
const dropdownRightMarginIfSpace = 10
/**
 * ドロップダウンの表示位置を計算する関数
 * @param dropdownEl ドロップダウン要素のDOM要素
 * @param dropdownBaseWidth ドロップダウンの基本幅
 * @param dropdownRightMarginIfSpace 余裕がある場合の右マージン
 * @returns 表示位置のスタイルオブジェクト
 *
 * - `dropdownEl` が window 右端からはみ出す場合, `left` を調整して `dropdownEl` を window 右端に表示する
 *   - 前提: `dropdownEl` は position: absolute で配置されており、button の 位置 に対して left 0, top 32px で配置されている
 */
function calculateDropdownPosition(
  dropdownEl: HTMLElement,
  dropdownBaseWidth: number,
  dropdownRightMarginIfSpace: number,
): {
  left: string
} {
  // ウィンドウの幅を取得
  const windowWidth = window.innerWidth

  // ドロップダウン要素の位置とサイズを取得
  const dropdownRect = dropdownEl.getBoundingClientRect()
  const dropdownWidth = dropdownRect.width

  // トリガー要素（親要素）の位置を取得
  const triggerEl = dropdownEl.offsetParent as HTMLElement
  const triggerRect = triggerEl.getBoundingClientRect()

  // 初期位置での右端の位置を計算（left: 0 の場合の位置）
  const baseRight = triggerRect.left + dropdownWidth

  // 初期位置でのはみ出し量を計算
  const overflowRight = baseRight - windowWidth

  // はみ出していない場合は位置調整不要
  if (overflowRight <= 0) {
    return { left: '0' }
  }

  // 画面に余裕があるかどうかを判定
  const hasSpace = windowWidth > dropdownBaseWidth * 1.2

  // はみ出している場合は、はみ出し量を左にずらし、可能であれば余白も追加
  const margin = hasSpace ? dropdownRightMarginIfSpace : 0
  const adjustedLeft = overflowRight * -1 - margin

  return {
    left: `${adjustedLeft}px`,
  }
}

export default {
  name: 'FilterItemAppend',
  components: {
    CascadeColumnSelectPanel,
  },
  props: {
    addFilterButtonText: {
      type: String,
      default: 'フィルターを追加',
    },
    specifiedColNames: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  data() {
    // リアクティブなデータを定義
    return {
      toggle: false, // ドロップダウンの表示状態
      dropdownStyle: {}, // ドロップダウンのスタイル
      FilterControlsServiceInstance: inject<FilterControlsService>(
        'FilterControlsServiceInstance',
      ),
    }
  },
  computed: {
    dropdownElemId() {
      return `${this._.uid}-dropdown-menu`
    },
  },
  watch: {
    toggle(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          // 次のTickでドロップダウンの位置を計算
          this.adjustDropdownPosition()
        } else {
          // reset
          this.dropdownStyle = {}
        }
      }
    },
  },
  methods: {
    /**
     * ドロップダウンの表示・非表示を切り替えるメソッド
     */
    toggleModal() {
      this.toggle = !this.toggle
    },
    /**
     * ドロップダウンを閉じるメソッド
     */
    closeAppendModal() {
      this.toggle = false
    },
    /**
     * フィルター項目を追加するメソッド
     * @param colNamePaths カラム名のパス配列
     */
    appendFilterItem(colNamePaths: string[]) {
      // フィルター項目を追加
      this.FilterControlsServiceInstance?.appendFilterItem(colNamePaths)
      this.toggle = false
    },
    /**
     * 高度なフィルターを追加するメソッド
     */
    appendModernFilter() {
      // 高度なフィルターを追加
      this.FilterControlsServiceInstance?.appendModernFilter()
      this.closeAppendModal()
    },
    adjustDropdownPosition() {
      setTimeout(() => {
        const triggerButton = this.$refs.triggerButton?.$el as HTMLElement
        const dropdownMenu = document.getElementById(this.dropdownElemId) as HTMLElement
        if (triggerButton && dropdownMenu) {
          const position = calculateDropdownPosition(
            // triggerButton,
            dropdownMenu,
          )
          this.dropdownStyle = {
            left: position.left,
          }
        }
      }, 100)
    },
  },
}
</script>
